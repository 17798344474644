import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "@material-ui/core/Container";
import RestaurantSummary from "../RestaurantSummary";

export type ViewRestaurantScreenProps = RouteComponentProps<{
  restaurantUUID: string;
}>;

const ViewRestaurantScreen: React.FC<ViewRestaurantScreenProps> = ({
  match,
}) => {
  return (
    <DashboardScreenTemplate screenTitle="View Restaurant">
      <Container>
        <RestaurantSummary restaurantUUID={match.params.restaurantUUID} />
      </Container>
    </DashboardScreenTemplate>
  );
};

export default ViewRestaurantScreen;
