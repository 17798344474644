import React from "react";
import { useField } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export interface FormikCheckboxFieldProps {
  label: string;
  name: string;
}

const FormikCheckboxField: React.FC<FormikCheckboxFieldProps> = ({
  name,
  label,
}) => {
  const [field] = useField<boolean>(name);
  return (
    <FormControlLabel
      control={<Checkbox color="primary" {...field} checked={field.value} />}
      label={label}
    />
  );
};

export default FormikCheckboxField;
