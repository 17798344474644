import { useRobotPickedUpOrder } from "mutations/deliveries/uber-eats-orders";
import React from "react";
import { useActionConfirmationDialog } from "components/common/ActionConfirmationDialog";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import { ButtonProps } from "@material-ui/core/Button";
import LoadableButton from "components/common/LoadableButton";

export interface OrderPickedUpActionButtonProps
  extends Omit<ButtonProps, "href" | "onClick"> {
  assignedRobotID: string;
  isLoading?: boolean;
}

const OrderPickedUpActionButton: React.FC<OrderPickedUpActionButtonProps> = ({
  children = "Order Picked Up",
  assignedRobotID,
  isLoading = false,
  ...buttonProps
}) => {
  const robotPickedUpOrderEvent = useRobotPickedUpOrder(assignedRobotID);
  const actionConfirmation = useActionConfirmationDialog();
  const { showAlert } = useAlertMessage();

  const handleClick = () => {
    actionConfirmation.open({
      confirmationText: "Make sure all the order items are in the robot",
      onConfirm: () => {
        robotPickedUpOrderEvent
          .mutateAsync()
          .then(() => {
            actionConfirmation.dismiss();
            showAlert({
              message: "Pick Up successfully acknowledged.",
              severity: "success",
            });
          })
          .catch(({ message }) => {
            showAlert({
              message: message || "Unknown error",
              severity: "error",
              autoCloseAfterMillis: 5000,
            });
          });
      },
    });
  };

  return (
    <LoadableButton
      isLoading={isLoading}
      onClick={handleClick}
      disabled={isLoading || robotPickedUpOrderEvent.isLoading}
      {...buttonProps}
    >
      {children}
    </LoadableButton>
  );
};

export default OrderPickedUpActionButton;
