import { range } from "ramda";

export const polynomialRollingHash = (
  s: string,
  p = 31,
  m: number = 1e9 + 9,
): number => {
  return range(0, s.length)
    .map((i) => s.charCodeAt(i))
    .reduce(
      ([hash, pPower], charCode) => [
        ((hash + charCode) * pPower) % m,
        (pPower * p) % m,
      ],
      [0, 1],
    )[0];
};
