import Place from "types/Location/Place";
import AutocompleteLocationCandidate from "types/Location/AutocompleteCandidate";
import GeocodedAddress from "types/Location/GeocodedAddress";

export const googleMapsLinkToPlace = (place: Place): string => {
  return googleMapsLink({ ...place.geolocation, ...place });
};

export const streetViewLinkToPlace = (place: Place): string => {
  const baseUrl = "https://www.google.com/maps/@?api=1&map_action=pano";
  const viewpointPart = `&viewpoint=${place.geolocation.lat},${place.geolocation.lng}`;
  return `${baseUrl}${viewpointPart}`;
};

export const locationCandidateFromPlace = (
  place: Place,
): AutocompleteLocationCandidate => {
  return {
    description: place.address_line_1,
    place_id: place.google_place_id || "",
    structured_formatting: {
      main_text: place.address_line_1,
      secondary_text: "",
    },
    types: [],
  };
};

export const googleMapsLink = ({
  lat,
  lng,
  google_place_id,
}: GeocodedAddress) => {
  const baseUrl = "https://www.google.com/maps/search/?api=1";
  const queryPart = `&query=${lat},${lng}`;
  const placeIdPart =
    google_place_id && google_place_id !== ""
      ? `&query_place_id=${google_place_id}`
      : "";
  return `${baseUrl}${queryPart}${placeIdPart}`;
};
