import AddressFieldData, {
  emptyAddressField,
} from "../RestaurantAddressField/Model";
import RestaurantContactsFieldArrayData, {
  emptyContactsData,
} from "../RestaurantContactsFieldArray/Model";
import RestaurantEmployeesFieldArrayData, {
  emptyEmployeesData,
} from "../RestaurantEmployeesFieldArray/Model";
import RestaurantParams from "types/deliveries/RestaurantParams";
import RestaurantExternalRefsFieldArrayData from "../RestaurantExternalRefsFieldArray/Model";

export default interface RestaurantFieldData {
  name: string;
  address: AddressFieldData;
  external_refs: RestaurantExternalRefsFieldArrayData;
  contacts: RestaurantContactsFieldArrayData;
  employees: RestaurantEmployeesFieldArrayData;
  drop_off_deadline_in_minutes: number;
}

export const initialRestaurantFieldData: RestaurantFieldData = {
  name: "",
  external_refs: [],
  address: emptyAddressField,
  contacts: emptyContactsData,
  employees: emptyEmployeesData,
  drop_off_deadline_in_minutes: 40,
};

export const restaurantFieldDataToRestaurant = (
  data: RestaurantFieldData,
): RestaurantParams => ({
  name: data.name,
  external_refs: data.external_refs,
  address_description: data.address.address_place!,
  geocoded_address: {
    ...data.address.address_place!.geolocation,
    google_place_id: data.address.address_place!.google_place_id,
  },
  contacts: data.contacts,
  employees: data.employees,
  drop_off_deadline_in_minutes: data.drop_off_deadline_in_minutes,
});
