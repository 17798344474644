import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { MapControls } from "@react-three/drei";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import Sidewalks from "components/AugmentedReality/sidewalks";
import { FileUpload } from "./FileUploder";

const MapScreen: React.FC = () => {
  const { robotID } = useParams<{ robotID: string }>();
  const cam = useRef<THREE.OrthographicCamera>();

  return (
    <div>
      <FileUpload />
      <Box width="100%" height="100%" position="absolute" zIndex={-1}>
        <Canvas
          orthographic
          camera={{ position: [0, 0, 50], zoom: 10, up: [0, 0, 1], far: 10000 }}
        >
          <orthographicCamera ref={cam} />
          <ambientLight />
          <pointLight position={[10, 10, 10]} />
          <Sidewalks robotID={robotID} />
          <MapControls camera={cam.current} />
        </Canvas>
      </Box>
    </div>
  );
};

export default MapScreen;
