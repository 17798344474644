import React from "react";
import LocationAutocompleteField from "components/Location/AutocompleteField";
import { locationCandidateFromPlace } from "lib/location/places";
import useDestinationStore from "components/teleop/useDestinationStore";
import Place from "types/Location/Place";

export interface TeleopDestinationSelectProps {
  defaultValue?: Place;
}

const TeleopDestinationSelect: React.FC<TeleopDestinationSelectProps> = ({
  defaultValue,
}) => {
  const [setDestination, selectedDestination] = useDestinationStore((s) => [
    s.setDestination,
    s.destination,
  ]);
  const destination = selectedDestination || defaultValue;

  return (
    <LocationAutocompleteField
      onSelect={setDestination}
      value={destination ? locationCandidateFromPlace(destination) : null}
      disableClearable={selectedDestination === null}
    />
  );
};

export default TeleopDestinationSelect;
