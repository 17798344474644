import React from "react";
import { Form, useFormikContext } from "formik";
import useStepper from "components/common/Stepper/useStepper";

export interface FormikWizardFormProps {
  stepsCount: number;
}

const FormikWizardForm: React.FC<FormikWizardFormProps> = ({
  children,
  stepsCount,
}) => {
  const { activeStep, advanceOneStep } = useStepper();
  const formik = useFormikContext();
  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    if (activeStep >= stepsCount - 1) {
      return formik.handleSubmit(evt);
    } else {
      evt.preventDefault();
      advanceOneStep();
    }
  };
  return <Form onSubmit={handleSubmit}>{children}</Form>;
};

export default FormikWizardForm;
