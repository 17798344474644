import { createContext, useContext, useEffect, useState } from "react";

import { GOOGLE_MAPS_API_KEY } from "config/constants";

import GoogleMapsService from "./";

const GoogleMapsServiceContext = createContext<GoogleMapsService>(
  new GoogleMapsService(GOOGLE_MAPS_API_KEY),
);

export default GoogleMapsServiceContext;

export const useGoogleMapsService = () => useContext(GoogleMapsServiceContext);

export const useGoogleMapsApi = () => {
  const [mapsApi, setMapsApi] = useState<undefined | typeof google.maps>();
  const service = useGoogleMapsService();
  useEffect(() => {
    service!.getMaps().then(setMapsApi);
  }, [service]);
  return mapsApi;
};
