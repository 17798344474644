import React from "react";
import { emptyRestaurantExternalRefFieldData } from "../RestaurantExternalRefField/Model";
import RestaurantExternalRefField from "../RestaurantExternalRefField";
import FormikFieldArray from "components/common/formik/FormikFieldArray";

export interface RestaurantExternalRefsFieldArrayProps {
  name: string;
}

const RestaurantExternalRefsFieldArray: React.FC<
  RestaurantExternalRefsFieldArrayProps
> = ({ name }) => (
  <FormikFieldArray
    label="Integration references"
    name={name}
    emptyFieldData={emptyRestaurantExternalRefFieldData}
    EntryField={RestaurantExternalRefField}
  />
);

export default RestaurantExternalRefsFieldArray;
