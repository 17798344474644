import TimeRangeFieldModel, {
  emptyTimeRangeField,
} from "components/common/TimeRangeField/Model";

export default interface MediaDownloaderFormData {
  timeRange: TimeRangeFieldModel;
  robot: string;
}

export const emptyMediaDownloaderForm = (): MediaDownloaderFormData => ({
  timeRange: emptyTimeRangeField(),
  robot: "",
});
