import React from "react";

import Grid from "@material-ui/core/Grid";

import EaterAddressPlaceField from "./PlaceField";
import EaterAddressComplementField from "./ComplementField";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import EaterAddressAutoSaveFormik from "./Formik";
import CompleteAddress, {
  completeAddressAsPlace,
} from "types/Location/CompleteAddress";

export interface EaterAddressAutoSaveFieldProps {
  name: string;
  order: UberEatsOrder;
  isEditable?: boolean;
  autoFocus?: boolean;
  required?: boolean;
}

const EaterAddressAutoSaveField: React.FC<EaterAddressAutoSaveFieldProps> = ({
  name,
  order,
  isEditable = true,
  autoFocus = false,
  required = false,
}) => {
  const currentEaterAddress: CompleteAddress = {
    address_description: order.recipient.address_description,
    geocoded_address: order.recipient.geocoded_address,
  };
  const currentEaterPlace = completeAddressAsPlace(currentEaterAddress);
  return (
    <EaterAddressAutoSaveFormik
      orderUUID={order.uuid}
      initialValues={{
        address_place: currentEaterPlace,
        address_complement: order.recipient.address_description.address_line_2,
      }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <EaterAddressPlaceField
            name={`address_place`}
            label="Address"
            autoFocus={autoFocus}
            required={required}
            isEditable={isEditable}
          />
        </Grid>
        <Grid item>
          <EaterAddressComplementField
            name={`address_complement`}
            isEditable={isEditable}
          />
        </Grid>
      </Grid>
    </EaterAddressAutoSaveFormik>
  );
};

export default EaterAddressAutoSaveField;
