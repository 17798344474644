import React from "react";
import { DateTimePicker, DateTimePickerProps } from "@material-ui/pickers";
import { useField } from "formik";

export interface DateTimeFieldProps
  extends Omit<
    DateTimePickerProps,
    "onChange" | "name" | "value" | "helperText" | "error" | "onError"
  > {
  autoFocus?: boolean;
  required?: boolean;
  name: string;
}

const DateTimeField: React.FC<DateTimeFieldProps> = ({
  autoFocus = false,
  required = false,
  name,
  ...pickerProps
}) => {
  const [field, meta, helpers] = useField<null | Date>(name);

  return (
    <DateTimePicker
      name={field.name}
      value={field.value}
      helperText={meta.error}
      error={Boolean(meta.error)}
      onChange={(date) => helpers.setValue(date, false)}
      {...pickerProps}
    />
  );
};

export default DateTimeField;
