import React from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";

export interface OrderCardFieldContainerProps extends BoxProps {
  variant: "default" | "needs-attention" | "warning";
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "4px",
    },
    needsAttention: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    warning: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  }),
);
const OrderCardFieldContainer: React.FC<OrderCardFieldContainerProps> = ({
  variant,
  ...boxProps
}) => {
  const cls = useStyles();
  const rootClass = clsx(cls.root, {
    [cls.needsAttention]: variant === "needs-attention",
    [cls.warning]: variant === "warning",
  });

  return <Box {...boxProps} padding="5px" className={rootClass} />;
};

export default OrderCardFieldContainer;
