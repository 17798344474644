import create from "zustand";
import Place from "types/Location/Place";

export type DestinationState = {
  destination: null | Place;
};

export type DestinationStoreAPI = {
  setDestination: (destination: null | Place) => void;
};

export type DestinationStore = DestinationState & DestinationStoreAPI;

const useDestinationStore = create<DestinationStore>((set) => ({
  destination: null,
  setDestination: (v) => set({ destination: v }),
}));

export default useDestinationStore;
