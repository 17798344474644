import React from "react";
import { useField } from "formik";
import { locationCandidateFromPlace } from "lib/location/places";
import LocationAutocompleteField from "components/Location/AutocompleteField";

export interface AddressPlaceFieldProps {
  name: string;
  label: string;
  autoFocus?: boolean;
  required?: boolean;
}

const AddressPlaceField: React.FC<AddressPlaceFieldProps> = ({
  name,
  label,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocationAutocompleteField
      {...props}
      label={label}
      value={field.value ? locationCandidateFromPlace(field.value) : null}
      onSelect={(place) => helpers.setValue(place)}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export default AddressPlaceField;
