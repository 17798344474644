import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";

interface ButtonProps {
  onClick: () => void;
}

interface RenderProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

export interface DialogWithTriggerButtonProps {
  button?: (buttonProps: ButtonProps) => React.ReactElement;
  dialogMaxWidth?: DialogProps["maxWidth"];
  children: (renderProps: RenderProps) => React.ReactNode;
  onClose?: () => void;
  scroll?: DialogProps["scroll"];
  PaperProps?: DialogProps["PaperProps"];
}

const DialogWithTriggerButton: React.FC<DialogWithTriggerButtonProps> = ({
  button = defaultButton,
  dialogMaxWidth = "md",
  children,
  onClose,
  scroll,
  PaperProps,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    if (onClose) onClose();
    setOpen(false);
  };
  return (
    <>
      {button({ onClick: () => setOpen(true) })}
      <Dialog
        PaperProps={PaperProps}
        scroll={scroll}
        maxWidth={dialogMaxWidth}
        open={isOpen}
        onClose={handleClose}
      >
        {children ? children({ isOpen, setOpen }) : null}
      </Dialog>
    </>
  );
};

const defaultButton = (props: ButtonProps) => <Button {...props} />;

export default DialogWithTriggerButton;
