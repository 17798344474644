import React, { useRef } from "react";
import { useFrame, extend } from "@react-three/fiber";
import { MeshLine, MeshLineMaterial } from "./meshline.js";
import * as THREE from "three";
extend({ MeshLine, MeshLineMaterial });

interface Props {
  points: THREE.Vector3[] | undefined;
  width: number;
  color: string;
}

export const Line: React.FC<Props> = ({ points, width, color }) => {
  const material = useRef<MeshLineMaterial>();
  useFrame(() => {
    if (material) {
      material.current!.uniforms!.dashOffset!.value -= 0.0005;
    }
  });

  return (
    <mesh>
      <meshLine attach="geometry" points={points} />
      return
      <meshLineMaterial
        transparent
        ref={material}
        side={THREE.DoubleSide}
        attach="material"
        depthTest={false}
        color={color}
        dashArray={0.02}
        dashRatio={0.75}
        sizeAttenuation={1}
        lineWidth={width}
      />
    </mesh>
  );
};
