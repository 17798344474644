import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";
import { useEffect, useMemo } from "react";
import { useIdentityStore } from "services/auth/identity/store";
import { DeliveryTeleopSessionStore } from "../useDeliveryTeleopSessionStore";

const useDeliveryTeleopSession = (robotID: string) => {
  const controller = useRobotConnectionController();
  const accessToken = useIdentityStore((s) => s.accessToken);

  useMemo(() => {
    DeliveryTeleopSessionStore.setState({ robotID });
  }, [robotID]);

  useEffect(() => {
    controller!.setAccessToken(robotID, accessToken!);
  }, [robotID, controller, accessToken]);

  useEffect(() => {
    controller!.startTeleop(robotID);
    return () => controller!.stopTeleop(robotID);
  }, [robotID, controller]);
};

export default useDeliveryTeleopSession;
