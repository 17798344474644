import React from "react";

import User from "types/auth/User";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export interface ViewUserViewProps {
  user: User;
  layoutDirection?: "row" | "column";
}

const ViewUserView: React.FC<ViewUserViewProps> = ({
  user,
  layoutDirection = "row",
}) => {
  return (
    <Grid container direction={layoutDirection} spacing={3}>
      <Grid item>
        <DataPreviewEntry
          label="Name"
          value={`${user.first_name} ${user.last_name}`}
        />
      </Grid>
      <Grid item>
        <DataPreviewEntry label="Email" value={user.email} />
      </Grid>
      <Grid item>
        <DataPreviewEntry label="Country Code" value={user.country_code} />
      </Grid>
      <Grid item>
        <BoolDataPreviewEntry label="Is Active" value={user.is_active} />
      </Grid>
      <Grid item>
        <BoolDataPreviewEntry
          label="Email Verified"
          value={user.is_email_verified}
        />
      </Grid>
      <Grid item>
        <DataPreviewEntry label="Roles" value={user.roles.join(", ")} />
      </Grid>
    </Grid>
  );
};

const DataPreviewEntry: React.FC<{
  label: string;
  value: null | undefined | string;
}> = ({ label, value }) => (
  <>
    <Typography variant="caption">{label}</Typography>
    <Typography variant="body1">{value || "-"}</Typography>
  </>
);

const BoolDataPreviewEntry: React.FC<{
  label: string;
  value: boolean;
}> = ({ label, value }) => (
  <DataPreviewEntry label={label} value={value ? "YES" : "NO"} />
);

export default ViewUserView;
