export default interface RestaurantExternalRefFieldData {
  origin: string;
  id: string;
}

export const emptyRestaurantExternalRefFieldData: RestaurantExternalRefFieldData =
  {
    origin: "",
    id: "",
  };
