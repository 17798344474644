import OrderParameters from "types/deliveries/OrderParameters";
import OrderInformationFieldData, {
  emptyOrderInformationField,
} from "../OrderInformationField/Model";
import DeliveryDetailsFieldData, {
  emptyDeliveryDetailsField,
} from "../DeliveryDetailsField/Model";
import OrderChargesFieldData, {
  emptyOrderChargesFieldData,
} from "../OrderChargesField/Model";
import { addressFieldDataToAddressDescription } from "components/Location/AddressField/Model";

export default interface FacedriveOrderWizardFormData {
  order_information: OrderInformationFieldData;
  delivery_information: DeliveryDetailsFieldData;
  charges: OrderChargesFieldData;
}

export const initialFacedriveOrderWizardFormData: FacedriveOrderWizardFormData =
  {
    order_information: emptyOrderInformationField,
    delivery_information: {
      ...emptyDeliveryDetailsField,
      has_direct_recipient_contact: true,
    },
    charges: emptyOrderChargesFieldData,
  };

export const facedriveOrderWizardFormDataToOrderParameters = (
  data: FacedriveOrderWizardFormData,
): OrderParameters => ({
  restaurant_uuid: data.order_information.restaurant!.uuid,
  external_id: data.order_information.external_id,
  is_mock: data.order_information.is_mock,
  sales_channel: "facedrive",
  recipient: {
    name: data.order_information.recipient_name,
    address_description: addressFieldDataToAddressDescription(
      data.delivery_information.recipient_address,
    ),
    geocoded_address: {
      ...data.delivery_information.recipient_address.address_place!.geolocation,
      google_place_id:
        data.delivery_information.recipient_address.address_place!
          .google_place_id,
    },
    phone_code: data.delivery_information.has_direct_recipient_contact
      ? ""
      : data.delivery_information.recipient_phone_number_or_code,
    phone_number: data.delivery_information.has_direct_recipient_contact
      ? data.delivery_information.recipient_phone_number_or_code
      : "",
  },
  pick_up_notes: "",
  drop_off_notes: data.delivery_information.drop_off_notes,
  charges: data.charges,
});
