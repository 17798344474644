import create from "zustand";

export type DrawerState = {
  isOpen: boolean;
};

export type DrawerAPI = {
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export const useDrawer = create<DrawerState & DrawerAPI>((set) => ({
  isOpen: false,

  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  toggle: () => set((state) => ({ ...state, isOpen: !state.isOpen })),
}));
