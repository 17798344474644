import React from "react";

import { Marker } from "@react-google-maps/api";
import Geocode from "types/Location/Geocode";

export interface SVGMarkerProps {
  position: Geocode;
  svgPath: string;
  fillColor?: string;
  fillOpacity?: number;
  strokeColor?: string;
  strokeOpacity?: number;
  strokeWeight?: number;
  scale?: number;
  visible?: boolean;
  rotationDegrees?: number;
  tooltip?: string;
  anchor?: [number, number];
}

const SVGMarker: React.FC<SVGMarkerProps> = React.memo(
  ({
    position,
    fillColor,
    svgPath,
    tooltip,
    scale = 1,
    fillOpacity = 1,
    visible = true,
    rotationDegrees = 0,
    strokeColor,
    strokeOpacity = 0,
    strokeWeight = 0,
    anchor,
  }) => {
    return (
      <Marker
        position={position}
        icon={{
          path: svgPath,
          rotation: rotationDegrees,
          strokeOpacity,
          strokeColor,
          fillColor,
          fillOpacity,
          scale,
          strokeWeight,
          anchor: anchor ? new google.maps.Point(...anchor) : undefined,
        }}
        title={tooltip}
        visible={visible}
      />
    );
  },
);

export default SVGMarker;
