// TODO add confirmation dialog when task is not on initial state - we don't
// want reassignments happening by mistake
import React from "react";

import RobotAutocompleteField from "components/robots/AutocompleteField";
import MenuItem from "@material-ui/core/MenuItem";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import {
  useSetUberEatsOrderAssignedRobot,
  useDeleteUberEatsOrderAssignedRobot,
} from "mutations/deliveries/uber-eats-orders";
import UberEatsOrder from "types/deliveries/UberEatsOrder";

export interface AssignToRobotActionProps {
  order: UberEatsOrder;
  onAssign: () => void;
}

const AssignToRobotAction = React.forwardRef<
  HTMLLIElement,
  AssignToRobotActionProps
>(({ onAssign, order }, ref) => {
  const { showAlert } = useAlertMessage();
  const setAssignedRobot = useSetUberEatsOrderAssignedRobot();
  const deleteAssignedRobot = useDeleteUberEatsOrderAssignedRobot();

  const handleAssign = (robotID: string) => {
    setAssignedRobot
      .mutateAsync({ orderUUID: order.uuid, robotID })
      .then((response) => {
        showAlert({
          message: `Order ${response.external_id} successfully assigned to ${response.assigned_robot_id}`,
          severity: "info",
          autoCloseAfterMillis: 2000,
        });
      })
      .catch(({ message }) => {
        showAlert({
          message: message || "Unknown error",
          severity: "error",
          autoCloseAfterMillis: 5000,
        });
      })
      .finally(() => onAssign());
  };

  const handleUnassign = () => {
    deleteAssignedRobot
      .mutateAsync(order.uuid)
      .then((response) => {
        showAlert({
          message: `Robot successfully unassigned from order ${response.external_id}`,
          severity: "info",
          autoCloseAfterMillis: 2000,
        });
      })
      .catch(({ message }) => {
        showAlert({
          message: message || "Unknown error",
          severity: "error",
          autoCloseAfterMillis: 5000,
        });
      })
      .finally(() => onAssign());
  };

  const handleRobotChanged = (robotID: null | string) => {
    if (
      robotID === order.assigned_robot_id ||
      (!robotID && !order.assigned_robot_id)
    ) {
      return;
    }
    if (robotID) {
      handleAssign(robotID);
    } else {
      handleUnassign();
    }
  };

  return (
    <MenuItem ref={ref}>
      <RobotAutocompleteField
        onChange={(robotID) => handleRobotChanged(robotID)}
        value={order.assigned_robot_id}
        label="Set Assigned Robot"
        helperText="Assigns order to given robot"
        disabled={setAssignedRobot.isLoading || deleteAssignedRobot.isLoading}
      />
    </MenuItem>
  );
});

export default AssignToRobotAction;
