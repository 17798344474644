import React, { useEffect, useState } from "react";

import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import MediaSearchForm from "../MediaSearchForm";
import { RouteComponentProps } from "react-router-dom";
import useAxios from "services/axios/useAxios";
import SimpleBackdrop from "components/SimpleBackdrop";
import RobotMediaPlayer from "../MediaPlayer";

export interface MediaDownloadScreenProps extends RouteComponentProps {}

const MediaDownloadScreen: React.FC<MediaDownloadScreenProps> = ({
  location,
}) => {
  const { showAlert } = useAlertMessage();
  const axios = useAxios("media-downloader");
  const [videoURL, setVideoURL] = useState<string>("");
  const qs = location.search;

  useEffect(() => {
    const params = new URLSearchParams(qs);
    axios
      .post<{ download_url: string }>("/download/", {
        robotID: params.get("robotID"),
        startTime: params.get("startTime"),
        endTime: params.get("endTime"),
      })
      .then((res) => setVideoURL(res.data.download_url))
      .catch(({ message }) =>
        showAlert({
          message: message || "Unknown error",
          severity: "error",
        }),
      );
  }, [axios, showAlert, qs]);

  return (
    <DashboardScreenTemplate screenTitle="Robot Videos">
      <MediaSearchForm />
      {videoURL === "" ? (
        <SimpleBackdrop />
      ) : (
        <RobotMediaPlayer videoURL={videoURL} />
      )}
    </DashboardScreenTemplate>
  );
};

export default MediaDownloadScreen;
