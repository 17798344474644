import { FieldArray, useField } from "formik";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import FormikFieldArrayLabel from "../FormikFieldArrayLabel";

export interface FormikFieldArrayProps<EntryFieldData> {
  name: string;
  label: string;
  EntryField: React.ComponentType<{ name: string }>;
  emptyFieldData: EntryFieldData;
}

function FormikFieldArray<FieldData>({
  label,
  name,
  EntryField,
  emptyFieldData,
}: React.PropsWithChildren<FormikFieldArrayProps<FieldData>>) {
  const [field] = useField<[]>(name);
  return (
    <FieldArray name={field.name}>
      {(formHelpers) => (
        <Box width="100%" minWidth="420px">
          <Grid container direction="column">
            <Grid item>
              <FormikFieldArrayLabel
                label={label}
                onAdd={() => formHelpers.push(emptyFieldData)}
              />
            </Grid>
            {field.value.map((_, idx) => (
              <Grid
                item
                key={idx}
                container
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs>
                  <EntryField name={`${field.name}[${idx}]`} />
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => formHelpers.remove(idx)}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </FieldArray>
  );
}

export default FormikFieldArray;
