import React from "react";
import Chip, { ChipProps } from "@material-ui/core/Chip";
import BuildIcon from "@material-ui/icons/Build";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HotelIcon from "@material-ui/icons/Hotel";

export interface RobotDeploymentKindChipProps
  extends Pick<ChipProps, "size" | "variant"> {
  deploymentKind: string;
}

const RobotDeploymentKindChip: React.VFC<RobotDeploymentKindChipProps> = ({
  deploymentKind,
  ...chipProps
}) => {
  switch (deploymentKind) {
    case "active":
      return (
        <Chip
          label="Active"
          color="primary"
          icon={<CheckCircleIcon />}
          {...chipProps}
        />
      );
    case "testing":
      return (
        <Chip
          label="Testing"
          color="primary"
          icon={<BuildIcon />}
          {...chipProps}
        />
      );
    case "retired":
      return <Chip label="Retired" icon={<HotelIcon />} {...chipProps} />;
    default:
      return <Chip label={`Unknown (${deploymentKind})`} {...chipProps} />;
  }
};

export default RobotDeploymentKindChip;
