import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import React from "react";
import MediaSearchForm from "../MediaSearchForm";

export interface MediaSearchScreenProps {}

const MediaSearchScreen: React.FC<MediaSearchScreenProps> = () => (
  <DashboardScreenTemplate screenTitle="Robot Videos">
    <MediaSearchForm />
  </DashboardScreenTemplate>
);

export default MediaSearchScreen;
