import { useMemo } from "react";
import { AxiosResponse } from "axios";

import useAxios from "services/axios/useAxios";
import User from "types/auth/User";

export default interface UsersService {
  getUser: (userUUID: string) => Promise<AxiosResponse<User>>;
  listUsers: () => Promise<AxiosResponse<User[]>>;
  activateUser: (userUUID: string) => Promise<AxiosResponse<User>>;
  deactivateUser: (userUUID: string) => Promise<AxiosResponse<User>>;
  resendVerificationLink: (userUUID: string) => Promise<AxiosResponse<User>>;
  setUserRoles: (
    userUUID: string,
    roles: string[],
  ) => Promise<AxiosResponse<User>>;
  createUser: (userParams: CreateUserInput) => Promise<AxiosResponse<User>>;
}

export interface CreateUserInput {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  country_code: string;
}

export const useUsersService = (): UsersService => {
  const axios = useAxios("auth");

  return useMemo(
    () => ({
      getUser: (userUUID: string) => axios.get(`/api/users/${userUUID}`),
      listUsers: () => axios.get("/api/users"),
      activateUser: (userUUID: string) =>
        axios.post(`/api/users/${userUUID}/activate`, {}),
      deactivateUser: (userUUID: string) =>
        axios.post(`/api/users/${userUUID}/deactivate`, {}),
      resendVerificationLink: (userUUID: string) =>
        axios.post(`/api/users/${userUUID}/send-verification-link`, {}),
      setUserRoles: (userUUID: string, roles: string[]) =>
        axios.put(`/api/users/${userUUID}/roles`, { roles }),
      createUser: (userParams: CreateUserInput) =>
        axios.post("/api/users", userParams),
    }),
    [axios],
  );
};
