import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import useBrowserNotification from "components/useBrowserNotification";
import { useEffect } from "react";
import useOrderForTeleopSession from "../useOrderForTeleopSession";

const useNewOrderNotifier = () => {
  const { data: activeOrderUUID } = useOrderForTeleopSession({
    select: (s) => s?.uuid,
  });
  const { showAlert } = useAlertMessage();
  const showNotification = useBrowserNotification();

  useEffect(() => {
    if (activeOrderUUID) {
      showAlert({
        message:
          "New Order Found! The delivery information will be displayed now",
        autoCloseAfterMillis: 3000,
        severity: "info",
      });
      showNotification({
        title: "New delivery available!",
        body: "A new order was assigned to you. Go back to the driving app to start!",
      });
    }
  }, [activeOrderUUID, showAlert, showNotification]);
};

export default useNewOrderNotifier;
