import React from "react";
import { useThrottledRobotPosition } from "queries/teleop/RobotTelemetry/Context";
import MapFixator from "components/Map/Fixator";
import { useRobot } from "fetchers/robots/robots";

export interface TeleopMapRobotFixatorProps {
  robotID: string;
}

// Controls wether map center should be fixed on the robot position
const TeleopMapRobotFixator: React.FC<TeleopMapRobotFixatorProps> = ({
  robotID,
}) => {
  const livePosition = useThrottledRobotPosition(robotID);
  const lastKnownPosition = useRobot(robotID, {
    enabled: !livePosition,
    refetchInterval: 3000,
    select: (r) => r.last_known_position,
  });
  const position = livePosition || lastKnownPosition.data;

  if (position) {
    return <MapFixator position={position} />;
  } else {
    return <></>;
  }
};

export default TeleopMapRobotFixator;
