import React, { useCallback, useEffect, useState } from "react";
import useVideoReprojection from "../useVideoReprojection";
import { POV } from "lib/reprojection";
import useGamepadStore from "components/gamepad/useGamepadStore";
import useGamepadBind from "components/gamepad/useGamepadBind";

export interface VideoReprojectionGamepadProps {}

const VideoReprojectionGamepad: React.FC<
  VideoReprojectionGamepadProps
> = () => {
  const [currentPOV, setCurrentPOV] = useState<POV & { rearView: boolean }>({
    projection: "equirectangular",
    yaw: 0.0,
    height: 1,
    rearView: false,
  });
  const { setPOV } = useVideoReprojection();

  useEffect(() => {
    setPOV(currentPOV);
  }, [setPOV, currentPOV]);

  useReprojectionSelectionControls(setCurrentPOV);
  useReprojectionDirectionControl(setCurrentPOV);
  return <></>;
};

const useReprojectionSelectionControls = (
  updatePOV: React.Dispatch<React.SetStateAction<POV & { rearView: boolean }>>,
) => {
  const onGamepadUp = useCallback(() => {
    updatePOV((pov) => ({
      ...pov,
      yaw: 0.0,
      projection: "equirectangular",
      rearView: false,
    }));
  }, [updatePOV]);

  const onGamepadDown = useCallback(() => {
    updatePOV((pov) => {
      if (pov.rearView) {
        return { ...pov, yaw: 0.0, rearView: false };
      } else {
        return { ...pov, yaw: 1.0, rearView: true };
      }
    });
  }, [updatePOV]);

  const onGamepadRight = useCallback(() => {
    updatePOV((pov) => ({ ...pov, projection: "stereographic" }));
  }, [updatePOV]);

  useGamepadBind("d_up", onGamepadUp);
  useGamepadBind("d_down", onGamepadDown);
  useGamepadBind("d_right", onGamepadRight);
};

const useReprojectionDirectionControl = (
  updatePOV: React.Dispatch<React.SetStateAction<POV & { rearView: boolean }>>,
) => {
  const [x, y] = useGamepadStore((s) =>
    s.gamepadState
      ? [s.gamepadState?.axes.l_x, s.gamepadState?.axes.l_y]
      : [0, 0],
  );
  let angle = -Math.atan2(x, y) / Math.PI;

  if (x * x + y * y < 0.5) {
    angle = 0.0;
  }

  useEffect(() => {
    if (isNaN(angle)) {
      return;
    }
    updatePOV((pov) => ({ ...pov, yaw: pov.rearView ? angle - 1 : angle }));
  }, [angle, updatePOV]);
};

export default VideoReprojectionGamepad;
