import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import MediaDownloaderFormData, { emptyMediaDownloaderForm } from "./Model";
import Schema from "./Schema";

export interface MediaDownloaderFormikProps {
  onSubmit: (
    formData: MediaDownloaderFormData,
    formHelpers: FormikHelpers<MediaDownloaderFormData>,
  ) => void;
  initialValues?: MediaDownloaderFormData;
}

const MediaDownloaderFormik: React.FC<MediaDownloaderFormikProps> = ({
  children,
  onSubmit = () => {},
  initialValues,
}) => (
  <Formik
    validationSchema={Schema}
    initialValues={initialValues || emptyMediaDownloaderForm()}
    onSubmit={onSubmit}
  >
    <Form>{children}</Form>
  </Formik>
);

export default MediaDownloaderFormik;
