import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import React from "react";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { useRobots } from "fetchers/robots/robots";
import Box from "@material-ui/core/Box";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import RobotCameraFeed from "components/teleop/RobotCameraFeed";
import useTeleopConnections from "./useTeleopConnections";

export interface ActiveRobotsCamerasScreenProps {}

const ActiveRobotsCamerasScreen: React.FC<
  ActiveRobotsCamerasScreenProps
> = () => {
  const robots = useRobots({
    select: (robots) =>
      robots.filter((robot) => robot.deployment_kind === "active"),
  });
  useTeleopConnections();
  return (
    <DashboardScreenTemplate screenTitle="All Cameras">
      {robots.data ? (
        <GridList cols={4} cellHeight={300} spacing={1}>
          {robots.data.map((robot) => (
            <GridListTile key={robot.id}>
              <Box width="100%" height="100%" zIndex={0}>
                <RobotCameraFeed robotID={robot.id} />
              </Box>
              <GridListTileBar title={robot.id} />
            </GridListTile>
          ))}
        </GridList>
      ) : null}
    </DashboardScreenTemplate>
  );
};

export default ActiveRobotsCamerasScreen;
