import React from "react";

import useBindLidCommand from "../useBindLidCommand";
import useBindDriveCommands from "../useBindDriveCommands";
import useBindSirenCommand from "../useBindSirenCommand";
import useBindLidarsCommand from "../useBindLidarsCommand";

export interface TeleopGamepadProps {
  robotID: string;
}

const TeleopGamepad: React.FC<TeleopGamepadProps> = ({ robotID }) => {
  useBindLidCommand(robotID);
  useBindDriveCommands(robotID);
  useBindSirenCommand(robotID);
  useBindLidarsCommand(robotID);

  return <></>;
};

export default TeleopGamepad;
