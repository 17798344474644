import React from "react";
import MapBox from "components/Map/MapBox";
import DialogWithTriggerButton from "components/common/DialogWithTriggerButton";
import DialogTriggerButton from "./TriggerButton";
import RobotsMapTrails from "components/robots/MapTrails";

export type RobotsMapProps = {};

const RobotsMapDialog: React.FC<RobotsMapProps> = () => {
  return (
    <DialogWithTriggerButton
      dialogMaxWidth="xl"
      button={(props) => <DialogTriggerButton {...props} />}
    >
      {() => (
        <MapBox width="70vw" height="70vh">
          <RobotsMapTrails />
        </MapBox>
      )}
    </DialogWithTriggerButton>
  );
};

export default RobotsMapDialog;
