export const firebaseAppConfig = {
  apiKey: "AIzaSyDKC4W1tqwGYF9uZRmpPH-DC96FvPOJRDw",
  authDomain: "tinyai.firebaseapp.com",
  databaseURL: "https://tinyai.firebaseio.com",
  projectId: "tinyai",
  storageBucket: "tinyai.appspot.com",
  messagingSenderId: "59257658742",
  appId: "1:59257658742:web:91bafe9e75c3858b1e6e57",
  measurementId: "G-NCNK4VQ14R",
};

export const fcmKeyPair =
  "BCydjfGnFhCimYDgLxj5PnHvlmCGlu10KSfXUdptQegpm2zHdoBgOAAV2luhnQGmN-e9i8RTAj7y93zfAJRLY4E";
