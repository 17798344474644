// Link combiners react-router links with material-ui links
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import MaterialLink, {
  LinkProps as MaterialLinkProps,
} from "@material-ui/core/Link";

export type LinkProps = RouterLinkProps & Omit<MaterialLinkProps, "component">;

const Link: React.FC<LinkProps> = (props) => (
  <MaterialLink
    component={RouterLink}
    color="textPrimary"
    underline="always"
    {...props}
  />
);

export default Link;
