import React from "react";
import Grid from "@material-ui/core/Grid";
import GenericParkingSpotFormik, {
  GenericParkingSpotFormikProps,
} from "./Formik";
import FormikTextField from "components/common/formik/FormikTextField";
import AddressField from "components/Location/AddressField";
import FormikSubmitButton from "components/common/formik/FormikSubmitButton";

export type GenericParkingSpotFormProps = GenericParkingSpotFormikProps;

const GenericParkingSpotForm: React.FC<GenericParkingSpotFormProps> = (
  formikProps,
) => {
  return (
    <GenericParkingSpotFormik {...formikProps}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <AddressField name="address" autoFocus required />
        </Grid>
        <Grid item>
          <FormikTextField
            autoFocus
            fullWidth
            required
            name="description"
            label="Address Description"
          />
        </Grid>
        <Grid item>
          <FormikSubmitButton />
        </Grid>
      </Grid>
    </GenericParkingSpotFormik>
  );
};

export default GenericParkingSpotForm;
