import React from "react";
import Stepper, { StepperProps } from "@material-ui/core/Stepper";
import useStepper from "./useStepper";

export type StepperViewProps = Omit<StepperProps, "activeStep">;

const StepperView: React.FC<StepperViewProps> = (props) => {
  const { activeStep } = useStepper();
  return <Stepper {...props} activeStep={activeStep} />;
};

export default StepperView;
