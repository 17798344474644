import React from "react";
import Grid from "@material-ui/core/Grid";
import DataPreviewEntry from "components/common/DataPreviewEntry";
import GoogleMapsLink from "components/Location/GoogleMapsLink";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import RecipientPhoneNumberAutoSaveField from "../UberEatsOrdersDataGrid/PhoneNumberAutoSaveField";
import EaterNameAutoSaveField from "./EaterNameAutoSaveField";
import EaterAddressAutoSaveField from "./EaterAddressAutoSaveField";
import DropOffNotesAutoSaveField from "./DropOffNotesAutoSaveField";

export interface OrderCardEaterInfoProps {
  order: UberEatsOrder;
  isEditable: boolean;
}

const OrderCardEaterInfo: React.FC<OrderCardEaterInfoProps> = ({
  order,
  isEditable,
}) => (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <EaterNameAutoSaveField
        isEditable={isEditable}
        orderUUID={order.uuid}
        currentName={order.recipient.name}
      />
    </Grid>
    <Grid item xs={6}>
      <RecipientPhoneNumberAutoSaveField
        isEditable={isEditable}
        orderUUID={order.uuid}
        currentPhoneNumber={order.recipient.phone_number}
      />
    </Grid>
    <Grid item xs={6}>
      {!isEditable && (
        <DataPreviewEntry
          label="Address"
          value={
            <GoogleMapsLink
              addressDescription={order.recipient.address_description}
              geocodedAddress={order.recipient.geocoded_address}
              noWrap
            />
          }
        />
      )}
      {isEditable && (
        <EaterAddressAutoSaveField name="eater_address" order={order} />
      )}
    </Grid>
    <Grid item xs={6}>
      <DataPreviewEntry
        label="Anonymized Phone Code"
        value={order.recipient.phone_code}
      />
    </Grid>
    <Grid item xs={6}>
      <DropOffNotesAutoSaveField
        isEditable={isEditable}
        orderUUID={order.uuid}
        currentDropOffNotes={order.drop_off_notes}
      />
    </Grid>
  </Grid>
);

export default OrderCardEaterInfo;
