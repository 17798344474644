import React from "react";

import Box from "@material-ui/core/Box";
import { RouteComponentProps } from "react-router-dom";
import querystring from "querystring";

import TeleopContainer from "components/teleop/TeleopContainer";
import RobotCameraFeed from "components/teleop/RobotCameraFeed";
import TeleopRobotController from "components/teleop/RobotController";
import TrajectoryIndicatorWidget from "../TrajectoryIndicatorWidget";
import GamepadWidget from "../GamepadWidget";
import SirenStatusWidget from "../SirenStatusWidget";
import GenericTeleopMapWidget from "../GenericTeleopMapWidget";
import SteerCompensationFactorWidget from "../SteerCompensationFactorWidget";

export type GenericDriveScreenProps = RouteComponentProps<{ robotID: string }>;

const GenericDriveScreen: React.FC<GenericDriveScreenProps> = ({
  match,
  location,
}) => {
  const robotID = match.params.robotID;
  return (
    <TeleopContainer robotID={robotID}>
      <TeleopRobotController robotID={robotID} />
      <Box width="100%" height="100%" position="absolute" zIndex={-1}>
        <RobotCameraFeed robotID={robotID} />
      </Box>
      <GamepadWidget />
      <SirenStatusWidget robotID={robotID} />
      <TrajectoryIndicatorWidget />
      <GenericTeleopMapWidget robotID={robotID} />
      {isTrimmingEnabled(location.search) ? (
        <SteerCompensationFactorWidget />
      ) : null}
    </TeleopContainer>
  );
};

const isTrimmingEnabled = (qs: string): boolean => {
  const flag = querystring.parse(qs.substr(1))["trimming_enabled"];
  return Boolean(flag);
};

export default GenericDriveScreen;
