import { createContext, useContext } from "react";

import GoogleAutocompleteService from "./";

const GoogleAutocompleteServiceContext = createContext<
  GoogleAutocompleteService | undefined
>(undefined);

export default GoogleAutocompleteServiceContext;

export const useGoogleAutocompleteService = () =>
  useContext(GoogleAutocompleteServiceContext);
