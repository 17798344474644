import React from "react";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import Typography from "@material-ui/core/Typography";

import { DeliveryStage } from "types/deliveries/UberEatsOrder";

export interface UberEatsOrderTimelineEventEntryProps {
  stage: DeliveryStage;
  isCurrentStage?: boolean;
}

const UberEatsOrderTimelineEventEntry: React.FC<
  UberEatsOrderTimelineEventEntryProps
> = ({ stage, isCurrentStage }) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={isCurrentStage ? "primary" : "grey"} />
        {isCurrentStage ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {stageKindToEventLabel(stage.kind)}
        <Typography variant="caption" color="textSecondary" paragraph>
          {new Date(stage.started_at).toLocaleTimeString()}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

const stageKindToEventLabel = (kind: string): string => {
  switch (kind) {
    case "order-received":
      return "Order Received";
    case "order-queued":
      return "Order Queued";
    case "robot-en-route-to-pick-up":
      return "Robot Left for Pick Up";
    case "robot-waiting-for-pick-up":
      return "Arrived at Pick Up";
    case "order-picked-up":
      return "Picked Up";
    case "robot-en-route-to-drop-off":
      return "Robot Left for Drop Off";
    case "robot-waiting-for-drop-off":
      return "Arrived at Drop Off";
    case "order-completed":
      return "Order Completed";
    case "order-canceled":
      return "Order Canceled";
    default:
      return "Unknown Event";
  }
};

export default UberEatsOrderTimelineEventEntry;
