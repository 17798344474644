import React from "react";

import { useField, FieldArray } from "formik";
import ChipInput, { Props as ChipInputProps } from "material-ui-chip-input";

import RolesFieldData from "./Model";

export interface UserRolesFieldArrayProps
  extends Omit<
    ChipInputProps,
    "value" | "onAdd" | "onDelete" | "defaultValue" | "onChange"
  > {
  name: string;
}

const UserRolesFieldArray: React.FC<UserRolesFieldArrayProps> = ({
  name,
  ...props
}) => {
  const [field] = useField<RolesFieldData>(name);
  return (
    <FieldArray name={field.name}>
      {(formHelpers) => (
        <ChipInput
          {...props}
          newChipKeys={["Enter", " ", ","]}
          value={field.value}
          onAdd={(val) => formHelpers.push(val)}
          onDelete={(_, index) => formHelpers.remove(index)}
        />
      )}
    </FieldArray>
  );
};

export default UserRolesFieldArray;
