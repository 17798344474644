import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import { useRobot } from "fetchers/robots/robots";
import { useSetDeploymentKind } from "mutations/robots";
import React, { useCallback } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Robot from "types/robots/Robot";

export interface DeploymentKindAutosaveFieldProps {
  robotID: string;
  onSuccess?: (robot: Robot) => void;
}

const DeploymentKindAutosaveField: React.FC<
  DeploymentKindAutosaveFieldProps
> = ({ robotID, onSuccess = () => {} }) => {
  const robot = useRobot(robotID);

  const setDeploymentKind = useSetDeploymentKind();

  const { showAlert } = useAlertMessage();

  const handleChange = useCallback(
    (deploymentKind: string) => {
      setDeploymentKind
        .mutateAsync({ robotID, deploymentKind })
        .then((robot) => {
          onSuccess(robot);
          showAlert({
            message: `${robot.id} set as ${deploymentKind}`,
            severity: "success",
          });
        })
        .catch(({ message }) => {
          showAlert({
            message: message || "Unknown error",
            severity: "error",
            autoCloseAfterMillis: 5000,
          });
        });
    },
    [setDeploymentKind, robotID, showAlert, onSuccess],
  );

  return (
    <FormControl
      disabled={!robot.data || setDeploymentKind.isLoading}
      fullWidth
    >
      <InputLabel>Deployment Kind</InputLabel>
      <Select
        value={robot.data?.deployment_kind || ""}
        onChange={(evt) => handleChange(evt.target.value as string)}
      >
        <MenuItem value="active">Active</MenuItem>
        <MenuItem value="testing">Testing</MenuItem>
        <MenuItem value="retired">Retired</MenuItem>
      </Select>
    </FormControl>
  );
};

export default DeploymentKindAutosaveField;
