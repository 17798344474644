import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Box from "@material-ui/core/Box";

import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import PartnersAPISwitch from "../PartnersAPISwitch";
import { useStyles } from "./styles";

export interface OrdersIntegrationTogglesScreenProps {}

const OrdersIntegrationTogglesScreen: React.FC<
  OrdersIntegrationTogglesScreenProps
> = () => {
  const cls = useStyles();
  return (
    <DashboardScreenTemplate screenTitle="Integrations">
      <Container>
        <Typography variant="h3">Accepting orders from</Typography>
        <Box width="100%" justifyContent="center" display="flex" paddingTop={3}>
          <List className={cls.listRoot}>
            <ListItem>
              <ListItemText primary="Partners API" />
              <ListItemSecondaryAction>
                <PartnersAPISwitch />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Box>
      </Container>
    </DashboardScreenTemplate>
  );
};

export default OrdersIntegrationTogglesScreen;
