import ReprojectedVideo from "components/augmented-reality/ReprojectedVideo";
import VideoReprojectionGamepad from "components/augmented-reality/VideoReprojectionGamepad";
import React from "react";
import RobotCameraFeedReprojection from "../RobotCameraFeedReprojection";

export interface RobotCameraFeedReprojectedProps {
  robotID: string;
}

const RobotCameraFeedReprojected: React.FC<RobotCameraFeedReprojectedProps> = ({
  robotID,
}) => (
  <RobotCameraFeedReprojection robotID={robotID}>
    <ReprojectedVideo />
    <VideoReprojectionGamepad />
  </RobotCameraFeedReprojection>
);

export default RobotCameraFeedReprojected;
