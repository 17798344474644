import { useEffect, useState } from "react";

export type BrowserNotificationPermissionProviderProps = {};

const useNotificationPermission = () => {
  const [permission, setPermission] =
    useState<NotificationPermission>("default");

  useEffect(() => {
    if (!("Notification" in window)) {
      console.error("Browser does not support native notifications");
      setPermission("denied");
    } else if (Notification.permission !== "default") {
      setPermission(Notification.permission);
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          setPermission("granted");
        }
      });
    }
  }, []);

  return permission;
};

export default useNotificationPermission;
