import React from "react";

import Grid from "@material-ui/core/Grid";
import FormikTextField from "components/common/formik/FormikTextField";

export interface ContactsEntryFieldProps {
  name: string;
}

const ContactsEntryField: React.FC<ContactsEntryFieldProps> = ({ name }) => (
  <Grid container direction="row" spacing={2}>
    <Grid item xs>
      <FormikTextField
        required
        name={`${name}.name`}
        label="Contact Name"
        fullWidth
      />
    </Grid>
    <Grid item xs>
      <FormikTextField
        required
        name={`${name}.phone_number`}
        label="Contact Phone Number"
        fullWidth
      />
    </Grid>
  </Grid>
);

export default ContactsEntryField;
