import React from "react";

import Grid from "@material-ui/core/Grid";

import FormikSubmitButton from "components/common/formik/FormikSubmitButton";

import RestaurantCreationFormik from "./Formik";
import RestaurantField from "../RestaurantField";

export interface RestaurantCreationFormProps {
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
}

const RestaurantCreationForm: React.FC<RestaurantCreationFormProps> = ({
  onSubmit = () => {},
  onSubmissionError = () => {},
}) => {
  return (
    <RestaurantCreationFormik
      onSubmit={() => {
        onSubmit();
      }}
      onSubmissionError={onSubmissionError}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <RestaurantField autoFocus required name="restaurant_info" />
        </Grid>
        <Grid item>
          <FormikSubmitButton />
        </Grid>
      </Grid>
    </RestaurantCreationFormik>
  );
};

export default RestaurantCreationForm;
