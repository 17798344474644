import React from "react";
import { useHistory } from "react-router-dom";
import MediaDownloaderForm, {
  MediaDownloaderFormProps,
} from "../MediaDownloaderForm";
import MediaDownloaderFormData from "../MediaDownloaderForm/Model";
import moment from "moment";
import momenttz from "moment-timezone";

export interface MediaSearchFormProps
  extends Pick<MediaDownloaderFormProps, "initialValues"> {}

const MediaSearchForm: React.FC<MediaSearchFormProps> = ({
  ...formikProps
}) => {
  const history = useHistory();
  return (
    <MediaDownloaderForm
      {...formikProps}
      onSubmit={(formData) => {
        history.push({
          pathname: "/robots/videos/download",
          search: searchQueryParams(formData),
        });
      }}
    />
  );
};

const searchQueryParams = (formData: MediaDownloaderFormData): string => {
  const startDate = isoStringWithTZ(
    formData.timeRange.startDate,
    formData.timeRange.timezone!.utc[0],
  );
  const endDate = isoStringWithTZ(
    formData.timeRange.endDate,
    formData.timeRange.timezone!.utc[0],
  );
  const robotID = formData.robot;

  return `?startTime=${startDate}&endTime=${endDate}&robotID=${robotID}`;
};

const isoStringWithTZ = (d: Date, timezoneName: string): string => {
  const offset = momenttz().tz(timezoneName).utcOffset();
  return moment(d).utcOffset(offset, true).toISOString();
};

export default MediaSearchForm;
