import React, { useState } from "react";
import ParkingSpotWizardOnboarding from "./Onboarding";
import ParkingSpotKind from "./ParkingSpotKind";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import RestaurantParkingSpotForm from "../RestaurantParkingSpotForm";
import GenericParkingSpotForm from "../GenericParkingSpotForm";
import SetParkToOffice from "./SetParkToOffice";

export interface ParkingSpotWizardProps {
  robotID: string;
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
}

const ParkingSpotWizard: React.FC<ParkingSpotWizardProps> = ({
  robotID,
  ...submissionProps
}) => {
  const [selectedKind, setParkingSpotKind] = useState<null | ParkingSpotKind>(
    null,
  );

  if (!selectedKind) {
    return <ParkingSpotWizardOnboarding onSelectKind={setParkingSpotKind} />;
  } else {
    return (
      <>
        <Tabs
          variant="fullWidth"
          value={selectedKind}
          onChange={(_, val) => setParkingSpotKind(val)}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Outside a restaurant" value="restaurant" />
          <Tab label="At the office" value="office" />
          <Tab label="On another address" value="generic" />
        </Tabs>
        <Box marginTop={2}>
          {selectedKind === "restaurant" && (
            <RestaurantParkingSpotForm robotID={robotID} {...submissionProps} />
          )}
          {selectedKind === "office" && (
            <SetParkToOffice robotID={robotID} {...submissionProps} />
          )}
          {selectedKind === "generic" && (
            <GenericParkingSpotForm robotID={robotID} {...submissionProps} />
          )}
        </Box>
      </>
    );
  }
};

export default ParkingSpotWizard;
