import React from "react";
import { Formik, Form } from "formik";
import CreateUserFormData, { initialCreateUserFormData } from "./Model";
import CreateUserFormSchema from "./Schema";
import { AxiosError } from "axios";
import { useCreateUser } from "mutations/users";

export interface CreateUserFormikProps {
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
}

const CreateUserFormik: React.FC<CreateUserFormikProps> = ({
  onSubmit = () => {},
  onSubmissionError = () => {},
  children,
}) => {
  const createUser = useCreateUser();
  return (
    <Formik
      validationSchema={CreateUserFormSchema}
      initialValues={initialCreateUserFormData}
      onSubmit={(v: CreateUserFormData, { setSubmitting }) => {
        console.log("...");
        createUser
          .mutateAsync(v)
          .then(() => {
            setSubmitting(false);
            onSubmit();
          })
          .catch((err: AxiosError<{ message: string }>) => {
            setSubmitting(false);
            onSubmissionError(
              err.response?.data || { message: "Unknown Error" },
            );
          });
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default CreateUserFormik;
