import React, { useCallback } from "react";
import { useGoogleMap } from "@react-google-maps/api";
import { useGoogleMapsService } from "services/location/GoogleMapsService/Context";
import useGamepadBind from "components/gamepad/useGamepadBind";

export interface TeleopMapGamepadProps {
  toggleFullScreen: () => void;
}

const TeleopMapGamepad: React.FC<TeleopMapGamepadProps> = ({
  toggleFullScreen,
}) => {
  const mapSvc = useGoogleMapsService();
  const map = useGoogleMap();
  const onToggle = useCallback(() => {
    toggleFullScreen();
    mapSvc.getMaps().then((api) => {
      if (map) {
        api?.event.trigger(map, "resize");
      }
    });
  }, [toggleFullScreen, mapSvc, map]);

  const onZoom = useCallback(
    (delta: number) => {
      if (map) {
        map.setZoom(map.getZoom() + delta);
      }
    },
    [map],
  );
  const onZoomIn = useCallback(() => {
    onZoom(1);
  }, [onZoom]);

  const onZoomOut = useCallback(() => {
    onZoom(-1);
  }, [onZoom]);

  useGamepadBind("a", onZoomOut);
  useGamepadBind("b", onZoomIn);
  useGamepadBind("x", onToggle);

  return <></>;
};

export default TeleopMapGamepad;
