import { Typography } from "@material-ui/core";
import React from "react";
import Robot from "types/robots/Robot";

export interface BatterySOCProps {
  robot: Robot;
}

const BatterySOC: React.FC<BatterySOCProps> = ({ robot }) => {
  let batteryPercentageStr = "unknown";
  let batteryVoltageStr = "unknown";
  let batteryUsageStr = "unknown";
  if (robot.last_known_battery_soc) {
    batteryPercentageStr = `${robot.last_known_battery_soc.percentage}%`;
    batteryVoltageStr = `${robot.last_known_battery_soc.voltage}V`;
    batteryUsageStr = `${robot.last_known_battery_soc.usage}J`;
  }
  return (
    <>
      <Typography variant="caption" display="block">
        Battery SOC
      </Typography>
      {batteryPercentageStr} | {batteryVoltageStr} | {batteryUsageStr}
    </>
  );
};

export default BatterySOC;
