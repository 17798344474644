import { AxiosError } from "axios";
import { useRobot } from "fetchers/robots/robots";
import { UseQueryOptions } from "react-query";
import Robot from "types/robots/Robot";
import useDeliveryTeleopSessionStore from "../useDeliveryTeleopSessionStore";

function useRobotForTeleopSession<TData = Robot>(
  queryOptions: UseQueryOptions<Robot, AxiosError, TData> = {},
) {
  const robotID = useDeliveryTeleopSessionStore((s) => s.robotID);

  return useRobot(robotID, queryOptions);
}

export default useRobotForTeleopSession;
