import React from "react";

import User from "types/auth/User";
import { useActivateUser } from "mutations/users";
import SimpleMutationActionContainer from "../SimpleMutationActionContainer";

export interface ActivateAccountActionProps {
  user: User;
  onSuccess: () => void;
}

const ActivateAccountAction = React.forwardRef<
  HTMLLIElement,
  ActivateAccountActionProps
>(({ user, onSuccess }, ref) => {
  const activateUserMutation = useActivateUser();

  return (
    <SimpleMutationActionContainer
      onSuccess={onSuccess}
      ref={ref}
      userUUID={user.uuid}
      mutate={(userUUID) => activateUserMutation.mutateAsync(userUUID)}
      successMessage={(user) => `User ${user.email} activated successfully`}
      failMessage={(msg) =>
        `Something went wrong when trying to activate account: ${msg}`
      }
    >
      Activate Account
    </SimpleMutationActionContainer>
  );
});

export default ActivateAccountAction;
