import React from "react";
import { Formik, Form } from "formik";
import EaterAddressFormSchema from "./Schema";
import { useSetUberEatsOrderRecipientAddress } from "mutations/deliveries/uber-eats-orders";
import { addressFieldDataToCompleteAddress } from "./Model";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import AddressFieldData, { emptyAddressField } from "./Model";

export interface EaterAddressAutoSaveFormikProps {
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
  initialValues?: AddressFieldData;
  orderUUID: string;
}

const EaterAddressAutoSaveFormik: React.FC<EaterAddressAutoSaveFormikProps> = ({
  orderUUID,
  onSubmit = () => {},
  onSubmissionError = () => {},
  initialValues = emptyAddressField,
  children,
}) => {
  const mutation = useSetUberEatsOrderRecipientAddress();
  const { showAlert } = useAlertMessage();
  return (
    <Formik
      validationSchema={EaterAddressFormSchema}
      initialValues={initialValues}
      onSubmit={(v: AddressFieldData, { setSubmitting }) => {
        if (v !== initialValues && v.address_place) {
          mutation
            .mutateAsync({
              orderUUID,
              address: addressFieldDataToCompleteAddress(v),
            })
            .then(() => {
              showAlert({
                message: "Eater Address updated successfully",
                severity: "success",
              });
              onSubmit();
            })
            .catch((err) => {
              showAlert({
                message: err.message,
                severity: "error",
              });

              onSubmissionError(err);
            })
            .finally(() => setSubmitting(false));
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default EaterAddressAutoSaveFormik;
