import { useCallback } from "react";

import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";
import GamepadButtons from "lib/gamepad/types/GamepadButtons";
import useTriggerWhileButtonIsPressed from "components/gamepad/useTriggerWhileButtonIsPressed";

export type LidarsCommandKeyMap = {
  disableButton: GamepadButtons;
};

const useBindLidarsCommand = (
  robotID: string,
  keymap: LidarsCommandKeyMap = { disableButton: "view" },
) => {
  const controller = useRobotConnectionController();
  const whileLidarsButtonPressed = useCallback(() => {
    controller?.sendLidarsDisableCommand(robotID);
  }, [controller, robotID]);

  useTriggerWhileButtonIsPressed(
    keymap.disableButton,
    whileLidarsButtonPressed,
    200,
  );
};

export default useBindLidarsCommand;
