import { useFrame, useThree } from "@react-three/fiber";
import useVideoReprojection from "../useVideoReprojection";
import VideoReprojectionCanvas from "../VideoReprojectionCanvas";
import React, { useEffect } from "react";

export interface ReprojectedVideoProps {}

const ReprojectedVideo: React.FC = () => (
  <VideoReprojectionCanvas>
    <ReprojectedVideoInner />
  </VideoReprojectionCanvas>
);

const ReprojectedVideoInner: React.FC = () => {
  const { gl, scene } = useThree();
  const { bindReprojection, render } = useVideoReprojection();

  useEffect(() => {
    bindReprojection({ renderer: gl, scene: scene });
  }, [gl, scene, bindReprojection]);

  useFrame(() => {
    render();
  }, 1);

  return <></>;
};

export default ReprojectedVideo;
