import { useEffect, useRef } from "react";

import useGamepadStore from "components/gamepad/useGamepadStore";
import GamepadButtons from "lib/gamepad/types/GamepadButtons";

const useTriggerWhileButtonIsPressed = (
  button: GamepadButtons,
  action: () => void,
  throttleMs = 0,
) => {
  const timeRef = useRef(0);
  const [isButtonPressed] = useGamepadStore(
    (s) => [s.gamepadState?.buttons[button], s.gamepadState?.timestamp || 0],
    () => false,
  );
  const asOf = Date.now();

  useEffect(() => {
    if (!isButtonPressed) {
      timeRef.current = 0;
    } else if (isButtonPressed && asOf - timeRef.current >= throttleMs) {
      timeRef.current = asOf;
      action();
    }
  }, [isButtonPressed, asOf, action, throttleMs]);
};

export default useTriggerWhileButtonIsPressed;
