import { FormikHelpers, Formik, Form } from "formik";
import { useSetParkingSpot } from "mutations/robots";
import React from "react";
import RobotParkingSpot from "types/robots/RobotParkingSpot";
import RestaurantParkingSpotFormData, {
  emptyRestaurantParkingSpotFormField,
} from "./Model";
import RestaurantParkingSpotFormSchema from "./Schema";

export interface RestaurantParkingSpotFormikProps {
  robotID: string;
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
}

const RestaurantParkingSpotFormik: React.FC<
  RestaurantParkingSpotFormikProps
> = ({
  children,
  robotID,
  onSubmit = () => {},
  onSubmissionError = () => {},
}) => {
  const setParkingSpot = useSetParkingSpot();
  return (
    <Formik
      validationSchema={RestaurantParkingSpotFormSchema}
      initialValues={emptyRestaurantParkingSpotFormField}
      onSubmit={(
        formData: RestaurantParkingSpotFormData,
        helpers: FormikHelpers<RestaurantParkingSpotFormData>,
      ) => {
        setParkingSpot
          .mutateAsync({
            robotID,
            parkingSpot: restaurantToParkingSpot(formData.restaurant),
          })
          .then(() => onSubmit())
          .catch((err) => onSubmissionError(err))
          .finally(() => helpers.setSubmitting(false));
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

const restaurantToParkingSpot = (
  restaurant: RestaurantParkingSpotFormData["restaurant"],
): RobotParkingSpot => ({
  ...restaurant!,
  description: `Park outside ${restaurant!.name}`,
});

export default RestaurantParkingSpotFormik;
