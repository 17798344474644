import * as yup from "yup";

import OrderInformationSchema from "../OrderInformationField/Schema";
import DeliveryDetailsFieldSchema from "../DeliveryDetailsField/Schema";

const SkipTheDishesOrderInformationFieldSchema = OrderInformationSchema.shape({
  external_id: yup
    .string()
    .required("External ID is required")
    .uuid(
      "Order ID shape is invalid. Make sure you copied it from dashboard URL",
    ),
});

export default yup.object().shape({
  order_information: SkipTheDishesOrderInformationFieldSchema,
  delivery_information: DeliveryDetailsFieldSchema,
});
