import { useContext } from "react";
import AxiosContext, { ServiceName } from "./Context";
import { AxiosInstance } from "axios";

const useAxios = (serviceName: ServiceName): AxiosInstance => {
  const services = useContext(AxiosContext);
  return services[serviceName];
};

export default useAxios;
