import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export interface OrderRescueReasonFieldProps {
  initValue: string;
  handleChange?: (rescueReason: string) => void;
}

const OrderRescueReasonField: React.FC<OrderRescueReasonFieldProps> = ({
  initValue,
  handleChange = (rescueReason: string) => {},
}) => {
  return (
    <FormControl fullWidth>
      <Select
        native
        name="rescue-reason"
        value={initValue}
        onChange={(evt) => {
          handleChange(evt.target.value as string);
        }}
      >
        <option aria-label="None" value="" />
        <optgroup label="Customer">
          <option value="Refused Robot">Refused Robot</option>
          <option value="Can't meet outside">Can't meet outside</option>
          <option value="Too long at drop-off">Too long at drop-off</option>
          <option value="Didn't pick up phone">Didn't pick up phone</option>
        </optgroup>
        <optgroup label="Restaurant">
          <option value="Order mixup">Order mixup</option>
          <option value="Order won't fit">Order won't fit</option>
          <option value="Too long at pick-up">Too long at pick-up</option>
          <option value="Didn't pick up phone">Didn't pick up phone</option>
          <option value="Refused to put food in">Refused to put food in</option>
        </optgroup>
        <optgroup label="Dispatch">
          <option value="Too far">Too far</option>
          <option value="Uber code failed">Uber code failed</option>
          <option value="No pilots available">No pilots available</option>
          <option value="No robots available">No robots available</option>
          <option value="Order was arriving late">
            Order was arriving late
          </option>
          <option value="Pick-up is in dead-zone">
            Pick-up is in dead-zone
          </option>
          <option value="Drop-off is in dead-zone">
            Drop-off is in dead-zone
          </option>
        </optgroup>
        <optgroup label="Robot">
          <option value="Collision">Collision</option>
          <option value="Vandalism">Vandalism</option>
          <option value="Software issue">Software issue</option>
          <option value="Hardware Issue">Hardware Issue</option>
          <option value="Weather Conditions">Weather Conditions</option>
          <option value="Food damaged/spilled">Food damaged/spilled</option>
          <option value="Drinks damaged/spilled">Drinks damaged/spilled</option>
          <option value="Construction blocked route">
            Construction blocked route
          </option>
        </optgroup>
        <optgroup label="Other">
          <option value="Other">Other Reason</option>
        </optgroup>
      </Select>
    </FormControl>
  );
};

export default OrderRescueReasonField;
