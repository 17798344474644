import { useRegisterFCMBinding } from "mutations/notifications";
import React, { useEffect } from "react";
import { useMessagingSubscriptionToken } from "services/firebase";

export interface PushNotificationSubscriberProps {}

const PushNotificationSubscriber: React.FC<
  PushNotificationSubscriberProps
> = () => {
  const fcmRegistrationToken = useMessagingSubscriptionToken();
  const { mutate: registerBinding } = useRegisterFCMBinding();

  useEffect(() => {
    if (fcmRegistrationToken) {
      registerBinding(fcmRegistrationToken);
    }
  }, [fcmRegistrationToken, registerBinding]);

  return <></>;
};

export default PushNotificationSubscriber;
