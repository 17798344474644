import { useUberEatsOrderQuery } from "fetchers/deliveries/uber-eats-orders";
import React from "react";
import ArrivedAtDropOffActionButton from "../ArrivedAtDropOffActionButton";
import ArrivedAtPickUpActionButton from "../ArrivedAtPickUpActionButton";
import LeftForDropOffActionButton from "../LeftForDropOffActionButton";
import LeftForPickUpActionButton from "../LeftForPickUpActionButton";
import OrderDroppedOffActionButton from "../OrderDroppedOffActionButton";
import OrderPickedUpActionButton from "../OrderPickedUpActionButton";
import { ButtonProps } from "@material-ui/core/Button";

export interface OrderStageActionButtonProps
  extends Omit<ButtonProps, "href" | "onClick"> {
  orderUUID: string;
}

const OrderStageActionButton: React.FC<OrderStageActionButtonProps> = ({
  orderUUID,
  ...buttonProps
}) => {
  const order = useUberEatsOrderQuery(orderUUID);
  if (order.data?.assigned_robot_id) {
    switch (order.data?.current_stage.kind) {
      case "order-queued":
        return (
          <LeftForPickUpActionButton
            assignedRobotID={order.data.assigned_robot_id}
            {...buttonProps}
          />
        );
      case "robot-en-route-to-pick-up":
        return (
          <ArrivedAtPickUpActionButton
            assignedRobotID={order.data.assigned_robot_id}
            {...buttonProps}
          />
        );
      case "robot-waiting-for-pick-up":
        return (
          <OrderPickedUpActionButton
            assignedRobotID={order.data.assigned_robot_id}
            {...buttonProps}
          />
        );
      case "order-picked-up":
        return (
          <LeftForDropOffActionButton
            assignedRobotID={order.data.assigned_robot_id}
            {...buttonProps}
          />
        );
      case "robot-en-route-to-drop-off":
        return (
          <ArrivedAtDropOffActionButton
            assignedRobotID={order.data.assigned_robot_id}
            {...buttonProps}
          />
        );
      case "robot-waiting-for-drop-off":
        return (
          <OrderDroppedOffActionButton
            assignedRobotID={order.data.assigned_robot_id}
            {...buttonProps}
          />
        );
    }
  }
  return <></>;
};

export default OrderStageActionButton;
