import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import OrderCardRestaurantInfo from "./RestaurantInfo";
import OrderCardEaterInfo from "./EaterInfo";
import OrderCardChargesInfo from "./ChargesInfo";
import OrderCardLiveMap from "./LiveMap";
import OrderRescueLink from "../OrderRescueLink";

export interface OrderCardDetailsProps {
  order: UberEatsOrder;
  isEditable: boolean;
}

const OrderCardDetails: React.FC<OrderCardDetailsProps> = ({
  order,
  isEditable,
}) => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item container direction="row" spacing={3}>
        <Grid item container direction="column" xs={7} spacing={3}>
          <Grid item>
            <Typography variant="subtitle1">Restaurant Information</Typography>
            <OrderCardRestaurantInfo isEditable={isEditable} order={order} />
          </Grid>
          <Divider variant="middle" />
          <Grid item>
            <Typography variant="subtitle1">Eater Information</Typography>
            <OrderCardEaterInfo isEditable={isEditable} order={order} />
          </Grid>
          <Divider variant="middle" />
          <Grid item>
            <Typography variant="subtitle1">Charges Information</Typography>
            <OrderCardChargesInfo isEditable={isEditable} order={order} />
          </Grid>
        </Grid>
        <Divider flexItem orientation="vertical" />
        <Grid item xs={4}>
          <Typography variant="subtitle1">Live Location</Typography>
          <OrderCardLiveMap order={order} />
        </Grid>
      </Grid>
      <Grid item>
        {order.is_under_rescue ? (
          <OrderRescueLink orderUUID={order.uuid} />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default OrderCardDetails;
