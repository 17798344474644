import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

export interface DialogTriggerButtonProps {
  onClick: () => void;
}

const DialogTriggerButton: React.FC<DialogTriggerButtonProps> = ({
  onClick,
}) => {
  return (
    <IconButton color="inherit" onClick={onClick}>
      <AddIcon />
    </IconButton>
  );
};

export default DialogTriggerButton;
