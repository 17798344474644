import { useMemo } from "react";
import { AxiosInstance, AxiosResponse } from "axios";
import useAxios from "services/axios/useAxios";
import Restaurant from "types/deliveries/Restaurant";
import RestaurantParams from "types/deliveries/RestaurantParams";
import { RestaurantContactInfo } from "types/deliveries/Restaurant";

class RestaurantsService {
  constructor(private axios: AxiosInstance) {}

  async createRestaurant(
    restaurantInfo: RestaurantParams,
  ): Promise<AxiosResponse<Restaurant>> {
    return await this.axios.post<Restaurant>(
      "/api/restaurants",
      restaurantInfo,
    );
  }

  async getRestaurant(
    restaurantUUID: string,
  ): Promise<AxiosResponse<Restaurant>> {
    return await this.axios.get<Restaurant>(
      `/api/restaurants/${restaurantUUID}`,
    );
  }

  async getAllRestaurants(): Promise<AxiosResponse<Restaurant[]>> {
    return await this.axios.get<Restaurant[]>("/api/restaurants");
  }

  async setContacts(
    restaurantUUID: string,
    contacts: RestaurantContactInfo[],
  ): Promise<AxiosResponse<Restaurant>> {
    return await this.axios.put<Restaurant>(
      `/api/restaurants/${restaurantUUID}/contacts`,
      contacts,
    );
  }
}

export const useRestaurantsService = () => {
  const axios = useAxios("restaurants");
  return useMemo(() => new RestaurantsService(axios), [axios]);
};

export default RestaurantsService;
