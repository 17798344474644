import React from "react";
import { DataGrid, ColDef, CellValue } from "@material-ui/data-grid";
import { useUsersQuery } from "fetchers/auth/users";
import User from "types/auth/User";
import UserRowActions from "./RowActions";
import SetRolesDialog from "./RowActions/SetRolesAction/Dialog";

export interface UsersDataGridProps {
  onSelectUser: (user: User) => void;
}

const UsersDataGrid: React.FC<UsersDataGridProps> = ({ onSelectUser }) => {
  const users = useUsersQuery();
  const rows: UserRow[] = (users.data || []).map((u) => ({
    id: u.uuid,
    full_name: `${u.first_name} ${u.last_name}`,
    ...u,
  }));
  return (
    <>
      <SetRolesDialog />
      <DataGrid
        showToolbar
        autoHeight
        rows={rows}
        loading={users.isLoading}
        columns={columns}
        onRowClick={(params) => onSelectUser(params.row as unknown as UserRow)}
      />
    </>
  );
};

const renderFlagCell = (params: { value: CellValue }) => {
  if (params.value) {
    return <>YES</>;
  } else {
    return <>NO</>;
  }
};

const columns: ColDef[] = [
  { field: "full_name", headerName: "Name", flex: 2 },
  { field: "email", headerName: "Email", flex: 2 },
  { field: "roles", headerName: "Roles", flex: 2 },
  {
    field: "is_active",
    headerName: "Active",
    flex: 1,
    renderCell: renderFlagCell,
  },
  {
    field: "is_email_verified",
    headerName: "Email Verified",
    flex: 1,
    renderCell: renderFlagCell,
  },
  { field: "country_code", headerName: "Country Code", flex: 1 },
  {
    field: "-",
    headerName: "Actions",
    flex: 0,
    disableClickEventBubbling: true,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      // type definition from material-ui's data grid is lacking, so we have to
      // do this forced conversion manually
      <UserRowActions user={params.row as unknown as UserRow} />
    ),
  },
];

interface UserRow extends User {
  id: string;
  full_name: string;
}

export default UsersDataGrid;
