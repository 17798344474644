import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { useUberEatsOrderQuery } from "fetchers/deliveries/uber-eats-orders";

export interface TeleopScreenButtonProps
  extends Omit<ButtonProps, "href" | "onClick" | "children"> {
  orderUUID: string;
}

const TeleopScreenButton: React.FC<TeleopScreenButtonProps> = ({
  orderUUID,
  ...buttonProps
}) => {
  const assignedRobot = useUberEatsOrderQuery(orderUUID, {
    select: (o) => o.assigned_robot_id,
  });
  if (assignedRobot.data) {
    return (
      <Button {...buttonProps} href={`/pick-up-with/${assignedRobot.data}`}>
        {assignedRobot.data} Teleop
      </Button>
    );
  } else {
    return (
      <Button {...buttonProps} disabled>
        Teleop Interface
      </Button>
    );
  }
};

export default TeleopScreenButton;
