import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import { useCancelUberEatsOrder } from "mutations/deliveries/uber-eats-orders";
import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ActionConfirmationDialog from "components/common/ActionConfirmationDialog/Old";

export interface CancelOrderActionProps {
  orderUUID: string;
  onCancel: () => void;
}

const CancelOrderAction = React.forwardRef<
  HTMLLIElement,
  CancelOrderActionProps
>(({ orderUUID, onCancel }, ref) => {
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const cancelOrder = useCancelUberEatsOrder();
  const { showAlert } = useAlertMessage();

  const handleCancel = () => {
    cancelOrder
      .mutateAsync(orderUUID)
      .then(() => {
        showAlert({
          message: "Order successfully canceled",
          severity: "success",
        });
      })
      .catch(({ message }) => {
        showAlert({
          message: message || "Unknown error",
          severity: "error",
          autoCloseAfterMillis: 5000,
        });
      })
      .finally(() => onCancel());
  };

  return (
    <>
      <MenuItem ref={ref} onClick={() => setConfirmOpen(true)}>
        Cancel Order
      </MenuItem>
      <ActionConfirmationDialog
        onConfirm={handleCancel}
        onClose={() => setConfirmOpen(false)}
        description="This will cancel the order. It cannot be undone"
        isOpen={isConfirmOpen}
        isLoading={cancelOrder.isLoading}
      />
    </>
  );
});

export default CancelOrderAction;
