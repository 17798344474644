import { unstable_batchedUpdates } from "react-dom";
import {
  GamepadConnectedEvent,
  GamepadPolledEvent,
} from "lib/gamepad/gamepad-events";
import { useEffect } from "react";
import store from "../useGamepadStore";
import useGamepadController from "../useGamepadController";
import GamepadState from "lib/gamepad/types/GamepadState";

const useGamepadStoreHydrator = () => {
  const controller = useGamepadController();

  useEffect(() => {
    if (controller) {
      const setGamepadState = (newState: GamepadState | null) =>
        unstable_batchedUpdates(() => {
          store.setState({ gamepadState: newState });
        });

      const onPollEvent = (evt: GamepadPolledEvent) =>
        setGamepadState(evt.detail.state);
      const onConnectedEvent = (evt: GamepadConnectedEvent) =>
        setGamepadState(evt.detail.state);
      const onDisconnectedEvent = () => setGamepadState(null);

      controller.addEventListener("gamepad-polled", onPollEvent);
      controller.addEventListener("gamepad-connected", onConnectedEvent);
      controller.addEventListener("gamepad-disconnected", onDisconnectedEvent);
      return () => {
        controller.removeEventListener(onPollEvent);
        controller.removeEventListener(onConnectedEvent);
        controller.removeEventListener(onDisconnectedEvent);
      };
    }
  }, [controller]);
};

export default useGamepadStoreHydrator;
