import { Timezone } from "../TimeZoneField/timezones";

export default interface TimeRangeFieldModel {
  startDate: Date;
  endDate: Date;
  timezone: null | Timezone;
}

export const emptyTimeRangeField = (): TimeRangeFieldModel => ({
  startDate: new Date(),
  endDate: new Date(),
  timezone: null,
});
