import React, { useState, useEffect } from "react";
import { ARState } from "components/AugmentedReality/arstate";
import { ESPG32617ToLocal } from "components/AugmentedReality/coords";

export const FileUpload = () => {
  const [file, setFile] = useState<[]>([]);
  const [index, setIndex] = useState(0);

  const handleUpload = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function fileReadCompleted() {
      // when the reader is done, the content is in reader.result.
      if (reader.result) {
        setFile(JSON.parse(reader.result.toString()));
        setIndex(1);
      }
    };
    reader.readAsText(file);
  };

  const press = (e: any) => {
    if (!file) {
      return;
    }

    if (e.key === "ArrowLeft" && index >= 1) {
      setIndex(index - 1);
    }
    if (e.key === "ArrowRight" && index + 1 < file.length) {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    if (file.length && file[index][0]) {
      const x = file[index][0]["x"];
      const y = file[index][0]["y"];
      const xy = ESPG32617ToLocal(x, y);
      ARState.setState({ worldPosition: [-xy[0], -xy[1], 0] });
      ARState.setState({ particles: file[index] });
    }
  }, [file, index]);

  return (
    <div
      onKeyDown={press}
      tabIndex={0}
      style={{ position: "absolute", top: "0px" }}
    >
      <input type="file" onChange={handleUpload} />
    </div>
  );
};
