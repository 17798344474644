import React from "react";

import Grid from "@material-ui/core/Grid";

import RestaurantContactsFieldArray from "components/deliveries/RestaurantContactsFieldArray";

import RestaurantContactsFormik, {
  RestaurantContactsFormikProps,
} from "./Formik";
import FormikSubmitButton from "components/common/formik/FormikSubmitButton";

export type RestaurantContactsFormProps = RestaurantContactsFormikProps;

const RestaurantContactsForm: React.FC<RestaurantContactsFormProps> = (
  formikProps,
) => {
  return (
    <RestaurantContactsFormik {...formikProps}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <RestaurantContactsFieldArray name="contacts" />
        </Grid>
        <Grid item>
          <FormikSubmitButton />
        </Grid>
      </Grid>
    </RestaurantContactsFormik>
  );
};

export default RestaurantContactsForm;
