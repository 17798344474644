import { useMemo } from "react";

import useAxios from "services/axios/useAxios";
import { AxiosResponse } from "axios";
import { TimestampedGeocode } from "types/Location/Geocode";
import { mapVals } from "lib/functional";

export default interface RobotsTrailsService {
  getTrails: () => Promise<
    AxiosResponse<{ [robotID: string]: TimestampedGeocode[] }>
  >;
}

export const useTrailsService = (): RobotsTrailsService => {
  const axios = useAxios("pilot-api");
  // TODO fix back-end to return json data directly
  return useMemo(
    () => ({
      getTrails: async () =>
        axios.get<string>("/api/robots/trails").then(convertToInternal),
    }),
    [axios],
  );
};

const convertToInternal = (
  originalResponse: AxiosResponse<string>,
): AxiosResponse<{ [robotID: string]: TimestampedGeocode[] }> => {
  const parsedPayload: { [robotID: string]: RawRobotPosition[] } = JSON.parse(
    originalResponse.data,
  );
  return {
    ...originalResponse,
    data: mapVals(parseRawTrail, parsedPayload),
  };
};
const parseRawTrail = (trail: RawRobotPosition[]): TimestampedGeocode[] =>
  trail.map((t) => ({
    lat: t.latitude,
    lng: t.longitude,
    timestamp: t.timestamp,
  }));

interface RawRobotPosition {
  latitude: number;
  longitude: number;
  timestamp: string;
}
