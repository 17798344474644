import React from "react";

import { Snackbar, Slide } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";

export interface AlertMessageViewProps {
  message: string;
  severity?: AlertProps["severity"];
  onCloseAlert?: () => void;
  autoCloseMillis?: number;
  isOpen?: boolean;
}

const AlertMessageView: React.FC<AlertMessageViewProps> = ({
  message,
  onCloseAlert,
  severity = "error",
  autoCloseMillis = 5000,
  isOpen = false,
}) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoCloseMillis}
      onClose={onCloseAlert}
      TransitionComponent={(props) => <Slide {...props} direction="down" />}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
    >
      <Alert severity={severity} onClose={onCloseAlert}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessageView;
