import create from "zustand";

type State = {
  angle: number;
  cameraHeight: number;
  lookAt: number;
  back: number;
  far: number; // camera far plane
  worldPosition: number[];
  particles: Particle[];
  projection: Projection;
  projection_angle: number;
};

type Particle = {
  x: number;
  y: number;
  angle: number;
  alive: number;
};

export enum Projection {
  EquirectangularFront,
  EquirectangularBack,
  Gnomonic,
  Stereographic,
}

export const ARState = create<State>((set) => ({
  angle: 0,
  cameraHeight: 1.1,
  lookAt: 100,
  back: -0.34,
  far: 0,
  worldPosition: [0, 0, 0],
  particles: [],
  projection: Projection.EquirectangularFront,
  projection_angle: 0.0,
}));
