import { useIdentityStore } from "services/auth/identity/store";
import { useMutation } from "react-query";
import useAxios from "services/axios/useAxios";
import User from "types/auth/User";
import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";

export const useRefreshAccessToken = () => {
  const axios = useAxios("auth");
  const identity = useIdentityStore();
  return useMutation(
    () =>
      axios
        .post<{ access_token: string; user: User }>(
          "/api/access-token",
          {},
          { withCredentials: true },
        )
        .then((res) => res.data),
    {
      mutationKey: "auth/refresh-access-token",
      onSuccess: ({ access_token, user }) => {
        identity.setIdentity(access_token, user);
      },
      onError: (err: AxiosError) => {
        if (
          err.response?.status &&
          err.response.status >= 400 &&
          err.response.status < 500
        ) {
          identity.signOut();
        }
      },
    },
  );
};

export const usePollRefreshAccessToken = (refreshIntervalMillis: number) => {
  const [requestsCount, setRequestsCount] = useState(0);
  const { mutateAsync, ...mutation } = useRefreshAccessToken();
  const refresh = useCallback(
    () => mutateAsync().finally(() => setRequestsCount((c) => c + 1)),
    [mutateAsync, setRequestsCount],
  );

  useEffect(() => {
    if (requestsCount === 0) {
      refresh();
    } else {
      const timeout = setTimeout(() => refresh(), refreshIntervalMillis);
      return () => clearTimeout(timeout);
    }
  }, [requestsCount, refresh, refreshIntervalMillis]);

  return mutation;
};
