import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { useIsUserMutating } from "mutations/users";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import User from "types/auth/User";

export interface SimpleMutationActionContainerProps {
  userUUID: string;
  mutate: (userUUID: string) => Promise<User>;
  successMessage: (user: User) => string;
  failMessage: (err: string) => string;
  onSuccess: () => void;
}

const SimpleMutationActionContainer = React.forwardRef<
  HTMLLIElement,
  React.PropsWithChildren<SimpleMutationActionContainerProps>
>(
  (
    { userUUID, onSuccess, mutate, successMessage, failMessage, children },
    ref,
  ) => {
    const activeMutations = useIsUserMutating();

    const { showAlert } = useAlertMessage();

    const handleAction = () => {
      mutate(userUUID)
        .then((user) => {
          onSuccess();
          showAlert({
            message: successMessage(user),
            severity: "success",
          });
        })
        .catch((err) => {
          showAlert({
            message: failMessage(err.message),
            severity: "error",
          });
        });
    };

    return (
      <MenuItem ref={ref} onClick={handleAction} disabled={activeMutations > 0}>
        {children}
      </MenuItem>
    );
  },
);

export default SimpleMutationActionContainer;
