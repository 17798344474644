import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "@material-ui/core/Container";

import ViewUser from "../ViewUser";
import DashboardScreenTemplate from "components/DashboardScreenTemplate";

interface MatchParams {
  userUUID: string;
}

export type ViewUserScreenProps = RouteComponentProps<MatchParams>;

const ViewUserScreen: React.FC<ViewUserScreenProps> = ({ match }) => {
  const userUUID = match.params["userUUID"];

  return (
    <DashboardScreenTemplate screenTitle="View User Info">
      <Container>
        <ViewUser userUUID={userUUID} />
      </Container>
    </DashboardScreenTemplate>
  );
};

export default ViewUserScreen;
