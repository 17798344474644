import React, { useEffect } from "react";

import * as uuid from "uuid";

import Grid from "@material-ui/core/Grid";

import FormikTextField from "components/common/formik/FormikTextField";
import RestaurantAutocompleteField from "../RestaurantAutocompleteField";
import OrderIsMockField from "../OrderIsMockField";
import { useField, useFormikContext } from "formik";

export interface OrderInformationFieldProps {
  autoFocus?: boolean;
  required?: boolean;
  name: string;
}

const OrderInformationField: React.FC<OrderInformationFieldProps> = ({
  autoFocus = false,
  required = false,
  name,
}) => {
  const [isMockField] = useField<boolean>(`${name}.is_mock`);
  const { setFieldValue } = useFormikContext();
  const isMock = isMockField.value;

  useEffect(() => {
    if (isMock) {
      setFieldValue(`${name}.external_id`, uuid.v4());
    }
  }, [isMock, setFieldValue, name]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <OrderIsMockField informationFieldName={name} />
      </Grid>
      {isMock ? null : (
        <Grid item>
          <FormikTextField
            fullWidth
            name={`${name}.external_id`}
            label="Order ID (eg: cfd35c59-373b-463e-9211-d7a5e0996773)"
            autoFocus={autoFocus}
            required={required}
            disabled={isMock}
          />
        </Grid>
      )}
      <Grid item>
        <FormikTextField
          fullWidth
          name={`${name}.recipient_name`}
          label="Customer name"
          required={required}
        />
      </Grid>
      <Grid item>
        <RestaurantAutocompleteField
          name={`${name}.restaurant`}
          required={required}
        />
      </Grid>
    </Grid>
  );
};

export default OrderInformationField;
