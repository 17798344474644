import React from "react";
import { useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import TeleopHUDContainer from "components/teleop/HUDContainer";
import TeleopMap from "components/teleop/Map";
import TeleopContainer from "components/teleop/TeleopContainer";
import RobotCameraFeed from "components/teleop/RobotCameraFeed";
import RobotMapMarker from "../Map/RobotMarker";

const SpectateScreen: React.FC = () => {
  const { robotID } = useParams<{ robotID: string }>();
  return (
    <TeleopContainer robotID={robotID}>
      <TeleopHUDContainer
        horizontalPosition="left"
        verticalPosition="top"
        alwaysActive={true}
      >
        <TeleopMap robotID={robotID}>
          <RobotMapMarker robotID={robotID} />
        </TeleopMap>
      </TeleopHUDContainer>
      <Box width="100%" height="100%" position="absolute" zIndex={-1}>
        <RobotCameraFeed robotID={robotID} />
      </Box>
      <Snackbar
        open
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert severity="info">
          Spectator mode: driving controls are disabled
        </Alert>
      </Snackbar>
    </TeleopContainer>
  );
};

export default SpectateScreen;
