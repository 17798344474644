import { RobotConnection } from "@tiny-mile/teleop";
import RobotTelemetryStore from "stores/teleop/RobotTelemetry";
import RobotConnectionStatusStore from "stores/teleop/RobotConnectionStatus";
import AudioSelectionCommand from "types/teleop/AudioSelectionCommand";
import { TELEOP_SIGNALING_URL } from "config/constants";
import { DriveMotionCommand } from "@tiny-mile/teleop";

export default class RobotConnectionController {
  private connections: { [robotID: string]: RobotConnection } = {};

  constructor(
    private telemetryStore: RobotTelemetryStore,
    private statusStore: RobotConnectionStatusStore,
  ) {}

  setAccessToken(robotID: string, accessToken: string) {
    this.getConnection(robotID).setAccessToken(accessToken);
  }

  startTeleop(robotID: string) {
    this.getConnection(robotID).connect();
  }

  stopTeleop(robotID: string) {
    const connection = this.getConnection(robotID);
    connection.close();
    connection.unbindVideos();
    this.statusStore.upsert(robotID, { robotID, signal: "unknown" });
  }

  bindVideo(robotID: string, videoElement: HTMLVideoElement) {
    this.getConnection(robotID).bindVideo(videoElement);
  }

  sendLidToggleCommand(robotID: string) {
    this.getConnection(robotID).sendLidToggleCommand();
  }

  sendLEDScreenLightToggleCommand(robotID: string) {
    this.getConnection(robotID).sendLEDScreenLightToggleCommand();
  }

  sendSirenEnableCommand(robotID: string) {
    this.getConnection(robotID).sendSirenEnableCommand();
  }

  sendStrobeLightPulseCommand(robotID: string) {
    this.getConnection(robotID).sendStrobeLightPulseCommand();
  }

  sendAudioSelectionCommand(robotID: string, audio: AudioSelectionCommand) {
    this.getConnection(robotID).sendPlaySoundCommand(audio);
  }

  sendLidarsDisableCommand(robotID: string) {
    this.getConnection(robotID).sendLidarsDisableCommand();
  }

  sendDriveCommand(robotID: string, parameters: DriveMotionCommand["drive"]) {
    this.getConnection(robotID).sendMoveCommand({ drive: parameters });
  }

  sendStopCommand(robotID: string) {
    this.getConnection(robotID).sendMoveCommand(STOP_ROBOT_COMMAND);
  }

  sendRotateCommand(robotID: string, rotation: number) {
    this.getConnection(robotID).sendMoveCommand({
      rotate: { rotate: rotation },
    });
  }

  private getConnection(robotID: string): RobotConnection {
    if (!this.connections[robotID]) {
      const connection = new RobotConnection(robotID, TELEOP_SIGNALING_URL);
      this.statusStore.upsert(robotID, { robotID, signal: "unknown" });
      this.telemetryStore.upsert(robotID, {
        robotID,
        lidStateMessage: "",
        sirenStateMessage: "",
        position: null,
        direction: null,
      });

      connection.addConnectionStatusListener((status) => {
        this.statusStore.upsert(robotID, (s) => ({ ...s, signal: status }));
      });
      connection.addLidStateListener((lidState) => {
        this.telemetryStore.upsert(robotID, (s) => ({
          ...s,
          lidStateMessage: lidState,
        }));
      });
      connection.addSirenStateListener((sirenState) => {
        this.telemetryStore.upsert(robotID, (s) => ({
          ...s,
          sirenStateMessage: sirenState,
        }));
      });
      connection.addPositionListener((position) => {
        this.telemetryStore.upsert(robotID, (s) => ({
          ...s,
          position: position,
        }));
      });
      connection.addDirectionListener((direction) => {
        this.telemetryStore.upsert(robotID, (s) => ({
          ...s,
          direction: direction,
        }));
      });
      connection.addLocalizationListener((msg) => {
        this.telemetryStore.upsert(robotID, (s) => ({
          ...s,
          localization: msg,
        }));
      });
      this.connections[robotID] = connection;
    }
    return this.connections[robotID];
  }
}

const STOP_ROBOT_COMMAND: DriveMotionCommand = {
  drive: { power: 0.0, steer: 0.0, steer_compensation: 0.0 },
};
