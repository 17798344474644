import { useHotkeys } from "react-hotkeys-hook";

import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";
import AudioSelectionCommand from "types/teleop/AudioSelectionCommand";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";

export const useTeleopDrivingHotkeys = (robotID: string, disable?: boolean) => {
  const controller = useRobotConnectionController();

  useHotkeys("space", () => {
    if (disable) {
      console.log(
        "robotID=%s: e-stop detected and ignored since hotkeys are disabled",
        robotID,
      );
    }
    console.log("robotID=%s: e-stop pressed", robotID);
    controller!.sendStopCommand(robotID);
  });
  useHotkeys("l", () => {
    controller?.sendLEDScreenLightToggleCommand(robotID);
    console.log("robotID=%s: LED screen light toggled", robotID);
  });

  useBindGreetingMessageHotkey("q", robotID);
  useBindAudioMessageHotkey("greetings", "w", robotID);
  useBindAudioMessageHotkey("farewells", "e", robotID);
  useBindAudioMessageHotkey("thank", "r", robotID);
  useBindAudioMessageHotkey("see", "t", robotID);
  useBindAudioMessageHotkey("excuse", "y", robotID);
  useBindAudioMessageHotkey("stop", "a", robotID);
  useBindAudioMessageHotkey("emergency", "s", robotID);
  useBindAudioMessageHotkey("assistance_fall", "d", robotID);
  useBindAudioMessageHotkey("assistance_button", "f", robotID);
  useBindAudioMessageHotkey("lid", "g", robotID);
  // useBindAudioMessageHotkey("alerts", "w", robotID);
  // useBindAudioMessageHotkey("yes", "u", robotID);
  // useBindAudioMessageHotkey("no", "i", robotID);
  // useBindAudioMessageHotkey("tunes", "o", robotID);
  // useBindAudioMessageHotkey("morning", "p", robotID);
  // useBindAudioMessageHotkey("afternoon", "a", robotID);
  // useBindAudioMessageHotkey("evening", "s", robotID);
  // useBindAudioMessageHotkey("night", "d", robotID);
  // useBindAudioMessageHotkey("qr1", "z", robotID);
  // useBindAudioMessageHotkey("qr2", "x", robotID);
  // useBindAudioMessageHotkey("qr3", "c", robotID);
};

const useBindAudioMessageHotkey = (
  audioType: string,
  key: string,
  robotID: string,
) => {
  const controller = useRobotConnectionController();
  const { showAlert } = useAlertMessage();

  useHotkeys(key, () => {
    const audio: AudioSelectionCommand = { type: audioType };
    controller?.sendAudioSelectionCommand(robotID, audio);
    console.log("robotID=%s: %s audio sent", robotID, audioType);
    showAlert({
      message: `Requesting robot ${robotID} to play '${audioType}' audio...`,
      autoCloseAfterMillis: 3000,
      severity: "info",
    });
  });
};

const useBindGreetingMessageHotkey = (key: string, robotID: string) => {
  const controller = useRobotConnectionController();
  const { showAlert } = useAlertMessage();
  const currentHour = new Date().getUTCHours();

  useHotkeys(key, () => {
    const morning: AudioSelectionCommand = { type: "morning" };
    const afternoon: AudioSelectionCommand = { type: "afternoon" };
    const evening: AudioSelectionCommand = { type: "evening" };
    const night: AudioSelectionCommand = { type: "night" };

    // 6:00 - 12:00 EST
    if (currentHour >= 10 && currentHour < 16) {
      controller?.sendAudioSelectionCommand(robotID, morning);
      console.log("robotID=%s: morning audio sent", robotID);
      showAlert({
        message: `Requesting robot ${robotID} to play 'morning' audio...`,
        autoCloseAfterMillis: 3000,
        severity: "info",
      });
    }
    // 12:00 - 17:00 EST
    if (currentHour >= 16 && currentHour < 21) {
      controller?.sendAudioSelectionCommand(robotID, afternoon);
      console.log("robotID=%s: afternoon audio sent", robotID);
      showAlert({
        message: `Requesting robot ${robotID} to play 'afternoon' audio...`,
        autoCloseAfterMillis: 3000,
        severity: "info",
      });
    }
    // 17:00 - 21:00 EST
    if (
      (currentHour >= 21 && currentHour < 24) ||
      (currentHour >= 0 && currentHour < 1)
    ) {
      controller?.sendAudioSelectionCommand(robotID, evening);
      console.log("robotID=%s: evening audio sent", robotID);
      showAlert({
        message: `Requesting robot ${robotID} to play 'evening' audio...`,
        autoCloseAfterMillis: 3000,
        severity: "info",
      });
    }
    // 21:00 - 6:00 EST
    if (currentHour >= 1 && currentHour < 10) {
      controller?.sendAudioSelectionCommand(robotID, night);
      console.log("robotID=%s: night audio sent", robotID);
      showAlert({
        message: `Requesting robot ${robotID} to play 'night' audio...`,
        autoCloseAfterMillis: 3000,
        severity: "info",
      });
    }
  });
};
