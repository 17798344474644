import { SSO_APP_URL } from "config/constants";

type SSOAction = "sign-in" | "sign-out";
const useSSORedirectURL = (action: SSOAction) => {
  return `${SSO_APP_URL}/${action}?redirect=${encodeURIComponent(
    window.location.href,
  )}`;
};

export default useSSORedirectURL;
