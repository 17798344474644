import React from "react";
import Box from "@material-ui/core/Box";
import NavigationDrawer from "components/Navigation/Drawer/WithLinks";
import useDocumentTitle from "components/common/useDocumentTitle";

export interface DashboardScreenTemplateProps {
  screenTitle: string;
}

const DashboardScreenTemplate: React.FC<DashboardScreenTemplateProps> = ({
  children,
  screenTitle,
}) => {
  useDocumentTitle(screenTitle);
  return (
    <Box display="flex">
      <NavigationDrawer />
      <Box
        component="main"
        padding={3}
        flexGrow={1}
        height="100vh"
        overflow="auto"
      >
        {children}
      </Box>
    </Box>
  );
};

export default DashboardScreenTemplate;
