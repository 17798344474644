import React from "react";

import { useRobotTelemetryStore } from "stores/teleop/RobotTelemetry/Context";
import Query from ".";
import QueryContext from "./Context";

const QueryProvider: React.FC = ({ children }) => {
  const store = useRobotTelemetryStore();
  const query = new Query(store!);
  return (
    <QueryContext.Provider value={query}>{children}</QueryContext.Provider>
  );
};

export default QueryProvider;
