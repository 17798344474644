import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";

import DrawerLink from "./DrawerLink";

const SpectateRobotsButton: React.FC = () => (
  <DrawerLink
    to="/robots/spectate-all"
    primary="Spectate Robots"
    icon={<VisibilityIcon />}
    restrictToRoles={["Supervisor", "Admin"]}
  />
);

export default SpectateRobotsButton;
