import * as rxjs from "rxjs/operators";
import { Memoize } from "typescript-memoize";

import { QueryEntity } from "@datorama/akita";
import { RobotTelemetryStoreState } from "stores/teleop/RobotTelemetry";
import RobotTelemetry from "types/teleop/RobotTelemetry";
import Geocode from "types/Location/Geocode";

export default class RobotTelemetryQuery extends QueryEntity<
  RobotTelemetryStoreState,
  RobotTelemetry
> {
  @Memoize()
  selectRobotPosition(robotID: string) {
    return this.selectEntity(robotID, "position");
  }

  @Memoize()
  selectValidRobotPosition(robotID: string) {
    return this.selectRobotPosition(robotID).pipe(
      rxjs.filter(
        (position): position is Geocode =>
          !!position && isValidLat(position.lat) && isValidLng(position.lng),
      ),
    );
  }

  selectLocalization(robotID: string) {
    return this.selectEntity(robotID, "localization");
  }

  selectDirection(robotID: string) {
    return this.selectEntity(robotID, "direction");
  }

  selectSirenState(robotID: string) {
    return this.selectEntity(robotID, "sirenStateMessage");
  }
}

const isValidLat = (x: number): boolean => x >= -90 && x <= 90;
const isValidLng = (x: number): boolean => x >= -180 && x <= 180;
