import React from "react";
import { Formik, Form } from "formik";
import RestaurantContactsFormSchema from "./Schema";
import RestaurantContactsFormData, {
  initialRestaurantContactsFormData,
} from "./Model";
import { useSetRestaurantContacts } from "mutations/deliveries/restaurants";

export interface RestaurantContactsFormikProps {
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
  initialValues?: RestaurantContactsFormData;
  restaurantUUID: string;
}

const RestaurantContactsFormik: React.FC<RestaurantContactsFormikProps> = ({
  restaurantUUID,
  onSubmit = () => {},
  onSubmissionError = () => {},
  initialValues = initialRestaurantContactsFormData,
  children,
}) => {
  const mutation = useSetRestaurantContacts();
  return (
    <Formik
      validationSchema={RestaurantContactsFormSchema}
      initialValues={initialValues}
      onSubmit={(v: RestaurantContactsFormData, { setSubmitting }) => {
        mutation
          .mutateAsync({
            restaurantUUID,
            contacts: v.contacts,
          })
          .then(() => {
            onSubmit();
          })
          .catch((err) => {
            onSubmissionError(err);
          })
          .finally(() => setSubmitting(false));
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default RestaurantContactsFormik;
