import { useRestaurant } from "fetchers/deliveries/restaurants";
import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import RestaurantSummaryView from "./View";

export interface RestaurantSummaryProps {
  restaurantUUID: string;
  direction?: "row" | "column";
}

const RestaurantSummary: React.FC<RestaurantSummaryProps> = ({
  restaurantUUID,
  direction = "row",
}) => {
  const restaurant = useRestaurant(restaurantUUID);
  if (!restaurant.data) {
    return (
      <>
        <LinearProgress />
        {restaurantUUID}
      </>
    );
  } else {
    return (
      <RestaurantSummaryView
        restaurant={restaurant.data}
        direction={direction}
      />
    );
  }
};

export default RestaurantSummary;
