import React from "react";

import SVGMarker, { SVGMarkerProps } from "./SVGMarker";

export type OrientationMarkerProps = Omit<SVGMarkerProps, "anchor" | "svgPath">;

const OrientationMarker: React.FC<OrientationMarkerProps> = React.memo(
  (props) => {
    return (
      <SVGMarker
        fillColor="lightgray"
        strokeColor="white"
        strokeOpacity={1}
        strokeWeight={1}
        {...props}
        svgPath="m 31.169922,-86.648438 v 73.240235 a 50,50 0 0 0 -35.0000001,47.636719 50,50 0 0 0 50.0000001,50 50,50 0 0 0 50,-50 50,50 0 0 0 -35,-47.683594 v -73.19336 z"
        scale={0.1}
        anchor={[46.1699, 35.4102]}
      />
    );
  },
);

export default OrientationMarker;
