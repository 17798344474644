import React from "react";

import { Redirect, RouteComponentProps, Switch, Route } from "react-router-dom";
import UberEatsOrdersDispatchScreen from "../UberEatsOrdersDispatchScreen";
import ViewUberEatsOrderScreen from "components/deliveries/ViewUberEatsOrderScreen";
import OrderCardsScreen from "components/deliveries/OrderCardsScreen";

export type DispatchSwitchProps = RouteComponentProps;

const DispatchSwitch: React.FC<DispatchSwitchProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/orders`} component={OrderCardsScreen} />
    <Route
      path={`${match.url}/uber-eats-orders/:orderUUID`}
      component={ViewUberEatsOrderScreen}
    />
    <Route
      path={`${match.url}/uber-eats-orders`}
      component={UberEatsOrdersDispatchScreen}
    />
    <Route path={match.url}>
      <Redirect to={`${match.url}/uber-eats-orders`} />
    </Route>
  </Switch>
);

export default DispatchSwitch;
