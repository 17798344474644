import React from "react";
import TeleopHUDContainer, {
  TeleopHUDContainerProps,
} from "components/teleop/HUDContainer";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useSteerCompensationStore from "../useSteerCompensationStore";
import SteerCompensationGamepad from "../SteerCompensationGamepad";

export type SteerCompensationFactorWidgetProps =
  Partial<TeleopHUDContainerProps>;

const SteerCompensationFactorWidget: React.FC<
  SteerCompensationFactorWidgetProps
> = (hudContainerProps) => {
  const compensationFactor = useSteerCompensationStore(
    (s) => s.compensationFactor,
  );
  return (
    <TeleopHUDContainer
      horizontalPosition="left"
      verticalPosition="bottom"
      alwaysActive={true}
      {...hudContainerProps}
    >
      <Grid container direction="column">
        <Grid item>
          <Typography variant="caption">Steering compensation</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">
            {compensationFactor.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Grid>
      </Grid>
      <SteerCompensationGamepad />
    </TeleopHUDContainer>
  );
};

export default SteerCompensationFactorWidget;
