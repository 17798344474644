import React from "react";
import Typography from "@material-ui/core/Typography";
import { useOrdersInProgress } from "fetchers/deliveries/uber-eats-orders";
import Grid from "@material-ui/core/Grid";
import OrderCardsListView from "./View";

export interface OrderCardsListProps {}

const OrderCardsList: React.FC<OrderCardsListProps> = () => {
  useOrdersInProgress({ refetchInterval: 10 * 1000 });
  const ordersToBeAssigned = useOrdersToBeAssigned();
  const rescueSuggestions = useRescueSuggestions();
  const assignedOrders = useAssignedOrders();
  return (
    <Grid container direction="column" spacing={3}>
      <Typography variant="h4">Rescue suggestions</Typography>
      <OrderCardsListView orders={rescueSuggestions.data || []} />
      <Typography variant="h4">Orders to be assigned</Typography>
      <OrderCardsListView orders={ordersToBeAssigned.data || []} />
      <Typography variant="h4">Assigned orders</Typography>
      <OrderCardsListView orders={assignedOrders.data || []} />
    </Grid>
  );
};

const useOrdersToBeAssigned = () =>
  useOrdersInProgress({
    select: (orders) => orders.filter((o) => !o.assigned_robot_id),
  });

const useRescueSuggestions = () =>
  useOrdersInProgress({
    select: (orders) =>
      orders.filter((o) => o.is_rescue_suggested && !o.is_under_rescue),
  });

const useAssignedOrders = () =>
  useOrdersInProgress({
    select: (orders) =>
      orders.filter(
        (o) =>
          o.assigned_robot_id && (o.is_under_rescue || !o.is_rescue_suggested),
      ),
  });

export default OrderCardsList;
