import { FormikHelpers, Formik, Form } from "formik";
import { useSetParkingSpot } from "mutations/robots";
import React from "react";
import RobotParkingSpot from "types/robots/RobotParkingSpot";
import GenericParkingSpotFormData, {
  emptyGenericParkingSpotFormField,
} from "./Model";
import GenericParkingSpotFormSchema from "./Schema";

export interface GenericParkingSpotFormikProps {
  robotID: string;
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
}

const GenericParkingSpotFormik: React.FC<GenericParkingSpotFormikProps> = ({
  children,
  robotID,
  onSubmit = () => {},
  onSubmissionError = () => {},
}) => {
  const setParkingSpot = useSetParkingSpot();
  return (
    <Formik
      validationSchema={GenericParkingSpotFormSchema}
      initialValues={emptyGenericParkingSpotFormField}
      onSubmit={(
        formData: GenericParkingSpotFormData,
        helpers: FormikHelpers<GenericParkingSpotFormData>,
      ) => {
        setParkingSpot
          .mutateAsync({
            robotID,
            parkingSpot: formDataToParkingSpot(formData),
          })
          .then(() => onSubmit())
          .catch((err) => onSubmissionError(err))
          .finally(() => helpers.setSubmitting(false));
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

const formDataToParkingSpot = ({
  description,
  address,
}: GenericParkingSpotFormData): RobotParkingSpot => ({
  description,
  address_description: address.address_place!,
  geocoded_address: {
    ...address.address_place!.geolocation,
    google_place_id: address.address_place!.google_place_id,
  },
});

export default GenericParkingSpotFormik;
