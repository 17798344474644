import React from "react";
import GroupWorkIcon from "@material-ui/icons/GroupWork";

import DrawerLink from "./DrawerLink";

const PartnersIntegrationsButton: React.FC = () => (
  <DrawerLink
    to="/integrations/orders"
    primary="Integrations"
    icon={<GroupWorkIcon />}
    restrictToRoles={["Supervisor", "Admin"]}
  />
);

export default PartnersIntegrationsButton;
