import React, { useState } from "react";
import { KnownOrderSalesChannel } from "types/deliveries/OrderSalesChannel";
import OrderWizardOnboarding from "./Onboarding";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import OrderWizardTab from "./Tab";

export interface OrderWizardProps {
  onSubmit?: () => void;
  onSubmissionError?: (err: any) => void;
}

const OrderWizard: React.FC<OrderWizardProps> = (formProps) => {
  const [selectedOrigin, setOrigin] = useState<null | KnownOrderSalesChannel>(
    null,
  );
  if (!selectedOrigin) {
    return <OrderWizardOnboarding onSelectOrigin={setOrigin} />;
  } else {
    return (
      <>
        <Tabs
          variant="fullWidth"
          value={selectedOrigin}
          onChange={(_, val) => setOrigin(val)}
          textColor="primary"
          indicatorColor="primary"
        >
          {Object.entries(tabsLabels).map(([origin, label]) => (
            <Tab label={label} value={origin} key={origin} />
          ))}
        </Tabs>
        <Box marginTop={2}>
          <OrderWizardTab origin={selectedOrigin} {...formProps} />
        </Box>
      </>
    );
  }
};

const tabsLabels: Record<KnownOrderSalesChannel, string> = {
  "uber-eats": "Uber Eats",
  facedrive: "Facedrive",
  doordash: "Doordash",
  "skip-the-dishes": "SkipTheDishes",
};

export default OrderWizard;
