import { useRestaurant } from "fetchers/deliveries/restaurants";
import React from "react";
import useOrderForTeleopSession from "../useOrderForTeleopSession";
import OrderAddressesWidgetView from "./View";

export interface OrderAddressesWidgetProps {}

const OrderAddressesWidget: React.FC<OrderAddressesWidgetProps> = () => {
  const order = useOrderForTeleopSession();
  const restaurant = useRestaurant(order.data?.restaurant_uuid);
  if (!order.data || !restaurant.data) {
    return <></>;
  } else {
    return (
      <OrderAddressesWidgetView
        pickUpAddress={restaurant.data}
        dropOffAddress={order.data.recipient}
      />
    );
  }
};

export default OrderAddressesWidget;
