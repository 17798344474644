// Linear progress component that maintains a placeholder box when not showing,
// to avoid twitch from showing/hidding
import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";

export interface StableLinearProgressProps {
  show: boolean;
}

const StableLinearProgress: React.FC<StableLinearProgressProps> = ({
  show,
}) => {
  if (show) {
    return <LinearProgress />;
  } else {
    return <Box height={4} />;
  }
};

export default StableLinearProgress;
