import { useMutation, useQueryClient } from "react-query";
import RestaurantParams from "types/deliveries/RestaurantParams";
import { RestaurantContactInfo } from "types/deliveries/Restaurant";
import { useRestaurantsService } from "services/deliveries/Restaurants";

export const useRestaurantCreator = () => {
  const svc = useRestaurantsService();
  const queryClient = useQueryClient();
  return useMutation(
    (newRestaurant: RestaurantParams) =>
      svc.createRestaurant(newRestaurant).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("deliveries/restaurants");
        queryClient.setQueryData(
          ["deliveries/restaurants", { uuid: data.uuid }],
          data,
        );
      },
    },
  );
};

export const useSetRestaurantContacts = () => {
  const svc = useRestaurantsService();
  const queryClient = useQueryClient();
  return useMutation(
    (params: SetRestaurantContactsParams) =>
      svc
        .setContacts(params.restaurantUUID, params.contacts)
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("deliveries/restaurants");
        queryClient.setQueryData(
          ["deliveries/restaurants", { uuid: data.uuid }],
          data,
        );
      },
    },
  );
};

interface SetRestaurantContactsParams {
  restaurantUUID: string;
  contacts: RestaurantContactInfo[];
}
