import React from "react";

import OrderInformationStep from "./OrderInfoStep";
import DeliveryDetailsStep from "./DeliveryDetailsStep";
import StepperProvider from "components/common/Stepper/Provider";
import StepperView from "components/common/Stepper/StepperView";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import DeliveryChargesStep from "./DeliveryChargesStep";
import FacedriveOrderWizardFormSchema from "./FormSchema";
import {
  initialFacedriveOrderWizardFormData,
  facedriveOrderWizardFormDataToOrderParameters,
} from "./FormModel";
import OrderWizardFormik from "../OrderWizardFormik";

export interface FacedriveOrderWizardProps {
  onCreated?: (order: UberEatsOrder) => void;
  onSubmissionError?: (err: any) => void;
}

const FacedriveOrderWizard: React.FC<FacedriveOrderWizardProps> = (props) => {
  return (
    <StepperProvider>
      <OrderWizardFormik
        validationSchema={FacedriveOrderWizardFormSchema}
        initialValues={initialFacedriveOrderWizardFormData}
        stepsCount={3}
        formDataTransformer={facedriveOrderWizardFormDataToOrderParameters}
        {...props}
      >
        <StepperView nonLinear orientation="vertical">
          <OrderInformationStep key={0} stepIndex={0} />
          <DeliveryDetailsStep key={1} stepIndex={1} />
          <DeliveryChargesStep key={2} stepIndex={2} />
        </StepperView>
      </OrderWizardFormik>
    </StepperProvider>
  );
};

export default FacedriveOrderWizard;
