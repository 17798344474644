import create from "zustand";

type SteerCompensationState = {
  // Compensation factor controls how much we will steer to one side or
  // another. A value < 0 means the robot will compensate by trying to steer to
  // the left, while > 0 means the robot will try to steer more to the right.
  compensationFactor: number;
};

type SteerCompensationAPI = {
  increaseCompensationToLeft: (delta: number) => void;
  increaseCompensationToRight: (delta: number) => void;
};

type SteerCompensationStore = SteerCompensationState & SteerCompensationAPI;

const useSteerCompensationStore = create<SteerCompensationStore>((set) => ({
  compensationFactor: 0.0,
  increaseCompensationToLeft: (delta: number) =>
    set(({ compensationFactor }) => ({
      compensationFactor: Math.max(compensationFactor - delta, -1),
    })),
  increaseCompensationToRight: (delta: number) =>
    set(({ compensationFactor }) => ({
      compensationFactor: Math.min(compensationFactor + delta, 1),
    })),
}));

export default useSteerCompensationStore;
