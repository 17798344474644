import React from "react";
import Grid from "@material-ui/core/Grid";
import MediaDownloaderFormik, { MediaDownloaderFormikProps } from "./Formik";
import TimeRangeField from "components/common/TimeRangeField";
import RobotAutocompleteField from "../RobotAutocompleteField";
import FormikSubmitButton from "components/common/formik/FormikSubmitButton";

export interface MediaDownloaderFormProps extends MediaDownloaderFormikProps {}

const MediaDownloaderForm: React.FC<MediaDownloaderFormProps> = ({
  ...formikProps
}) => (
  <MediaDownloaderFormik {...formikProps}>
    <Grid container spacing={1}>
      <Grid item>
        <RobotAutocompleteField required fullWidth name="robot" />
      </Grid>
      <Grid item>
        <TimeRangeField required name="timeRange" />
      </Grid>
      <Grid item>
        <FormikSubmitButton />
      </Grid>
    </Grid>
  </MediaDownloaderFormik>
);

export default MediaDownloaderForm;
