import { useCallback, useEffect, useState } from "react";
import useNotificationPermission from "./useNotificationPermission";

export interface ShowNotificationProps {
  title: string;
  body: string;
  onClose?: () => void;
}

const useBrowserNotification = () => {
  const permission = useNotificationPermission();
  const [notificationProps, setProps] = useState<null | ShowNotificationProps>(
    null,
  );
  useEffect(() => {
    if ("Notification" in window && notificationProps) {
      const { title, body, onClose = () => {} } = notificationProps;
      if (permission === "granted") {
        const notification = newNotification(title, { body });
        if (notification) {
          const visibilityHandler = () => {
            if (document.visibilityState === "visible") {
              notification.close();
            }
          };
          document.addEventListener("visibilitychange", visibilityHandler);
          notification.addEventListener("close", onClose);
          notification.addEventListener("click", (e) => {
            window.focus();
            (e.target as Notification).close();
          });
          return () => {
            document.removeEventListener("visibilitychange", visibilityHandler);
            notification.close();
          };
        }
      }
    }
  }, [notificationProps, permission]);

  return useCallback(
    (props: ShowNotificationProps) => setProps(props),
    [setProps],
  );
};

const newNotification = (
  ...args: ConstructorParameters<typeof Notification>
): null | Notification => {
  // Android throws TypeError when trying to construct a notification
  try {
    return new Notification(...args);
  } catch (err) {
    return null;
  }
};

export default useBrowserNotification;
