import React from "react";

import { combineProviders } from "react-combine-providers";

import GoogleAutocompleteServiceProvider from "services/location/GoogleAutocompleteService/Provider";

import RobotTelemetryStoreProvider from "stores/teleop/RobotTelemetry/Provider";
import RobotTelemetryQueryProvider from "queries/teleop/RobotTelemetry/Provider";

import RobotConnectionStatusStoreProvider from "stores/teleop/RobotConnectionStatus/Provider";
import RobotConnectionStatusQueryProvider from "queries/teleop/RobotConnectionStatus/Provider";
import ReactQueryProvider from "components/ReactQueryProvider";

import RobotConnectionController from "controllers/teleop/RobotConnection/Provider";
import IdentityStoreProvider from "components/auth/IdentityStoreProvider";
import GamepadStoreProvider from "components/gamepad/GamepadStoreProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const MasterProvider: React.FC = ({ children }) => {
  const providers = combineProviders();

  providers.push(IdentityStoreProvider);
  providers.push(ReactQueryProvider);
  providers.push(GoogleAutocompleteServiceProvider);

  providers.push(GamepadStoreProvider);

  providers.push(RobotTelemetryStoreProvider);
  providers.push(RobotTelemetryQueryProvider);

  providers.push(RobotConnectionStatusStoreProvider);
  providers.push(RobotConnectionStatusQueryProvider);

  providers.push(RobotConnectionController);
  providers.push(({ children }) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {children}
    </MuiPickersUtilsProvider>
  ));

  const MasterProvider = providers.master();

  return <MasterProvider>{children}</MasterProvider>;
};

export default MasterProvider;
