import React from "react";

import Grid from "@material-ui/core/Grid";

import TeleopHUDContainer, {
  TeleopHUDContainerProps,
} from "components/teleop/HUDContainer";
import TeleopDestinationSelect from "../DestinationSelect";
import GenericTeleopMap from "./Map";
import TeleopDestinationLinks from "./DestinationLinks";

export interface GenericTeleopMapWidgetProps
  extends Partial<TeleopHUDContainerProps> {
  robotID: string;
}

const GenericTeleopMapWidget: React.FC<GenericTeleopMapWidgetProps> = ({
  robotID,
  ...hudContainerProps
}) => {
  return (
    <TeleopHUDContainer
      horizontalPosition="left"
      verticalPosition="top"
      alwaysActive={true}
      {...hudContainerProps}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <GenericTeleopMap robotID={robotID} />
        </Grid>
        <Grid item>
          <TeleopDestinationSelect />
        </Grid>
        <Grid item>
          <TeleopDestinationLinks />
        </Grid>
      </Grid>
    </TeleopHUDContainer>
  );
};

export default GenericTeleopMapWidget;
