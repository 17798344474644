import useGamepadStore from "components/gamepad/useGamepadStore";
import React from "react";
import TrajectoryIndicatorView from "./View";

export interface GamepadTrajectoryIndicatorProps {}

const GamepadTrajectoryIndicator: React.FC<
  GamepadTrajectoryIndicatorProps
> = () => {
  const [throttleForward, throttleBackward] = useGamepadStore((s) => [
    s.gamepadState?.axes.r_trigger || 0,
    s.gamepadState?.axes.l_trigger || 0,
  ]);
  const rudder = useGamepadStore((s) => s.gamepadState?.axes.r_x || 0);

  return (
    <TrajectoryIndicatorView
      rudder={rudder}
      throttle={throttleForward > 0 ? throttleForward : throttleBackward}
    />
  );
};

export default GamepadTrajectoryIndicator;
