import { useQuery, UseQueryOptions, QueryOptions } from "react-query";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import { useUberEatsOrdersService } from "services/deliveries/UberEatsOrders";
import { AxiosError } from "axios";
import useAxios from "services/axios/useAxios";

export const useUberEatsOrders = (
  queryOptions?: UseQueryOptions<UberEatsOrder[]>,
) => {
  const baseOptions = useUberEatsOrdersOptions();
  return useQuery({ ...baseOptions, ...queryOptions });
};

export const useUberEatsOrdersOptions = (): QueryOptions<UberEatsOrder[]> => {
  const service = useUberEatsOrdersService();
  return {
    queryKey: ["deliveries/uber-eats-orders", "all"],
    queryFn: () => service.getAll().then((res) => res.data),
    initialData: [],
  };
};

export function useUberEatsOrderQuery<TData = UberEatsOrder>(
  orderUUID: string,
  queryOptions?: UseQueryOptions<UberEatsOrder, AxiosError, TData>,
) {
  const service = useUberEatsOrdersService();
  return useQuery(
    ["deliveries/uber-eats-orders", { uuid: orderUUID }],
    () => service.getOrder(orderUUID).then((res) => res.data),
    { ...queryOptions },
  );
}

export function useActiveUberEatsOrderForRobot<TData = null | UberEatsOrder>(
  robotID: string,
  queryOptions?: UseQueryOptions<null | UberEatsOrder, AxiosError, TData>,
) {
  const service = useUberEatsOrdersService();
  return useQuery(
    ["deliveries/uber-eats-orders", "active-robot-orders", { robotID }],
    () =>
      service
        .getActiveOrderForRobot(robotID)
        .then((res) => res.data)
        .catch((err) => {
          if (err.response.status === 404) {
            return null;
          } else {
            throw err;
          }
        }),
    { ...queryOptions },
  );
}

export const useOrdersInProgress = (
  queryOptions?: UseQueryOptions<UberEatsOrder[]>,
) => {
  const axios = useAxios("uber-eats-orders");
  return useQuery(
    ["deliveries/orders", "in-progress"],
    () => axios.get("/api/orders/in-progress").then((res) => res.data),
    queryOptions,
  );
};
