import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { useRobotsService } from "services/robots/RobotsService";
import Robot from "types/robots/Robot";

export function useRobot<TData = Robot>(
  robotID: null | undefined | string,
  queryOptions?: UseQueryOptions<Robot, AxiosError, TData>,
) {
  const service = useRobotsService();
  return useQuery(
    ["robots/robots", { id: robotID }],
    () => service.getRobot(robotID!).then((res) => res.data),
    { ...queryOptions, enabled: Boolean(robotID) },
  );
}

export function useRobots<TData = Robot[]>(
  queryOptions?: UseQueryOptions<Robot[], AxiosError, TData>,
) {
  const service = useRobotsService();
  return useQuery(
    ["robots/robots"],
    () => service.listRobots().then((res) => res.data),
    queryOptions,
  );
}
