import { createMuiTheme } from "@material-ui/core/styles";

// App's Custom theme
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: { main: "#f75eba" },
  },
});

export default theme;
