import { AxiosError } from "axios";
import { useActiveUberEatsOrderForRobot } from "fetchers/deliveries/uber-eats-orders";
import { UseQueryOptions } from "react-query";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import useDeliveryTeleopSessionStore from "../useDeliveryTeleopSessionStore";

export default function useOrderForTeleopSession<TData = null | UberEatsOrder>(
  queryOptions?: UseQueryOptions<null | UberEatsOrder, AxiosError, TData>,
) {
  const robotID = useDeliveryTeleopSessionStore((s) => s.robotID);
  return useActiveUberEatsOrderForRobot(robotID, {
    refetchInterval: 2000,
    ...queryOptions,
  });
}
