import AddressDescription from "./AddressDescription";
import GeocodedAddress from "./GeocodedAddress";
import Place from "./Place";

export default interface CompleteAddress {
  address_description: AddressDescription;
  geocoded_address: GeocodedAddress;
}

export const completeAddressAsPlace = ({
  address_description,
  geocoded_address,
}: CompleteAddress): Place => ({
  ...address_description,
  geolocation: geocoded_address,
  google_place_id: geocoded_address.google_place_id,
});
