import AddressDescription from "types/Location/AddressDescription";
import { completeAddressAsPlace } from "types/Location/CompleteAddress";
import GeocodedAddress from "types/Location/GeocodedAddress";
import Place from "types/Location/Place";

export default interface RobotDestination {
  description?: string;
  address_description: AddressDescription;
  geocoded_address: GeocodedAddress;
}

export const robotDestinationAsPlace = (dest: RobotDestination): Place =>
  completeAddressAsPlace(dest);
