import React from "react";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import VisibilityIcon from "@material-ui/icons/Visibility";

import VisibleTo from "components/auth/VisibleTo";
import DeliverWithButton from "./DeliverWithButton";
import RobotCardMenu from "../Menu";

export interface RobotCardActionsProps {
  robotID: string;
  canDrive?: boolean;
}

const RobotCardActions: React.FC<RobotCardActionsProps> = ({
  robotID,
  canDrive,
}) => {
  return (
    <CardActions disableSpacing>
      <VisibleTo roles={["Admin", "Rescuer"]}>
        <Link to={`/drive/${robotID}`}>
          <IconButton>
            <PlayArrowIcon />
          </IconButton>
        </Link>
      </VisibleTo>
      {canDrive ? <DeliverWithButton robotID={robotID} /> : null}
      <Link to={`/spectate/${robotID}`}>
        <IconButton>
          <VisibilityIcon />
        </IconButton>
      </Link>
      <VisibleTo roles={["Admin", "Supervisor", "Rescuer"]}>
        <Link
          to={`/pick-up-with/${robotID}`}
          title="Mobile friendly pick up interface"
        >
          <IconButton>
            <LockOpenIcon />
          </IconButton>
        </Link>
      </VisibleTo>
      <VisibleTo roles={["Admin", "Supervisor"]}>
        <RobotCardMenu robotID={robotID} />
      </VisibleTo>
    </CardActions>
  );
};

export default RobotCardActions;
