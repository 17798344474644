import React from "react";
import DeliveryRoute from "./Route";
import TeleopMap from "components/teleop/Map";
import RobotMapMarker from "components/teleop/Map/RobotMarker";
import useDeliveryTeleopSessionStore from "../useDeliveryTeleopSessionStore";

export interface DeliveryTaskMapProps {}

const DeliveryTaskMap: React.FC<DeliveryTaskMapProps> = () => {
  const robotID = useDeliveryTeleopSessionStore((s) => s.robotID);

  return (
    <TeleopMap robotID={robotID}>
      <DeliveryRoute robotID={robotID} />
      <RobotMapMarker robotID={robotID} />
    </TeleopMap>
  );
};

export default DeliveryTaskMap;
