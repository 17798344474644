import { StoreConfig, EntityStore } from "@datorama/akita";

import RobotConnectionStatus from "types/teleop/RobotConnectionStatus";

export interface RobotConnectionStatusStoreState {
  ui: {};
}

const defaultInitialState = {
  ui: {},
};

@StoreConfig({ name: "RobotConnectionStatusStore", idKey: "robotID" })
export default class RobotConnectionStatusStore extends EntityStore<
  RobotConnectionStatusStoreState,
  RobotConnectionStatus
> {
  constructor(
    initialState: RobotConnectionStatusStoreState = defaultInitialState,
  ) {
    super(initialState);
  }
}
