import useGamepadBind from "components/gamepad/useGamepadBind";
import React from "react";

import useSteerCompensationStore from "../useSteerCompensationStore";

export interface SteerCompensationGamepadProps {}

const SteerCompensationGamepad: React.FC<
  SteerCompensationGamepadProps
> = () => {
  const { increaseCompensationToLeft, increaseCompensationToRight } =
    useSteerCompensationStore();

  const onLeftPressed = () => {
    increaseCompensationToLeft(COMPENSATION_INCREASE_DELTA);
  };

  const onRightPressed = () => {
    increaseCompensationToRight(COMPENSATION_INCREASE_DELTA);
  };

  useGamepadBind("d_left", onLeftPressed);
  useGamepadBind("d_right", onRightPressed);

  return <></>;
};

const COMPENSATION_INCREASE_DELTA = 0.1;

export default SteerCompensationGamepad;
