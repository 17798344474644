import RestaurantAutocompleteFieldData from "components/deliveries/RestaurantAutocompleteField/Model";

export default interface RestaurantParkingSpotFormData {
  restaurant: RestaurantAutocompleteFieldData;
}

export const emptyRestaurantParkingSpotFormField: RestaurantParkingSpotFormData =
  {
    restaurant: null,
  };
