import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import { RouteComponentProps } from "react-router-dom";
import OrderInfoLink from "./OrderInfoLink";
import RestaurantDirectionsButton from "./RestaurantDirectionsButton";
import EaterDirectionsButton from "./EaterDirectionsButton";
import RobotDirectionsButton from "./RobotDirectionsButton";
import TeleopScreenButton from "./TeleopScreenButton";
import OrderStageActionButton from "components/deliveries/OrderStageActionButton";

export interface OrderRescueScreenProps
  extends RouteComponentProps<{ orderUUID: string }> {}

const OrderRescueScreen: React.FC<OrderRescueScreenProps> = ({ match }) => {
  const orderUUID = match.params.orderUUID;

  return (
    <DashboardScreenTemplate screenTitle="Rescue Order">
      <ButtonGroup
        variant="contained"
        color="primary"
        orientation="vertical"
        fullWidth
      >
        <OrderInfoLink orderUUID={orderUUID} />
        <RestaurantDirectionsButton orderUUID={orderUUID} />
        <EaterDirectionsButton orderUUID={orderUUID} />
        <RobotDirectionsButton orderUUID={orderUUID} />
        <TeleopScreenButton orderUUID={orderUUID} />
        <OrderStageActionButton orderUUID={orderUUID} />
      </ButtonGroup>
    </DashboardScreenTemplate>
  );
};

export default OrderRescueScreen;
