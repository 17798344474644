import * as yup from "yup";

import CountryCodeFieldSchema from "components/common/CountryCodeField/Schema";

export default yup.object().shape({
  email: yup.string().required("Required").min(3),
  password: yup.string().required("Required").min(6),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  country_code: CountryCodeFieldSchema.required("Required"),
});
