import React from "react";

import Button from "@material-ui/core/Button";
import useStepper from "./useStepper";

export type StepperNextButtonProps = {};

const StepperNextButton: React.FC<StepperNextButtonProps> = () => {
  const { advanceOneStep } = useStepper();
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => advanceOneStep()}
    >
      Next
    </Button>
  );
};

export default StepperNextButton;
