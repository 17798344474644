import React from "react";

import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { useField, FieldHookConfig } from "formik";

export type FormikTextFieldProps = TextFieldProps & FieldHookConfig<string>;

const FormikTextField: React.FC<FormikTextFieldProps> = (props) => {
  const [field, meta] = useField<string>(props);

  return (
    <TextField
      {...props}
      {...field}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export default FormikTextField;
