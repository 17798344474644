import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { useUberEatsOrderQuery } from "fetchers/deliveries/uber-eats-orders";
import { googleMapsLink } from "lib/location/places";

export interface EaterDirectionsButtonProps
  extends Omit<ButtonProps, "href" | "onClick" | "children"> {
  orderUUID: string;
}

const EaterDirectionsButton: React.FC<EaterDirectionsButtonProps> = ({
  orderUUID,
  ...buttonProps
}) => {
  const eaterLocation = useUberEatsOrderQuery(orderUUID, {
    select: (o) => o.recipient.geocoded_address,
  });
  if (eaterLocation.data) {
    return (
      <Button {...buttonProps} href={googleMapsLink(eaterLocation.data)}>
        Eater directions
      </Button>
    );
  } else {
    return (
      <Button {...buttonProps} disabled>
        Eater directions
      </Button>
    );
  }
};

export default EaterDirectionsButton;
