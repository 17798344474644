import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";

export interface OrderInfoLinkProps
  extends Omit<ButtonProps, "href" | "onClick" | "children"> {
  orderUUID: string;
}

const OrderInfoLink: React.FC<OrderInfoLinkProps> = ({
  orderUUID,
  ...props
}) => (
  <Button href={`/orders/uber-eats/${orderUUID}`} {...props}>
    Order Information
  </Button>
);

export default OrderInfoLink;
