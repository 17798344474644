import React, { useState } from "react";

import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import BusinessIcon from "@material-ui/icons/Business";

import AutocompleteInput from "components/common/AutocompleteInput";
import Restaurant from "types/deliveries/Restaurant";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useRestaurants } from "fetchers/deliveries/restaurants";

export interface RestaurantAutocompleteFieldViewProps {
  label: string;
  autoFocus?: boolean;
  required?: boolean;
  onQueryChange?: (query: string) => void;
  value: null | Restaurant;
  initialQuery?: string;
  onChange: AutocompleteProps<
    null | Restaurant,
    false,
    false,
    false
  >["onChange"];
  onBlur?: AutocompleteProps<null | Restaurant, false, false, false>["onBlur"];
  onFocus?: AutocompleteProps<
    null | Restaurant,
    false,
    false,
    false
  >["onFocus"];
}

const RestaurantAutocompleteFieldView: React.FC<RestaurantAutocompleteFieldViewProps> =
  React.memo(
    ({
      label,
      autoFocus = false,
      required = false,
      onChange,
      value = null,
      initialQuery = "",
      onBlur = () => {},
      onFocus = () => {},
      onQueryChange = () => {},
    }) => {
      const { data, isFetching, isLoading } = useRestaurants();
      const [userQuery, setUserQuery] = useState(initialQuery);

      const candidates: Restaurant[] = _filterAutocompleteCandidates(
        userQuery,
        data || [],
      );
      if (
        value &&
        !candidates.find((candidate) => value.uuid === candidate.uuid)
      ) {
        candidates.push(value);
      }

      return (
        <Autocomplete
          autoComplete
          loading={isFetching || isLoading}
          disabled={isLoading}
          options={candidates}
          getOptionLabel={_restaurantLabel}
          getOptionSelected={(option, val) => option.uuid === val.uuid}
          renderOption={(option: Restaurant) => (
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <BusinessIcon fontSize="small" />
              </Grid>
              <Grid item>
                <Typography> {`${option.name}`} </Typography>
                <Typography>
                  {` ${option.address_description.address_line_1} `}
                </Typography>
              </Grid>
            </Grid>
          )}
          renderInput={(params) => (
            <AutocompleteInput
              required={required}
              autoFocus={autoFocus}
              label={label}
              isLoading={isFetching}
              {...params}
            />
          )}
          onInputChange={(_, newQuery) => {
            setUserQuery(newQuery);
            onQueryChange(newQuery);
          }}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
        />
      );
    },
  );

const _restaurantLabel = (restaurant: Restaurant) => restaurant.name;

const _filterAutocompleteCandidates = (
  query: string,
  restaurants: Restaurant[],
) =>
  restaurants.filter((restaurant) =>
    _restaurantLabel(restaurant).toLowerCase().startsWith(query.toLowerCase()),
  );

export default RestaurantAutocompleteFieldView;
