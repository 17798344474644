import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useSetUberEatsOrderRecipientName } from "mutations/deliveries/uber-eats-orders";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";

export interface EaterNameAutoSaveFieldProps {
  orderUUID: string;
  currentName: string;
  isEditable: boolean;
}

const EaterNameAutoSaveField: React.FC<EaterNameAutoSaveFieldProps> = ({
  orderUUID,
  currentName,
  isEditable,
}) => {
  const [name, setName] = useState(currentName);
  const { showAlert } = useAlertMessage();
  const setEaterName = useSetUberEatsOrderRecipientName();

  const handleSubmit = () => {
    if (currentName !== name) {
      setEaterName
        .mutateAsync({ orderUUID, name })
        .then((order) => {
          setName(order.recipient.name);
          showAlert({
            message: "Eater name updated successfully",
            severity: "success",
          });
        })
        .catch(({ message }) => {
          setName(currentName);
          showAlert({
            message: message || "Unknown error",
            severity: "error",
            autoCloseAfterMillis: 5000,
          });
        });
    }
  };
  return (
    <Box lineHeight={1}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextField
          value={name || ""}
          onChange={(e) => setName(e.currentTarget.value)}
          onBlur={handleSubmit}
          variant="outlined"
          label="Name"
          disabled={!isEditable || setEaterName.isLoading}
          fullWidth
        />
      </form>
    </Box>
  );
};

export default EaterNameAutoSaveField;
