import React from "react";
import useOrderForTeleopSession from "../useOrderForTeleopSession";
import LeftForPickUpActionSnackbar from "./LeftForPickUpAction";
import ArrivedAtPickUpActionSnackbar from "./ArrivedAtPickUpAction";
import LeftForDropOffActionSnackbar from "./LeftForDropOffAction";
import ArrivedAtDropOffActionSnackbar from "./ArrivedAtDropOffAction";
import OrderDroppedOffActionSnackbar from "./OrderDroppedOffAction";
import OrderPickedUpActionSnackbar from "./OrderPickedUpAction";
import useRobotForTeleopSession from "../useRobotForTeleopSession";
import ParkIdleRobotActionSnackbarView from "./ParkIdleRobotView";

export interface DeliveryStageActionSnackbarProps {}

const DeliveryStageActionSnackbar: React.FC<
  DeliveryStageActionSnackbarProps
> = () => {
  const activeOrder = useOrderForTeleopSession();
  const robot = useRobotForTeleopSession();
  switch (activeOrder.data?.current_stage.kind) {
    case "order-queued":
      return <LeftForPickUpActionSnackbar />;
    case "robot-en-route-to-pick-up":
      return <ArrivedAtPickUpActionSnackbar />;
    case "robot-waiting-for-pick-up":
      return <OrderPickedUpActionSnackbar />;
    case "order-picked-up":
      return <LeftForDropOffActionSnackbar />;
    case "robot-en-route-to-drop-off":
      return <ArrivedAtDropOffActionSnackbar />;
    case "robot-waiting-for-drop-off":
      return <OrderDroppedOffActionSnackbar />;
    case undefined:
      if (robot.data) {
        return <ParkIdleRobotActionSnackbarView robot={robot.data} />;
      } else {
        return <></>;
      }
    default:
      return <></>;
  }
};

export default DeliveryStageActionSnackbar;
