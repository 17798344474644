import { useMutation } from "react-query";
import useAxios from "services/axios/useAxios";

export const useRegisterFCMBinding = () => {
  const axios = useAxios("notifications");
  return useMutation((registrationToken: string) =>
    axios.post("/api/web-apps/pilot-ui/bindings/fcm", {
      registration_token: registrationToken,
    }),
  );
};
