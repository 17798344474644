import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "components/common/Link";
import { useRobot } from "fetchers/robots/robots";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useUser } from "fetchers/auth/users";

export interface AssignedPilotProps {
  order: UberEatsOrder;
}

const AssignedPilot: React.FC<AssignedPilotProps> = ({ order }) => {
  const robot = useRobot(order.assigned_robot_id);
  const user = useUser(robot.data?.assigned_pilot_uuid);
  if (order.assigned_robot_id) {
    if (user.data) {
      return (
        <Link to={`/users/${user.data.uuid}`}>
          <Typography noWrap>{user.data.first_name}</Typography>
        </Link>
      );
    } else if (robot.data) {
      if (!robot.data.assigned_pilot_uuid) {
        return <>-</>;
      } else {
        return (
          <Link to={`/users/${robot.data.assigned_pilot_uuid}`}>
            <Typography noWrap>{robot.data.assigned_pilot_uuid}</Typography>
          </Link>
        );
      }
    } else {
      return <CircularProgress size={20} />;
    }
  } else {
    return <>-</>;
  }
};

export default AssignedPilot;
