import React from "react";

import Menu from "@material-ui/core/Menu";

export interface DeliveryOrderRowMenuContainerProps {
  anchorEl: null | Element;
  open: boolean;
  onClose: () => void;
}

const DeliveryOrderRowMenuContainer: React.FC<
  DeliveryOrderRowMenuContainerProps
> = ({ anchorEl, open, children, onClose }) => {
  return (
    <Menu
      id="order-row-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{ style: { width: "35ch" } }}
    >
      {children}
    </Menu>
  );
};

export default DeliveryOrderRowMenuContainer;
