import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import ParkingSpotWizard from "../ParkingSpotWizard";

export interface ParkingSpotWizardDialogProps {
  robotID: string;
  onSubmit: () => void;
  onSubmissionError?: (error: { message: string }) => void;
  isOpen: boolean;
  onClose: () => void;
}

const ParkingSpotWizardDialog: React.FC<ParkingSpotWizardDialogProps> = ({
  robotID,
  onSubmit,
  onSubmissionError = () => {},
  isOpen,
  onClose,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <Box padding={3} minWidth="md">
        <ParkingSpotWizard
          robotID={robotID}
          onSubmit={onSubmit}
          onSubmissionError={onSubmissionError}
        />
      </Box>
    </Dialog>
  );
};

export default ParkingSpotWizardDialog;
