import React, { useState } from "react";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import Switch from "@material-ui/core/Switch";
import {
  useFinishRescue,
  useStartRescue,
} from "mutations/deliveries/uber-eats-orders";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import DialogWithTriggerButton from "components/common/DialogWithTriggerButton";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import OrderRescueReasonField from "../OrderRescueReason";

export interface OrderRescueSwitchProps {
  order: UberEatsOrder;
}

const OrderRescueSwitch: React.FC<OrderRescueSwitchProps> = ({ order }) => {
  const { showAlert } = useAlertMessage();
  const startRescue = useStartRescue();
  const finishRescue = useFinishRescue();
  const [rescueReason, setRescueReason] = useState(order.rescue_reason || "");

  const handleStartRescue = () => {
    startRescue
      .mutateAsync({ orderUUID: order.uuid, rescueReason: rescueReason })
      .then(() => {
        showAlert({
          message: `Rescue for order was dispatched successfully`,
          severity: "info",
          autoCloseAfterMillis: 2000,
        });
      })
      .catch(({ message }) => {
        showAlert({
          message: message || "Unknown error",
          severity: "error",
          autoCloseAfterMillis: 5000,
        });
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.checked) {
      finishRescue
        .mutateAsync(order.uuid)
        .then(() => {
          showAlert({
            message: `Rescue for order was completed successfully`,
            severity: "info",
            autoCloseAfterMillis: 2000,
          });
        })
        .catch(({ message }) => {
          showAlert({
            message: message || "Unknown error",
            severity: "error",
            autoCloseAfterMillis: 5000,
          });
        });
    }
  };

  if (!order.is_under_rescue) {
    return (
      <DialogWithTriggerButton
        button={(props) => (
          <Switch
            {...props}
            checked={order.is_under_rescue}
            color="primary"
            disabled={startRescue.isLoading || finishRescue.isLoading}
          />
        )}
      >
        {(props) => (
          <>
            <DialogTitle>Rescue Reason</DialogTitle>
            <DialogContent>
              <DialogContentText>
                What is the reason for this rescue?
              </DialogContentText>
              <OrderRescueReasonField
                initValue={rescueReason}
                handleChange={setRescueReason}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleStartRescue}>Confirm</Button>
              <Button onClick={() => props.setOpen(false)}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </DialogWithTriggerButton>
    );
  }
  return (
    <Switch
      checked={order.is_under_rescue}
      onChange={handleChange}
      color="primary"
      disabled={startRescue.isLoading || finishRescue.isLoading}
    />
  );
};

export default OrderRescueSwitch;
