import React from "react";
import { useField } from "formik";
import Restaurant from "types/deliveries/Restaurant";
import RestaurantAutocompleteFieldView from "./View";

export interface RestaurantAutocompleteFieldProps {
  autoFocus?: boolean;
  required?: boolean;
  label?: string;
  name: string;
}

const RestaurantAutocompleteField: React.FC<
  RestaurantAutocompleteFieldProps
> = ({
  autoFocus = false,
  required = false,
  label = "Choose a Restaurant",
  name,
}) => {
  const [field, , helpers] = useField<null | Restaurant>(name);
  return (
    <RestaurantAutocompleteFieldView
      {...field}
      onChange={(_, newVal) => {
        helpers.setValue(newVal);
      }}
      onBlur={() => helpers.setTouched(true)}
      autoFocus={autoFocus}
      required={required}
      label={label}
    />
  );
};

export default RestaurantAutocompleteField;
