import * as THREE from "three";

export enum Projection {
  Equirectangular,
  Gnomonic,
  Stereographic,
}

class EquirectangularMaterial extends THREE.RawShaderMaterial {
  constructor() {
    super({
      uniforms: {
        cube: { value: null },
        video: { value: null },
        rotation: { value: new THREE.Vector3(0, 0, 0) },
        projection: { value: 0 },
        angle: { value: 0.0 },
        height: { value: 0.0 },
      },
      vertexShader: `
      attribute vec3 position;
      attribute vec2 uv;
      uniform mat4 projectionMatrix;
      uniform mat4 modelViewMatrix;
      varying vec2 vUv;
      void main()  {
        vUv = vec2(uv.x, uv.y);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }`,
      fragmentShader: `
      precision mediump float;
      uniform samplerCube cube;
      uniform sampler2D video;
      uniform vec3 rotation;
      uniform int projection;
      uniform float angle;
      uniform float height;
      varying vec2 vUv;
      const float PI = 3.1415926536;
      const float PI_2 = PI * 0.5;
      const float PI2 = PI * 2.0;
      float localRadius = 0.15;


        // Convert latitude, longitude into a 3d point on he unit-sphere.
        // Create a point on the unit-sphere from the latitude and longitude
        // X increases from left to right [-1 to 1]
        // Y increases from bottom to top [-1 to 1]
        // Z increases from back to front [-1 to 1]
        vec3 latLonToPoint(vec2 latLon)
        {
            vec3 point;
            latLon.x -= PI_2;
            point.x = cos(latLon.x) * sin(latLon.y);
            point.y = sin(latLon.x);
            point.z = cos(latLon.x) * cos(latLon.y);
            return point;
        }

        // A transformation matrix rotating about the x axis by th degrees.
        mat3 Rx(float th) {
            return mat3(1, 0, 0,
                0, cos(th), -sin(th),
                0, sin(th), cos(th));
        }

        // A transformation matrix rotating about the y axis by th degrees.
        mat3 Ry(float th)
        {
            return mat3(cos(th), 0, sin(th),
                0,    1,    0,
               -sin(th), 0, cos(th));
        }

        // A transformation matrix rotating about the z axis by th degrees.
        mat3 Rz(float th)
        {
            return mat3(cos(th), -sin(th), 0,
                        sin(th),  cos(th), 0,
                          0,         0   , 1);
        }

        // Rotate a point vector by th.x then th.y then th.z, and return the rotated point.
        vec3 rotatePoint(vec3 p, vec3 th)
        {
            return Rx(th.r) * Ry(th.g) * Rz(th.b) * p;
        }

        // Convert a 3D point on the unit sphere into latitude and longitude.
        vec2 pointToLatLon(vec3 point)
        {
            vec2 latLon;
            latLon.x = asin(point.y) + PI_2;
            latLon.y = atan(point.x, point.z);
            return latLon;
        }

        // Convert x, y pixel coordinates from an Equirectangular image into latitude/longitude coordinates.
        vec2 uvToLatLon(vec2 uv)
        {
            return vec2(uv.y * PI, //vertical [0, PI]
                        uv.x * 2.0* PI); //horizontal [0, 2PI]
        }

        // Convert latitude, longitude to x, y pixel coordinates on an equirectangular image.


        // Main function, convert screen coordinate system to spherical coordinates in stereographic / gnomonic projection
        // screenCoord: [0, 1], centralPoint: [0, 1], FoVScale: vec2(0.9, 0.2) recommended, localRadius
        vec2 calcSphericalCoordsFromProjections(in vec2 screenCoord, in vec2 centralPoint, in vec2 FoVScale, in bool stereographic) {
            vec2 cp = (centralPoint * 2.0 - 1.0) * vec2(PI, PI_2);  // horizontal [-PI, PI], vertical [-PI_2, PI_2]

            // Convert screen coord in gnomonic mapping to spherical coord in [PI, PI/2]
            vec2 convertedScreenCoord = (screenCoord * 2.0 - 1.0) * FoVScale * vec2(PI, PI_2);
            float x = convertedScreenCoord.x, y = convertedScreenCoord.y;

            float rou = sqrt(x * x + y * y), c = stereographic ? 2.0 * atan(rou / localRadius / 2.0) : atan(rou);
            float sin_c = sin( c ), cos_c = cos( c );

            float lat = asin(cos_c * sin(cp.y) + (y * sin_c * cos(cp.y)) / rou);
            float lon = cp.x + atan(x * sin_c, rou * cos(cp.y) * cos_c - y * sin(cp.y) * sin_c);

            lat = (lat / PI_2 + 1.0) * 0.5; lon = (lon / PI + 1.0) * 0.5; //[0, 1]

            // uncomment the following if centralPoint ranges out of [0, PI/2] [0, PI]
            lon -= 1.0 * float(lon > 1.0);  lon += 1.0 * float(lon < 0.0);
            lat -= 1.0 * float(lat > 1.0);  lat += 1.0 * float(lat < 0.0);

            // convert spherical coord to cubemap coord
           return  vec2(lat * PI, lon * PI2);
        }

        vec2 calcSphericalCoordsInStereographicProjection(in vec2 screenCoord, in vec2 centralPoint, in vec2 FoVScale) {
            return calcSphericalCoordsFromProjections(screenCoord, centralPoint, FoVScale, true);
        }

        vec2 calcSphericalCoordsInGnomonicProjection(in vec2 screenCoord, in vec2 centralPoint, in vec2 FoVScale) {
            return calcSphericalCoordsFromProjections(screenCoord, centralPoint, FoVScale, false);
        }


        vec3 latLonToCubeDir(vec2 latLon) {
            vec3 dir = vec3(sin(latLon.x) * -sin(latLon.y),
                            cos(latLon.x),
                            sin(latLon.x) * -cos(latLon.y));
            normalize(dir);
            return dir;
        }

        vec2 latLonToUv(vec2 latLon)
        {
            vec2 uv;
            uv.y = fract(latLon.x / PI);
            uv.x = fract(latLon.y / PI2);
            return uv;
        }

        void main() {
            // Latitude refers to the vertical measurement and spans from [-PI_2, PI_2]
            // Longitude refers to the horizontal measurement and spans from [0, 2*PI]
            vec2 latLon;
            vec2 latLon2;
            if (projection == ${Projection.Equirectangular}) {
                latLon = uvToLatLon(vUv);
                vec3 point = latLonToPoint(latLon);
                point = rotatePoint(point, vec3(0.0, angle, 0.0) * PI);
                latLon = pointToLatLon(point);
                latLon2 = latLon;
            } else if (projection == ${Projection.Gnomonic}) {
                vec2 FoVScale = vec2(2.5, 2.5);
                vec2 centralPoint = vec2(0.5, 0.0);
                latLon = calcSphericalCoordsInGnomonicProjection(vUv, centralPoint, FoVScale);
                FoVScale = vec2(0.25, 0.25) * height;
                latLon2 = calcSphericalCoordsInGnomonicProjection(vUv, centralPoint, FoVScale);
            } else if (projection == ${Projection.Stereographic}) {
                vec2 FoVScale = vec2(0.5, 0.5) * 0.25;
                vec2 centralPoint = vec2(0.5 -angle/ 2.0, 0.3);
                latLon = calcSphericalCoordsInStereographicProjection(vUv, centralPoint, FoVScale);
                latLon2 = latLon;
            }

            vec2 sourcePixel = latLonToUv(latLon);
            vec4 videoColor = texture2D(video, sourcePixel);
            vec3 dir = latLonToCubeDir(latLon2);
            vec4 sceneColor = textureCube(cube, dir);
            sceneColor.a *= 0.0; // add transparency
            if (projection == ${Projection.Gnomonic}) {
                float opacity = smoothstep(0.6/height, 3.0/height, distance(vec2(vUv.x*2.0-0.5,vUv.y),vec2(0.5)));
                videoColor.a = 1.0 - opacity;
                sceneColor.a = max(0.2, opacity); // add transparency
            }
            gl_FragColor = mix(videoColor, sceneColor, sceneColor.a);

      }`,
      side: THREE.DoubleSide,
      transparent: true,
    });
  }
}

export default EquirectangularMaterial;
