import React from "react";

import Button, { ButtonProps } from "@material-ui/core/Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export type DataGridActionsButtonProps = Pick<
  ButtonProps,
  "onClick" | "size" | "disabled"
>;

const DataGridActionsButton: React.FC<DataGridActionsButtonProps> = (props) => {
  return (
    <Button {...props}>
      <MoreVertIcon />
    </Button>
  );
};

export default DataGridActionsButton;
