import React, { useState, useEffect, useCallback } from "react";
import IconButton from "@material-ui/core/IconButton";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import { useGoogleMap } from "@react-google-maps/api";
import Geocode from "types/Location/Geocode";

export interface MapFixatorProps {
  position: Geocode;
}

const MapFixator: React.FC<MapFixatorProps> = ({ position }) => {
  const [isFixed, setFixed] = useState(true);
  const dragStartCallback = useCallback(() => {
    setFixed(false);
  }, []);

  const map = useGoogleMap();

  useEffect(() => {
    if (map) {
      const listener = map.addListener("dragstart", dragStartCallback);
      return () => {
        listener.remove();
      };
    }
  }, [map, dragStartCallback]);

  useEffect(() => {
    if (map && position && isFixed) {
      map.panTo(position);
    }
  }, [map, isFixed, position]);

  if (isFixed) {
    return <></>;
  } else {
    return (
      <IconButton onClick={() => setFixed(true)}>
        <GpsFixedIcon />
      </IconButton>
    );
  }
};

export default MapFixator;
