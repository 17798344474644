import React, { useState } from "react";
import Link from "@material-ui/core/Link";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

export interface OrderRescueLinkProps {
  orderUUID: string;
}

const OrderRescueLink: React.FC<OrderRescueLinkProps> = ({ orderUUID }) => {
  const rescueURL = `${window.location.origin}/rescue-order/${orderUUID}`;
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const onCopy = async () => {
    await navigator.clipboard.writeText(rescueURL);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 1000);
  };
  return (
    <>
      <Link href={rescueURL} color="textPrimary">
        {rescueURL}
      </Link>
      <Tooltip
        title="Copied!"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={isTooltipOpen}
        onClose={() => setTooltipOpen(false)}
      >
        <IconButton onClick={onCopy}>
          <FileCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default OrderRescueLink;
