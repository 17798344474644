import create from "zustand";
import { AlertOptions } from "./model";

export type AlertMessageState = {
  options: AlertOptions;
  isOpen: boolean;
  dismissed: boolean;
};

export type AlertMessageAPI = {
  showAlert: (options: AlertOptions) => void;
  reopen: () => void;
  dismiss: () => void;
};

export const useAlertMessageStore = create<AlertMessageState & AlertMessageAPI>(
  (set) => ({
    options: {
      message: "",
      showLoading: false,
    },
    isOpen: false,
    dismissed: false,
    showAlert: (options: AlertOptions) => set({ options, isOpen: true }),
    reopen: () => set({ isOpen: true }),
    dismiss: () => set({ isOpen: false }),
  }),
);
