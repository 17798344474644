import React, { useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import { useUser } from "fetchers/auth/users";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import ViewUserView from "./View";

export interface ViewUserProps {
  userUUID: string;
}

const ViewUser: React.FC<ViewUserProps> = ({ userUUID }) => {
  const user = useUser(userUUID);
  const { showAlert } = useAlertMessage();

  useEffect(() => {
    if (user.isError) {
      showAlert({
        message: "Error when trying to fetch user data.",
        severity: "error",
      });
    }
  }, [user.isError, showAlert]);

  if (user.isLoading) {
    return <CircularProgress />;
  } else if (user.isError) {
    return <></>;
  } else {
    return <ViewUserView user={user.data!} />;
  }
};

export default ViewUser;
