import { useRobotArrivedAtDropOff } from "mutations/deliveries/uber-eats-orders";
import React from "react";
import { useActionConfirmationDialog } from "components/common/ActionConfirmationDialog";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import { ButtonProps } from "@material-ui/core/Button";
import LoadableButton from "components/common/LoadableButton";

export interface ArrivedAtDropOffActionButtonProps
  extends Omit<ButtonProps, "href" | "onClick"> {
  assignedRobotID: string;
  isLoading?: boolean;
}

const ArrivedAtDropOffActionButton: React.FC<
  ArrivedAtDropOffActionButtonProps
> = ({
  children = "I've arrived at drop-off",
  assignedRobotID,
  isLoading = false,
  ...buttonProps
}) => {
  const robotArrivedAtDropOffEvent = useRobotArrivedAtDropOff(assignedRobotID);
  const actionConfirmation = useActionConfirmationDialog();
  const { showAlert } = useAlertMessage();

  const handleClick = () => {
    actionConfirmation.open({
      confirmationText:
        "Remember to confirm the drop-off location. Check Street View if you are unsure.",
      onConfirm: () => {
        robotArrivedAtDropOffEvent
          .mutateAsync()
          .then(() => {
            actionConfirmation.dismiss();
            showAlert({
              message:
                "Arrival confirmed. Wait for the customer to identify themselves",
              severity: "success",
            });
          })
          .catch(({ message }) => {
            showAlert({
              message: message || "Unknown error",
              severity: "error",
              autoCloseAfterMillis: 5000,
            });
          });
      },
    });
  };

  return (
    <LoadableButton
      onClick={handleClick}
      isLoading={isLoading}
      disabled={isLoading || robotArrivedAtDropOffEvent.isLoading}
      {...buttonProps}
    >
      {children}
    </LoadableButton>
  );
};

export default ArrivedAtDropOffActionButton;
