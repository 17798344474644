import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useSetUberEatsOrderDropOffNotes } from "mutations/deliveries/uber-eats-orders";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";

export interface DropOffNotesAutoSaveFieldProps {
  orderUUID: string;
  currentDropOffNotes: string;
  isEditable: boolean;
}

const DropOffNotesAutoSaveField: React.FC<DropOffNotesAutoSaveFieldProps> = ({
  orderUUID,
  currentDropOffNotes,
  isEditable,
}) => {
  const [dropOffNotes, setDropOffNotes] = useState(currentDropOffNotes);
  const { showAlert } = useAlertMessage();
  const setOrderDropOffNotes = useSetUberEatsOrderDropOffNotes();

  const handleSubmit = () => {
    if (currentDropOffNotes !== dropOffNotes) {
      setOrderDropOffNotes
        .mutateAsync({ orderUUID, dropOffNotes })
        .then((order) => {
          setDropOffNotes(order.drop_off_notes);
          showAlert({
            message: "Drop off notes updated successfully",
            severity: "success",
          });
        })
        .catch(({ message }) => {
          setDropOffNotes(currentDropOffNotes);
          showAlert({
            message: message || "Unknown error",
            severity: "error",
            autoCloseAfterMillis: 5000,
          });
        });
    }
  };
  return (
    <Box lineHeight={1}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextField
          value={dropOffNotes || ""}
          onChange={(e) => setDropOffNotes(e.currentTarget.value)}
          onBlur={handleSubmit}
          variant="outlined"
          label="Drop Off Notes"
          disabled={!isEditable || setOrderDropOffNotes.isLoading}
          fullWidth
        />
      </form>
    </Box>
  );
};

export default DropOffNotesAutoSaveField;
