import React from "react";

import DialogWithTriggerButton from "components/common/DialogWithTriggerButton";
import DialogContent from "@material-ui/core/DialogContent";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import RestaurantCreationForm from "../RestaurantCreationForm";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

export type RestaurantsDashboardActionsProps = {};

const RestaurantsDashboardActions: React.FC<
  RestaurantsDashboardActionsProps
> = () => {
  const { showAlert } = useAlertMessage();

  const onSubmit = (setOpen: (isOpen: boolean) => void) => {
    setOpen(false);
    showAlert({
      message: "Restaurant created!",
      severity: "success",
    });
  };

  const onSubmissionError = (error: { message?: string }) => {
    showAlert({
      message: error.message || "Unknown error.",
      severity: "error",
      autoCloseAfterMillis: 2000,
    });
  };

  return (
    <Box textAlign="right">
      <DialogWithTriggerButton
        button={(props) => (
          <Button {...props} variant="contained" color="primary">
            {" "}
            Add{" "}
          </Button>
        )}
        dialogMaxWidth="md"
      >
        {(props) => (
          <DialogContent>
            <Box width="700px">
              <RestaurantCreationForm
                onSubmit={() => onSubmit(props.setOpen)}
                onSubmissionError={onSubmissionError}
              />
            </Box>
          </DialogContent>
        )}
      </DialogWithTriggerButton>
    </Box>
  );
};

export default RestaurantsDashboardActions;
