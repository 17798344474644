import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { StreetViewPanorama } from "@react-google-maps/api";

import MapBox from "components/Map/MapBox";

import TeleopMapRobotFixator from "./RobotFixator";
import MapGamepad from "./Gamepad";

export interface TeleopMapProps {
  robotID: string;
}

const TeleopMap: React.FC<TeleopMapProps> = ({ robotID, children }) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const [isStreetViewVisible, setStreetViewVisible] = useState(false);

  useHotkeys("m", () => {
    setFullScreen((s) => !s);
  });

  useHotkeys("s", () => {
    setStreetViewVisible((s) => !s);
  });

  const dimensions = isFullScreen ? FULL_SCREEN_DIMENSIONS : WIDGET_DIMENSIONS;

  return (
    <MapBox {...dimensions}>
      <TeleopMapRobotFixator robotID={robotID} />
      <MapGamepad toggleFullScreen={() => setFullScreen((s) => !s)} />
      {isStreetViewVisible ? <StreetViewPanorama /> : null}
      {children}
    </MapBox>
  );
};

const FULL_SCREEN_DIMENSIONS = { width: "100vw", height: "100vh" };
const WIDGET_DIMENSIONS = { width: "300px", height: "300px" };

export default TeleopMap;
