import { useCallback } from "react";
import { useIdentityStore } from "./store";

export const useCanAccess = () => {
  const userRoles = useIdentityStore((state) => state.user?.roles || []);
  return useCallback(
    (requiredRoles: string[]): boolean =>
      roleListsIntersect(userRoles, requiredRoles),
    [userRoles],
  );
};

export const useUserHasRoles = (roles: string[]) => {
  return useIdentityStore(
    ({ user }) => user && roleListsIntersect(user.roles, roles),
  );
};

const roleListsIntersect = (lhs: string[], rhs: string[]): boolean => {
  for (const role of lhs) {
    if (rhs.includes(role)) {
      return true;
    }
  }
  return false;
};
