import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "fixed",
      display: "flex",
      padding: theme.spacing(1),
      zIndex: theme.zIndex.drawer + 25,
      alignContent: "center",
    },
    opaqueContainer: {
      opacity: "8%",
      "&:hover": {
        opacity: "100%",
      },
    },
    verticalTop: {
      top: 0,
    },
    verticalCenter: {
      height: "100%",
      alignItems: "center",
    },
    verticalBottom: {
      bottom: 0,
    },
    horizontalLeft: {
      left: 0,
    },
    horizontalCenter: {
      width: "100%",
      justifyContent: "center",
    },
    horizontalRight: {
      right: 0,
    },
  }),
);
