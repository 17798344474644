import { useUsersQuery } from "fetchers/auth/users";
import { UseQueryOptions } from "react-query";
import User from "types/auth/User";

const usePilots = (queryOptions?: UseQueryOptions<User[]>) =>
  useUsersQuery({
    ...queryOptions,
    select: (users) => users.filter((user) => user.roles.includes("Pilot")),
  });

export default usePilots;
