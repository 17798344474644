import Restaurant from "types/deliveries/Restaurant";

export default interface OrderInformationFieldData {
  recipient_name: string;
  restaurant: null | Restaurant;
  external_id: string;
  is_mock: boolean;
}

export const emptyOrderInformationField: OrderInformationFieldData = {
  recipient_name: "",
  restaurant: null,
  external_id: "",
  is_mock: false,
};
