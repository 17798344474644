import React, { useEffect, useRef, useState } from "react";
import ARDirections from "./ARDirections";
import Polygon from "./polygon";
import * as THREE from "three";
import { ARState } from "./arstate";
import { MAPS_URL } from "config/constants";
import { centerUniverse } from "./coords";
import { useFrame } from "@react-three/fiber";
import { Localization } from "./localization";

interface Props {
  robotID: string;
}

const Sidewalks: React.FC<Props> = ({ robotID }) => {
  /* Only necessary when we have odometry information
   const [lastPosition, setLastPosition] = useState(null);
      useEffect(() => {
        if (lastPosition == null) {
          setLastPosition(position);
          return;
        }
        var angle = ARState.getState().angle;
        let x = Math.cos(angle);
        let y = Math.sin(angle);
        let forward = Math.abs(lastPosition.x - position.x);
        let lateral = lastPosition.y - position.y;
        moveRobot(new THREE.Vector3(x * forward, y * forward, 0));
        setLastPosition(position);
    }, []);
    */

  const [sidewalksId, setSidewalksId] = useState([]);

  const getVisible = async () => {
    const pos = ARState.getState().worldPosition;
    const response = await fetch(`${MAPS_URL}/visible`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        xmin: -pos[0] + centerUniverse.x - 150,
        ymin: -pos[1] + centerUniverse.y - 150,
        xmax: -pos[0] + centerUniverse.x + 150,
        ymax: -pos[1] + centerUniverse.y + 150,
      }),
    });
    const r = await response.json();
    setSidewalksId(r.sort());
  };

  useEffect(() => {
    getVisible();
    (async () => {
      const interval = setInterval(getVisible, 1 * 5000);
      return () => clearInterval(interval);
    })();
  }, []);

  const ref = useRef<THREE.Group>();
  useFrame(() => {
    const pos = ARState.getState().worldPosition;
    ref?.current?.position.set(pos[0], pos[1], pos[2]);
  });

  return (
    <group ref={ref}>
      {sidewalksId.map((value, _) => {
        return <Polygon key={value} url={`${MAPS_URL}/sidewalk/${value}`} />;
      })}
      <ARDirections robotID={robotID} />
      <Localization robotID={robotID} />
    </group>
  );
};

export default Sidewalks;
