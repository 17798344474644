import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import RestaurantSummary from "../RestaurantSummary";
import DataPreviewEntry from "components/common/DataPreviewEntry";
import UberEatsOrderTimelineView from "../UberEatsOrderTimeline/View";

export interface UberEatsOrderSummaryViewProps {
  order: UberEatsOrder;
}

const UberEatsOrderSummaryView: React.FC<UberEatsOrderSummaryViewProps> = ({
  order,
}) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <DataPreviewEntry label="Order ID" value={order.external_id} />
          </Grid>
          <Grid item>
            <DataPreviewEntry
              label="Current Stage"
              value={order.current_stage.kind}
            />
          </Grid>
          <Grid item>
            <DataPreviewEntry
              label="Is Mock"
              value={order.is_mock ? "Yes" : "No"}
            />
          </Grid>
          <Grid item>
            <DataPreviewEntry
              label="Assigned Robot"
              value={order.assigned_robot_id}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">Restaurant</Typography>
          </Grid>
          <Grid item>
            <RestaurantSummary
              direction="column"
              restaurantUUID={order.restaurant_uuid}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle1">Delivery details</Typography>
          </Grid>
          <Grid item>
            <DataPreviewEntry
              label="Customer name"
              value={order.recipient.name}
            />
          </Grid>
          <Grid item>
            <DataPreviewEntry
              label="Customer phone number"
              value={order.recipient.phone_number}
            />
          </Grid>
          <Grid item>
            <DataPreviewEntry
              label="Delivery Address"
              value={order.recipient.address_description.address_line_1}
            />
          </Grid>
          <Grid item>
            <DataPreviewEntry
              label="Delivery Address complement"
              value={order.recipient.address_description.address_line_2}
            />
          </Grid>
          <Grid item>
            <DataPreviewEntry
              label="Drop-off Notes"
              value={order.drop_off_notes}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <UberEatsOrderTimelineView order={order} />
      </Grid>
    </Grid>
  );
};

export default UberEatsOrderSummaryView;
