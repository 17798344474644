import { useRobotLeftForPickUp } from "mutations/deliveries/uber-eats-orders";
import React from "react";
import { useActionConfirmationDialog } from "components/common/ActionConfirmationDialog";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import { ButtonProps } from "@material-ui/core/Button";
import LoadableButton from "components/common/LoadableButton";

export interface LeftForPickUpActionButtonProps
  extends Omit<ButtonProps, "href" | "onClick"> {
  assignedRobotID: string;
  isLoading?: boolean;
}

const LeftForPickUpActionButton: React.FC<LeftForPickUpActionButtonProps> = ({
  children = "I'm on my way to pick-up",
  assignedRobotID,
  isLoading = false,
  ...buttonProps
}) => {
  const robotLeftForPickUpEvent = useRobotLeftForPickUp(assignedRobotID);
  const actionConfirmation = useActionConfirmationDialog();
  const { showAlert } = useAlertMessage();

  const handleClick = () => {
    actionConfirmation.open({
      confirmationText:
        "Remember to confirm the pick-up location. Check Street View if you are unsure.",
      onConfirm: () => {
        robotLeftForPickUpEvent
          .mutateAsync()
          .then(() => {
            actionConfirmation.dismiss();
            showAlert({
              message:
                "Have a nice trip! Let us know when you arrive at the pick up location",
              severity: "success",
            });
          })
          .catch(({ message }) => {
            showAlert({
              message: message || "Unknown error",
              severity: "error",
              autoCloseAfterMillis: 5000,
            });
          });
      },
    });
  };

  return (
    <LoadableButton
      isLoading={isLoading}
      onClick={handleClick}
      disabled={isLoading || robotLeftForPickUpEvent.isLoading}
      {...buttonProps}
    >
      {children}
    </LoadableButton>
  );
};

export default LeftForPickUpActionButton;
