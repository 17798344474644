export const jet = [
  [0.0, [0.0, 0.0, 0.5]],
  [0.002, [0.0, 0.0, 0.5]],
  [0.004, [0.0, 0.0, 0.518]],
  [0.006, [0.0, 0.0, 0.518]],
  [0.008, [0.0, 0.0, 0.536]],
  [0.01, [0.0, 0.0, 0.536]],
  [0.012, [0.0, 0.0, 0.553]],
  [0.014, [0.0, 0.0, 0.553]],
  [0.016, [0.0, 0.0, 0.571]],
  [0.018, [0.0, 0.0, 0.571]],
  [0.02, [0.0, 0.0, 0.589]],
  [0.022, [0.0, 0.0, 0.589]],
  [0.023, [0.0, 0.0, 0.607]],
  [0.025, [0.0, 0.0, 0.607]],
  [0.027, [0.0, 0.0, 0.625]],
  [0.029, [0.0, 0.0, 0.625]],
  [0.031, [0.0, 0.0, 0.643]],
  [0.033, [0.0, 0.0, 0.643]],
  [0.035, [0.0, 0.0, 0.66]],
  [0.037, [0.0, 0.0, 0.66]],
  [0.039, [0.0, 0.0, 0.678]],
  [0.041, [0.0, 0.0, 0.678]],
  [0.043, [0.0, 0.0, 0.696]],
  [0.045, [0.0, 0.0, 0.696]],
  [0.047, [0.0, 0.0, 0.714]],
  [0.049, [0.0, 0.0, 0.714]],
  [0.051, [0.0, 0.0, 0.732]],
  [0.053, [0.0, 0.0, 0.732]],
  [0.055, [0.0, 0.0, 0.75]],
  [0.057, [0.0, 0.0, 0.75]],
  [0.059, [0.0, 0.0, 0.767]],
  [0.061, [0.0, 0.0, 0.767]],
  [0.063, [0.0, 0.0, 0.785]],
  [0.065, [0.0, 0.0, 0.785]],
  [0.067, [0.0, 0.0, 0.803]],
  [0.068, [0.0, 0.0, 0.803]],
  [0.07, [0.0, 0.0, 0.821]],
  [0.072, [0.0, 0.0, 0.821]],
  [0.074, [0.0, 0.0, 0.839]],
  [0.076, [0.0, 0.0, 0.839]],
  [0.078, [0.0, 0.0, 0.857]],
  [0.08, [0.0, 0.0, 0.857]],
  [0.082, [0.0, 0.0, 0.874]],
  [0.084, [0.0, 0.0, 0.874]],
  [0.086, [0.0, 0.0, 0.892]],
  [0.088, [0.0, 0.0, 0.892]],
  [0.09, [0.0, 0.0, 0.91]],
  [0.092, [0.0, 0.0, 0.91]],
  [0.094, [0.0, 0.0, 0.928]],
  [0.096, [0.0, 0.0, 0.928]],
  [0.098, [0.0, 0.0, 0.946]],
  [0.1, [0.0, 0.0, 0.946]],
  [0.102, [0.0, 0.0, 0.963]],
  [0.104, [0.0, 0.0, 0.963]],
  [0.106, [0.0, 0.0, 0.981]],
  [0.108, [0.0, 0.0, 0.981]],
  [0.11, [0.0, 0.0, 0.999]],
  [0.112, [0.0, 0.0, 0.999]],
  [0.114, [0.0, 0.0, 1.0]],
  [0.115, [0.0, 0.0, 1.0]],
  [0.117, [0.0, 0.0, 1.0]],
  [0.119, [0.0, 0.0, 1.0]],
  [0.121, [0.0, 0.0, 1.0]],
  [0.123, [0.0, 0.0, 1.0]],
  [0.125, [0.0, 0.002, 1.0]],
  [0.127, [0.0, 0.002, 1.0]],
  [0.129, [0.0, 0.018, 1.0]],
  [0.131, [0.0, 0.018, 1.0]],
  [0.133, [0.0, 0.033, 1.0]],
  [0.135, [0.0, 0.033, 1.0]],
  [0.137, [0.0, 0.049, 1.0]],
  [0.139, [0.0, 0.049, 1.0]],
  [0.141, [0.0, 0.065, 1.0]],
  [0.143, [0.0, 0.065, 1.0]],
  [0.145, [0.0, 0.08, 1.0]],
  [0.147, [0.0, 0.08, 1.0]],
  [0.149, [0.0, 0.096, 1.0]],
  [0.151, [0.0, 0.096, 1.0]],
  [0.153, [0.0, 0.112, 1.0]],
  [0.155, [0.0, 0.112, 1.0]],
  [0.157, [0.0, 0.127, 1.0]],
  [0.159, [0.0, 0.127, 1.0]],
  [0.16, [0.0, 0.143, 1.0]],
  [0.162, [0.0, 0.143, 1.0]],
  [0.164, [0.0, 0.159, 1.0]],
  [0.166, [0.0, 0.159, 1.0]],
  [0.168, [0.0, 0.175, 1.0]],
  [0.17, [0.0, 0.175, 1.0]],
  [0.172, [0.0, 0.19, 1.0]],
  [0.174, [0.0, 0.19, 1.0]],
  [0.176, [0.0, 0.206, 1.0]],
  [0.178, [0.0, 0.206, 1.0]],
  [0.18, [0.0, 0.222, 1.0]],
  [0.182, [0.0, 0.222, 1.0]],
  [0.184, [0.0, 0.237, 1.0]],
  [0.186, [0.0, 0.237, 1.0]],
  [0.188, [0.0, 0.253, 1.0]],
  [0.19, [0.0, 0.253, 1.0]],
  [0.192, [0.0, 0.269, 1.0]],
  [0.194, [0.0, 0.269, 1.0]],
  [0.196, [0.0, 0.284, 1.0]],
  [0.198, [0.0, 0.284, 1.0]],
  [0.2, [0.0, 0.3, 1.0]],
  [0.202, [0.0, 0.3, 1.0]],
  [0.204, [0.0, 0.316, 1.0]],
  [0.205, [0.0, 0.316, 1.0]],
  [0.207, [0.0, 0.331, 1.0]],
  [0.209, [0.0, 0.331, 1.0]],
  [0.211, [0.0, 0.347, 1.0]],
  [0.213, [0.0, 0.347, 1.0]],
  [0.215, [0.0, 0.363, 1.0]],
  [0.217, [0.0, 0.363, 1.0]],
  [0.219, [0.0, 0.378, 1.0]],
  [0.221, [0.0, 0.378, 1.0]],
  [0.223, [0.0, 0.394, 1.0]],
  [0.225, [0.0, 0.394, 1.0]],
  [0.227, [0.0, 0.41, 1.0]],
  [0.229, [0.0, 0.41, 1.0]],
  [0.231, [0.0, 0.425, 1.0]],
  [0.233, [0.0, 0.425, 1.0]],
  [0.235, [0.0, 0.441, 1.0]],
  [0.237, [0.0, 0.441, 1.0]],
  [0.239, [0.0, 0.457, 1.0]],
  [0.241, [0.0, 0.457, 1.0]],
  [0.243, [0.0, 0.473, 1.0]],
  [0.245, [0.0, 0.473, 1.0]],
  [0.247, [0.0, 0.488, 1.0]],
  [0.249, [0.0, 0.488, 1.0]],
  [0.25, [0.0, 0.504, 1.0]],
  [0.252, [0.0, 0.504, 1.0]],
  [0.254, [0.0, 0.52, 1.0]],
  [0.256, [0.0, 0.52, 1.0]],
  [0.258, [0.0, 0.535, 1.0]],
  [0.26, [0.0, 0.535, 1.0]],
  [0.262, [0.0, 0.551, 1.0]],
  [0.264, [0.0, 0.551, 1.0]],
  [0.266, [0.0, 0.567, 1.0]],
  [0.268, [0.0, 0.567, 1.0]],
  [0.27, [0.0, 0.582, 1.0]],
  [0.272, [0.0, 0.582, 1.0]],
  [0.274, [0.0, 0.598, 1.0]],
  [0.276, [0.0, 0.598, 1.0]],
  [0.278, [0.0, 0.614, 1.0]],
  [0.28, [0.0, 0.614, 1.0]],
  [0.282, [0.0, 0.629, 1.0]],
  [0.284, [0.0, 0.629, 1.0]],
  [0.286, [0.0, 0.645, 1.0]],
  [0.288, [0.0, 0.645, 1.0]],
  [0.29, [0.0, 0.661, 1.0]],
  [0.292, [0.0, 0.661, 1.0]],
  [0.294, [0.0, 0.676, 1.0]],
  [0.295, [0.0, 0.676, 1.0]],
  [0.297, [0.0, 0.692, 1.0]],
  [0.299, [0.0, 0.692, 1.0]],
  [0.301, [0.0, 0.708, 1.0]],
  [0.303, [0.0, 0.708, 1.0]],
  [0.305, [0.0, 0.724, 1.0]],
  [0.307, [0.0, 0.724, 1.0]],
  [0.309, [0.0, 0.739, 1.0]],
  [0.311, [0.0, 0.739, 1.0]],
  [0.313, [0.0, 0.755, 1.0]],
  [0.315, [0.0, 0.755, 1.0]],
  [0.317, [0.0, 0.771, 1.0]],
  [0.319, [0.0, 0.771, 1.0]],
  [0.321, [0.0, 0.786, 1.0]],
  [0.323, [0.0, 0.786, 1.0]],
  [0.325, [0.0, 0.802, 1.0]],
  [0.327, [0.0, 0.802, 1.0]],
  [0.329, [0.0, 0.818, 1.0]],
  [0.331, [0.0, 0.818, 1.0]],
  [0.333, [0.0, 0.833, 1.0]],
  [0.335, [0.0, 0.833, 1.0]],
  [0.337, [0.0, 0.849, 1.0]],
  [0.339, [0.0, 0.849, 1.0]],
  [0.341, [0.0, 0.865, 0.996]],
  [0.342, [0.0, 0.865, 0.996]],
  [0.344, [0.0, 0.88, 0.984]],
  [0.346, [0.0, 0.88, 0.984]],
  [0.348, [0.0, 0.896, 0.971]],
  [0.35, [0.0, 0.896, 0.971]],
  [0.352, [0.009, 0.912, 0.958]],
  [0.354, [0.009, 0.912, 0.958]],
  [0.356, [0.022, 0.927, 0.946]],
  [0.358, [0.022, 0.927, 0.946]],
  [0.36, [0.035, 0.943, 0.933]],
  [0.362, [0.035, 0.943, 0.933]],
  [0.364, [0.047, 0.959, 0.92]],
  [0.366, [0.047, 0.959, 0.92]],
  [0.368, [0.06, 0.975, 0.908]],
  [0.37, [0.06, 0.975, 0.908]],
  [0.372, [0.073, 0.99, 0.895]],
  [0.374, [0.073, 0.99, 0.895]],
  [0.376, [0.085, 1.0, 0.882]],
  [0.378, [0.085, 1.0, 0.882]],
  [0.38, [0.098, 1.0, 0.87]],
  [0.382, [0.098, 1.0, 0.87]],
  [0.384, [0.111, 1.0, 0.857]],
  [0.386, [0.111, 1.0, 0.857]],
  [0.387, [0.123, 1.0, 0.844]],
  [0.389, [0.123, 1.0, 0.844]],
  [0.391, [0.136, 1.0, 0.832]],
  [0.393, [0.136, 1.0, 0.832]],
  [0.395, [0.149, 1.0, 0.819]],
  [0.397, [0.149, 1.0, 0.819]],
  [0.399, [0.161, 1.0, 0.806]],
  [0.401, [0.161, 1.0, 0.806]],
  [0.403, [0.174, 1.0, 0.794]],
  [0.405, [0.174, 1.0, 0.794]],
  [0.407, [0.187, 1.0, 0.781]],
  [0.409, [0.187, 1.0, 0.781]],
  [0.411, [0.199, 1.0, 0.769]],
  [0.413, [0.199, 1.0, 0.769]],
  [0.415, [0.212, 1.0, 0.756]],
  [0.417, [0.212, 1.0, 0.756]],
  [0.419, [0.225, 1.0, 0.743]],
  [0.421, [0.225, 1.0, 0.743]],
  [0.423, [0.237, 1.0, 0.731]],
  [0.425, [0.237, 1.0, 0.731]],
  [0.427, [0.25, 1.0, 0.718]],
  [0.429, [0.25, 1.0, 0.718]],
  [0.431, [0.262, 1.0, 0.705]],
  [0.432, [0.262, 1.0, 0.705]],
  [0.434, [0.275, 1.0, 0.693]],
  [0.436, [0.275, 1.0, 0.693]],
  [0.438, [0.288, 1.0, 0.68]],
  [0.44, [0.288, 1.0, 0.68]],
  [0.442, [0.3, 1.0, 0.667]],
  [0.444, [0.3, 1.0, 0.667]],
  [0.446, [0.313, 1.0, 0.655]],
  [0.448, [0.313, 1.0, 0.655]],
  [0.45, [0.326, 1.0, 0.642]],
  [0.452, [0.326, 1.0, 0.642]],
  [0.454, [0.338, 1.0, 0.629]],
  [0.456, [0.338, 1.0, 0.629]],
  [0.458, [0.351, 1.0, 0.617]],
  [0.46, [0.351, 1.0, 0.617]],
  [0.462, [0.364, 1.0, 0.604]],
  [0.464, [0.364, 1.0, 0.604]],
  [0.466, [0.376, 1.0, 0.591]],
  [0.468, [0.376, 1.0, 0.591]],
  [0.47, [0.389, 1.0, 0.579]],
  [0.472, [0.389, 1.0, 0.579]],
  [0.474, [0.402, 1.0, 0.566]],
  [0.476, [0.402, 1.0, 0.566]],
  [0.477, [0.414, 1.0, 0.553]],
  [0.479, [0.414, 1.0, 0.553]],
  [0.481, [0.427, 1.0, 0.541]],
  [0.483, [0.427, 1.0, 0.541]],
  [0.485, [0.44, 1.0, 0.528]],
  [0.487, [0.44, 1.0, 0.528]],
  [0.489, [0.452, 1.0, 0.515]],
  [0.491, [0.452, 1.0, 0.515]],
  [0.493, [0.465, 1.0, 0.503]],
  [0.495, [0.465, 1.0, 0.503]],
  [0.497, [0.478, 1.0, 0.49]],
  [0.499, [0.478, 1.0, 0.49]],
  [0.501, [0.49, 1.0, 0.478]],
  [0.503, [0.49, 1.0, 0.478]],
  [0.505, [0.503, 1.0, 0.465]],
  [0.507, [0.503, 1.0, 0.465]],
  [0.509, [0.515, 1.0, 0.452]],
  [0.511, [0.515, 1.0, 0.452]],
  [0.513, [0.528, 1.0, 0.44]],
  [0.515, [0.528, 1.0, 0.44]],
  [0.517, [0.541, 1.0, 0.427]],
  [0.519, [0.541, 1.0, 0.427]],
  [0.521, [0.553, 1.0, 0.414]],
  [0.523, [0.553, 1.0, 0.414]],
  [0.524, [0.566, 1.0, 0.402]],
  [0.526, [0.566, 1.0, 0.402]],
  [0.528, [0.579, 1.0, 0.389]],
  [0.53, [0.579, 1.0, 0.389]],
  [0.532, [0.591, 1.0, 0.376]],
  [0.534, [0.591, 1.0, 0.376]],
  [0.536, [0.604, 1.0, 0.364]],
  [0.538, [0.604, 1.0, 0.364]],
  [0.54, [0.617, 1.0, 0.351]],
  [0.542, [0.617, 1.0, 0.351]],
  [0.544, [0.629, 1.0, 0.338]],
  [0.546, [0.629, 1.0, 0.338]],
  [0.548, [0.642, 1.0, 0.326]],
  [0.55, [0.642, 1.0, 0.326]],
  [0.552, [0.655, 1.0, 0.313]],
  [0.554, [0.655, 1.0, 0.313]],
  [0.556, [0.667, 1.0, 0.3]],
  [0.558, [0.667, 1.0, 0.3]],
  [0.56, [0.68, 1.0, 0.288]],
  [0.562, [0.68, 1.0, 0.288]],
  [0.564, [0.693, 1.0, 0.275]],
  [0.566, [0.693, 1.0, 0.275]],
  [0.568, [0.705, 1.0, 0.262]],
  [0.569, [0.705, 1.0, 0.262]],
  [0.571, [0.718, 1.0, 0.25]],
  [0.573, [0.718, 1.0, 0.25]],
  [0.575, [0.731, 1.0, 0.237]],
  [0.577, [0.731, 1.0, 0.237]],
  [0.579, [0.743, 1.0, 0.225]],
  [0.581, [0.743, 1.0, 0.225]],
  [0.583, [0.756, 1.0, 0.212]],
  [0.585, [0.756, 1.0, 0.212]],
  [0.587, [0.769, 1.0, 0.199]],
  [0.589, [0.769, 1.0, 0.199]],
  [0.591, [0.781, 1.0, 0.187]],
  [0.593, [0.781, 1.0, 0.187]],
  [0.595, [0.794, 1.0, 0.174]],
  [0.597, [0.794, 1.0, 0.174]],
  [0.599, [0.806, 1.0, 0.161]],
  [0.601, [0.806, 1.0, 0.161]],
  [0.603, [0.819, 1.0, 0.149]],
  [0.605, [0.819, 1.0, 0.149]],
  [0.607, [0.832, 1.0, 0.136]],
  [0.609, [0.832, 1.0, 0.136]],
  [0.611, [0.844, 1.0, 0.123]],
  [0.613, [0.844, 1.0, 0.123]],
  [0.614, [0.857, 1.0, 0.111]],
  [0.616, [0.857, 1.0, 0.111]],
  [0.618, [0.87, 1.0, 0.098]],
  [0.62, [0.87, 1.0, 0.098]],
  [0.622, [0.882, 1.0, 0.085]],
  [0.624, [0.882, 1.0, 0.085]],
  [0.626, [0.895, 1.0, 0.073]],
  [0.628, [0.895, 1.0, 0.073]],
  [0.63, [0.908, 1.0, 0.06]],
  [0.632, [0.908, 1.0, 0.06]],
  [0.634, [0.92, 1.0, 0.047]],
  [0.636, [0.92, 1.0, 0.047]],
  [0.638, [0.933, 1.0, 0.035]],
  [0.64, [0.933, 1.0, 0.035]],
  [0.642, [0.946, 0.988, 0.022]],
  [0.644, [0.946, 0.988, 0.022]],
  [0.646, [0.958, 0.974, 0.009]],
  [0.648, [0.958, 0.974, 0.009]],
  [0.65, [0.971, 0.959, 0.0]],
  [0.652, [0.971, 0.959, 0.0]],
  [0.654, [0.984, 0.945, 0.0]],
  [0.656, [0.984, 0.945, 0.0]],
  [0.658, [0.996, 0.93, 0.0]],
  [0.659, [0.996, 0.93, 0.0]],
  [0.661, [1.0, 0.916, 0.0]],
  [0.663, [1.0, 0.916, 0.0]],
  [0.665, [1.0, 0.901, 0.0]],
  [0.667, [1.0, 0.901, 0.0]],
  [0.669, [1.0, 0.887, 0.0]],
  [0.671, [1.0, 0.887, 0.0]],
  [0.673, [1.0, 0.872, 0.0]],
  [0.675, [1.0, 0.872, 0.0]],
  [0.677, [1.0, 0.858, 0.0]],
  [0.679, [1.0, 0.858, 0.0]],
  [0.681, [1.0, 0.843, 0.0]],
  [0.683, [1.0, 0.843, 0.0]],
  [0.685, [1.0, 0.829, 0.0]],
  [0.687, [1.0, 0.829, 0.0]],
  [0.689, [1.0, 0.814, 0.0]],
  [0.691, [1.0, 0.814, 0.0]],
  [0.693, [1.0, 0.8, 0.0]],
  [0.695, [1.0, 0.8, 0.0]],
  [0.697, [1.0, 0.785, 0.0]],
  [0.699, [1.0, 0.785, 0.0]],
  [0.701, [1.0, 0.771, 0.0]],
  [0.703, [1.0, 0.771, 0.0]],
  [0.705, [1.0, 0.756, 0.0]],
  [0.706, [1.0, 0.756, 0.0]],
  [0.708, [1.0, 0.741, 0.0]],
  [0.71, [1.0, 0.741, 0.0]],
  [0.712, [1.0, 0.727, 0.0]],
  [0.714, [1.0, 0.727, 0.0]],
  [0.716, [1.0, 0.712, 0.0]],
  [0.718, [1.0, 0.712, 0.0]],
  [0.72, [1.0, 0.698, 0.0]],
  [0.722, [1.0, 0.698, 0.0]],
  [0.724, [1.0, 0.683, 0.0]],
  [0.726, [1.0, 0.683, 0.0]],
  [0.728, [1.0, 0.669, 0.0]],
  [0.73, [1.0, 0.669, 0.0]],
  [0.732, [1.0, 0.654, 0.0]],
  [0.734, [1.0, 0.654, 0.0]],
  [0.736, [1.0, 0.64, 0.0]],
  [0.738, [1.0, 0.64, 0.0]],
  [0.74, [1.0, 0.625, 0.0]],
  [0.742, [1.0, 0.625, 0.0]],
  [0.744, [1.0, 0.611, 0.0]],
  [0.746, [1.0, 0.611, 0.0]],
  [0.748, [1.0, 0.596, 0.0]],
  [0.75, [1.0, 0.596, 0.0]],
  [0.751, [1.0, 0.582, 0.0]],
  [0.753, [1.0, 0.582, 0.0]],
  [0.755, [1.0, 0.567, 0.0]],
  [0.757, [1.0, 0.567, 0.0]],
  [0.759, [1.0, 0.553, 0.0]],
  [0.761, [1.0, 0.553, 0.0]],
  [0.763, [1.0, 0.538, 0.0]],
  [0.765, [1.0, 0.538, 0.0]],
  [0.767, [1.0, 0.524, 0.0]],
  [0.769, [1.0, 0.524, 0.0]],
  [0.771, [1.0, 0.509, 0.0]],
  [0.773, [1.0, 0.509, 0.0]],
  [0.775, [1.0, 0.495, 0.0]],
  [0.777, [1.0, 0.495, 0.0]],
  [0.779, [1.0, 0.48, 0.0]],
  [0.781, [1.0, 0.48, 0.0]],
  [0.783, [1.0, 0.466, 0.0]],
  [0.785, [1.0, 0.466, 0.0]],
  [0.787, [1.0, 0.451, 0.0]],
  [0.789, [1.0, 0.451, 0.0]],
  [0.791, [1.0, 0.436, 0.0]],
  [0.793, [1.0, 0.436, 0.0]],
  [0.795, [1.0, 0.422, 0.0]],
  [0.796, [1.0, 0.422, 0.0]],
  [0.798, [1.0, 0.407, 0.0]],
  [0.8, [1.0, 0.407, 0.0]],
  [0.802, [1.0, 0.393, 0.0]],
  [0.804, [1.0, 0.393, 0.0]],
  [0.806, [1.0, 0.378, 0.0]],
  [0.808, [1.0, 0.378, 0.0]],
  [0.81, [1.0, 0.364, 0.0]],
  [0.812, [1.0, 0.364, 0.0]],
  [0.814, [1.0, 0.349, 0.0]],
  [0.816, [1.0, 0.349, 0.0]],
  [0.818, [1.0, 0.335, 0.0]],
  [0.82, [1.0, 0.335, 0.0]],
  [0.822, [1.0, 0.32, 0.0]],
  [0.824, [1.0, 0.32, 0.0]],
  [0.826, [1.0, 0.306, 0.0]],
  [0.828, [1.0, 0.306, 0.0]],
  [0.83, [1.0, 0.291, 0.0]],
  [0.832, [1.0, 0.291, 0.0]],
  [0.834, [1.0, 0.277, 0.0]],
  [0.836, [1.0, 0.277, 0.0]],
  [0.838, [1.0, 0.262, 0.0]],
  [0.84, [1.0, 0.262, 0.0]],
  [0.841, [1.0, 0.248, 0.0]],
  [0.843, [1.0, 0.248, 0.0]],
  [0.845, [1.0, 0.233, 0.0]],
  [0.847, [1.0, 0.233, 0.0]],
  [0.849, [1.0, 0.219, 0.0]],
  [0.851, [1.0, 0.219, 0.0]],
  [0.853, [1.0, 0.204, 0.0]],
  [0.855, [1.0, 0.204, 0.0]],
  [0.857, [1.0, 0.19, 0.0]],
  [0.859, [1.0, 0.19, 0.0]],
  [0.861, [1.0, 0.175, 0.0]],
  [0.863, [1.0, 0.175, 0.0]],
  [0.865, [1.0, 0.16, 0.0]],
  [0.867, [1.0, 0.16, 0.0]],
  [0.869, [1.0, 0.146, 0.0]],
  [0.871, [1.0, 0.146, 0.0]],
  [0.873, [1.0, 0.131, 0.0]],
  [0.875, [1.0, 0.131, 0.0]],
  [0.877, [1.0, 0.117, 0.0]],
  [0.879, [1.0, 0.117, 0.0]],
  [0.881, [1.0, 0.102, 0.0]],
  [0.883, [1.0, 0.102, 0.0]],
  [0.885, [1.0, 0.088, 0.0]],
  [0.886, [1.0, 0.088, 0.0]],
  [0.888, [0.999, 0.073, 0.0]],
  [0.89, [0.999, 0.073, 0.0]],
  [0.892, [0.981, 0.059, 0.0]],
  [0.894, [0.981, 0.059, 0.0]],
  [0.896, [0.963, 0.044, 0.0]],
  [0.898, [0.963, 0.044, 0.0]],
  [0.9, [0.946, 0.03, 0.0]],
  [0.902, [0.946, 0.03, 0.0]],
  [0.904, [0.928, 0.015, 0.0]],
  [0.906, [0.928, 0.015, 0.0]],
  [0.908, [0.91, 0.001, 0.0]],
  [0.91, [0.91, 0.001, 0.0]],
  [0.912, [0.892, 0.0, 0.0]],
  [0.914, [0.892, 0.0, 0.0]],
  [0.916, [0.874, 0.0, 0.0]],
  [0.918, [0.874, 0.0, 0.0]],
  [0.92, [0.857, 0.0, 0.0]],
  [0.922, [0.857, 0.0, 0.0]],
  [0.924, [0.839, 0.0, 0.0]],
  [0.926, [0.839, 0.0, 0.0]],
  [0.928, [0.821, 0.0, 0.0]],
  [0.93, [0.821, 0.0, 0.0]],
  [0.932, [0.803, 0.0, 0.0]],
  [0.933, [0.803, 0.0, 0.0]],
  [0.935, [0.785, 0.0, 0.0]],
  [0.937, [0.785, 0.0, 0.0]],
  [0.939, [0.767, 0.0, 0.0]],
  [0.941, [0.767, 0.0, 0.0]],
  [0.943, [0.75, 0.0, 0.0]],
  [0.945, [0.75, 0.0, 0.0]],
  [0.947, [0.732, 0.0, 0.0]],
  [0.949, [0.732, 0.0, 0.0]],
  [0.951, [0.714, 0.0, 0.0]],
  [0.953, [0.714, 0.0, 0.0]],
  [0.955, [0.696, 0.0, 0.0]],
  [0.957, [0.696, 0.0, 0.0]],
  [0.959, [0.678, 0.0, 0.0]],
  [0.961, [0.678, 0.0, 0.0]],
  [0.963, [0.66, 0.0, 0.0]],
  [0.965, [0.66, 0.0, 0.0]],
  [0.967, [0.643, 0.0, 0.0]],
  [0.969, [0.643, 0.0, 0.0]],
  [0.971, [0.625, 0.0, 0.0]],
  [0.973, [0.625, 0.0, 0.0]],
  [0.975, [0.607, 0.0, 0.0]],
  [0.977, [0.607, 0.0, 0.0]],
  [0.978, [0.589, 0.0, 0.0]],
  [0.98, [0.589, 0.0, 0.0]],
  [0.982, [0.571, 0.0, 0.0]],
  [0.984, [0.571, 0.0, 0.0]],
  [0.986, [0.553, 0.0, 0.0]],
  [0.988, [0.553, 0.0, 0.0]],
  [0.99, [0.536, 0.0, 0.0]],
  [0.992, [0.536, 0.0, 0.0]],
  [0.994, [0.518, 0.0, 0.0]],
  [0.996, [0.518, 0.0, 0.0]],
  [0.998, [0.5, 0.0, 0.0]],
  [1.0, [0.5, 0.0, 0.0]],
];

function enforceBounds(x: number) {
  if (x < 0) {
    return 0;
  } else if (x > 1) {
    return 1;
  } else {
    return x;
  }
}

export function interpolateLinearly(x: number, values: any) {
  // Split values into four lists
  const x_values = [];
  const r_values = [];
  const g_values = [];
  const b_values = [];
  for (const i in values) {
    x_values.push(values[i][0]);
    r_values.push(values[i][1][0]);
    g_values.push(values[i][1][1]);
    b_values.push(values[i][1][2]);
  }

  let i = 1;
  while (x_values[i] < x) {
    i = i + 1;
  }
  i = i - 1;

  const width = Math.abs(x_values[i] - x_values[i + 1]);
  const scaling_factor = (x - x_values[i]) / width;

  // Get the new color values though interpolation
  const r = r_values[i] + scaling_factor * (r_values[i + 1] - r_values[i]);
  const g = g_values[i] + scaling_factor * (g_values[i + 1] - g_values[i]);
  const b = b_values[i] + scaling_factor * (b_values[i + 1] - b_values[i]);

  return [enforceBounds(r), enforceBounds(g), enforceBounds(b)];
}
