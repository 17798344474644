import React from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";

import SecureRoute from "components/auth/SecureRoute";
import RobotsScreen from "components/robots/RobotsScreen";
import RobotsSfuScreen from "components/RobotsScreen";
import SpectateScreen from "components/teleop/SpectateScreen";
import MapScreen from "components/teleop/MapScreen";
import DispatchSwitch from "components/dispatch/DispatchSwitch";
import ViewUserScreen from "components/users/ViewUserScreen";
import UsersListScreen from "components/users/UsersListScreen";
import GenericDriveScreen from "components/teleop/GenericDriveScreen";
import ViewUberEatsOrderScreen from "components/deliveries/ViewUberEatsOrderScreen";
import RestaurantsScreen from "components/deliveries/RestaurantsScreen";
import DeliveryTeleopScreen from "components/delivery-teleop/DeliveryTeleopScreen";
import LidScreen from "components/delivery-teleop/LidScreen";
import ViewRestaurantScreen from "components/deliveries/ViewRestaurantScreen";
import ActiveRobotsCamerasScreen from "components/robots/ActiveRobotsCamerasScreen";
import OrderRescueScreen from "components/rescues/OrderRescueScreen/V2";
import OrdersIntegrationTogglesScreen from "components/integrations/OrdersIntegrationTogglesScreen";
import MediaSearchScreen from "components/robots/MediaSearchScreen";
import MediaDownloadScreen from "components/robots/MediaDownloadScreen";
import PlaybackScreen from "components/robots/PlaybackScreen";

const DashboardSwitch = () => {
  const location = useLocation();

  return (
    <Switch>
      <SecureRoute
        path="/robots/spectate-all"
        component={ActiveRobotsCamerasScreen}
      />
      <SecureRoute path="/robots/videos/search" component={MediaSearchScreen} />
      <SecureRoute
        path="/robots/videos/download"
        component={MediaDownloadScreen}
      />
      <SecureRoute path="/robots/playback" component={PlaybackScreen} />
      <SecureRoute path="/robots/sfu" component={RobotsSfuScreen} />
      <SecureRoute path="/robots" component={RobotsScreen} />
      <SecureRoute path="/users/:userUUID" component={ViewUserScreen} />
      <SecureRoute path="/users" component={UsersListScreen} />
      <SecureRoute
        path={`/orders/uber-eats/:orderUUID`}
        component={ViewUberEatsOrderScreen}
      />
      <SecureRoute
        path="/deliver-with/:robotID"
        component={DeliveryTeleopScreen}
      />
      <SecureRoute path="/drive/:robotID" component={GenericDriveScreen} />
      <SecureRoute path="/spectate/:robotID">
        <SpectateScreen />
      </SecureRoute>
      <SecureRoute path="/pick-up-with/:robotID" component={LidScreen} />
      <SecureRoute
        path="/restaurants/:restaurantUUID"
        component={ViewRestaurantScreen}
      />
      <SecureRoute path="/restaurants" component={RestaurantsScreen} />
      <SecureRoute path="/map">
        <MapScreen />
      </SecureRoute>
      <SecureRoute
        path="/rescue-order/:orderUUID"
        component={OrderRescueScreen}
      />
      <SecureRoute path="/dispatch" component={DispatchSwitch} />
      <SecureRoute
        path="/integrations/orders"
        component={OrdersIntegrationTogglesScreen}
      />
      <Route path="/">
        <Redirect
          to={{
            pathname: "/robots",
            state: { from: location },
          }}
        />
      </Route>
    </Switch>
  );
};

export default DashboardSwitch;
