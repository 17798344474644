import { useRobotDroppedOffOrder } from "mutations/deliveries/uber-eats-orders";
import React from "react";
import { useActionConfirmationDialog } from "components/common/ActionConfirmationDialog";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import { ButtonProps } from "@material-ui/core/Button";
import LoadableButton from "components/common/LoadableButton";

export interface OrderDroppedOffActionButtonProps
  extends Omit<ButtonProps, "href" | "onClick"> {
  assignedRobotID: string;
  isLoading?: boolean;
}

const OrderDroppedOffActionButton: React.FC<
  OrderDroppedOffActionButtonProps
> = ({
  children = "Order Dropped Off",
  assignedRobotID,
  isLoading = false,
  ...buttonProps
}) => {
  const robotDroppedOffOrderEvent = useRobotDroppedOffOrder(assignedRobotID);
  const actionConfirmation = useActionConfirmationDialog();
  const { showAlert } = useAlertMessage();

  const handleClick = () => {
    actionConfirmation.open({
      confirmationText: "Verify customer got all items from the robot",
      onConfirm: () => {
        robotDroppedOffOrderEvent
          .mutateAsync()
          .then(() => {
            actionConfirmation.dismiss();
            showAlert({
              message: "Great! Mission accomplished \u2b50",
              severity: "success",
            });
          })
          .catch(({ message }) => {
            showAlert({
              message: message || "Unknown error",
              severity: "error",
              autoCloseAfterMillis: 5000,
            });
          });
      },
    });
  };

  return (
    <LoadableButton
      isLoading={isLoading}
      onClick={handleClick}
      disabled={isLoading || robotDroppedOffOrderEvent.isLoading}
      {...buttonProps}
    >
      {children}
    </LoadableButton>
  );
};

export default OrderDroppedOffActionButton;
