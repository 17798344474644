import React from "react";
import TeleopHUDContainer, {
  TeleopHUDContainerProps,
} from "components/teleop/HUDContainer";
import DeliveryStageActionSnackbar from "../StageActionSnackbar";

export interface DeliveryStageActionSnackbarWidgetProps
  extends TeleopHUDContainerProps {}

const DeliveryStageActionSnackbarWidget: React.FC<
  DeliveryStageActionSnackbarWidgetProps
> = ({ ...hudProps }) => {
  return (
    <TeleopHUDContainer
      horizontalPosition="center"
      verticalPosition="bottom"
      alwaysActive={true}
      {...hudProps}
    >
      <DeliveryStageActionSnackbar />
    </TeleopHUDContainer>
  );
};

export default DeliveryStageActionSnackbarWidget;
