import React from "react";
import Grid from "@material-ui/core/Grid";
import DataPreviewEntry from "components/common/DataPreviewEntry";
import GoogleMapsLink from "components/Location/GoogleMapsLink";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import { useRestaurant } from "fetchers/deliveries/restaurants";
import LinearProgress from "@material-ui/core/LinearProgress";
import PickUpNotesAutoSaveField from "./PickUpNotesAutoSaveField";

export interface OrderCardRestaurantInfoProps {
  order: UberEatsOrder;
  isEditable: boolean;
}

const OrderCardRestaurantInfo: React.FC<OrderCardRestaurantInfoProps> = ({
  order,
  isEditable,
}) => {
  const restaurant = useRestaurant(order.restaurant_uuid);
  if (!restaurant.data) {
    return <LinearProgress />;
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <DataPreviewEntry label="Restaurant" value={restaurant.data.name} />
        </Grid>
        <Grid item xs={6}>
          <DataPreviewEntry
            label="Phone Number"
            value={restaurant.data.contacts[0]?.phone_number}
          />
        </Grid>
        <Grid item xs={6}>
          <PickUpNotesAutoSaveField
            isEditable={isEditable}
            orderUUID={order.uuid}
            currentPickUpNotes={order.pick_up_notes}
          />
        </Grid>
        <Grid item xs={6}>
          <DataPreviewEntry
            label="Address"
            value={
              <GoogleMapsLink
                addressDescription={restaurant.data.address_description}
                geocodedAddress={restaurant.data.geocoded_address}
                noWrap
              />
            }
          />
        </Grid>
      </Grid>
    );
  }
};

export default OrderCardRestaurantInfo;
