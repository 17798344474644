import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import { useRestaurant } from "fetchers/deliveries/restaurants";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface OrderCardDropOffDeadlineCellProps {
  order: UberEatsOrder;
  asOf: Date;
}

const OrderCardDropOffDeadlineCell: React.FC<
  OrderCardDropOffDeadlineCellProps
> = ({ order, asOf }) => {
  const restaurant = useRestaurant(order.restaurant_uuid);
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="caption">Drop Off Deadline</Typography>
      </Grid>
      <Grid>
        {restaurant.data ? (
          expectedDropOffInMinutes(
            order,
            restaurant.data.drop_off_deadline_in_minutes,
            asOf,
          ) + " minutes"
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};

const expectedDropOffInMinutes = (
  order: UberEatsOrder,
  totalExpectedDropOffTimeInMinutes: number,
  asOf: Date,
): number => {
  const totalExpectedDropOffTimeInMillis =
    60 * 1000 * totalExpectedDropOffTimeInMinutes;
  const expectedDropOffInMillis =
    new Date(order.requested_at).getTime() + totalExpectedDropOffTimeInMillis;
  return Math.round((expectedDropOffInMillis - asOf.getTime()) / (60 * 1000));
};

export default OrderCardDropOffDeadlineCell;
