import {
  POV,
  ReprojectionController,
  videoReprojection,
} from "lib/reprojection";
import React, { useCallback, useEffect, useState } from "react";
import VideoReprojectionContext, {
  ReprojectionBindParams,
} from "../VideoReprojectionContext";

export interface VideoReprojectionProps {
  source: HTMLVideoElement;
}

const VideoReprojection: React.FC<VideoReprojectionProps> = ({
  children,
  source,
}) => {
  const [bindParams, setBindParams] = useState<null | ReprojectionBindParams>(
    null,
  );
  const [pov, setPOV] = useState<POV>({
    yaw: 0.0,
    height: 1.1,
    projection: "equirectangular",
  });
  const [controller, setController] = useState<ReprojectionController>({
    render: () => {},
    setPOV: () => {},
  });
  const render = useCallback(() => {
    return controller.render();
  }, [controller]);

  useEffect(() => {
    controller.setPOV(pov);
  }, [controller, pov]);

  useEffect(() => {
    if (bindParams && source) {
      setController(videoReprojection({ ...bindParams, sourceVideo: source }));
    }
  }, [bindParams, setController, source]);

  return (
    <VideoReprojectionContext.Provider
      value={{
        setPOV,
        render,
        source,
        bindReprojection: setBindParams,
      }}
    >
      {children}
    </VideoReprojectionContext.Provider>
  );
};

export default VideoReprojection;
