import React from "react";
import Grid from "@material-ui/core/Grid";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import SubTotalInCentsAutoSaveField from "./SubTotalInCentsAutoSaveField";
import TipsInCentsAutoSaveField from "./TipsInCentsAutoSaveField";

export interface OrderCardChargesInfoProps {
  order: UberEatsOrder;
  isEditable: boolean;
}

const OrderCardChargesInfo: React.FC<OrderCardChargesInfoProps> = ({
  order,
  isEditable,
}) => (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <SubTotalInCentsAutoSaveField
        isEditable={isEditable}
        orderUUID={order.uuid}
        currentSubTotalInCents={order.charges!.sub_total_in_cents}
      />
    </Grid>
    <Grid item xs={6}>
      <TipsInCentsAutoSaveField
        isEditable={isEditable}
        orderUUID={order.uuid}
        currentTipsInCents={order.charges!.tips_in_cents}
      />
    </Grid>
  </Grid>
);

export default OrderCardChargesInfo;
