import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TeleopContainer from "components/teleop/TeleopContainer";
import { RouteComponentProps } from "react-router-dom";
import RobotCameraFeed from "components/teleop/RobotCameraFeed";
import ActuateLidButton from "components/teleop/ActuateLidButton";
import TeleopHUDContainer from "components/teleop/HUDContainer";
import useDeliveryTeleopSession from "../useDeliveryTeleopSession";
import RobotPickedUpOrderActionButton from "./RobotPickedUpOrderActionButton";

export interface LidScreenProps
  extends RouteComponentProps<{ robotID: string }> {}

const LidScreen: React.FC<LidScreenProps> = ({ match }) => {
  const robotID = match.params.robotID;
  useDeliveryTeleopSession(robotID);
  return (
    <>
      <TeleopContainer robotID={robotID}>
        <Box width="100%" height="100%" position="absolute" zIndex={-1}>
          <RobotCameraFeed robotID={robotID} />
        </Box>
        <TeleopHUDContainer
          verticalPosition="bottom"
          horizontalPosition="center"
          alwaysActive
        >
          <Paper style={{ padding: "1em" }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <ActuateLidButton robotID={robotID} />
              </Grid>
              <Grid item>
                <RobotPickedUpOrderActionButton />
              </Grid>
            </Grid>
          </Paper>
        </TeleopHUDContainer>
      </TeleopContainer>
    </>
  );
};

export default LidScreen;
