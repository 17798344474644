import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Link from "@material-ui/core/Link";

import useSSORedirectURL from "services/auth/useSSORedirectURL";

const SignOutButton: React.FC = () => {
  const ssoRedirectURL = useSSORedirectURL("sign-out");

  return (
    <ListItem button key="Sign Out" component={Link} href={ssoRedirectURL}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Sign Out" />
    </ListItem>
  );
};

export default SignOutButton;
