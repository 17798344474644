import { useEffect, useContext, useState } from "react";
import { AxiosInstance } from "axios";
import { useIdentityStore } from "services/auth/identity/store";
import AxiosContext from "./Context";

export interface AxiosAuthenticationInterceptorsParams {
  authHeader: null | string;
}

const useAxiosAuthenticationInterceptors = ({
  authHeader,
}: AxiosAuthenticationInterceptorsParams) => {
  const instances = useContext(AxiosContext);
  const { signOut } = useIdentityStore();
  const [isInjected, setInjected] = useState(false);

  useEffect(() => {
    if (authHeader) {
      const interceptors: {
        instance: AxiosInstance;
        interceptor: number;
      }[] = [];

      for (const instance of Object.values(instances)) {
        instance.defaults.headers.common["Authorization"] = authHeader;

        const interceptor = instance.interceptors.response.use((res) => res);
        interceptors.push({ instance, interceptor });
      }
      setInjected(true);

      return () => {
        for (const instance of Object.values(instances)) {
          delete instance.defaults.headers.common["Authorization"];
        }
        for (const { instance, interceptor } of interceptors) {
          instance.interceptors.response.eject(interceptor);
        }
        setInjected(false);
      };
    }
  }, [authHeader, instances, setInjected, signOut]);

  return isInjected;
};

export default useAxiosAuthenticationInterceptors;
