import React from "react";
import Place from "types/Location/Place";
import Grid, { GridProps } from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  googleMapsLinkToPlace,
  streetViewLinkToPlace,
} from "lib/location/places";

export interface GoogleMapsLinksProps {
  address: Place;
  direction?: GridProps["direction"];
  fullWidth?: boolean;
}

const GoogleMapsLinks: React.FC<GoogleMapsLinksProps> = ({
  address,
  direction = "row",
  fullWidth = false,
}) => {
  return (
    <Grid item container direction={direction} spacing={1}>
      <Grid item>
        <Button
          fullWidth={fullWidth}
          target="_blank"
          variant="outlined"
          href={googleMapsLinkToPlace(address)}
        >
          Google Maps
        </Button>
      </Grid>
      <Grid item>
        <Button
          fullWidth={fullWidth}
          target="_blank"
          variant="outlined"
          href={streetViewLinkToPlace(address)}
        >
          Street View
        </Button>
      </Grid>
    </Grid>
  );
};

export default GoogleMapsLinks;
