import { useRestaurant } from "fetchers/deliveries/restaurants";
import RobotDestination from "types/teleop/RobotDestination";
import useOrderForTeleopSession from "../useOrderForTeleopSession";
import useRobotForTeleopSession from "../useRobotForTeleopSession";

const useRobotDestinationGoal = (): null | RobotDestination => {
  const order = useOrderForTeleopSession();
  const restaurant = useRestaurant(order.data?.restaurant_uuid);
  const parkingSpot = useRobotForTeleopSession({
    select: (robot) => robot.parking_spot,
  });

  switch (order.data?.current_stage.kind) {
    case "order-queued":
    case "robot-en-route-to-pick-up":
      return restaurant.data || null;
    case "order-picked-up":
    case "robot-en-route-to-drop-off":
      return order.data.recipient;
    case undefined:
      return parkingSpot.data || null;
    default:
      return null;
  }
};

export default useRobotDestinationGoal;
