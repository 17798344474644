import React, { useEffect, useState } from "react";
import Geocode from "types/Location/Geocode";
import { Polyline, Marker } from "@react-google-maps/api";

export interface Trail {
  trail: Geocode[];
  color: string;
  label: string;
}

export interface MapTrailProps {
  trails: Trail[];
}

const ToLine = (points: Geocode[]) => {
  return points.map((p) => new google.maps.LatLng(p.lat, p.lng));
};

const MapTrail: React.FC<MapTrailProps> = ({ trails }) => {
  // animate the path
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setOffset((offset) => (offset - 0.2 + 100) % 100);
    }, 10);
    return () => clearInterval(interval);
  }, [offset]);

  return (
    <>
      {trails.map((props) => (
        <React.Fragment key={props.label}>
          <Marker
            position={ToLine(props.trail)[0]}
            title={props.label}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              scale: 4,
              strokeOpacity: 1.0,
              strokeColor: props.color,
              fillOpacity: 1.0,
              fillColor: props.color,
            }}
          />
          <Polyline
            path={ToLine(props.trail)}
            options={{
              strokeOpacity: 0.6,
              strokeColor: props.color,
              icons: [
                {
                  icon: {
                    path: "M 0,-1 0,1",
                    strokeOpacity: 1,
                  },
                  offset: offset + "px",
                  repeat: "20px",
                },
              ],
            }}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default MapTrail;
