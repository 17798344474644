import React from "react";
import Grid from "@material-ui/core/Grid";
import RestaurantParkingSpotFormik, {
  RestaurantParkingSpotFormikProps,
} from "./Formik";
import FormikSubmitButton from "components/common/formik/FormikSubmitButton";
import RestaurantAutocompleteField from "components/deliveries/RestaurantAutocompleteField";

export type RestaurantParkingSpotFormProps = RestaurantParkingSpotFormikProps;

const RestaurantParkingSpotForm: React.FC<RestaurantParkingSpotFormProps> = (
  formikProps,
) => {
  return (
    <RestaurantParkingSpotFormik {...formikProps}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <RestaurantAutocompleteField autoFocus required name="restaurant" />
        </Grid>
        <Grid item>
          <FormikSubmitButton />
        </Grid>
      </Grid>
    </RestaurantParkingSpotFormik>
  );
};

export default RestaurantParkingSpotForm;
