import React from "react";
import { Canvas } from "@react-three/fiber";
import VideoReprojectionContext from "../VideoReprojectionContext";
import useVideoReprojection from "../useVideoReprojection";

export interface VideoReprojectionCanvasProps {}

const VideoReprojectionCanvas: React.FC<VideoReprojectionCanvasProps> = ({
  children,
}) => {
  const ctxValue = useVideoReprojection();
  return (
    <Canvas>
      <VideoReprojectionContext.Provider value={ctxValue}>
        {children}
      </VideoReprojectionContext.Provider>
    </Canvas>
  );
};

export default VideoReprojectionCanvas;
