import React from "react";
import MapRoutePath from "components/Map/RoutePath";
import { useThrottledRobotPosition } from "queries/teleop/RobotTelemetry/Context";
import useRobotDestination from "../useRobotDestination";

export interface DeliveryRouteProps {
  robotID: string;
}

const DeliveryRoute: React.FC<DeliveryRouteProps> = ({ robotID }) => {
  const robotPosition = useThrottledRobotPosition(robotID, 10000);
  const destination = useRobotDestination();
  if (robotPosition && destination) {
    return (
      <MapRoutePath
        origin={robotPosition}
        destination={destination.geocoded_address}
      />
    );
  } else {
    return <></>;
  }
};

export default DeliveryRoute;
