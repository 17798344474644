import React from "react";

import User from "types/auth/User";
import { useResendVerificationLink } from "mutations/users";
import SimpleMutationActionContainer from "./SimpleMutationActionContainer";

export interface ResendVerificationLinkProps {
  user: User;
  onSuccess: () => void;
}

const ResendVerificationLink = React.forwardRef<
  HTMLLIElement,
  ResendVerificationLinkProps
>(({ user, onSuccess }, ref) => {
  const resendMutation = useResendVerificationLink();

  return (
    <SimpleMutationActionContainer
      ref={ref}
      userUUID={user.uuid}
      mutate={(userUUID) => resendMutation.mutateAsync(userUUID)}
      successMessage={(user) => `Sent ${user.email} the confirmation link`}
      failMessage={(msg) =>
        `Something went wrong when trying to re-send verification link: ${msg}`
      }
      onSuccess={onSuccess}
    >
      Re-send Verification Link
    </SimpleMutationActionContainer>
  );
});

export default ResendVerificationLink;
