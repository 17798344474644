import * as yup from "yup";

export const ContactInfoSchema = yup.object().shape({
  name: yup.string().required("Required"),
  phone_number: yup.string().required("Required"),
});

export default yup
  .array()
  .of(ContactInfoSchema.required("Contact info must not be blank"))
  .required("Required");
