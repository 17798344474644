import React from "react";
import useActivateTeleop from "../useActivateTeleop";

export interface TeleopContainerProps {
  robotID: string;
}

const TeleopContainer: React.FC<TeleopContainerProps> = ({
  robotID,
  children,
}) => {
  useActivateTeleop(robotID);
  return <>{children}</>;
};

export default TeleopContainer;
