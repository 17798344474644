import React, { useEffect, createRef } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { ARState } from "./arstate";
import { ESPG32617ToLocal } from "./coords";
import { interpolateLinearly, jet } from "./colormap";

interface LocalizationProps {
  robotID: string;
}

export const Localization: React.FC<LocalizationProps> = ({ robotID }) => {
  const len = ARState.getState().particles.length;
  const [refs, setRefs] = React.useState<any[]>([]);

  useEffect(() => {
    setRefs((refs) =>
      Array(len)
        .fill(undefined)
        .map((_, i) => refs[i] || createRef()),
    );
  }, [len]);

  const dir = new THREE.Vector3(1, 0, 0);
  useFrame(() => {
    const particles = ARState.getState().particles;
    for (let i = 0; i < len; i++) {
      if (!refs[i] || refs[i].current === undefined) {
        continue;
      }
      const p = particles[i];
      const pos = ESPG32617ToLocal(p.x, p.y);
      refs[i].current.position.set(pos[0], pos[1], 0.1);
      dir.set(Math.cos(p.angle), Math.sin(p.angle), 0);
      refs[i].current.setDirection(dir);
      const c = interpolateLinearly(1.0 - p.alive, jet);
      const color = new THREE.Color(c[0], c[1], c[2]);
      refs[i].current.setColor(color);
    }
  });

  const origin = new THREE.Vector3(0, 0, 0);
  const red = 0xff0000;
  return (
    <>
      {refs.map((el, i) => (
        <arrowHelper
          key={i}
          ref={refs[i]}
          args={[dir, origin, 0.6, red, 0.4, 0.4]}
        />
      ))}
    </>
  );
};
