import * as yup from "yup";

export const EmployeeInfoSchema = yup.object().shape({
  user_uuid: yup.string().required("Required"),
});

export default yup
  .array()
  .of(EmployeeInfoSchema.required("Employee info must not be blank"))
  .required("Required");
