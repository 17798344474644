import React from "react";

import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { useField, FieldHookConfig } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";

export type FormikCurrencyFieldProps = TextFieldProps & FieldHookConfig<number>;

const FormikCurrencyField: React.FC<FormikCurrencyFieldProps> = (props) => {
  const [field, meta, helpers] = useField<number>(props);

  return (
    <TextField
      {...props}
      {...field}
      value={inputRepresentation(field.value)}
      onChange={(evt) => {
        helpers.setValue(parseInput(evt.currentTarget.value));
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  );
};

const parseInput = (v: string): number => {
  const sanitized = v.replaceAll(/\D/g, "");
  if (sanitized === "") {
    return 0;
  } else {
    return Number.parseInt(sanitized);
  }
};

const inputRepresentation = (v: number) => (v / 100).toFixed(2);

export default FormikCurrencyField;
