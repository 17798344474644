import React from "react";

import Grid from "@material-ui/core/Grid";

import FormikWizardStep from "components/common/formik/FormikWizardStep";
import OrderInformationField from "../OrderInformationField";
import StepperNextButton from "components/common/Stepper/StepperNextButton";

export interface OrderWizardPickUpInfoStepProps {
  stepIndex: number;
}

const OrderWizardPickUpInfoStep: React.FC<OrderWizardPickUpInfoStepProps> = (
  stepProps,
) => {
  return (
    <FormikWizardStep
      {...stepProps}
      fieldName="order_information"
      label="Order Information"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <OrderInformationField autoFocus required name="order_information" />
        </Grid>
        <Grid item>
          <StepperNextButton />
        </Grid>
      </Grid>
    </FormikWizardStep>
  );
};

export default OrderWizardPickUpInfoStep;
