import { useMemo } from "react";
import { AxiosInstance, AxiosResponse } from "axios";

import useAxios from "services/axios/useAxios";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import CompleteAddress from "types/Location/CompleteAddress";
import OrderParameters from "types/deliveries/OrderParameters";

class UberEatsOrdersService {
  constructor(private axios: AxiosInstance) {}

  async createOrder(
    parameters: OrderParameters,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.post<UberEatsOrder>("/api/orders", parameters);
  }

  async getAll(): Promise<AxiosResponse<UberEatsOrder[]>> {
    return await this.axios.get<UberEatsOrder[]>("/api/orders");
  }

  async getOrder(orderUUID: string): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.get<UberEatsOrder>(`/api/orders/${orderUUID}`);
  }

  async setRecipientContacts(
    orderUUID: string,
    contacts: DeliveryRecipientContacts,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.put<UberEatsOrder>(
      `/api/orders/${orderUUID}/recipient/contact-info`,
      contacts,
    );
  }

  async setRecipientName(
    orderUUID: string,
    name: DeliveryRecipientName,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.put<UberEatsOrder>(
      `/api/orders/${orderUUID}/recipient/name`,
      name,
    );
  }

  async setRecipientAddress(
    orderUUID: string,
    address: CompleteAddress,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.put<UberEatsOrder>(
      `/api/orders/${orderUUID}/recipient/address`,
      address,
    );
  }

  async setPickUpNotes(
    orderUUID: string,
    pickUpNotes: DeliveryPickUpNotes,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.put<UberEatsOrder>(
      `/api/orders/${orderUUID}/pick-up-notes`,
      pickUpNotes,
    );
  }

  async setDropOffNotes(
    orderUUID: string,
    dropOffNotes: DeliveryDropOffNotes,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.put<UberEatsOrder>(
      `/api/orders/${orderUUID}/drop-off-notes`,
      dropOffNotes,
    );
  }

  async setOrderSubTotalInCents(
    orderUUID: string,
    subTotalInCents: DeliverySubTotalInCents,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    const deliveryCharges: DeliveryCharges = {
      sub_total_in_cents: subTotalInCents.sub_total_in_cents,
      tips_in_cents: -1,
    };
    return await this.axios.put<UberEatsOrder>(
      `/api/orders/${orderUUID}/charges`,
      deliveryCharges,
    );
  }

  async setOrderTipsInCents(
    orderUUID: string,
    tipsInCents: DeliveryTipsInCents,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    const deliveryCharges: DeliveryCharges = {
      sub_total_in_cents: -1,
      tips_in_cents: tipsInCents.tips_in_cents,
    };
    return await this.axios.put<UberEatsOrder>(
      `/api/orders/${orderUUID}/charges`,
      deliveryCharges,
    );
  }

  async setAssignedRobot(
    orderUUID: string,
    robotID: string,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.put<UberEatsOrder>(
      `/api/orders/${orderUUID}/assigned-robot`,
      { robot_id: robotID },
    );
  }

  async deleteAssignedRobot(
    orderUUID: string,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.delete<UberEatsOrder>(
      `/api/orders/${orderUUID}/assigned-robot`,
      {},
    );
  }

  async cancelOrder(orderUUID: string): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.post<UberEatsOrder>(
      `/api/orders/${orderUUID}/cancel`,
      {},
    );
  }

  async getActiveOrderForRobot(
    robotID: string,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.get<UberEatsOrder>(
      `/api/robots/${robotID}/active-order/pilot-view`,
    );
  }

  async sendRobotLeftForPickUp(
    orderUUID: string,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.sendAssignedRobotEvent(orderUUID, "left-for-pick-up");
  }

  async sendRobotArrivedAtPickUp(
    orderUUID: string,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.sendAssignedRobotEvent(orderUUID, "arrived-at-pick-up");
  }

  async sendRobotPickedUpOrder(
    orderUUID: string,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.sendAssignedRobotEvent(orderUUID, "order-picked-up");
  }

  async sendRobotLeftForDropOff(
    orderUUID: string,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.sendAssignedRobotEvent(orderUUID, "left-for-drop-off");
  }

  async sendRobotArrivedAtDropOff(
    orderUUID: string,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.sendAssignedRobotEvent(orderUUID, "arrived-at-drop-off");
  }

  async sendRobotDroppedOffOrder(
    orderUUID: string,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.sendAssignedRobotEvent(orderUUID, "order-dropped-off");
  }

  async startRescue(
    orderUUID: string,
    rescueReason: DeliveryRescueReason,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.post<UberEatsOrder>(
      `/api/orders/${orderUUID}/start-rescue`,
      rescueReason,
    );
  }

  private async sendAssignedRobotEvent(
    orderUUID: string,
    eventKind: string,
  ): Promise<AxiosResponse<UberEatsOrder>> {
    return await this.axios.post<UberEatsOrder>(
      `/api/orders/${orderUUID}/assigned-robot/events`,
      { kind: eventKind },
    );
  }
}

export const useUberEatsOrdersService = () => {
  const axios = useAxios("uber-eats-orders");
  return useMemo(() => new UberEatsOrdersService(axios), [axios]);
};

export interface DeliveryRecipientContacts {
  phone_number: string;
}

export interface DeliveryRecipientName {
  name: string;
}

export interface DeliveryPickUpNotes {
  pick_up_notes: string;
}

export interface DeliveryDropOffNotes {
  drop_off_notes: string;
}

export interface DeliverySubTotalInCents {
  sub_total_in_cents: number;
}

export interface DeliveryTipsInCents {
  tips_in_cents: number;
}

export interface DeliveryCharges {
  sub_total_in_cents: number;
  tips_in_cents: number;
}

export interface DeliveryRescueReason {
  reason: string;
}

export default UberEatsOrdersService;
