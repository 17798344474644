import React from "react";
import { KnownOrderSalesChannel } from "types/deliveries/OrderSalesChannel";
import UberEatsOrderWizard from "../UberEatsOrderWizard";
import DoordashOrderWizard from "../DoordashOrderWizard";
import FacedriveOrderWizard from "../FacedriveOrderWizard";
import SkipTheDishesOrderWizard from "../SkipTheDishesOrderWizard";

export interface OrderWizardTabProps {
  origin: KnownOrderSalesChannel;
  onSubmit?: () => void;
  onSubmissionError?: (err: any) => void;
}

const OrderWizardTab: React.FC<OrderWizardTabProps> = ({
  origin,
  onSubmit = () => {},
  onSubmissionError = () => {},
}) => {
  switch (origin) {
    case "uber-eats":
      return (
        <UberEatsOrderWizard
          onCreated={onSubmit}
          onSubmissionError={onSubmissionError}
        />
      );
    case "doordash":
      return (
        <DoordashOrderWizard
          onCreated={onSubmit}
          onSubmissionError={onSubmissionError}
        />
      );
    case "facedrive":
      return (
        <FacedriveOrderWizard
          onCreated={onSubmit}
          onSubmissionError={onSubmissionError}
        />
      );
    case "skip-the-dishes":
      return (
        <SkipTheDishesOrderWizard
          onCreated={onSubmit}
          onSubmissionError={onSubmissionError}
        />
      );
  }
};

export default OrderWizardTab;
