import React from "react";
import classnames from "classnames";

import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import DrawerHeader from "./DrawerHeader";

import { useDrawer } from "./store";

import { useStyles } from "../styles";

export interface NavigationDrawerProps {
  hideMinimized?: boolean;
}

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  children,
  hideMinimized,
}) => {
  const cls = useStyles();

  const drawer = useDrawer();

  const transitionClassName = classnames({
    [cls.drawerOpen]: drawer.isOpen,
    [cls.drawerClose]: !drawer.isOpen,
    [cls.hide]: !drawer.isOpen && hideMinimized,
  });
  const mainClassName = classnames(cls.drawer, transitionClassName);

  return (
    <Drawer
      variant="permanent"
      className={mainClassName}
      classes={{ paper: transitionClassName }}
    >
      <DrawerHeader />
      <Divider />
      <div onClick={() => drawer.close()}>{children}</div>
    </Drawer>
  );
};

export default NavigationDrawer;
