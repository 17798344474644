import React from "react";
import CompleteAddress from "types/Location/CompleteAddress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AddressSummary from "components/Location/AddressSummary";
import GoogleMapsLinks from "components/Location/GoogleMapsLinks";
import { completeAddressAsPlace } from "types/Location/CompleteAddress";

export interface OrderAddressesWidgetViewProps {
  pickUpAddress: CompleteAddress;
  dropOffAddress: CompleteAddress;
}

const OrderAddressesWidgetView: React.FC<OrderAddressesWidgetViewProps> = ({
  pickUpAddress,
  dropOffAddress,
}) => (
  <Grid container direction="row" spacing={2}>
    <Grid item>
      <Typography variant="subtitle1">Pick Up Address</Typography>
      <Box width="100%" paddingLeft={2}>
        <AddressSummary address={pickUpAddress.address_description} />
        <GoogleMapsLinks address={completeAddressAsPlace(pickUpAddress)} />
      </Box>
    </Grid>
    <Grid item>
      <Typography variant="subtitle1">Drop Off Address</Typography>
      <Box width="100%" paddingLeft={2}>
        <AddressSummary address={dropOffAddress.address_description} />
        <GoogleMapsLinks address={completeAddressAsPlace(dropOffAddress)} />
      </Box>
    </Grid>
  </Grid>
);

export default OrderAddressesWidgetView;
