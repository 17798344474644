import { useEffect, useState } from "react";
import { firebaseAppConfig, fcmKeyPair } from "config/firebase";
import firebase from "firebase/app";
import "firebase/messaging";
import { singletonHook } from "react-singleton-hook";

const useNewFirebaseApp = () => {
  if (firebase.apps.length <= 0) {
    return firebase.initializeApp(firebaseAppConfig);
  } else {
    return firebase.app();
  }
};

export const useFirebaseApp = singletonHook(null, useNewFirebaseApp);

const useGetNewMessagingSubscriptionToken = () => {
  const [token, setToken] = useState<null | string>(null);
  const app = useFirebaseApp();

  useEffect(() => {
    if (app) {
      firebase
        .messaging(app)
        .getToken({ vapidKey: fcmKeyPair })
        .then((currentToken) => {
          if (currentToken) {
            setToken(currentToken);
          } else {
            console.log(
              "no registration token available. request permission to generate one.",
            );
          }
        })
        .catch((err) => {
          console.log("an error occurred while retrieving token. ", err);
        });
    }
  }, [app]);

  return token;
};

export const useMessagingSubscriptionToken = singletonHook(
  null,
  useGetNewMessagingSubscriptionToken,
);
