import React from "react";
import Grid from "@material-ui/core/Grid";

import FormikWizardStep from "components/common/formik/FormikWizardStep";
import StepperBackButton from "components/common/Stepper/BackButton";
import FormikSubmitButton from "components/common/formik/FormikSubmitButton";
import OrderChargesField from "../OrderChargesField";

export interface OrderWizardChargesStepProps {
  stepIndex: number;
}

const OrderWizardChargesStep: React.FC<OrderWizardChargesStepProps> = (
  stepProps,
) => {
  return (
    <FormikWizardStep
      {...stepProps}
      fieldName="charges"
      label="Delivery charges"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <OrderChargesField name="charges" />
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item>
            <StepperBackButton />
          </Grid>
          <Grid item>
            <FormikSubmitButton />
          </Grid>
        </Grid>
      </Grid>
    </FormikWizardStep>
  );
};

export default OrderWizardChargesStep;
