import MapRoutePath from "components/Map/RoutePath";
import React from "react";
import useDestinationStore from "components/teleop/useDestinationStore";
import { useThrottledRobotPosition } from "queries/teleop/RobotTelemetry/Context";

export interface DestinationRouteProps {
  robotID: string;
}

const DestinationRoute: React.FC<DestinationRouteProps> = ({ robotID }) => {
  const { destination } = useDestinationStore();
  const robotPosition = useThrottledRobotPosition(robotID, 10000);
  if (destination) {
    return (
      <MapRoutePath
        origin={robotPosition}
        destination={destination.geolocation}
      />
    );
  } else {
    return <></>;
  }
};

export default DestinationRoute;
