import React from "react";

import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable, { Column } from "material-table";

import UberEatsOrderRowMenu from "./RowMenu";
import { useUberEatsOrders } from "fetchers/deliveries/uber-eats-orders";
import StableLinearProgress from "components/common/StableLinearProgress";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import RecipientPhoneNumberAutoSaveField from "./PhoneNumberAutoSaveField";
import tableIcons from "./tableIcons";

export type UberEatsOrdersDataGridProps = {};

const UberEatsOrdersDataGrid: React.FC<UberEatsOrdersDataGridProps> = () => {
  const { data, isFetching } = useUberEatsOrders({
    refetchInterval: 10 * 1000,
  });
  const history = useHistory();

  if (!data) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <StableLinearProgress show={isFetching} />
        <MaterialTable
          columns={columns}
          data={data}
          icons={tableIcons}
          title="Orders"
          onRowClick={(_, order) => {
            if (order) {
              history.push(`/orders/uber-eats/${order.uuid}`);
            }
          }}
          options={tableOptions}
        />
      </>
    );
  }
};

const columns: Column<UberEatsOrder>[] = [
  {
    title: "Menu",
    render: (order) => <UberEatsOrderRowMenu order={order} />,
    disableClick: true,
  },
  {
    field: "requested_at",
    title: "Requested At",
    type: "datetime",
    defaultSort: "asc",
  },
  { title: "Current stage", field: "current_stage.kind" },
  {
    title: "Current stage ETA",
    field: "current_stage.eta_timestamp",
    type: "time",
  },
  { field: "restaurant_uuid", title: "Restaurant UUID" },
  {
    title: "Recipient Name",
    field: "recipient.name",
  },
  {
    title: "Recipient Phone Number",
    disableClick: true,
    render: (order) => (
      <RecipientPhoneNumberAutoSaveField
        orderUUID={order.uuid}
        currentPhoneNumber={order.recipient.phone_number}
      />
    ),
  },
  {
    title: "Recipient Address",
    field: "recipient.address_description.address_line_1",
  },
  { field: "assigned_robot_id", title: "Assigned Robot" },
  { field: "sales_channel", title: "Sales Channel" },
  {
    title: "Is in final stage",
    field: "current_stage.is_final",
    type: "boolean",
    defaultFilter: "unchecked",
  },
];

const tableOptions = {
  filtering: true,
  sorting: true,
};

export default UberEatsOrdersDataGrid;
