import React from "react";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";

import DrawerLink from "./DrawerLink";

const TeleopButton: React.FC = () => (
  <DrawerLink
    to="/teleop"
    primary="Start Piloting"
    icon={<VideogameAssetIcon />}
  />
);

export default TeleopButton;
