import React from "react";

import DeliveryMap from "../DeliveryMap";
import Grid from "@material-ui/core/Grid";
import DeliveryTeleopMapDestinationOverrideSelect from "../MapDestinationOverrideSelect";
import DeliveryTeleopGoogleMapsDestinationLinks from "../GoogleMapsDestinationLinks";
import OrderAddressesWidget from "../OrderAddressesWidget";

export interface DeliveryTeleopMapWidgetProps {}

const DeliveryTeleopMapWidget: React.FC<DeliveryTeleopMapWidgetProps> = () => (
  <Grid container direction="row" spacing={2}>
    <Grid item>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <DeliveryMap />
        </Grid>
        <Grid item>
          <DeliveryTeleopMapDestinationOverrideSelect />
        </Grid>
        <Grid item>
          <DeliveryTeleopGoogleMapsDestinationLinks />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <OrderAddressesWidget />
    </Grid>
  </Grid>
);

export default DeliveryTeleopMapWidget;
