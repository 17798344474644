import GamepadButtons from "lib/gamepad/types/GamepadButtons";
import { useEffect } from "react";
import useGamepadController from "../useGamepadController";

const useGamepadBind = (button: GamepadButtons, action: () => void) => {
  const gamepad = useGamepadController();
  useEffect(() => {
    if (gamepad) {
      gamepad.bind(button, action);
      return () => gamepad.unbind(button);
    }
  }, [gamepad, button, action]);
};

export default useGamepadBind;
