let defaultAppEnv: string;

switch (process.env.NODE_ENV) {
  case "production":
    defaultAppEnv = "prod";
    break;
  case "development":
    defaultAppEnv = "dev";
    break;
  default:
    defaultAppEnv = process.env.NODE_ENV;
}

export const APP_ENV = process.env.REACT_APP_ENV || defaultAppEnv;

export const ROOT_DOMAIN =
  process.env.REACT_APP_ROOT_DOMAIN || `${APP_ENV}.gcp.tinymile.ai`;

export const GOOGLE_MAPS_API_KEY = "AIzaSyADq6Pu2J1Ergm17eqtP_oqltB2b4D7Xi4";

export const SERVICES_URLS = {
  "pilot-api":
    process.env["REACT_APP_PILOT_API_URL"] ||
    `https://pilot-api.${ROOT_DOMAIN}`,
  teleop:
    process.env["REACT_APP_TELEOP_URL"] || `https://teleop.${ROOT_DOMAIN}`,
  "delivery-orders":
    process.env["REACT_APP_DELIVERY_ORDERS_URL"] ||
    `https://delivery-orders.${ROOT_DOMAIN}`,
  restaurants:
    process.env["REACT_APP_RESTAURANTS_URL"] ||
    `https://restaurants.${ROOT_DOMAIN}`,
  "uber-eats-orders":
    process.env["REACT_APP_UBER_EATS_ORDERS_URL"] ||
    `https://uber-eats-orders.${ROOT_DOMAIN}`,
  auth: process.env["REACT_APP_AUTH_URL"] || `https://auth.${ROOT_DOMAIN}`,
  maps: process.env["REACT_APP_MAPS_URL"] || `https://maps.${ROOT_DOMAIN}`,
  robots:
    process.env["REACT_APP_ROBOTS_URL"] || `https://robots.${ROOT_DOMAIN}`,
  notifications:
    process.env["REACT_APP_NOTIFICATIONS_URL"] ||
    `https://notifications.${ROOT_DOMAIN}`,
  "partners-api":
    process.env["REACT_APP_PARTNERS_API_URL"] ||
    `https://partners-api.${ROOT_DOMAIN}`,
  "media-downloader":
    process.env["REACT_APP_MEDIA_DOWNLOADER_URL"] ||
    `https://media-downloader.${ROOT_DOMAIN}`,
};

export const BASE_API = `${SERVICES_URLS["pilot-api"]}/api`;

export const ROBOT_DISCOVERY_URL = `${SERVICES_URLS["teleop"]}/robots`;
export const TELEOP_SIGNALING_URL = `${SERVICES_URLS["teleop"]}/signal/users`;
export const MAPS_URL = `${SERVICES_URLS["maps"]}`;

let defaultSSOAppURL: string;
if (APP_ENV === "prod") {
  defaultSSOAppURL = "https://sso.tinymile.ai";
} else {
  defaultSSOAppURL = `https://sso.${APP_ENV}.tinymile.ai`;
}

export const SSO_APP_URL =
  process.env["REACT_APP_SSO_APP_URL"] || defaultSSOAppURL;

export const OFFICE_ADDRESS = {
  address_description: {
    address_line_1: "5431 Monroe Rd",
    address_line_2: "",
    locality: "Charlotte",
    state: "North Carolina",
    country: "United States",
    postal_code: "NC 28212",
  },
  geocoded_address: {
    lat: 35.190259,
    lng: -80.775609,
    google_place_id: "ChIJl1RcKUogVIgRSGLO3Jix9l8",
  },
};
export const APP_SIGNAL_API_KEY = process.env["REACT_APP_SIGNAL_API_KEY"];
