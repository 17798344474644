import React from "react";
import ActivateAccountAction from "./ActivateAction";
import DeactivateAccountAction from "./DeactivateAction";
import User from "types/auth/User";

export interface ToggleActiveStatusActionProps {
  user: User;
  onSuccess: () => void;
}

const ToggleActiveStatusAction = React.forwardRef<
  HTMLLIElement,
  ToggleActiveStatusActionProps
>(({ user, onSuccess }, ref) => {
  if (user.is_active) {
    return (
      <DeactivateAccountAction onSuccess={onSuccess} ref={ref} user={user} />
    );
  } else {
    return (
      <ActivateAccountAction onSuccess={onSuccess} ref={ref} user={user} />
    );
  }
});

export default ToggleActiveStatusAction;
