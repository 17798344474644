import React from "react";

import Store from ".";
import StoreContext from "./Context";

const StoreProvider: React.FC = ({ children }) => (
  <StoreContext.Provider value={new Store()}>{children}</StoreContext.Provider>
);

export default StoreProvider;
