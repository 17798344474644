import useAxiosAuthenticationInterceptors from "services/axios/useAxiosAuthenticationInterceptors";
import { useIdentityStore } from "services/auth/identity/store";

const useAuthAxiosFacade = () => {
  const { accessToken } = useIdentityStore(
    ({ isAuthenticated, accessToken }) => ({
      isAuthenticated,
      accessToken,
    }),
  );
  return useAxiosAuthenticationInterceptors({
    authHeader: accessToken ? `Bearer ${accessToken}` : null,
  });
};

export default useAuthAxiosFacade;
