import { Form, Formik, FormikHelpers } from "formik";
import { useCreateRobot } from "mutations/robots";
import React from "react";
import RobotCreationFormData, { initialRobotCreationFormData } from "./Model";
import RobotCreationFormSchema from "./Schema";

export interface RobotCreationFormikProps {
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
}

const RobotCreationFormik: React.FC<RobotCreationFormikProps> = ({
  children,
  onSubmit = () => {},
  onSubmissionError = () => {},
}) => {
  const createRobot = useCreateRobot();

  return (
    <Formik
      validationSchema={RobotCreationFormSchema}
      initialValues={initialRobotCreationFormData}
      onSubmit={(
        formData: RobotCreationFormData,
        helpers: FormikHelpers<RobotCreationFormData>,
      ) => {
        createRobot
          .mutateAsync(formData)
          .then(() => onSubmit())
          .catch((err) => onSubmissionError(err))
          .finally(() => helpers.setSubmitting(false));
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default RobotCreationFormik;
