import RobotDestination from "types/teleop/RobotDestination";
import useDestinationStore from "components/teleop/useDestinationStore";
import useRobotDestinationGoal from "../useRobotDestinationGoal";

export default function useRobotDestination(): null | RobotDestination {
  const goal = useRobotDestinationGoal();
  const destinationOverride = useDestinationStore((s) => s.destination);

  if (destinationOverride) {
    return {
      address_description: destinationOverride,
      geocoded_address: {
        ...destinationOverride.geolocation,
        google_place_id: destinationOverride.google_place_id,
      },
    };
  } else {
    return goal;
  }
}
