import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { useRestaurantsService } from "services/deliveries/Restaurants";
import Restaurant from "types/deliveries/Restaurant";

export function useRestaurant<TData = Restaurant>(
  restaurantUUID?: string,
  queryOptions?: UseQueryOptions<Restaurant, AxiosError, TData>,
) {
  const service = useRestaurantsService();
  return useQuery(
    ["deliveries/restaurants", { uuid: restaurantUUID }],
    () => service.getRestaurant(restaurantUUID!).then((res) => res.data),
    { ...queryOptions, enabled: !!restaurantUUID },
  );
}

export const useRestaurants = (
  queryOptions?: UseQueryOptions<Restaurant[], AxiosError>,
) => {
  const service = useRestaurantsService();
  return useQuery(
    ["deliveries/restaurants", "all"],
    () => service.getAllRestaurants().then((res) => res.data),
    { ...queryOptions },
  );
};
