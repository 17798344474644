import GamepadAxes from "./types/GamepadAxes";
import GamepadButtons from "./types/GamepadButtons";
import GamepadState from "./types/GamepadState";

export class GamepadConnectedEvent extends CustomEvent<ConnectedGamepadEventDetails> {
  constructor(detail: ConnectedGamepadEventDetails) {
    super("gamepad-connected", { detail: detail });
  }
}

export class GamepadDisconnectedEvent extends CustomEvent<GamepadDisconnectedEventDetails> {
  constructor(gamepad: Gamepad) {
    super("gamepad-disconnected", { detail: { gamepad } });
  }
}

export class GamepadPolledEvent extends CustomEvent<ConnectedGamepadEventDetails> {
  constructor(detail: ConnectedGamepadEventDetails) {
    super("gamepad-polled", { detail: detail });
  }
}

export class GamepadButtonEvent extends CustomEvent<GamepadButtonEventDetails> {
  constructor(eventType: string, detail: GamepadButtonEventDetails) {
    super(eventType, { detail: detail });
  }
}

export class GamepadButtonPressedEvent extends GamepadButtonEvent {
  constructor(detail: GamepadButtonEventDetails) {
    super("gamepad-button-pressed", detail);
  }
}

export class GamepadButtonReleasedEvent extends GamepadButtonEvent {
  constructor(detail: GamepadButtonEventDetails) {
    super("gamepad-button-released", detail);
  }
}

export class GamepadAxisMovedEvent extends CustomEvent<GamepadAxisMovedEventDetails> {
  constructor(detail: GamepadAxisMovedEventDetails) {
    super("gamepad-axis-moved", { detail: detail });
  }
}

export interface ConnectedGamepadEventDetails {
  state: GamepadState;
}

export interface GamepadButtonEventDetails
  extends ConnectedGamepadEventDetails {
  button: GamepadButtons;
}

export interface GamepadAxisMovedEventDetails
  extends ConnectedGamepadEventDetails {
  axis: GamepadAxes;
  x: number;
  delta: number;
}

export interface GamepadDisconnectedEventDetails {
  gamepad: Gamepad;
}

type ExtendedGamepadEvent =
  | GamepadConnectedEvent
  | GamepadDisconnectedEvent
  | GamepadPolledEvent
  | GamepadButtonPressedEvent
  | GamepadButtonReleasedEvent
  | GamepadAxisMovedEvent;

export interface GamepadEventsMap {
  "gamepad-connected": GamepadConnectedEvent;
  "gamepad-disconnected": GamepadDisconnectedEvent;
  "gamepad-polled": GamepadPolledEvent;
  "gamepad-button-pressed": GamepadButtonPressedEvent;
  "gamepad-button-released": GamepadButtonReleasedEvent;
  "gamepad-axis-moved": GamepadAxisMovedEvent;
}

export default ExtendedGamepadEvent;
