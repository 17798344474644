import React from "react";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";

import DrawerLink from "./DrawerLink";

const MediaSearchButton: React.FC = () => (
  <DrawerLink
    to="/robots/playback"
    primary="Media Search"
    icon={<VideoLibraryIcon />}
    restrictToRoles={["Supervisor", "Admin"]}
  />
);

export default MediaSearchButton;
