import React from "react";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import OrderCardFieldContainer from "./FieldContainer";

export interface OrderCardImportantTimeSummaryProps {
  order: UberEatsOrder;
  asOf: Date;
}

const OrderCardImportantTimeSummary: React.FC<
  OrderCardImportantTimeSummaryProps
> = ({ order, asOf }) => {
  return (
    <OrderCardFieldContainer
      variant={order.is_rescue_suggested ? "warning" : "default"}
    >
      <Grid container direction="column">
        <Grid item>
          <Typography variant="caption">{eventDescription(order)}</Typography>
        </Grid>
        <Grid item>{eventTime(order, asOf)}</Grid>
      </Grid>
    </OrderCardFieldContainer>
  );
};

const eventDescription = (order: UberEatsOrder): string => {
  switch (order.current_stage.kind) {
    case "order-received":
      return "Created";

    case "order-queued":
      return "Assigned to Robot";

    case "robot-en-route-to-drop-off":
    case "robot-en-route-to-pick-up":
      return "Arriving In";

    case "robot-waiting-for-pick-up":
      return "Parked at Pick Up";

    case "order-picked-up":
      return "Order Picked Up";

    case "robot-waiting-for-drop-off":
      return "Parked at Drop Off";

    case "order-completed":
      return "Completed";

    case "order-canceled":
      return "Canceled";

    default:
      return "Unknown Stage";
  }
};

const eventTime = (order: UberEatsOrder, asOf: Date): string => {
  switch (order.current_stage.kind) {
    case "order-received":
    case "order-queued":
    case "robot-waiting-for-pick-up":
    case "order-picked-up":
    case "robot-waiting-for-drop-off":
    case "order-completed":
    case "order-canceled":
      return `${minutesBetweenDates(
        asOf,
        new Date(order.current_stage.started_at),
      )} minutes ago`;

    case "robot-en-route-to-pick-up":
    case "robot-en-route-to-drop-off":
      if (!order.current_stage.eta_timestamp) {
        return "N/A";
      } else {
        return `${minutesBetweenDates(
          asOf,
          new Date(order.current_stage.eta_timestamp),
        )} minutes`;
      }

    default:
      return "-";
  }
};

const minutesBetweenDates = (d0: Date, d1: Date): number =>
  Math.round(Math.abs((d1.getTime() - d0.getTime()) / (60 * 1000)));

export default OrderCardImportantTimeSummary;
