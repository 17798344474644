import React from "react";
import Grid from "@material-ui/core/Grid";
import DataPreviewEntry from "components/common/DataPreviewEntry";
import Link from "@material-ui/core/Link";
import { googleMapsLink } from "lib/location/places";
import Typography from "@material-ui/core/Typography";
import Restaurant from "types/deliveries/Restaurant";

export interface RestaurantSummaryViewProps {
  restaurant: Restaurant;
  direction: "row" | "column";
}

const RestaurantSummaryView: React.FC<RestaurantSummaryViewProps> = ({
  restaurant,
  direction,
}) => (
  <Grid container spacing={2} direction={direction}>
    <Grid item>
      <DataPreviewEntry label="Name" value={restaurant.name} />
    </Grid>
    <Grid item>
      <DataPreviewEntry
        label="Address"
        value={
          <Link
            href={googleMapsLink(restaurant.geocoded_address)}
            underline="always"
          >
            {restaurant.address_description.address_line_1}
          </Link>
        }
      />
    </Grid>
    <Grid item>
      <DataPreviewEntry
        label="Drop-Off Deadline"
        value={`${restaurant.drop_off_deadline_in_minutes} min`}
      />
    </Grid>
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="subtitle1">Contacts</Typography>
        </Grid>
        {restaurant.contacts.map((contact, i) => (
          <Grid item key={i}>
            <DataPreviewEntry
              label={contact.name}
              value={contact.phone_number}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="subtitle1">Integrations</Typography>
        </Grid>
        {restaurant.external_refs.map((ref, i) => (
          <Grid item key={i}>
            <DataPreviewEntry label={ref.origin} value={ref.id} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);

export default RestaurantSummaryView;
