import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import { useRobots } from "fetchers/robots/robots";
import RobotCardView from "../RobotCard/View";
import Link from "components/common/Link";
import Typography from "@material-ui/core/Typography";
import { useIdentityStore } from "services/auth/identity/store";
import RobotsScreenActions from "./Actions";
import Robot from "types/robots/Robot";
import PushNotificationSubscriber from "components/notifications/PushNotificationSubscriber";

export default function SpacingGrid() {
  const robots = useRobots({
    refetchInterval: 3000,
    select: (robots) => robots.sort(robotComparator),
  });
  const userUUID = useIdentityStore((s) => s.user?.uuid);

  return (
    <DashboardScreenTemplate screenTitle="Robots">
      <PushNotificationSubscriber />
      <RobotsScreenActions />
      <Grid container spacing={4}>
        {(robots.data || []).map((robot) => (
          <Grid key={robot["id"]} item>
            <RobotCardView
              robot={robot}
              selected={robot.assigned_pilot_uuid === userUUID}
              canDrive={robot.assigned_pilot_uuid === userUUID}
            />
          </Grid>
        ))}
      </Grid>
      <Box paddingTop={3}>
        <Typography variant="body2" paragraph align="center">
          Didn't find the robot you were looking for? Try{" "}
          <Link to="/robots/sfu">here</Link> instead or contact an admin through
          Slack
        </Typography>
      </Box>
    </DashboardScreenTemplate>
  );
}

const robotIDCollator = new Intl.Collator([], { numeric: true });

const robotComparator = (rA: Robot, rB: Robot): number => {
  if (rA.deployment_kind === rB.deployment_kind) {
    return robotIDCollator.compare(rB.id, rA.id);
  }
  return (
    deploymentKindPrecendence(rA.deployment_kind) -
    deploymentKindPrecendence(rB.deployment_kind)
  );
};

const deploymentKindPrecendence = (k: string): number => {
  switch (k) {
    case "active":
      return 0;
    case "testing":
      return 1;
    case "retired":
      return 100;
    default:
      return 50;
  }
};
