type GamepadAxes = "l_trigger" | "r_trigger" | "l_x" | "l_y" | "r_x" | "r_y";
export default GamepadAxes;

export const gamepadAxes: GamepadAxes[] = [
  "l_trigger",
  "r_trigger",
  "l_x",
  "l_y",
  "r_x",
  "r_y",
];
