import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import User from "types/auth/User";
import useSetRolesDialogStore from "./useSetRolesDialogStore";

export interface SetRolesActionProps {
  user: User;
}

const SetRolesAction = React.forwardRef<HTMLLIElement, SetRolesActionProps>(
  ({ user }, ref) => {
    const dialog = useSetRolesDialogStore();
    return (
      <MenuItem ref={ref} onClick={() => dialog.open(user)}>
        Set Roles
      </MenuItem>
    );
  },
);

export default SetRolesAction;
