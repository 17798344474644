import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { useCancelUberEatsOrder } from "mutations/deliveries/uber-eats-orders";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import { useActionConfirmationDialog } from "components/common/ActionConfirmationDialog";

export interface CancelOrderButtonProps extends Omit<ButtonProps, "onClick"> {
  orderUUID: string;
}

const CancelOrderButton: React.FC<CancelOrderButtonProps> = ({
  orderUUID,
  ...buttonProps
}) => {
  const cancelOrder = useCancelUberEatsOrder();
  const { showAlert } = useAlertMessage();
  const confirmationDialog = useActionConfirmationDialog();

  const handleClick = () => {
    confirmationDialog.open({
      confirmationText: "This will cancel the order. It cannot be undone",
      onConfirm: handleCancel,
    });
  };

  const handleCancel = () => {
    cancelOrder
      .mutateAsync(orderUUID)
      .then(() => {
        showAlert({
          message: "Order successfully canceled",
          severity: "success",
        });
        confirmationDialog.dismiss();
      })
      .catch(({ message }) => {
        showAlert({
          message: message || "Unknown error",
          severity: "error",
          autoCloseAfterMillis: 5000,
        });
      })
      .finally(() => confirmationDialog.clearLoading());
  };

  return (
    <Button onClick={handleClick} {...buttonProps}>
      Cancel Order
    </Button>
  );
};

export default CancelOrderButton;
