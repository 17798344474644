import React from "react";

import { Marker } from "@react-google-maps/api";
import Geocode from "types/Location/Geocode";

export interface UnknownLocationMarkerProps {
  position?: Geocode;
  tooltip?: string;
}

const UnknownLocationMarker: React.FC<UnknownLocationMarkerProps> = ({
  position = { lat: 43.655997, lng: -79.356572 },
  tooltip = "Location is unknown: showing last known location",
}) => {
  return <Marker position={position} title={tooltip} label="?" />;
};

export default UnknownLocationMarker;
