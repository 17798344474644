import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Box from "@material-ui/core/Box";

import TeleopRobotController from "components/teleop/RobotController";
import RobotCameraFeed from "components/teleop/RobotCameraFeed";
import useDeliveryTeleopSession from "../useDeliveryTeleopSession";
import GamepadWidget from "components/teleop/GamepadWidget";
import TrajectoryIndicatorWidget from "components/teleop/TrajectoryIndicatorWidget";
import DeliveryTeleopMapWidget from "../DeliveryMapWidget";
import NewOrderNotification from "../NewOrderNotification";
import DeliveryStageActionSnackbarWidget from "../StageActionSnackbarWidget";
import DeliveryTeleopHeartbeatWatcher from "../HeartbeatWatcher";
import TeleopHUDContainer from "components/teleop/HUDContainer";
import SuggestionsLink from "components/common/SuggestionsLink";

export type DeliveryTeleopScreenProps = RouteComponentProps<{
  robotID: string;
}>;

const DeliveryTeleopScreen: React.FC<DeliveryTeleopScreenProps> = ({
  match,
}) => {
  const { robotID } = match.params;

  useDeliveryTeleopSession(robotID);
  return (
    <>
      <TeleopRobotController robotID={robotID} />
      <TeleopHUDContainer
        verticalPosition="top"
        horizontalPosition="left"
        alwaysActive
      >
        <DeliveryTeleopMapWidget />
      </TeleopHUDContainer>
      <TeleopHUDContainer
        verticalPosition="top"
        horizontalPosition="right"
        alwaysActive
      >
        <SuggestionsLink />
      </TeleopHUDContainer>
      <TeleopHUDContainer
        verticalPosition="bottom"
        horizontalPosition="left"
        alwaysActive
      ></TeleopHUDContainer>
      <TeleopHUDContainer
        verticalPosition="bottom"
        horizontalPosition="right"
        alwaysActive
      >
        <GamepadWidget />
      </TeleopHUDContainer>
      <TrajectoryIndicatorWidget />
      <NewOrderNotification />
      <DeliveryTeleopHeartbeatWatcher robotID={robotID} />
      <DeliveryStageActionSnackbarWidget />
      <Box width="100%" height="100%" position="absolute" zIndex={-1}>
        <RobotCameraFeed robotID={robotID} />
      </Box>
    </>
  );
};

export default DeliveryTeleopScreen;
