import React from "react";
import ArrivedAtDropOffActionButton from "components/deliveries/ArrivedAtDropOffActionButton";
import useDeliveryTeleopSessionStore from "../useDeliveryTeleopSessionStore";
import useOrderForTeleopSession from "../useOrderForTeleopSession";
import StageActionSnackbarContainer from "./StageActionSnackbarContainer";

export interface ArrivedAtDropOffActionSnackbarProps {}

const ArrivedAtDropOffActionSnackbar: React.FC<
  ArrivedAtDropOffActionSnackbarProps
> = () => {
  const robotID = useDeliveryTeleopSessionStore((s) => s.robotID);
  const order = useOrderForTeleopSession();

  if (!order.data) {
    return <></>;
  }

  return (
    <StageActionSnackbarContainer
      action={
        <ArrivedAtDropOffActionButton
          variant="contained"
          color="primary"
          assignedRobotID={robotID}
        />
      }
    >
      A delivery drop-off is expected on{" "}
      {order.data.recipient.address_description.address_line_1}
    </StageActionSnackbarContainer>
  );
};

export default ArrivedAtDropOffActionSnackbar;
