import React, { useEffect, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import AutocompleteInput from "components/common/AutocompleteInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import { useGoogleAutocompleteService } from "services/location/GoogleAutocompleteService/Context";
import AutocompleteLocationCandidate from "types/Location/AutocompleteCandidate";
import { useDebouncedCallback } from "use-debounce";
import Place from "types/Location/Place";

export interface LocationAutocompleteFieldProps {
  onChange?: (candidate: AutocompleteLocationCandidate | null) => void;
  onSelect?: (chosenLocation: null | Place) => void;
  onClear?: () => void;
  value?: AutocompleteLocationCandidate | null;
  label?: string;
  autoFocus?: boolean;
  error?: boolean;
  helperText?: boolean | string;
  required?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
}

const LocationAutocompleteField: React.FC<LocationAutocompleteFieldProps> = ({
  onSelect,
  onChange,
  value = null,
  label,
  autoFocus,
  error = false,
  helperText = false,
  required = false,
  disableClearable = false,
  onClear = () => {},
  disabled = false,
}) => {
  const [touched, setTouched] = useState(false);
  const [selectedState, setSelected] =
    useState<AutocompleteLocationCandidate | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState("");
  const [availableOptions, setAvailableOptions] = useState<
    AutocompleteLocationCandidate[]
  >([]);
  const autocompleteService = useGoogleAutocompleteService();
  const minLengthAutocomplete = 2;
  const selected = touched ? selectedState : value;

  const fetchSuggestions = useDebouncedCallback(async (value) => {
    let suggestions: AutocompleteLocationCandidate[] = [];
    if (autocompleteService && value.length >= minLengthAutocomplete) {
      const receivedSugestions = await autocompleteService!.getPlacePredictions(
        value,
      );
      suggestions = receivedSugestions;
    }
    if (
      selected &&
      !suggestions.find((v) => selected.place_id === v.place_id)
    ) {
      suggestions.push(selected);
    }
    setAvailableOptions(suggestions);
    setLoading(false);
  }, 200);

  const handleChange = (
    newSelected: AutocompleteLocationCandidate | null,
    reason: string,
  ) => {
    if (reason === "clear") {
      onClear();
    }
    setTouched(true);
    setSelected(newSelected);
    if (onChange) {
      onChange(newSelected);
    }
    if (onSelect) {
      if (newSelected) {
        setLoading(true);
        autocompleteService!
          .getPlaceForPrediction(newSelected)
          .then(onSelect)
          .finally(() => setLoading(false));
      } else {
        onSelect(null);
      }
    }
  };

  useEffect(() => {
    if (query === "") {
      setAvailableOptions(selected ? [selected] : []);
    }
    setLoading(true);
    fetchSuggestions.callback(query);
  }, [selected, query, fetchSuggestions]);

  return (
    <Autocomplete
      autoComplete
      getOptionLabel={(option: AutocompleteLocationCandidate) =>
        option.description
      }
      getOptionSelected={(option, selected) =>
        option.place_id === selected?.place_id
      }
      loading={loading}
      renderInput={(params) => (
        <>
          <AutocompleteInput
            error={error}
            autoFocus={autoFocus}
            label={label ?? "Address"}
            isLoading={loading}
            required={required}
            {...params}
          />
          {helperText ? (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          ) : null}
        </>
      )}
      renderOption={(option: AutocompleteLocationCandidate) => (
        <Typography>{option.description}</Typography>
      )}
      options={availableOptions}
      value={selected}
      onChange={(_event, newVal, reason) => handleChange(newVal, reason)}
      onInputChange={(_, newQuery) => setQuery(newQuery)}
      disableClearable={disableClearable}
      disabled={disabled}
    />
  );
};

export default LocationAutocompleteField;
