import GoogleMapsLinks from "components/Location/GoogleMapsLinks";
import React from "react";
import useRobotDestination from "../useRobotDestination";
import { robotDestinationAsPlace } from "types/teleop/RobotDestination";

export interface DeliveryTeleopGoogleMapsDestinationLinksProps {}

const DeliveryTeleopGoogleMapsDestinationLinks: React.FC<
  DeliveryTeleopGoogleMapsDestinationLinksProps
> = () => {
  const destination = useRobotDestination();
  if (destination) {
    return <GoogleMapsLinks address={robotDestinationAsPlace(destination)} />;
  } else {
    return <></>;
  }
};

export default DeliveryTeleopGoogleMapsDestinationLinks;
