import React from "react";
import { useField, useFormikContext } from "formik";
import { locationCandidateFromPlace } from "lib/location/places";
import LocationAutocompleteField from "components/Location/AutocompleteField";

export interface EaterAddressPlaceFieldProps {
  name: string;
  label: string;
  isEditable: boolean;
  autoFocus?: boolean;
  required?: boolean;
}

const EaterAddressPlaceField: React.FC<EaterAddressPlaceFieldProps> = ({
  name,
  label,
  isEditable,
  ...props
}) => {
  const { submitForm } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  return (
    <LocationAutocompleteField
      {...props}
      label={label}
      value={field.value ? locationCandidateFromPlace(field.value) : null}
      onSelect={(place) => {
        helpers.setValue(place);
        submitForm();
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      disabled={!isEditable}
    />
  );
};

export default EaterAddressPlaceField;
