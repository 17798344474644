import React, { useEffect, useMemo } from "react";

import { Route, RouteProps } from "react-router-dom";
import { usePollRefreshAccessToken } from "mutations/auth";
import SimpleBrackdrop from "components/SimpleBackdrop";
import useAuthAxiosFacade from "services/auth/axios/useAxiosAuthFacade";
import useSSORedirectURL from "services/auth/useSSORedirectURL";

export type SecureRouteProps = RouteProps;

const SecureRoute: React.FC<SecureRouteProps> = (props) => {
  const axiosReady = useAuthAxiosFacade();
  const mutation = usePollRefreshAccessToken(60 * 1000);
  const ssoRedirectURL = useSSORedirectURL("sign-in");

  const errorResponseStatus = mutation.error?.response?.status;

  useEffect(() => {
    if (
      errorResponseStatus &&
      errorResponseStatus >= 400 &&
      errorResponseStatus < 500
    ) {
      window.location.href = ssoRedirectURL;
    }
  }, [errorResponseStatus, ssoRedirectURL]);

  return useMemo(() => {
    if (!axiosReady) {
      return <SimpleBrackdrop />;
    } else {
      return <Route {...props} />;
    }
  }, [axiosReady, props]);
};

export default SecureRoute;
