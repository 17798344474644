import React from "react";
import { useStyles } from "./styles";
import { useThrottledSirenState } from "queries/teleop/RobotTelemetry/Context";

export type SirenStatusWidgetProps = {
  robotID: string;
};

const SirenStatusWidget: React.FC<SirenStatusWidgetProps> = ({ robotID }) => {
  const cls = useStyles();
  const sirenState = useThrottledSirenState(robotID);

  return (
    <div className={cls.container}>
      <p>Siren: {sirenState}</p>
    </div>
  );
};

export default SirenStatusWidget;
