import { ReprojectionController } from "lib/reprojection";
import { createContext } from "react";

export interface VideoReprojection extends ReprojectionController {
  source: HTMLVideoElement;
  bindReprojection: (params: ReprojectionBindParams) => void;
}

export interface ReprojectionBindParams {
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
}

export default createContext<VideoReprojection>({
  render: () => {},
  setPOV: () => {},
  bindReprojection: () => {},
  source: document.createElement("video"),
});
