import { UseQueryOptions, useQuery } from "react-query";

import { useTrailsService } from "services/robots/TrailsService";
import { TimestampedGeocode } from "types/Location/Geocode";

export const useRobotsTrailsQuery = (
  queryOptions?: UseQueryOptions<{ [robotID: string]: TimestampedGeocode[] }>,
) => {
  const service = useTrailsService();
  return useQuery(
    ["robots/trails", "all"],
    () => service.getTrails().then((res) => res.data),
    { initialData: {}, ...queryOptions },
  );
};
