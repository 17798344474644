import React from "react";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Step, { StepProps } from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import { useField } from "formik";
import useStepper from "components/common/Stepper/useStepper";

export interface FormikWizardStepProps
  extends Omit<StepProps, "key" | "completed"> {
  fieldName: string;
  stepIndex: number;
  label: string;
  isStepClickable?: boolean;
}

const FormikWizardStep: React.FC<FormikWizardStepProps> = ({
  fieldName,
  label,
  children,
  stepIndex,
  isStepClickable = false,
  ...props
}) => {
  const [, meta] = useField(fieldName);
  const { activeStep, setActiveStep } = useStepper();
  const isActiveStep = activeStep === stepIndex;

  return (
    <Step
      {...props}
      key={fieldName}
      completed={meta.touched && !meta.error && stepIndex < activeStep}
    >
      <StepButton
        onClick={() => setActiveStep(stepIndex)}
        completed={meta.touched && !meta.error && !isActiveStep}
      >
        <StepLabel error={meta.touched && !isActiveStep && Boolean(meta.error)}>
          {label}
        </StepLabel>
      </StepButton>
      {children ? <StepContent>{children}</StepContent> : null}
    </Step>
  );
};

export default FormikWizardStep;
