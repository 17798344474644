import React from "react";
import { useField } from "formik";
import RobotAutocompleteFieldView from "../AutocompleteField";

export interface RobotAutocompleteFieldProps {
  autoFocus?: boolean;
  required?: boolean;
  label?: string;
  fullWidth?: boolean;
  name: string;
}

const RobotAutocompleteField: React.FC<RobotAutocompleteFieldProps> = ({
  name,
  ...viewProps
}) => {
  const [field, , helpers] = useField<string>(name);
  return (
    <RobotAutocompleteFieldView
      {...viewProps}
      {...field}
      onChange={(s) => helpers.setValue(s === null ? "" : s)}
    />
  );
};

export default RobotAutocompleteField;
