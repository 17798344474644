import React, { useState } from "react";

import DataGridRowActionsButton from "components/common/DataGridRowActionsButton";
import UberEatsOrderRowMenuContainer from "./Container";
import AssignToRobotAction from "./AssignToRobotAction";
import CancelOrderAction from "./CancelOrderAction";
import UberEatsOrder from "types/deliveries/UberEatsOrder";

export interface UberEatsOrderRowMenuProps {
  order: UberEatsOrder;
}

const UberEatsOrderRowMenu: React.FC<UberEatsOrderRowMenuProps> = ({
  order,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const closeMenu = () => setAnchorEl(null);

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <DataGridRowActionsButton
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
      />
      <UberEatsOrderRowMenuContainer
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
      >
        <AssignToRobotAction order={order} onAssign={closeMenu} />
        {order.current_stage.is_final ? null : (
          <CancelOrderAction orderUUID={order.uuid} onCancel={closeMenu} />
        )}
      </UberEatsOrderRowMenuContainer>
    </>
  );
};

export default UberEatsOrderRowMenu;
