import React, { useCallback, useState } from "react";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import OrderCardSummary from "./Summary";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import OrderCardDetails from "./Details";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CancelOrderButton from "../CancelOrderButton";

export interface OrderCardViewProps {
  order: UberEatsOrder;
  asOf: Date;
}

const OrderCardView: React.FC<OrderCardViewProps> = ({ order, asOf }) => {
  const [isEditable, setEditable] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => {
    setExpanded((isExpanded) => !isExpanded);
  }, [setExpanded]);

  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
      <Card>
        <CardHeader
          title={titleText(order)}
          action={
            <IconButton onClick={toggleExpanded}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          }
        />
        <CardContent>
          <OrderCardSummary order={order} asOf={asOf} />
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box paddingTop={3}>
              <OrderCardDetails isEditable={isEditable} order={order} />
            </Box>
          </Collapse>
        </CardContent>
        <CardActions>
          {!isEditable && (
            <Button onClick={() => setEditable(true)}>Edit Order</Button>
          )}
          {isEditable && (
            <Button onClick={() => setEditable(false)}>Finished Editing</Button>
          )}
          <CancelOrderButton orderUUID={order.uuid} />
        </CardActions>
      </Card>
    </ClickAwayListener>
  );
};

const titleText = (order: UberEatsOrder): string =>
  `${order.is_mock ? "(mock) " : ""}${
    order.sales_channel
  }:${externalIDRepresentation(order)} / ${stageRepresentation(order)}`;

const stageRepresentation = (order: UberEatsOrder): string => {
  switch (order.current_stage.kind) {
    case "order-received":
      return "New";
    case "order-queued":
      return "Queued";
    case "robot-en-route-to-pick-up":
      return "En Route To Pick Up";
    case "robot-waiting-for-pick-up":
      return "Waiting For Pick Up";
    case "order-picked-up":
      return "Order Picked Up";
    case "robot-en-route-to-drop-off":
      return "En Route to Drop Off";
    case "robot-waiting-for-drop-off":
      return "Waiting to Drop Off";
    case "order-completed":
      return "Completed";
    case "order-canceled":
      return "Canceled";
    default:
      return `Unknown stage (${order.current_stage.kind})`;
  }
};

const externalIDRepresentation = (order: UberEatsOrder): string => {
  switch (order.sales_channel) {
    case "uber-eats":
      return order.external_id.substr(-5).toUpperCase();
    default:
      return order.external_id;
  }
};

export default OrderCardView;
