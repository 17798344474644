import React from "react";
import useNewOrderNotifier from "../useNewOrderNotifier";

export interface NewOrderNotificationProps {}

const NewOrderNotification: React.FC<NewOrderNotificationProps> = () => {
  useNewOrderNotifier();
  return <></>;
};

export default NewOrderNotification;
