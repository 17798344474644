import React from "react";

import Grid from "@material-ui/core/Grid";
import AddressField from "components/Location/AddressField";
import FormikTextField from "components/common/formik/FormikTextField";
import FormikCheckboxField from "components/common/formik/FormikCheckboxField";
import { useField } from "formik";

export interface DeliveryDetailsFieldProps {
  autoFocus?: boolean;
  required?: boolean;
  name: string;
}

const DeliveryDetailsField: React.FC<DeliveryDetailsFieldProps> = ({
  autoFocus = false,
  required = false,
  name,
}) => {
  const [hasPhoneNumberField] = useField<boolean>(
    `${name}.has_direct_recipient_contact`,
  );
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <AddressField
          name={`${name}.recipient_address`}
          autoFocus={autoFocus}
          required={required}
        />
      </Grid>
      <Grid item>
        <FormikTextField
          fullWidth
          label={hasPhoneNumberField.value ? "Phone Number" : "Phone Code"}
          required={required}
          name={`${name}.recipient_phone_number_or_code`}
        />
      </Grid>
      <Grid item>
        <FormikCheckboxField
          name={`${name}.has_direct_recipient_contact`}
          label="This is recipient's own phone number"
        />
      </Grid>
      <Grid item>
        <FormikTextField
          fullWidth
          name={`${name}.drop_off_notes`}
          label="Drop off notes"
        />
      </Grid>
    </Grid>
  );
};

export default DeliveryDetailsField;
