import React from "react";
import ParkingSpotKind from "./ParkingSpotKind";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

export interface ParkingSpotWizardOnboardingProps {
  onSelectKind: (kind: ParkingSpotKind) => void;
}

const ParkingSpotWizardOnboarding: React.FC<
  ParkingSpotWizardOnboardingProps
> = ({ onSelectKind }) => {
  return (
    <>
      <Typography variant="body1" paragraph>
        What kind of parking spot is it?
      </Typography>
      <Grid container justify="space-around" spacing={3}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onSelectKind("restaurant")}
          >
            Outside a restaurant
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onSelectKind("office")}
          >
            At the office
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onSelectKind("generic")}
          >
            On another address
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ParkingSpotWizardOnboarding;
