import React, { useEffect, useState } from "react";
import OrientationMarker from "components/Map/OrientationMarker";
import { useThrottledRobotPosition } from "queries/teleop/RobotTelemetry/Context";
import { useThrottledRobotDirection } from "queries/teleop/RobotTelemetry/Context";
import LastKnownRobotPositionMarker from "../LastKnownRobotPositionMarker";

export interface RobotMapMarkerProps {
  robotID: string;
}

const RobotMapMarker: React.FC<RobotMapMarkerProps> = ({ robotID }) => {
  const livePosition = useThrottledRobotPosition(robotID);
  const liveDirection = useThrottledRobotDirection(robotID);
  const [isLive, setStreamLiveness] = useState(false);

  useEffect(() => {
    setStreamLiveness(true);
    const timeoutID = setTimeout(() => setStreamLiveness(false), 3000);
    return () => clearTimeout(timeoutID);
  }, [livePosition]);
  if (livePosition) {
    return (
      <OrientationMarker
        position={livePosition}
        fillColor={isLive ? "blue" : "lightGray"}
        rotationDegrees={liveDirection != null ? liveDirection.bearing : 0}
      />
    );
  } else {
    return <LastKnownRobotPositionMarker robotID={robotID} />;
  }
};

export default RobotMapMarker;
