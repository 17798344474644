import React from "react";

import Menu from "@material-ui/core/Menu";

export interface RestaurantRowMenuContainerProps {
  anchorEl: null | Element;
  open: boolean;
  onClose: () => void;
}

const RestaurantRowMenuContainer: React.FC<RestaurantRowMenuContainerProps> = ({
  anchorEl,
  open,
  children,
  onClose,
}) => {
  return (
    <Menu
      id="restaurant-row-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{ style: { width: "35ch" } }}
    >
      {children}
    </Menu>
  );
};

export default RestaurantRowMenuContainer;
