import GoogleMapsService from "../GoogleMapsService";
import Place from "types/Location/Place";
import AutocompleteLocationCandidate from "types/Location/AutocompleteCandidate";
import { OFFICE_ADDRESS } from "config/constants";

// Selected the basic details fields to avoid costlier charges
const DETAILS_FIELDS = [
  "adr_address", // see http://microformats.org/wiki/adr
  "address_components",
  "business_status",
  "formatted_address",
  "geometry",
  "name",
  "types",
];

class GoogleAutocompleteService {
  private sessionToken: null | google.maps.places.AutocompleteSessionToken =
    null;
  private virtualElementHack: HTMLDivElement = document.createElement("div");

  constructor(private mapsService: GoogleMapsService) {}

  private async getService(): Promise<google.maps.places.AutocompleteService> {
    const mapsApi = await this.mapsService.getMaps();
    return new mapsApi!.places.AutocompleteService();
  }

  private async getSessionToken(destroy?: boolean) {
    const mapsApi = await this.mapsService.getMaps();
    if (!this.sessionToken) {
      this.sessionToken = new mapsApi!.places.AutocompleteSessionToken();
    }
    const sessionToken = this.sessionToken;
    if (destroy) {
      this.sessionToken = null;
    }
    return sessionToken;
  }

  private async getPlaceDetails(
    placeId: string,
  ): Promise<google.maps.places.PlaceResult> {
    const sessionToken = await this.getSessionToken(true);
    const mapsApi = await this.mapsService.getMaps();
    const placesApi = new mapsApi!.places.PlacesService(
      this.virtualElementHack,
    );

    return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
      placesApi.getDetails(
        { sessionToken, placeId, fields: DETAILS_FIELDS },
        (result, serviceStatus) => {
          if (serviceStatus === google.maps.places.PlacesServiceStatus.OK) {
            resolve(result);
          } else {
            reject(
              new Error(
                `Request for google places details failed with status ${serviceStatus}`,
              ),
            );
          }
        },
      );
    });
  }

  async getPlaceForPrediction(
    prediction: AutocompleteLocationCandidate,
  ): Promise<Place> {
    const details = await this.getPlaceDetails(prediction.place_id);
    return {
      google_place_id: prediction.place_id,
      address_line_1: prediction.structured_formatting.main_text,
      address_line_2: "",
      geolocation: {
        lat: details!.geometry!.location.lat(),
        lng: details!.geometry!.location.lng(),
      },
      country: details!.address_components!.find((v) =>
        v.types.includes("country"),
      )?.long_name!,
      state: details!.address_components!.find((v) =>
        v.types.includes("administrative_area_level_1"),
      )?.long_name!,
      locality: details!.address_components!.find((v) =>
        v.types.includes("locality"),
      )?.long_name!,
      postal_code: details!.address_components!.find((v) =>
        v.types.includes("postal_code"),
      )?.long_name!,
    };
  }

  async getPlacePredictions(
    query: string,
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    try {
      const service = await this.getService();
      const sessionToken = await this.getSessionToken();

      const predictionsPromise = new Promise<
        google.maps.places.AutocompletePrediction[]
      >((resolve, reject) =>
        service!.getPlacePredictions(
          {
            input: query,
            // componentRestrictions: { country: "ca" },
            // bounds: new maps!.LatLngBounds(
            //   { lat: 43.586189, lng: -79.544202 },
            //   { lat: 43.717546, lng: -79.358477 }
            // ),
            location: new google.maps.LatLng(
              OFFICE_ADDRESS.geocoded_address.lat,
              OFFICE_ADDRESS.geocoded_address.lng,
            ),
            radius: 50000,
            types: ["address"], // https://developers.google.com/places/supported_types#table3
            sessionToken,
          },
          (result, serviceStatus) => {
            // TODO handle errors
            switch (serviceStatus) {
              case google.maps.places.PlacesServiceStatus.OK:
                resolve(result);
                break;
              case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                resolve([]);
                break;
              default:
                reject(
                  new Error(
                    `Request to google places autocomplete failed with status ${serviceStatus}`,
                  ),
                );
            }
          },
        ),
      );

      return await predictionsPromise;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
}

export default GoogleAutocompleteService;
