import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import React from "react";
import Container from "@material-ui/core/Container";
import OrderCardsList from "../OrderCardsList";
import OrderWizardDialog from "components/dispatch/OrderWizardDialog";
import Box from "@material-ui/core/Box";
import { useIsFetching } from "react-query";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";

export interface OrderCardsScreenProps {}

const OrderCardsScreen: React.FC<OrderCardsScreenProps> = () => {
  const isLoading = useIsFetching("deliveries/orders");
  return (
    <DashboardScreenTemplate screenTitle="Orders">
      <Box minHeight="30px">{isLoading ? <LinearProgress /> : null}</Box>
      <Container>
        <OrderWizardDialog TriggerButton={OrderWizardTriggerButton} />
        <OrderCardsList />
      </Container>
    </DashboardScreenTemplate>
  );
};

const OrderWizardTriggerButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <Box marginBottom={3} display="flex" width="100%" flexDirection="row-reverse">
    <Button variant="contained" color="primary" onClick={onClick}>
      Create Order
    </Button>
  </Box>
);

export default OrderCardsScreen;
