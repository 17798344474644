import React, { useState } from "react";

import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import { filter as fuzzy } from "fuzzyjs";

import AutocompleteInput from "components/common/AutocompleteInput";
import User from "types/auth/User";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import usePilots from "../usePilots";

export interface PilotAutocompleteFieldViewProps {
  label?: string;
  autoFocus?: boolean;
  required?: boolean;
  onQueryChange?: (query: string) => void;
  isLoading?: boolean;
  value: null | User;
  initialQuery?: string;
  onChange: AutocompleteProps<null | User, false, false, false>["onChange"];
  onBlur?: AutocompleteProps<null | User, false, false, false>["onBlur"];
  onFocus?: AutocompleteProps<null | User, false, false, false>["onFocus"];
}

const PilotAutocompleteFieldView: React.FC<PilotAutocompleteFieldViewProps> =
  React.memo(
    ({
      label = "Assigned pilot",
      autoFocus = false,
      required = false,
      onChange,
      value = null,
      initialQuery = "",
      onBlur = () => {},
      onFocus = () => {},
      onQueryChange = () => {},
      isLoading = false,
    }) => {
      const pilots = usePilots();
      const [userQuery, setUserQuery] = useState(initialQuery);

      const candidates: User[] = _filterAutocompleteCandidates(
        userQuery,
        pilots.data || [],
      );
      if (
        value &&
        !candidates.find((candidate) => value.uuid === candidate.uuid)
      ) {
        candidates.push(value);
      }

      return (
        <Autocomplete
          autoComplete
          fullWidth
          loading={pilots.isFetching || pilots.isLoading || isLoading}
          disabled={pilots.isLoading || isLoading}
          options={candidates}
          getOptionLabel={userLabel}
          getOptionSelected={(option, val) => option.uuid === val.uuid}
          renderOption={(option: User) => (
            <Grid container direction="column">
              <Grid item>
                <Typography variant="body2">
                  {`${option.first_name}`} {`${option.last_name}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{` ${option.email} `}</Typography>
              </Grid>
            </Grid>
          )}
          renderInput={(params) => (
            <AutocompleteInput
              required={required}
              autoFocus={autoFocus}
              label={label}
              isLoading={pilots.isFetching}
              {...params}
            />
          )}
          onInputChange={(_, newQuery) => {
            setUserQuery(newQuery);
            onQueryChange(newQuery);
          }}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
        />
      );
    },
  );

const userLabel = (user: User) =>
  `${user.email} (${user.first_name} ${user.last_name})`;

const _filterAutocompleteCandidates = (query: string, users: User[]) =>
  users.filter(
    fuzzy(query, {
      sourceAccessor: userLabel,
    }),
  );

export default PilotAutocompleteFieldView;
