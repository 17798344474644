import React from "react";

import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

export interface RestaurantContactsFieldArrayLabelProps {
  onAddContact: () => void;
}

const RestaurantContactsFieldArrayLabel: React.FC<
  RestaurantContactsFieldArrayLabelProps
> = ({ onAddContact }) => (
  <Grid container direction="row" alignItems="center" spacing={2}>
    <Grid item>
      <Typography variant="subtitle1">Contacts</Typography>
    </Grid>
    <Grid item>
      <IconButton onClick={() => onAddContact()} size="small">
        <AddIcon />
      </IconButton>
    </Grid>
  </Grid>
);

export default RestaurantContactsFieldArrayLabel;
