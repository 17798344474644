import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import React from "react";
import RestaurantsDashboardActions from "./Actions";
import RestaurantsDataGrid from "../RestaurantsDataGrid";

export interface RestaurantsScreenProps {}

const RestaurantsScreen: React.FC<RestaurantsScreenProps> = () => {
  return (
    <DashboardScreenTemplate screenTitle="Restaurants">
      <RestaurantsDashboardActions />
      <RestaurantsDataGrid />
    </DashboardScreenTemplate>
  );
};

export default RestaurantsScreen;
