import GamepadEventTarget from "./GamepadEventTarget";
import GamepadState from "./types/GamepadState";
import {
  GamepadAxisMovedEvent,
  GamepadButtonEvent,
  GamepadButtonPressedEvent,
  GamepadButtonReleasedEvent,
  GamepadConnectedEvent,
  GamepadDisconnectedEvent,
  GamepadPolledEvent,
} from "./gamepad-events";
import GamepadButtons, { gamepadButtons } from "./types/GamepadButtons";
import GamepadAxes, { gamepadAxes } from "./types/GamepadAxes";

export class GamepadController extends GamepadEventTarget {
  constructor(private gamepadID: number, pollingFreqInHz = 120) {
    super();

    this.gamepadConnectedListener = (evt: Event) => {
      const e = evt as GamepadEvent;
      if (e.gamepad.index === this.gamepadID) {
        this.dispatchEvent(
          new GamepadConnectedEvent({ state: parseGamepad(e.gamepad) }),
        );
      }
    };

    this.gamepadDisconnectedListener = (evt: Event) => {
      const e = evt as GamepadEvent;
      if (e.gamepad.index === this.gamepadID) {
        this.dispatchEvent(new GamepadDisconnectedEvent(e.gamepad));
      }
    };

    this.currentState = null;

    this.keybinds = {};

    window.addEventListener("gamepadconnected", this.gamepadConnectedListener);
    window.addEventListener(
      "gamepaddisconnected",
      this.gamepadDisconnectedListener,
    );

    this.pollingInterval = setInterval(() => {
      this.poll();
    }, 1 / pollingFreqInHz);
  }

  destroy() {
    clearInterval(this.pollingInterval);

    window.removeEventListener(
      "gamepadconnected",
      this.gamepadConnectedListener,
    );
    window.removeEventListener(
      "gamepaddisconnected",
      this.gamepadDisconnectedListener,
    );
  }

  poll() {
    const gamepad = this.getGamepad();

    if (gamepad) {
      const newState = parseGamepad(gamepad);
      const prevState = this.currentState;

      this.dispatchEvent(new GamepadPolledEvent({ state: newState }));

      buttonEventsForNewState(prevState, newState).forEach((evt) =>
        this.dispatchEvent(evt),
      );
      axisEventsForNewState(prevState, newState).forEach((evt) =>
        this.dispatchEvent(evt),
      );

      this.currentState = newState;
    }
  }

  bind(button: GamepadButtons, listener: () => void) {
    if (this.keybinds[button]) {
      console.warn(
        `Overriding existing bind for button ${button} in gamepad ${this.gamepadID}.`,
      );
    }

    const wrappedListener = (evt: GamepadButtonPressedEvent) => {
      if (evt.detail.button === button) {
        listener();
      }
    };

    this.keybinds[button] = wrappedListener;
    this.addEventListener("gamepad-button-pressed", wrappedListener);
  }

  unbind(button: GamepadButtons) {
    const listener = this.keybinds[button];

    if (listener) {
      this.removeEventListener(listener);
      delete this.keybinds[button];
    }
  }

  private getGamepad(): Gamepad | null {
    const gamepads = navigator.getGamepads();
    if (gamepads.length > this.gamepadID) {
      return gamepads[this.gamepadID];
    }
    return null;
  }

  private gamepadConnectedListener: (evt: Event) => void;
  private gamepadDisconnectedListener: (evt: Event) => void;
  private currentState: GamepadState | null;
  private keybinds: {
    [k in GamepadButtons]?: (evt: GamepadButtonPressedEvent) => void;
  };
  private pollingInterval: ReturnType<typeof setInterval>;
}

const axisEventsForNewState = (
  prevState: null | GamepadState,
  currState: null | GamepadState,
) => {
  return gamepadAxes
    .map((axis) => axisEventForNewState(axis, prevState, currState))
    .filter((evt): evt is GamepadAxisMovedEvent => !!evt);
};

const axisEventForNewState = (
  axis: GamepadAxes,
  prevState: null | GamepadState,
  currState: null | GamepadState,
): GamepadAxisMovedEvent | null => {
  if (
    !prevState ||
    !currState ||
    prevState.axes[axis] === currState.axes[axis]
  ) {
    return null;
  } else {
    return new GamepadAxisMovedEvent({
      state: currState,
      axis,
      x: currState.axes[axis],
      delta: currState.axes[axis] - prevState.axes[axis],
    });
  }
};

const buttonEventsForNewState = (
  prevState: null | GamepadState,
  currState: null | GamepadState,
): GamepadButtonEvent[] => {
  return gamepadButtons
    .map((button) => buttonEventForNewState(button, prevState, currState))
    .filter((v): v is GamepadButtonEvent => v != null);
};

const buttonEventForNewState = (
  button: GamepadButtons,
  prevState: null | GamepadState,
  currState: null | GamepadState,
): GamepadButtonEvent | null => {
  if (
    !prevState ||
    !currState ||
    prevState.buttons[button] === currState.buttons[button]
  ) {
    return null;
  } else if (currState.buttons[button]) {
    return new GamepadButtonPressedEvent({ state: currState, button });
  } else {
    return new GamepadButtonReleasedEvent({ state: currState, button });
  }
};

const parseGamepad = (gpad: Gamepad): GamepadState => ({
  gamepad: gpad,
  buttons: {
    a: !!parseInt(gpad.buttons[0].value.toFixed(0)),
    b: !!parseInt(gpad.buttons[1].value.toFixed(0)),
    x: !!parseInt(gpad.buttons[2].value.toFixed(0)),
    y: !!parseInt(gpad.buttons[3].value.toFixed(0)),
    l_bumper: !!parseInt(gpad.buttons[4].value.toFixed(0)),
    r_bumper: !!parseInt(gpad.buttons[5].value.toFixed(0)),
    view: !!parseInt(gpad.buttons[8].value.toFixed(0)),
    menu: !!parseInt(gpad.buttons[9].value.toFixed(0)),
    l_stick: !!parseInt(gpad.buttons[10].value.toFixed(0)),
    r_stick: !!parseInt(gpad.buttons[11].value.toFixed(0)),
    d_up: !!parseInt(gpad.buttons[12].value.toFixed(0)),
    d_down: !!parseInt(gpad.buttons[13].value.toFixed(0)),
    d_left: !!parseInt(gpad.buttons[14].value.toFixed(0)),
    d_right: !!parseInt(gpad.buttons[15].value.toFixed(0)),
  },
  axes: {
    l_trigger: parseFloat(gpad.buttons[6].value.toFixed(2)),
    r_trigger: parseFloat(gpad.buttons[7].value.toFixed(2)),
    l_x: parseFloat(gpad.axes[0].toFixed(2)),
    l_y: -parseFloat(gpad.axes[1].toFixed(2)),
    r_x: parseFloat(gpad.axes[2].toFixed(2)),
    r_y: -parseFloat(gpad.axes[3].toFixed(2)),
  },
  timestamp: gpad.timestamp,
});
