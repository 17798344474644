import React from "react";
import { useStyles } from "./styles";
import VisualGamepad from "components/gamepad/VisualGamepad";

export type GamepadWidgetProps = {};

const GamepadWidget: React.FC<GamepadWidgetProps> = () => {
  const cls = useStyles();

  return (
    <div className={cls.container}>
      <VisualGamepad />
    </div>
  );
};

export default GamepadWidget;
