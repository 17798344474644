import React from "react";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";

import DrawerLink from "./DrawerLink";

const OrderCardsButton: React.FC = () => (
  <DrawerLink
    to="/dispatch/orders"
    primary="Dispatch"
    icon={<ViewAgendaIcon />}
    restrictToRoles={["Supervisor", "Admin"]}
  />
);

export default OrderCardsButton;
