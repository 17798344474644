import React from "react";
import Grid from "@material-ui/core/Grid";
import DateTimeField from "../DateTimeField";
import TimeZoneField from "../TimeZoneField";

export interface TimeRangeFieldProps {
  autoFocus?: boolean;
  required?: boolean;
  name: string;
}

const TimeRangeField: React.FC<TimeRangeFieldProps> = ({
  autoFocus = false,
  required = false,
  name,
}) => {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs>
        <DateTimeField
          name={`${name}.startDate`}
          autoFocus={autoFocus}
          required={required}
          label="Start date"
          fullWidth
        />
      </Grid>

      <Grid item xs>
        <DateTimeField
          name={`${name}.endDate`}
          required={required}
          label="End date"
          fullWidth
        />
      </Grid>

      <Grid item xs>
        <TimeZoneField
          name={`${name}.timezone`}
          required={required}
          label="Time zone"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default TimeRangeField;
