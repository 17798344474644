import React from "react";

import Alert from "@material-ui/lab/Alert";
import Robot from "types/robots/Robot";

export interface ParkIdleRobotActionSnackbarViewProps {
  robot: Robot;
}

const ParkIdleRobotActionSnackbarView: React.FC<
  ParkIdleRobotActionSnackbarViewProps
> = ({ robot }) => (
  <Alert icon={false} severity="info">
    {robot.parking_spot.description} at{" "}
    {robot.parking_spot.address_description.address_line_1}
  </Alert>
);

export default ParkIdleRobotActionSnackbarView;
