import React from "react";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import { useDrawer } from "./store";

export interface DrawerHeaderProps {}

const DrawerHeader: React.FC<DrawerHeaderProps> = () => {
  const drawer = useDrawer();
  if (drawer.isOpen) {
    return (
      <Box alignItems="center" justifyContent="flex-end" display="flex">
        <IconButton onClick={() => drawer.close()}>
          <ChevronLeftIcon />
        </IconButton>
      </Box>
    );
  } else {
    return (
      <Box alignItems="center" justifyContent="center" display="flex">
        <IconButton onClick={() => drawer.open()}>
          <MenuIcon />
        </IconButton>
      </Box>
    );
  }
};

export default DrawerHeader;
