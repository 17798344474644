import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

export interface LoadableButtonViewProps extends ButtonProps {
  isLoading: boolean;
}

const LoadableButtonView: React.FC<LoadableButtonViewProps> = ({
  isLoading,
  fullWidth,
  ...buttonProps
}) => (
  <Box position="relative" width={fullWidth ? "100%" : "fit-content"}>
    <Button {...buttonProps} fullWidth={fullWidth} disabled={isLoading} />
    {isLoading && (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        marginLeft="-12px"
        marginTop="-12px"
      >
        <CircularProgress size={24} />
      </Box>
    )}
  </Box>
);

export default LoadableButtonView;
