import React from "react";

import Box from "@material-ui/core/Box";

import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import OrderWizard from "components/deliveries/OrderWizard";
import DialogWithTriggerButton from "components/common/DialogWithTriggerButton";

import DefaultTriggerButton from "./DialogTriggerButton";

export interface OrderWizardDialogProps {
  TriggerButton?: React.FC<{ onClick: () => void }>;
}

const OrderWizardDialog: React.FC<OrderWizardDialogProps> = ({
  TriggerButton = DefaultTriggerButton,
}) => {
  const { showAlert } = useAlertMessage();

  const onSubmit = (setOpen: (isOpen: boolean) => void) => {
    setOpen(false);
    showAlert({
      message: "Order created successfuly!",
      severity: "success",
    });
  };

  const onSubmissionError = (error: { message: string }) => {
    showAlert({
      message: error.message,
      severity: "error",
      autoCloseAfterMillis: 2000,
    });
  };

  return (
    <DialogWithTriggerButton button={(props) => <TriggerButton {...props} />}>
      {(props) => (
        <Box padding={3} minWidth={600}>
          <OrderWizard
            onSubmit={() => onSubmit(props.setOpen)}
            onSubmissionError={onSubmissionError}
          />
        </Box>
      )}
    </DialogWithTriggerButton>
  );
};

export default OrderWizardDialog;
