import React from "react";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AssignedRobotAutosaveField from "../AssignedRobotAutosaveField";
import AssignedPilot from "./AssignedPilot";
import OrderRescueSwitch from "../OrderRescueSwitch";
import OrderCardImportantTimeSummary from "./ImportantTimeSummary";
import OrderCardFieldContainer from "./FieldContainer";
import Box from "@material-ui/core/Box";
import OrderCardDropOffDeadlineCell from "./DropOffDeadlineCell";

export interface OrderCardSummaryProps {
  order: UberEatsOrder;
  asOf: Date;
}

const OrderCardSummary: React.FC<OrderCardSummaryProps> = ({ order, asOf }) => {
  return (
    <Grid container direction="row" justify="space-evenly" spacing={2}>
      <Grid item xs>
        <OrderCardFieldContainer
          variant={order.assigned_robot_id ? "default" : "needs-attention"}
        >
          <AssignedRobotAutosaveField order={order} label="Assigned Robot" />
        </OrderCardFieldContainer>
      </Grid>
      <Grid item xs>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">Pilot</Typography>
          </Grid>
          <Grid item>
            <Box maxWidth="170px">
              <AssignedPilot order={order} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <OrderCardImportantTimeSummary order={order} asOf={asOf} />
      </Grid>
      <Grid item xs>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">Needs Rescue</Typography>
          </Grid>
          <Grid item>
            <OrderRescueSwitch order={order} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <OrderCardDropOffDeadlineCell order={order} asOf={asOf} />
      </Grid>
    </Grid>
  );
};

export default OrderCardSummary;
