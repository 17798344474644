import React from "react";
import useFeatureFlag from "components/common/useFeatureFlag";
import RobotCameraFeedReprojected from "./Reprojected";
import RobotCameraFeedRaw from "./Raw";

export interface RobotCameraFeedProps {
  robotID: string;
}

const RobotCameraFeed: React.FC<RobotCameraFeedProps> = ({ robotID }) => {
  const reprojection = useFeatureFlag("reproject");
  if (reprojection.isEnabled) {
    return <RobotCameraFeedReprojected robotID={robotID} />;
  } else {
    return <RobotCameraFeedRaw robotID={robotID} />;
  }
};

export default RobotCameraFeed;
