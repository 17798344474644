import React from "react";

import Grid from "@material-ui/core/Grid";

import FormikTextField from "components/common/formik/FormikTextField";

import AddressPlaceField from "./PlaceField";

export interface AddressFieldProps {
  autoFocus?: boolean;
  required?: boolean;
  name: string;
}

const AddressField: React.FC<AddressFieldProps> = ({
  autoFocus = false,
  required = false,
  name,
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <AddressPlaceField
          name={`${name}.address_place`}
          label="Address"
          autoFocus={autoFocus}
          required={required}
        />
      </Grid>
      <Grid item>
        <FormikTextField
          fullWidth
          name={`${name}.address_complement`}
          label="Address Complement"
        />
      </Grid>
    </Grid>
  );
};

export default AddressField;
