import React from "react";
import AddressDescription from "types/Location/AddressDescription";
import Grid from "@material-ui/core/Grid";
import DataPreviewEntry from "components/common/DataPreviewEntry";

export interface AddressSummaryProps {
  address: AddressDescription;
}

const AddressSummary: React.FC<AddressSummaryProps> = ({ address }) => (
  <Grid container direction="column">
    <Grid item>
      <DataPreviewEntry label="Address" value={address.address_line_1} />
    </Grid>
    <Grid item>
      <DataPreviewEntry label="Complement" value={address.address_line_2} />
    </Grid>
  </Grid>
);

export default AddressSummary;
