import React, { useEffect, useMemo } from "react";
import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";
import VideoReprojection from "components/augmented-reality/VideoReprojection";

export interface RobotCameraFeedReprojectionProps {
  robotID: string;
}

const RobotCameraFeedReprojection: React.FC<
  RobotCameraFeedReprojectionProps
> = ({ robotID, children }) => {
  const video = useMemo(() => document.createElement("video"), []);
  const controller = useRobotConnectionController();
  useEffect(() => {
    controller!.bindVideo(robotID, video);
  }, [robotID, controller, video]);

  return <VideoReprojection source={video}>{children}</VideoReprojection>;
};

export default RobotCameraFeedReprojection;
