import React from "react";

import Alert from "@material-ui/lab/Alert";

export interface StageActionSnackbarContainerProps {
  action?: React.ReactNode;
}

const StageActionSnackbarContainer: React.FC<
  StageActionSnackbarContainerProps
> = ({ action, children }) => (
  <Alert icon={false} severity="info" action={action}>
    {children}
  </Alert>
);

export default StageActionSnackbarContainer;
