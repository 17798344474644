import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useSetUberEatsOrderPickUpNotes } from "mutations/deliveries/uber-eats-orders";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";

export interface PickUpNotesAutoSaveFieldProps {
  orderUUID: string;
  currentPickUpNotes: string;
  isEditable: boolean;
}

const PickUpNotesAutoSaveField: React.FC<PickUpNotesAutoSaveFieldProps> = ({
  orderUUID,
  currentPickUpNotes,
  isEditable,
}) => {
  const [pickUpNotes, setPickUpNotes] = useState(currentPickUpNotes);
  const { showAlert } = useAlertMessage();
  const setOrderPickUpNotes = useSetUberEatsOrderPickUpNotes();

  const handleSubmit = () => {
    if (currentPickUpNotes !== pickUpNotes) {
      setOrderPickUpNotes
        .mutateAsync({ orderUUID, pickUpNotes })
        .then((order) => {
          setPickUpNotes(order.pick_up_notes);
          showAlert({
            message: "Pick Up notes updated successfully",
            severity: "success",
          });
        })
        .catch(({ message }) => {
          setPickUpNotes(currentPickUpNotes);
          showAlert({
            message: message || "Unknown error",
            severity: "error",
            autoCloseAfterMillis: 5000,
          });
        });
    }
  };
  return (
    <Box lineHeight={1}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextField
          value={pickUpNotes || ""}
          onChange={(e) => setPickUpNotes(e.currentTarget.value)}
          onBlur={handleSubmit}
          variant="outlined"
          label="PickUpNotes"
          disabled={!isEditable || setOrderPickUpNotes.isLoading}
          fullWidth
        />
      </form>
    </Box>
  );
};

export default PickUpNotesAutoSaveField;
