import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { useUberEatsOrderQuery } from "fetchers/deliveries/uber-eats-orders";
import { googleMapsLink } from "lib/location/places";
import { useRobot } from "fetchers/robots/robots";

export interface RobotDirectionsButtonProps
  extends Omit<ButtonProps, "href" | "onClick" | "children"> {
  orderUUID: string;
}

const RobotDirectionsButton: React.FC<RobotDirectionsButtonProps> = ({
  orderUUID,
  ...buttonProps
}) => {
  const order = useUberEatsOrderQuery(orderUUID, {
    select: (o) => o.assigned_robot_id,
    refetchInterval: 3000,
  });
  const robot = useRobot(order.data, { refetchInterval: 3000 });

  if (robot.data?.last_known_position) {
    return (
      <Button
        {...buttonProps}
        href={googleMapsLink(robot.data.last_known_position)}
      >
        Robot directions
      </Button>
    );
  } else {
    return (
      <Button {...buttonProps} disabled>
        Robot directions
      </Button>
    );
  }
};

export default RobotDirectionsButton;
