import React from "react";

import TeleopMap from "../Map";
import DestinationRoute from "../Map/DestinationRoute";
import RobotMapMarker from "../Map/RobotMarker";

export interface GenericTeleopMapProps {
  robotID: string;
}

const GenericTeleopMap: React.FC<GenericTeleopMapProps> = ({ robotID }) => {
  return (
    <TeleopMap robotID={robotID}>
      <RobotMapMarker robotID={robotID} />
      <DestinationRoute robotID={robotID} />
    </TeleopMap>
  );
};

export default GenericTeleopMap;
