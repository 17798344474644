import { useRobotPickedUpOrder } from "mutations/deliveries/uber-eats-orders";
import React from "react";
import Button from "@material-ui/core/Button";
import useOrderForTeleopSession from "../useOrderForTeleopSession";
import { useActionConfirmationDialog } from "components/common/ActionConfirmationDialog";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import useDeliveryTeleopSessionStore from "../useDeliveryTeleopSessionStore";

export interface RobotPickedUpOrderActionButtonProps {}

const RobotPickedUpOrderActionButton: React.FC<
  RobotPickedUpOrderActionButtonProps
> = () => {
  const robotID = useDeliveryTeleopSessionStore((s) => s.robotID);
  const robotPickedUpOrderEvent = useRobotPickedUpOrder(robotID);
  const order = useOrderForTeleopSession();
  const actionConfirmation = useActionConfirmationDialog();
  const { showAlert } = useAlertMessage();

  if (
    !order.data ||
    order.data.current_stage.kind !== "robot-waiting-for-pick-up"
  ) {
    return <></>;
  } else {
    const handleClick = () => {
      actionConfirmation.open({
        confirmationText: "Make sure all the order items are in the robot",
        onConfirm: () => {
          robotPickedUpOrderEvent
            .mutateAsync()
            .then(() => {
              actionConfirmation.dismiss();
              showAlert({
                message:
                  "Pick Up successfully acknowledged. Soon a driver will take over.",
                severity: "success",
              });
            })
            .catch(({ message }) => {
              showAlert({
                message: message || "Unknown error",
                severity: "error",
                autoCloseAfterMillis: 5000,
              });
            });
        },
      });
    };

    return (
      <Button
        fullWidth
        disabled={order.isLoading || robotPickedUpOrderEvent.isLoading}
        onClick={handleClick}
        variant="contained"
        color="primary"
      >
        {robotID} has the order
      </Button>
    );
  }
};

export default RobotPickedUpOrderActionButton;
