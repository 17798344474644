import GamepadEventTypes from "./types/GamepadEventTypes";
import ExtendedGamepadEvent, { GamepadEventsMap } from "./gamepad-events";

export default class ExtendedGamepadEventTarget {
  constructor() {
    this.listeners = [];
  }

  addEventListener<T extends GamepadEventTypes>(
    eventType: T,
    listener: (evt: GamepadEventsMap[T]) => void,
  ) {
    const listenerObj = new GamepadEventListener(eventType, listener);
    this.listeners.push(listenerObj);
  }

  removeEventListener(listener: (evt: any) => void) {
    const idx = this.listeners.findIndex(
      (candidate) => candidate.func === listener,
    );
    if (idx !== -1) {
      this.listeners.splice(idx, 1);
    }
  }

  dispatchEvent(event: ExtendedGamepadEvent): boolean {
    this.listeners.slice().forEach((listener) => listener.receive(event));
    return !event.defaultPrevented;
  }

  eventListeners<T extends GamepadEventTypes>(
    eventType: T,
  ): GamepadEventListenerFunction<T>[] {
    return this.listeners
      .slice()
      .filter((l): l is GamepadEventListener<T> => l.eventType === eventType)
      .map((l) => l.func);
  }

  private listeners: GamepadEventListener<any>[];
}

class GamepadEventListener<T extends GamepadEventTypes> {
  constructor(
    public eventType: T,
    public func: GamepadEventListenerFunction<T>,
  ) {}

  isEventReceivable(evt: ExtendedGamepadEvent): evt is GamepadEventForType<T> {
    return evt.type === this.eventType;
  }

  receive(evt: ExtendedGamepadEvent) {
    if (this.isEventReceivable(evt)) {
      this.func(evt);
    }
  }
}

type GamepadEventForType<T extends GamepadEventTypes> = GamepadEventsMap[T];
type GamepadEventListenerFunction<T extends GamepadEventTypes> = (
  evt: GamepadEventsMap[T],
) => void;
