import React from "react";
import AlertMessageView from "./View";
import { useAlertMessageStore } from "./store";

export type AlertMessageProps = {};

const AlertMessage: React.FC<AlertMessageProps> = () => {
  const { isOpen, options, dismiss } = useAlertMessageStore();
  return (
    <AlertMessageView {...options} isOpen={isOpen} onCloseAlert={dismiss} />
  );
};

export default AlertMessage;
