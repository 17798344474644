import React from "react";

import { useGoogleMapsService } from "services/location/GoogleMapsService/Context";

import GoogleAutocompleteService from "./";
import GoogleAutocompleteServiceContext from "./Context";

const GoogleAutocompleteServiceProvider: React.FC = ({ children }) => {
  const mapsService = useGoogleMapsService();
  return (
    <GoogleAutocompleteServiceContext.Provider
      value={new GoogleAutocompleteService(mapsService)}
    >
      {children}
    </GoogleAutocompleteServiceContext.Provider>
  );
};

export default GoogleAutocompleteServiceProvider;
