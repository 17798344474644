import React from "react";

export interface RobotMediaPlayerProps {
  videoURL: string;
}

const RobotMediaPlayer: React.FC<RobotMediaPlayerProps> = ({ videoURL }) => (
  <video style={{ width: "100%" }} controls>
    <source src={videoURL} />
  </video>
);

export default RobotMediaPlayer;
