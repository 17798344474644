import React from "react";

import MapTrail from "components/Map/Trail";
import { useRobotsTrailsQuery } from "fetchers/robots/trails";
import { stringToColour } from "lib/random/color";

const RobotsMapTrails: React.FC = () => {
  const { data } = useRobotsTrailsQuery({
    refetchInterval: 10 * 1000,
  });
  if (data) {
    const trails = Object.keys(data).map((robotID) => ({
      label: robotID,
      trail: data[robotID],
      color: stringToColour(robotID),
    }));
    return <MapTrail trails={trails} />;
  } else {
    return <></>;
  }
};

export default RobotsMapTrails;
