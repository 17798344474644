import { useCallback } from "react";

import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";
import GamepadButtons from "lib/gamepad/types/GamepadButtons";
import useTriggerWhileButtonIsPressed from "components/gamepad/useTriggerWhileButtonIsPressed";

export type SirenCommandKeyMap = {
  honkButton: GamepadButtons;
};

const useBindSirenCommand = (
  robotID: string,
  keymap: SirenCommandKeyMap = { honkButton: "menu" },
) => {
  const controller = useRobotConnectionController();
  const whileSirenButtonPressed = useCallback(() => {
    controller?.sendSirenEnableCommand(robotID);
  }, [controller, robotID]);

  useTriggerWhileButtonIsPressed(
    keymap.honkButton,
    whileSirenButtonPressed,
    500,
  );
};

export default useBindSirenCommand;
