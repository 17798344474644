import React, { useEffect, useState, useCallback } from "react";

import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import { useSendHeartbeat } from "mutations/robots";
import { useHistory } from "react-router-dom";

export interface DeliveryTeleopHeartbeatWatcherProps {
  robotID: string;
}

const DeliveryTeleopHeartbeatWatcher: React.FC<
  DeliveryTeleopHeartbeatWatcherProps
> = ({ robotID }) => {
  const [isFirstTry, setIsFirstTry] = useState(true);
  const sendHeartbeat = useSendHeartbeatCallback(robotID, isFirstTry);

  useEffect(() => {
    if (sendHeartbeat.isSuccess && isFirstTry) {
      setIsFirstTry(false);
    }
  }, [sendHeartbeat, isFirstTry, setIsFirstTry]);

  useEffect(() => {
    if (sendHeartbeat.isIdle) {
      sendHeartbeat.mutate();
    } else if (sendHeartbeat.isSuccess) {
      const ptr = setTimeout(() => {
        sendHeartbeat.mutate();
      }, 3000);
      return () => clearTimeout(ptr);
    }
  }, [sendHeartbeat]);

  return <></>;
};

const useSendHeartbeatCallback = (robotID: string, isFirstTry: boolean) => {
  const { mutateAsync, isLoading, isIdle, isSuccess } = useSendHeartbeat();
  const { showAlert } = useAlertMessage();
  const history = useHistory();

  const mutate = useCallback(() => {
    mutateAsync(robotID).catch((err) => {
      if (err.response.status === 403) {
        history.replace("/");
        if (!isFirstTry) {
          showAlert({
            message: `You were unassigned from robot ${robotID}`,
            severity: "info",
          });
        } else {
          showAlert({
            message: `You are not assigned to robot ${robotID}`,
            severity: "warning",
          });
        }
      }
    });
  }, [mutateAsync, robotID, showAlert, history, isFirstTry]);
  return { mutate, isLoading, isIdle, isSuccess };
};

export default DeliveryTeleopHeartbeatWatcher;
