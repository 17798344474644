import { useIsMutating, useMutation, useQueryClient } from "react-query";
import { useUsersService, CreateUserInput } from "services/auth/UsersService";
import User from "types/auth/User";

export const useActivateUser = () => {
  const svc = useUsersService();
  const queryClient = useQueryClient();
  return useMutation(
    (userUUID: string) => svc.activateUser(userUUID).then((res) => res.data),
    {
      mutationKey: ["auth/users", "activate-account"],
      onSuccess: (data) => {
        queryClient.invalidateQueries("auth/users");
        queryClient.setQueryData(["auth/users", { uuid: data.uuid }], data);
      },
    },
  );
};

export const useDeactivateUser = () => {
  const svc = useUsersService();
  const queryClient = useQueryClient();
  return useMutation(
    (userUUID: string) => svc.deactivateUser(userUUID).then((res) => res.data),
    {
      mutationKey: ["auth/users", "deactivate-account"],
      onSuccess: (data) => {
        queryClient.invalidateQueries("auth/users");
        queryClient.setQueryData(["auth/users", { uuid: data.uuid }], data);
      },
    },
  );
};

export const useResendVerificationLink = () => {
  const svc = useUsersService();
  const queryClient = useQueryClient();
  return useMutation(
    (userUUID: string) =>
      svc.resendVerificationLink(userUUID).then((res) => res.data),
    {
      mutationKey: ["auth/users", "resend-verification-link"],
      onSuccess: (data) => {
        queryClient.invalidateQueries("auth/users");
        queryClient.setQueryData(["auth/users", { uuid: data.uuid }], data);
      },
    },
  );
};

export const useSetUserRoles = () => {
  const svc = useUsersService();
  const queryClient = useQueryClient();
  return useMutation(
    ({ uuid, roles }: SetUserRolesInput) =>
      svc.setUserRoles(uuid, roles).then((res) => res.data),
    {
      mutationKey: ["auth/users", "set-roles"],
      onSuccess: (data) => {
        queryClient.invalidateQueries("auth/users");
        queryClient.setQueryData(["auth/users", { uuid: data.uuid }], data);
      },
    },
  );
};

export const useCreateUser = () => {
  const svc = useUsersService();
  const queryClient = useQueryClient();
  return useMutation(
    (userParams: CreateUserInput) =>
      svc.createUser(userParams).then((res) => res.data),
    {
      mutationKey: ["auth/users", "set-roles"],
      onSuccess: (data) => {
        queryClient.invalidateQueries("auth/users");
        queryClient.setQueryData(["auth/users", { uuid: data.uuid }], data);
      },
    },
  );
};

type SetUserRolesInput = Pick<User, "uuid" | "roles">;

export const useIsUserMutating = () => {
  return useIsMutating({ mutationKey: ["auth/users"] });
};
