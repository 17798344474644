import GREEN from "@material-ui/core/colors/green";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      width: "256px",
    },
    content: {
      minHeight: "144px",
    },
    rootSelected: {
      borderLeft: `solid ${GREEN[500]} 2px`,
    },
  }),
);
