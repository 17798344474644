import React from "react";
import Grid from "@material-ui/core/Grid";

import CountryCodeField from "components/common/CountryCodeField";
import FormikSubmitButton from "components/common/formik/FormikSubmitButton";
import FormikTextField from "components/common/formik/FormikTextField";

import CreateUserFormik, { CreateUserFormikProps } from "./Formik";

export type CreateUserFormProps = CreateUserFormikProps;

const CreateUserForm: React.FC<CreateUserFormProps> = (formikProps) => {
  return (
    <CreateUserFormik {...formikProps}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <FormikTextField
            name="email"
            label="Email"
            required
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item>
          <FormikTextField
            name="password"
            label="Initial Password"
            required
            fullWidth
          />
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item>
            <FormikTextField
              name="first_name"
              label="First Name"
              required
              fullWidth
            />
          </Grid>
          <Grid item>
            <FormikTextField
              name="last_name"
              label="Last Name"
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item>
          <CountryCodeField
            name="country_code"
            label="Country"
            required
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormikSubmitButton />
        </Grid>
      </Grid>
    </CreateUserFormik>
  );
};

export default CreateUserForm;
