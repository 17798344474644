import { useMutation, useQueryClient } from "react-query";
import useAxios from "services/axios/useAxios";

export const useEnablePartnersAPI = () => {
  const axios = useAxios("partners-api");
  const queryClient = useQueryClient();
  return useMutation(() => axios.post("/api/enable-order-creation", {}), {
    onSuccess: () => {
      queryClient.setQueryData(["partners-api/capacity"], {
        is_accepting_orders: true,
      });
    },
  });
};

export const useDisablePartnersAPI = () => {
  const axios = useAxios("partners-api");
  const queryClient = useQueryClient();
  return useMutation(() => axios.post("/api/disable-order-creation", {}), {
    onSuccess: () => {
      queryClient.setQueryData(["partners-api/capacity"], {
        is_accepting_orders: false,
      });
    },
  });
};
