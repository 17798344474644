import React from "react";
import AddressDescription from "types/Location/AddressDescription";
import GeocodedAddress from "types/Location/GeocodedAddress";
import Link from "@material-ui/core/Link";
import { googleMapsLink } from "lib/location/places";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";

export interface GoogleMapsLinkProps extends TypographyProps {
  addressDescription: AddressDescription;
  geocodedAddress: GeocodedAddress;
}

const GoogleMapsLink: React.FC<GoogleMapsLinkProps> = ({
  addressDescription,
  geocodedAddress,
  ...typographyProps
}) => (
  <Link href={googleMapsLink(geocodedAddress)} underline="always">
    <Typography {...typographyProps}>
      {addressDescription.address_line_1} <LaunchIcon fontSize="inherit" />
    </Typography>
  </Link>
);

export default GoogleMapsLink;
