import { AxiosResponse } from "axios";
import { useMemo } from "react";
import useAxios from "services/axios/useAxios";
import Robot from "types/robots/Robot";
import RobotParkingSpot from "types/robots/RobotParkingSpot";

export default interface RobotsService {
  getRobot: (robotID: string) => Promise<AxiosResponse<Robot>>;
  listRobots: () => Promise<AxiosResponse<Robot[]>>;
  setAssignedPilot: (
    robotID: string,
    userUUID: string,
  ) => Promise<AxiosResponse<Robot>>;
  removeAssignedPilot: (robotID: string) => Promise<AxiosResponse<Robot>>;
  createRobot: (
    params: RobotCreationParameters,
  ) => Promise<AxiosResponse<Robot>>;
  sendPilotHeartbeat: (robotID: string) => Promise<AxiosResponse<Robot>>;
  setDeploymentKind: (
    robotID: string,
    deploymentKind: string,
  ) => Promise<AxiosResponse<Robot>>;
  setParkingSpot: (
    robotID: string,
    parkingSpot: RobotParkingSpot,
  ) => Promise<AxiosResponse<Robot>>;
}

export const useRobotsService = (): RobotsService => {
  const axios = useAxios("robots");
  return useMemo(
    () => ({
      getRobot: (robotID: string) => axios.get(`/api/robots/${robotID}`, {}),
      listRobots: () => axios.get("/api/robots", {}),
      setAssignedPilot: (robotID: string, userUUID: string) =>
        axios.post(`/api/robots/${robotID}/assigned-pilot`, {
          user_uuid: userUUID,
        }),
      removeAssignedPilot: (robotID: string) =>
        axios.delete(`/api/robots/${robotID}/assigned-pilot`, {}),
      createRobot: (params: RobotCreationParameters) =>
        axios.post("/api/robots", { id: params.id, deployment_kind: "active" }),
      sendPilotHeartbeat: (robotID: string) =>
        axios.post(`/api/robots/${robotID}/pilot-heartbeat`, {}),
      setDeploymentKind: (robotID: string, deploymentKind: string) =>
        axios.post(`/api/robots/${robotID}/deployment-kind`, {
          deployment_kind: deploymentKind,
        }),
      setParkingSpot: (robotID: string, parkingSpot: RobotParkingSpot) =>
        axios.post(`/api/robots/${robotID}/parking-spot`, {
          parking_spot: parkingSpot,
        }),
    }),
    [axios],
  );
};

export interface RobotCreationParameters {
  id: string;
}
