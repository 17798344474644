import React from "react";
import Typography from "@material-ui/core/Typography";
import Robot from "types/robots/Robot";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { googleMapsLink } from "lib/location/places";

export interface ParkingSpotProps {
  robot: Robot;
}

const ParkingSpot: React.FC<ParkingSpotProps> = ({ robot }) => {
  return (
    <Box width={240}>
      <Typography variant="caption" display="block">
        Parking Spot
      </Typography>
      <Link
        href={googleMapsLink(robot.parking_spot.geocoded_address)}
        underline="always"
      >
        <Typography noWrap>
          {robot.parking_spot.description} @{" "}
          {robot.parking_spot.address_description.address_line_1}
        </Typography>
      </Link>
    </Box>
  );
};

export default ParkingSpot;
