import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibleTo from "components/auth/VisibleTo";

const useStyles = makeStyles({
  root: {
    width: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  available: {
    backgroundColor: green[500],
  },
  driven: {
    backgroundColor: "rgb(181 156 61)",
  },
});

const RobotCard = ({ name, driver }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        className={driver === undefined ? classes.available : classes.driven}
        action={
          <IconButton
            aria-label="settings"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        }
        title={name}
        subheader={driver === undefined ? "available" : driver}
      />
      <Menu
        id="menu-settings"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Open Terminal</MenuItem>
        <MenuItem onClick={handleClose}>Config</MenuItem>
      </Menu>

      <CardContent>
        <Typography color="textSecondary">
          Last ping
          <br />
          Driver Name
          <br />
          Cpu usage
          <br />
          Battery left
          <br />
          Disk space left
          <br />
        </Typography>
      </CardContent>
      <CardActions>
        <VisibleTo roles={["Admin"]}>
          <Link to={`/drive/${name}`}>
            <IconButton>
              <PlayArrowIcon />
            </IconButton>
          </Link>
        </VisibleTo>
        <VisibleTo roles={["Pilot"]}>
          <Link
            to={`/deliver-with/${name}`}
            title="Use robot to do delivery tasks"
          >
            <IconButton>
              <LocalShippingIcon />
            </IconButton>
          </Link>
        </VisibleTo>
        <Link to={`/spectate/${name}`}>
          <IconButton>
            <VisibilityIcon />
          </IconButton>
        </Link>
        <VisibleTo roles={["Supervisor", "Rescuer"]}>
          <Link
            to={`/pick-up-with/${name}`}
            title="Mobile friendly pick up interface"
          >
            <IconButton>
              <LockOpenIcon />
            </IconButton>
          </Link>
        </VisibleTo>
      </CardActions>
    </Card>
  );
};

export default RobotCard;
