import React from "react";

import Controller from ".";
import ControllerContext from "./Context";
import { useRobotTelemetryStore } from "stores/teleop/RobotTelemetry/Context";
import { useRobotConnectionStatusStore } from "stores/teleop/RobotConnectionStatus/Context";

const Provider: React.FC = ({ children }) => {
  const telemetryStore = useRobotTelemetryStore();
  const connStatusStore = useRobotConnectionStatusStore();
  const controller = new Controller(telemetryStore!, connStatusStore!);
  return (
    <ControllerContext.Provider value={controller}>
      {children}
    </ControllerContext.Provider>
  );
};

export default Provider;
