import CountryCodeFieldData from "components/common/CountryCodeField/Model";

export default interface CreateUserFormData {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  country_code: CountryCodeFieldData;
}

export const initialCreateUserFormData: CreateUserFormData = {
  email: "",
  password: "miletiny1234",
  first_name: "",
  last_name: "",
  country_code: "",
};
