import DataGridRowActionsButton from "components/common/DataGridRowActionsButton";
import React, { useState } from "react";
import UserRowActionsContainer from "./Container";
import User from "types/auth/User";
import ToggleActiveStatusAction from "./ToggleActiveStatusAction";
import ResendVerificationLink from "./ResendVerificationLinkAction";
import SetRolesAction from "./SetRolesAction";

export interface UserRowActionsProps {
  user: User;
}

const UserRowActions: React.FC<UserRowActionsProps> = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const closeMenu = () => setAnchorEl(null);
  const isOpen = Boolean(anchorEl);
  return (
    <>
      <DataGridRowActionsButton
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
      />
      <UserRowActionsContainer
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
      >
        <ToggleActiveStatusAction onSuccess={closeMenu} user={user} />
        {user.is_email_verified ? null : (
          <ResendVerificationLink onSuccess={closeMenu} user={user} />
        )}
        {user.is_active ? <SetRolesAction user={user} /> : null}
      </UserRowActionsContainer>
    </>
  );
};

export default UserRowActions;
