import React from "react";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import { useField, FieldArray } from "formik";

import ContactsEntryField from "./EntryField";
import RestaurantContactsFieldArrayLabel from "./Label";

export interface RestaurantContactsFieldArrayProps {
  name: string;
}

const RestaurantContactsFieldArray: React.FC<
  RestaurantContactsFieldArrayProps
> = ({ name }) => {
  const [field] = useField<{ name: string; phone_number: string }[]>(name);
  return (
    <FieldArray name={field.name}>
      {(formHelpers) => (
        <Box width="100%" minWidth="420px">
          <Grid container direction="column">
            <Grid item>
              <RestaurantContactsFieldArrayLabel
                onAddContact={() =>
                  formHelpers.push({ name: "", phone_number: "" })
                }
              />
            </Grid>
            {field.value.map((_contact, index) => (
              <Grid
                item
                key={index}
                container
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs>
                  <ContactsEntryField name={`${field.name}[${index}]`} />
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => formHelpers.remove(index)}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </FieldArray>
  );
};

export default RestaurantContactsFieldArray;
