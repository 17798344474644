import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listRoot: {
      width: "100%",
      maxWidth: "600px",
      backgroundColor: theme.palette.background.paper,
    },
  }),
);
