import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import RestaurantSetContactsDialog, {
  RestaurantSetContactsDialogProps,
} from "components/deliveries/RestaurantSetContactsDialog";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import MenuItem from "@material-ui/core/MenuItem";

export type RestaurantSetContactsActionProps = Omit<
  RestaurantSetContactsDialogProps,
  "isOpen" | "onClose"
>;

const RestaurantSetContactsAction: React.FC<
  RestaurantSetContactsActionProps
> = ({ restaurant, onSubmit, ...dialogProps }) => {
  const { showAlert } = useAlertMessage();
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = () => {
    setIsOpen(false);
    onSubmit();
    showAlert({
      message: `Contacts for Restaurant ID - ${restaurant.uuid} were set successfully!`,
      severity: "success",
    });
  };

  const handleError = (error: { message: string }) => {
    onSubmit();
    showAlert({
      message: `An error occurred when trying to set the contacts: ${error.message}`,
      severity: "error",
    });
  };
  return (
    <>
      <RestaurantSetContactsDialog
        restaurant={restaurant}
        {...dialogProps}
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onSubmissionError={handleError}
        onClose={() => setIsOpen(false)}
      />
      <MenuItem onClick={() => setIsOpen(true)}>
        <Typography variant="button">Set Restaurant Contacts</Typography>
      </MenuItem>
    </>
  );
};

export default RestaurantSetContactsAction;
