import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";

import theme from "config/theme";
import AlertMessage from "components/common/AlertMessage";
import ActionConfirmationDialog from "components/common/ActionConfirmationDialog";

import DashboardSwitch from "components/DashboardSwitch";
import "./styles.css";
import MasterProvider from "./MasterProvider";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <BrowserRouter>
          <MasterProvider>
            <DashboardSwitch />
            <ActionConfirmationDialog />
            <AlertMessage />
          </MasterProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
