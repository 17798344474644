import React from "react";

import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";

export interface AutocompleteInputProps extends AutocompleteRenderInputParams {
  isLoading?: boolean;
  label: string;
  autoFocus?: boolean;
  error?: boolean;
  required?: boolean;
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  isLoading = false,
  required = false,
  ...props
}) => {
  return (
    <TextField
      {...props}
      required={required}
      variant="standard"
      label={props.label}
      InputProps={{
        ...props.InputProps,
        autoComplete: "off",
        endAdornment: (
          <>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {props.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
};

export default AutocompleteInput;
