import RestaurantFieldData, {
  initialRestaurantFieldData,
} from "components/deliveries/RestaurantField/Model";

export default interface RestaurantCreationFormData {
  restaurant_info: RestaurantFieldData;
}

export const initialRestaurantCreationFormData: RestaurantCreationFormData = {
  restaurant_info: initialRestaurantFieldData,
};
