import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";
import { useActionConfirmationDialog } from "components/common/ActionConfirmationDialog";

export interface ActuateLidButtonProps {
  robotID: string;
  doConfirm?: boolean;
}

const ActuateLidButton: React.FC<ActuateLidButtonProps> = ({
  robotID,
  doConfirm = false,
}) => {
  const controller = useRobotConnectionController();
  const [isDisabled, setDisabled] = useState(false);
  const clickHandler = () => {
    setDisabled(true);
    controller?.sendLidToggleCommand(robotID);
    setTimeout(() => setDisabled(false), 1000);
  };

  const actionConfirmation = useActionConfirmationDialog();
  const clickHandlerWithConfirmation = () => {
    actionConfirmation.open({
      confirmationText: "Are you sure to actuate toggle lid?",
      onConfirm: () => {
        actionConfirmation.dismiss();
        clickHandler();
      },
    });
  };

  const actualClickHandler = doConfirm
    ? clickHandlerWithConfirmation
    : clickHandler;

  return (
    <Button
      fullWidth
      disabled={isDisabled}
      startIcon={<LockOpenIcon />}
      onClick={actualClickHandler}
      variant="contained"
      color="primary"
    >
      Toggle Lid
    </Button>
  );
};

export default ActuateLidButton;
