import React from "react";

import RobotAutocompleteField from "components/robots/AutocompleteField";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import {
  useSetUberEatsOrderAssignedRobot,
  useDeleteUberEatsOrderAssignedRobot,
} from "mutations/deliveries/uber-eats-orders";
import UberEatsOrder from "types/deliveries/UberEatsOrder";

export interface AssignedRobotAutosaveFieldProps {
  label?: string;
  helperText?: string;
  order: UberEatsOrder;
  onAssign?: () => void;
}

const AssignedRobotAutosaveField: React.FC<AssignedRobotAutosaveFieldProps> = ({
  onAssign = () => {},
  order,
  label,
  helperText,
}) => {
  const { showAlert } = useAlertMessage();
  const setAssignedRobot = useSetUberEatsOrderAssignedRobot();
  const deleteAssignedRobot = useDeleteUberEatsOrderAssignedRobot();

  const handleAssign = (robotID: string) => {
    setAssignedRobot
      .mutateAsync({ orderUUID: order.uuid, robotID })
      .then((response) => {
        showAlert({
          message: `Order ${response.external_id} successfully assigned to ${response.assigned_robot_id}`,
          severity: "info",
          autoCloseAfterMillis: 2000,
        });
      })
      .catch(({ message }) => {
        showAlert({
          message: message || "Unknown error",
          severity: "error",
          autoCloseAfterMillis: 5000,
        });
      })
      .finally(() => onAssign());
  };

  const handleUnassign = () => {
    deleteAssignedRobot
      .mutateAsync(order.uuid)
      .then((response) => {
        showAlert({
          message: `Robot successfully unassigned from order ${response.external_id}`,
          severity: "info",
          autoCloseAfterMillis: 2000,
        });
      })
      .catch(({ message }) => {
        showAlert({
          message: message || "Unknown error",
          severity: "error",
          autoCloseAfterMillis: 5000,
        });
      })
      .finally(() => onAssign());
  };

  const handleRobotChanged = (robotID: null | string) => {
    if (
      robotID === order.assigned_robot_id ||
      (!robotID && !order.assigned_robot_id)
    ) {
      return;
    }
    if (robotID) {
      handleAssign(robotID);
    } else {
      handleUnassign();
    }
  };

  return (
    <RobotAutocompleteField
      onChange={(robotID) => handleRobotChanged(robotID)}
      value={order.assigned_robot_id}
      label={label}
      helperText={helperText}
      disabled={setAssignedRobot.isLoading || deleteAssignedRobot.isLoading}
    />
  );
};

export default AssignedRobotAutosaveField;
