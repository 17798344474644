import React from "react";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "components/common/formik/FormikTextField";

export interface RestaurantExternalRefFieldProps {
  autoFocus?: boolean;
  name: string;
}

const RestaurantExternalRefField: React.FC<RestaurantExternalRefFieldProps> = ({
  autoFocus = false,
  name,
}) => (
  <Grid container direction="row" spacing={2}>
    <Grid item>
      <FormikTextField
        fullWidth
        name={`${name}.origin`}
        label="Origin"
        autoFocus={autoFocus}
        required
      />
    </Grid>
    <Grid item>
      <FormikTextField
        fullWidth
        name={`${name}.id`}
        label="ID at Origin"
        required
      />
    </Grid>
  </Grid>
);

export default RestaurantExternalRefField;
