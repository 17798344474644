import { UseQueryOptions, useQuery } from "react-query";

import User from "types/auth/User";
import { useUsersService } from "services/auth/UsersService";
import { AxiosError } from "axios";

export const useUser = (
  userUUID: string | null | undefined,
  queryOptions?: UseQueryOptions<User, AxiosError>,
) => {
  const svc = useUsersService();
  return useQuery(
    ["auth/users", { uuid: userUUID }],
    () => svc.getUser(userUUID!).then((res) => res.data),
    { ...queryOptions, enabled: !!userUUID },
  );
};

export const useUsersQuery = (queryOptions?: UseQueryOptions<User[]>) => {
  const svc = useUsersService();
  return useQuery(
    ["auth/users"],
    () => svc.listUsers().then((res) => res.data),
    queryOptions,
  );
};
