import React from "react";

import OrderWizardDialog from "../OrderWizardDialog";
import RobotsMapDialog from "components/robots/RobotsMapDialog";
import { RouteComponentProps } from "react-router-dom";
import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import UberEatsOrdersDataGrid from "components/deliveries/UberEatsOrdersDataGrid";

export type DeliveryOrderDispatchScreenProps = RouteComponentProps<{}>;

const DeliveryOrderDispatchScreen: React.FC<
  DeliveryOrderDispatchScreenProps
> = () => {
  return (
    <DashboardScreenTemplate screenTitle="Delivery Orders Dispatch">
      <OrderWizardDialog />
      <RobotsMapDialog />
      <UberEatsOrdersDataGrid />
    </DashboardScreenTemplate>
  );
};

export default DeliveryOrderDispatchScreen;
