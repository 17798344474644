import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import useAxios from "services/axios/useAxios";

import PartnersAPICapacity from "types/partners-api/PartnersAPICapacity";

export function usePartnersAPICapacity<TData = PartnersAPICapacity>(
  queryOptions?: UseQueryOptions<PartnersAPICapacity, AxiosError, TData>,
) {
  const axios = useAxios("partners-api");
  return useQuery(
    "partners-api/capacity",
    () => axios.get("/api/capacity").then((res) => res.data),
    queryOptions,
  );
}
