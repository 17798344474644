import React from "react";
import useGamepadStoreHydrator from "../useGamepadStoreHydrator";

export interface GamepadStoreProviderProps {}

const GamepadStoreProvider: React.FC<GamepadStoreProviderProps> = ({
  children,
}) => {
  useGamepadStoreHydrator();
  return <>{children}</>;
};

export default GamepadStoreProvider;
