import FormikSubmitButton from "components/common/formik/FormikSubmitButton";
import React from "react";
import Box from "@material-ui/core/Box";
import UserRolesFieldArray from "../UserRolesFieldArray";
import UserRolesFormik, { UserRolesFormikProps } from "./Formik";

export type UserRolesFormProps = UserRolesFormikProps;

const UserRolesForm: React.FC<UserRolesFormProps> = (props) => {
  return (
    <UserRolesFormik {...props}>
      <Box marginBottom={2}>
        <UserRolesFieldArray name="roles" fullWidth />
      </Box>
      <FormikSubmitButton />
    </UserRolesFormik>
  );
};

export default UserRolesForm;
