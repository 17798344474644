import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import VisibleTo from "components/auth/VisibleTo";

export interface RobotCardMenuItemContainerProps {
  requiredRoles: string[];
}

const RobotCardMenuItemContainer = React.forwardRef<
  HTMLLIElement,
  React.PropsWithChildren<RobotCardMenuItemContainerProps>
>(({ requiredRoles, children }, ref): JSX.Element => {
  return (
    <VisibleTo roles={requiredRoles}>
      <MenuItem ref={ref}>{children}</MenuItem>
    </VisibleTo>
  );
});

export default RobotCardMenuItemContainer;
