import User from "types/auth/User";
import create from "zustand";

type SetRolesDialogState = {
  isOpen: boolean;
  user?: null | User;
};

type SetRolesDialogAPI = {
  open: (user: User) => void;
  close: () => void;
};

type SetRolesDialogStore = SetRolesDialogState & SetRolesDialogAPI;

const useSetRolesDialogStore = create<SetRolesDialogStore>((set) => ({
  isOpen: false,
  open: (user) => set({ isOpen: true, user }),
  close: () => set({ isOpen: false, user: null }),
}));

export default useSetRolesDialogStore;
