import Place from "types/Location/Place";
import CompleteAddress from "types/Location/CompleteAddress";

export default interface AddressFieldData {
  address_place: null | Place;
  address_complement: string;
}

export const emptyAddressField: AddressFieldData = {
  address_place: null,
  address_complement: "",
};

export const addressFieldDataToCompleteAddress = ({
  address_place,
  address_complement,
}: AddressFieldData): CompleteAddress => ({
  address_description: {
    address_line_1: address_place!.address_line_1,
    address_line_2: address_complement,
    locality: address_place!.locality,
    state: address_place!.state,
    country: address_place!.country,
    postal_code: address_place!.postal_code,
  },
  geocoded_address: {
    lat: address_place!.geolocation.lat,
    lng: address_place!.geolocation.lng,
    google_place_id: address_place!.google_place_id,
  },
});
