import React from "react";
import { useActionConfirmationDialogStore } from "./store";
import ActionConfirmationDialogView from "./View";

export interface ActionConfirmationDialogProps {}

const ActionConfirmationDialog: React.FC<
  ActionConfirmationDialogProps
> = () => {
  const { isOpen, isLoading, dismiss, setLoading, options } =
    useActionConfirmationDialogStore();

  const handleConfirm = () => {
    setLoading();
    options.onConfirm();
  };

  return (
    <ActionConfirmationDialogView
      isOpen={isOpen}
      description={options.confirmationText}
      isLoading={isLoading}
      onConfirm={handleConfirm}
      onClose={() => dismiss()}
    />
  );
};

export const useActionConfirmationDialog = () =>
  useActionConfirmationDialogStore(({ clearLoading, open, dismiss }) => ({
    clearLoading,
    open,
    dismiss,
  }));

export default ActionConfirmationDialog;
