import { useEffect } from "react";
import { useRobots } from "fetchers/robots/robots";
import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";
import { useIdentityStore } from "services/auth/identity/store";

const useTeleopConnections = () => {
  const robotIDs = useRobots<string[]>({
    select: (robots) =>
      robots
        .filter((robot) => robot.deployment_kind === "active")
        .map((robot) => robot.id)
        .sort(),
  });

  const controller = useRobotConnectionController();
  const accessToken = useIdentityStore((s) => s.accessToken);

  useEffect(() => {
    if (robotIDs.data) {
      for (const robotID of robotIDs.data) {
        controller!.setAccessToken(robotID, accessToken!);
      }
    }
  }, [robotIDs.data, controller, accessToken]);

  useEffect(() => {
    if (robotIDs.data) {
      for (const robotID of robotIDs.data) {
        controller!.startTeleop(robotID);
      }
      return () => {
        for (const robotID of robotIDs.data) {
          controller!.stopTeleop(robotID);
        }
      };
    }
  }, [robotIDs.data, controller]);
};

export default useTeleopConnections;
