import * as yup from "yup";

export const RoleSchema = yup
  .string()
  .required("Required")
  .matches(
    RegExp("[a-zA-Z0-9-_]+"),
    "Only alphanumeric, dash or underscores allowed",
  );

export default yup.array().of(RoleSchema);
