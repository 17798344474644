import React from "react";

import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

export interface FormikFieldArrayLabelProps {
  label: string;
  onAdd: () => void;
}

const FormikFieldArrayLabel: React.FC<FormikFieldArrayLabelProps> = ({
  label,
  onAdd,
}) => (
  <Grid container direction="row" alignItems="center" spacing={2}>
    <Grid item>
      <Typography variant="subtitle1">{label}</Typography>
    </Grid>
    <Grid item>
      <IconButton onClick={() => onAdd()} size="small">
        <AddIcon />
      </IconButton>
    </Grid>
  </Grid>
);

export default FormikFieldArrayLabel;
