import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import { useField } from "formik";
import AutocompleteInput, {
  AutocompleteInputProps,
} from "components/common/AutocompleteInput";
import timezones, { Timezone } from "./timezones";

export interface TimeZoneFieldProps
  extends Pick<
    AutocompleteInputProps,
    "label" | "autoFocus" | "required" | "fullWidth"
  > {
  name: string;
}

const TimeZoneField: React.FC<TimeZoneFieldProps> = ({
  name,
  ...inputProps
}) => {
  const [field, , helpers] = useField<null | Timezone>(name);
  return (
    <Autocomplete
      autoComplete
      options={timezones}
      getOptionLabel={(tz) => tz["text"] || ""}
      autoHighlight
      renderOption={(option) => (
        <Typography variant="subtitle1">{option["text"]}</Typography>
      )}
      renderInput={(params) => (
        <AutocompleteInput {...inputProps} {...params} />
      )}
      value={field.value}
      onChange={(_, newVal: null | Timezone) => {
        helpers.setValue(newVal);
      }}
      onBlur={() => helpers.setTouched(true)}
    />
  );
};

export default TimeZoneField;
