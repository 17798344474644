import { createContext } from "react";

export interface StepperAPI {
  activeStep: number;
  setActiveStep: (newStep: number) => void;
  advanceOneStep: () => void;
  goBackOneStep: () => void;
}

export default createContext<StepperAPI>({
  activeStep: 0,
  setActiveStep: () => {},
  advanceOneStep: () => {},
  goBackOneStep: () => {},
});
