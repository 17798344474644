import { useEffect } from "react";
import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";
import { useIdentityStore } from "services/auth/identity/store";

export default function (robotID: null | undefined | string) {
  const controller = useRobotConnectionController();
  const accessToken = useIdentityStore((s) => s.accessToken);

  useEffect(() => {
    if (robotID) {
      controller!.setAccessToken(robotID, accessToken!);
    }
  }, [robotID, controller, accessToken]);

  useEffect(() => {
    if (robotID) {
      controller!.startTeleop(robotID);
      return () => controller!.stopTeleop(robotID);
    }
  }, [robotID, controller]);
}
