import React from "react";

import Appsignal from "@appsignal/javascript";
import { ErrorBoundary as AppsignalErrorBoundary } from "@appsignal/react";
import { APP_SIGNAL_API_KEY } from "config/constants";

const appsignal = new Appsignal({
  key: APP_SIGNAL_API_KEY,
  revision: process.env["REACT_APP_COMMIT_SHA"] || "unknown",
});

export interface ErrorBoundaryProps {}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => (
  <AppsignalErrorBoundary
    instance={appsignal}
    fallback={() => <>Uh oh... Something went wrong</>}
  >
    {children}
  </AppsignalErrorBoundary>
);
export default ErrorBoundary;
