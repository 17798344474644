import { useRobotArrivedAtPickUp } from "mutations/deliveries/uber-eats-orders";
import React from "react";
import { useActionConfirmationDialog } from "components/common/ActionConfirmationDialog";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import { ButtonProps } from "@material-ui/core/Button";
import LoadableButton from "components/common/LoadableButton";

export interface ArrivedAtPickUpActionButtonProps
  extends Omit<ButtonProps, "href" | "onClick"> {
  assignedRobotID: string;
  isLoading?: boolean;
}

const ArrivedAtPickUpActionButton: React.FC<
  ArrivedAtPickUpActionButtonProps
> = ({
  children = "I've arrived at pick-up",
  assignedRobotID,
  isLoading = false,
  ...buttonProps
}) => {
  const robotArrivedAtPickUpEvent = useRobotArrivedAtPickUp(assignedRobotID);
  const actionConfirmation = useActionConfirmationDialog();
  const { showAlert } = useAlertMessage();

  const handleClick = () => {
    actionConfirmation.open({
      confirmationText:
        "Remember to confirm the pick-up location. Check Street View if you are unsure.",
      onConfirm: () => {
        robotArrivedAtPickUpEvent
          .mutateAsync()
          .then(() => {
            actionConfirmation.dismiss();
            showAlert({
              message:
                "Arrival confirmed. Wait for the restaurant to identify themselves",
              severity: "success",
            });
          })
          .catch(({ message }) => {
            showAlert({
              message: message || "Unknown error",
              severity: "error",
              autoCloseAfterMillis: 5000,
            });
          });
      },
    });
  };

  return (
    <LoadableButton
      isLoading={isLoading}
      onClick={handleClick}
      disabled={isLoading || robotArrivedAtPickUpEvent.isLoading}
      {...buttonProps}
    >
      {children}
    </LoadableButton>
  );
};

export default ArrivedAtPickUpActionButton;
