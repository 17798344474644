import React from "react";
import Grid from "@material-ui/core/Grid";

import FormikCurrencyField from "components/common/formik/FormikCurrencyField";

export interface OrderChargesFieldProps {
  autoFocus?: boolean;
  name: string;
}

const OrderChargesField: React.FC<OrderChargesFieldProps> = ({
  autoFocus = false,
  name,
}) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <FormikCurrencyField
          fullWidth
          name={`${name}.sub_total_in_cents`}
          label="Sub-total"
          autoFocus={autoFocus}
          required
        />
      </Grid>
      <Grid item>
        <FormikCurrencyField
          fullWidth
          name={`${name}.tips_in_cents`}
          label="Tips"
          required
        />
      </Grid>
    </Grid>
  );
};

export default OrderChargesField;
