import { useUberEatsOrdersService } from "services/deliveries/UberEatsOrders";
import OrderParameters from "types/deliveries/OrderParameters";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import CompleteAddress from "types/Location/CompleteAddress";
import { AxiosResponse } from "axios";
import { useActiveUberEatsOrderForRobot } from "fetchers/deliveries/uber-eats-orders";
import useAxios from "services/axios/useAxios";

export const useCreateUberEatsOrder = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();
  return useMutation(
    (parameters: OrderParameters) =>
      svc.createOrder(parameters).then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useSetUberEatsOrderRecipientContacts = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();
  return useMutation(
    (params: SetUberEatsOrderRecipientContactsParams) =>
      svc
        .setRecipientContacts(params.orderUUID, {
          phone_number: params.phoneNumber,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useSetUberEatsOrderRecipientName = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();
  return useMutation(
    (params: SetUberEatsOrderRecipientNameParams) =>
      svc
        .setRecipientName(params.orderUUID, {
          name: params.name,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useSetUberEatsOrderRecipientAddress = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();
  return useMutation(
    (params: SetUberEatsOrderRecipientAddressParams) =>
      svc
        .setRecipientAddress(params.orderUUID, params.address)
        .then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useSetUberEatsOrderPickUpNotes = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();
  return useMutation(
    (params: SetUberEatsOrderPickUpNotesParams) =>
      svc
        .setPickUpNotes(params.orderUUID, {
          pick_up_notes: params.pickUpNotes,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useSetUberEatsOrderDropOffNotes = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();
  return useMutation(
    (params: SetUberEatsOrderDropOffNotesParams) =>
      svc
        .setDropOffNotes(params.orderUUID, {
          drop_off_notes: params.dropOffNotes,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useSetUberEatsOrderSubTotalInCents = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();
  return useMutation(
    (params: SetUberEatsOrderSubTotalInCentsParams) =>
      svc
        .setOrderSubTotalInCents(params.orderUUID, {
          sub_total_in_cents: params.subTotalInCents,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useSetUberEatsOrderTipsInCents = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();
  return useMutation(
    (params: SetUberEatsOrderTipsInCentsParams) =>
      svc
        .setOrderTipsInCents(params.orderUUID, {
          tips_in_cents: params.tipsInCents,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useSetUberEatsOrderAssignedRobot = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();

  return useMutation(
    (params: SetUberEatsOrderAssignedRobotParams) =>
      svc
        .setAssignedRobot(params.orderUUID, params.robotID)
        .then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useDeleteUberEatsOrderAssignedRobot = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();

  return useMutation(
    (orderUUID: string) =>
      svc.deleteAssignedRobot(orderUUID).then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useCancelUberEatsOrder = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();

  return useMutation(
    (orderUUID: string) => svc.cancelOrder(orderUUID).then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useRobotLeftForPickUp = (robotID: string) => {
  const svc = useUberEatsOrdersService();
  return useRobotEventForActiveOrder(robotID, (orderUUID) =>
    svc.sendRobotLeftForPickUp(orderUUID),
  );
};

export const useRobotArrivedAtPickUp = (robotID: string) => {
  const svc = useUberEatsOrdersService();
  return useRobotEventForActiveOrder(robotID, (orderUUID) =>
    svc.sendRobotArrivedAtPickUp(orderUUID),
  );
};

export const useRobotPickedUpOrder = (robotID: string) => {
  const svc = useUberEatsOrdersService();
  return useRobotEventForActiveOrder(robotID, (orderUUID) =>
    svc.sendRobotPickedUpOrder(orderUUID),
  );
};

export const useRobotLeftForDropOff = (robotID: string) => {
  const svc = useUberEatsOrdersService();
  return useRobotEventForActiveOrder(robotID, (orderUUID) =>
    svc.sendRobotLeftForDropOff(orderUUID),
  );
};

export const useRobotArrivedAtDropOff = (robotID: string) => {
  const svc = useUberEatsOrdersService();
  return useRobotEventForActiveOrder(robotID, (orderUUID) =>
    svc.sendRobotArrivedAtDropOff(orderUUID),
  );
};

export const useRobotDroppedOffOrder = (robotID: string) => {
  const svc = useUberEatsOrdersService();
  return useRobotEventForActiveOrder(robotID, (orderUUID) =>
    svc.sendRobotDroppedOffOrder(orderUUID),
  );
};

export const useFinishRescue = () => {
  const axios = useAxios("uber-eats-orders");
  const queryClient = useQueryClient();
  return useMutation(
    (orderUUID: string) =>
      axios
        .post<UberEatsOrder>(`/api/orders/${orderUUID}/finish-rescue`, {})
        .then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

export const useStartRescue = () => {
  const svc = useUberEatsOrdersService();
  const queryClient = useQueryClient();
  return useMutation(
    (params: SetUberEatsOrderStartRescueParams) =>
      svc
        .startRescue(params.orderUUID, {
          reason: params.rescueReason,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) =>
        updateQueriesRelatedToMutatedOrders(queryClient, data),
    },
  );
};

const useRobotEventForActiveOrder = (
  robotID: string,
  eventFunction: (orderUUID: string) => Promise<AxiosResponse<UberEatsOrder>>,
) => {
  const activeOrder = useActiveUberEatsOrderForRobot(robotID);
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      if (!activeOrder.data) {
        throw new Error("no active order found for robot");
      }
      return (await eventFunction(activeOrder.data.uuid)).data;
    },
    {
      onSuccess: (data) =>
        updateQueriesRelatedToRobotEventMutation(queryClient, robotID, data),
    },
  );
};

const updateQueriesRelatedToRobotEventMutation = (
  queryClient: QueryClient,
  robotID: string,
  order: UberEatsOrder,
) => {
  updateQueriesRelatedToMutatedOrders(queryClient, order);
  queryClient.setQueryData(
    [
      "deliveries/uber-eats-orders",
      "active-robot-orders",
      { robotID: robotID },
    ],
    order,
  );
};

const updateQueriesRelatedToMutatedOrders = (
  queryClient: QueryClient,
  ...orders: UberEatsOrder[]
) => {
  queryClient.invalidateQueries("deliveries/uber-eats-orders");
  queryClient.invalidateQueries("deliveries/orders");
  for (const order of orders) {
    queryClient.setQueryData(
      ["deliveries/uber-eats-orders", { uuid: order.uuid }],
      order,
    );
  }
};

interface SetUberEatsOrderRecipientContactsParams {
  orderUUID: string;
  phoneNumber: string;
}

interface SetUberEatsOrderRecipientNameParams {
  orderUUID: string;
  name: string;
}

interface SetUberEatsOrderRecipientAddressParams {
  orderUUID: string;
  address: CompleteAddress;
}

interface SetUberEatsOrderPickUpNotesParams {
  orderUUID: string;
  pickUpNotes: string;
}

interface SetUberEatsOrderDropOffNotesParams {
  orderUUID: string;
  dropOffNotes: string;
}

interface SetUberEatsOrderSubTotalInCentsParams {
  orderUUID: string;
  subTotalInCents: number;
}

interface SetUberEatsOrderTipsInCentsParams {
  orderUUID: string;
  tipsInCents: number;
}

interface SetUberEatsOrderAssignedRobotParams {
  orderUUID: string;
  robotID: string;
}

interface SetUberEatsOrderStartRescueParams {
  orderUUID: string;
  rescueReason: string;
}
