import { useRobot } from "fetchers/robots/robots";
import React from "react";
import UnknownLocationMarker from "../UnknownLocationMarker";

export interface LastKnownRobotPositionMarkerProps {
  robotID: string;
  refetchIntervalInMs?: number;
}

const LastKnownRobotPositionMarker: React.FC<
  LastKnownRobotPositionMarkerProps
> = ({ robotID, refetchIntervalInMs = 3000 }) => {
  const lastKnownLocation = useRobot(robotID, {
    refetchInterval: refetchIntervalInMs,
    select: (r) => r.last_known_position,
  });
  if (lastKnownLocation.data) {
    return (
      <UnknownLocationMarker
        position={lastKnownLocation.data}
        tooltip={`${robotID} position seen at ${new Date(
          lastKnownLocation.data.received_at,
        ).toLocaleString()}`}
      />
    );
  } else {
    return <></>;
  }
};

export default LastKnownRobotPositionMarker;
