type GamepadButtons =
  | "a"
  | "b"
  | "x"
  | "y"
  | "l_bumper"
  | "r_bumper"
  | "view"
  | "menu"
  | "l_stick"
  | "r_stick"
  | "d_up"
  | "d_down"
  | "d_left"
  | "d_right";

export default GamepadButtons;

export const gamepadButtons: GamepadButtons[] = [
  "a",
  "b",
  "x",
  "y",
  "l_bumper",
  "r_bumper",
  "view",
  "menu",
  "l_stick",
  "r_stick",
  "d_up",
  "d_down",
  "d_left",
  "d_right",
];
