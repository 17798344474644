import React from "react";
import OrderCard from "../OrderCard";
import Grid from "@material-ui/core/Grid";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

export interface OrderCardsListViewProps {
  orders: UberEatsOrder[];
}

const OrderCardsListView: React.FC<OrderCardsListViewProps> = ({ orders }) => {
  if (orders.length === 0) {
    return (
      <Box padding={3} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          No orders to show
        </Typography>
      </Box>
    );
  }
  return (
    <Grid container direction="column" spacing={3}>
      {orders.map((o) => (
        <Grid item key={o.uuid}>
          <OrderCard order={o} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OrderCardsListView;
