import React from "react";

import OrderInformationStep from "./OrderInfoStep";
import DeliveryDetailsStep from "./DeliveryDetailsStep";
import StepperProvider from "components/common/Stepper/Provider";
import StepperView from "components/common/Stepper/StepperView";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import DeliveryChargesStep from "./DeliveryChargesStep";
import SkipTheDishesOrderWizardFormSchema from "./FormSchema";
import {
  initialSkipTheDishesOrderWizardFormData,
  skipthedishesOrderWizardFormDataToOrderParameters,
} from "./FormModel";
import OrderWizardFormik from "../OrderWizardFormik";

export interface SkipTheDishesOrderWizardProps {
  onCreated?: (order: UberEatsOrder) => void;
  onSubmissionError?: (err: any) => void;
}

const SkipTheDishesOrderWizard: React.FC<SkipTheDishesOrderWizardProps> = (
  props,
) => {
  return (
    <StepperProvider>
      <OrderWizardFormik
        validationSchema={SkipTheDishesOrderWizardFormSchema}
        initialValues={initialSkipTheDishesOrderWizardFormData}
        stepsCount={3}
        formDataTransformer={skipthedishesOrderWizardFormDataToOrderParameters}
        {...props}
      >
        <StepperView nonLinear orientation="vertical">
          <OrderInformationStep key={0} stepIndex={0} />
          <DeliveryDetailsStep key={1} stepIndex={1} />
          <DeliveryChargesStep key={2} stepIndex={2} />
        </StepperView>
      </OrderWizardFormik>
    </StepperProvider>
  );
};

export default SkipTheDishesOrderWizard;
