import { useEffect } from "react";

import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";
import useSteerCompensationStore from "../useSteerCompensationStore";
import useGamepadStore from "components/gamepad/useGamepadStore";
import GamepadButtons from "lib/gamepad/types/GamepadButtons";
import GamepadAxes from "lib/gamepad/types/GamepadAxes";

export type DriveCommandsKeyMap = {
  powerAxis: GamepadAxes;
  reversePowerAxis: GamepadAxes;
  emergencyStopButton: GamepadButtons;
  rotationButton: GamepadButtons;
  steerAxis: GamepadAxes;
};

const useBindDriveCommands = (
  robotID: string,
  keymap: DriveCommandsKeyMap = defaultDriveCommandsKeyMap,
) => {
  const controller = useRobotConnectionController();
  const compensationFactor = useSteerCompensationStore(
    (s) => s.compensationFactor,
  );
  const [isEStopPressed, isRotation, power, powerReverse, steer] =
    useGamepadStore(
      (s) =>
        [
          s.gamepadState?.buttons[keymap.emergencyStopButton],
          s.gamepadState?.buttons[keymap.rotationButton],
          s.gamepadState?.axes[keymap.powerAxis] || 0,
          s.gamepadState?.axes[keymap.reversePowerAxis] || 0,
          removeDeadZone(0.1, s.gamepadState?.axes[keymap.steerAxis] || 0),
        ] as const,
    );

  useEffect(() => {
    if (isEStopPressed) {
      controller?.sendStopCommand(robotID);
    } else if (isRotation) {
      controller?.sendRotateCommand(robotID, steer);
    } else if (power > 0) {
      controller?.sendDriveCommand(robotID, {
        power: power,
        steer: steer + compensationFactor,
        steer_compensation: compensationFactor,
      });
    } else if (powerReverse > 0) {
      controller?.sendDriveCommand(robotID, {
        power: -powerReverse,
        steer: -steer + compensationFactor,
        steer_compensation: compensationFactor,
      });
    }
  });
};

const removeDeadZone = (deadZoneAbsVal: number, v: number) => {
  if (v > -deadZoneAbsVal && v < deadZoneAbsVal) {
    return 0.0;
  } else {
    return v;
  }
};

export const defaultDriveCommandsKeyMap: DriveCommandsKeyMap = {
  powerAxis: "r_trigger",
  reversePowerAxis: "l_trigger",
  emergencyStopButton: "l_bumper",
  rotationButton: "r_bumper",
  steerAxis: "r_x",
};

export default useBindDriveCommands;
