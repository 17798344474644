import React from "react";
import { useField } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export interface OrderIsMockFieldProps {
  informationFieldName: string;
}

const OrderIsMockField: React.FC<OrderIsMockFieldProps> = ({
  informationFieldName,
}) => {
  const [field] = useField<boolean>(`${informationFieldName}.is_mock`);
  return (
    <FormControlLabel
      control={<Checkbox color="primary" {...field} checked={field.value} />}
      label="Is Mock"
    />
  );
};

export default OrderIsMockField;
