import React from "react";

import SVGMarker, { SVGMarkerProps } from "./SVGMarker";

export type PersonPinMarkerProps = Omit<SVGMarkerProps, "anchor" | "svgPath">;

const PersonPinMarker: React.FC<PersonPinMarkerProps> = React.memo((props) => {
  return (
    <SVGMarker
      fillColor="lightgray"
      strokeColor="white"
      strokeOpacity={1}
      strokeWeight={1}
      {...props}
      svgPath="M12 2c-4.97 0-9 4.03-9 9 0 4.17 2.84 7.67 6.69 8.69L12 22l2.31-2.31C18.16 18.67 21 15.17 21 11c0-4.97-4.03-9-9-9zm0 2c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.3c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
      scale={1.8}
      anchor={[16, 22]}
    />
  );
});

export default PersonPinMarker;
