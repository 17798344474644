import * as yup from "yup";
import AddressFieldSchema from "components/Location/AddressField/Schema";

export default yup.object().shape({
  recipient_address: AddressFieldSchema.required(
    "Recipient address is required",
  ),
  has_direct_recipient_contact: yup.boolean(),
  recipient_phone_number_or_code: yup.string().required("Required"),
  drop_off_notes: yup.string(),
});
