import React from "react";

import { Formik, FormikHelpers, Form } from "formik";
import RestaurantCreationFormSchema from "./Schema";
import { useRestaurantCreator } from "mutations/deliveries/restaurants";
import { restaurantFieldDataToRestaurant } from "components/deliveries/RestaurantField/Model";
import RestaurantCreationFormData, {
  initialRestaurantCreationFormData,
} from "./Model";

export interface RestaurantCreationFormikProps {
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
}

const RestaurantCreationFormik: React.FC<RestaurantCreationFormikProps> = ({
  children,
  onSubmit = () => {},
  onSubmissionError = () => {},
}) => {
  const creator = useRestaurantCreator();
  return (
    <Formik
      validationSchema={RestaurantCreationFormSchema}
      initialValues={initialRestaurantCreationFormData}
      onSubmit={(
        formData: RestaurantCreationFormData,
        helpers: FormikHelpers<RestaurantCreationFormData>,
      ) => {
        creator
          .mutateAsync(
            restaurantFieldDataToRestaurant(formData.restaurant_info),
          )
          .then(() => onSubmit())
          .catch((err) => onSubmissionError(err))
          .finally(() => helpers.setSubmitting(false));
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default RestaurantCreationFormik;
