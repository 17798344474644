import React from "react";

import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormikTextField from "components/common/formik/FormikTextField";
import RestaurantAddressField from "../RestaurantAddressField";
import RestaurantContactsFieldArray from "../RestaurantContactsFieldArray";
import RestaurantExternalRefsFieldArray from "../RestaurantExternalRefsFieldArray";

export interface RestaurantFieldProps {
  autoFocus?: boolean;
  required?: boolean;
  name: string;
}

const RestaurantField: React.FC<RestaurantFieldProps> = ({
  autoFocus = false,
  required = false,
  name,
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs>
          <FormikTextField
            name={`${name}.name`}
            required={required}
            fullWidth
            label="Restaurant Name"
            autoFocus={autoFocus}
          />
        </Grid>
        <Grid item>
          <FormikTextField
            name={`${name}.drop_off_deadline_in_minutes`}
            label="Drop Off Deadline (minutes)"
            required={required}
            InputProps={{
              endAdornment: <InputAdornment position="end">min</InputAdornment>,
            }}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid item>
        <RestaurantAddressField required={required} name={`${name}.address`} />
      </Grid>
      <Grid item>
        <RestaurantExternalRefsFieldArray name={`${name}.external_refs`} />
      </Grid>
      <Grid item>
        <RestaurantContactsFieldArray name={`${name}.contacts`} />
      </Grid>
    </Grid>
  );
};

export default RestaurantField;
