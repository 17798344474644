import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MapIcon from "@material-ui/icons/Map";

export interface DialogTriggerButtonProps {
  onClick: () => void;
}

const DialogTriggerButton: React.FC<DialogTriggerButtonProps> = ({
  onClick,
}) => {
  return (
    <IconButton color="inherit" onClick={onClick}>
      <MapIcon />
    </IconButton>
  );
};

export default DialogTriggerButton;
