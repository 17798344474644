import React from "react";
import RobotDeploymentKindChip from "../RobotDeploymentKindChip";
import Grid from "@material-ui/core/Grid";
import Robot from "types/robots/Robot";
import Typography from "@material-ui/core/Typography";

export interface RobotCardHeaderTitleProps {
  robot: Robot;
}

const RobotCardHeaderTitle: React.FC<RobotCardHeaderTitleProps> = ({
  robot,
}) => {
  return (
    <Grid container direction="row" alignItems="flex-start" spacing={1}>
      <Grid item>
        <Typography variant="h5">{robot.id}</Typography>
      </Grid>
      <Grid item>
        <RobotDeploymentKindChip
          deploymentKind={robot.deployment_kind}
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default RobotCardHeaderTitle;
