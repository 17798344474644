import React, { useState } from "react";
import StepperContext from "./Context";

export interface StepperProviderProps {
  initialActiveStep?: number;
}

const StepperProvider: React.FC<StepperProviderProps> = ({
  initialActiveStep = 0,
  children,
}) => {
  const [activeStep, setActiveStep] = useState<number>(initialActiveStep);
  const api = {
    activeStep,
    setActiveStep,
    advanceOneStep: () => setActiveStep((step) => step + 1),
    goBackOneStep: () => setActiveStep((step) => step - 1),
  };
  return (
    <StepperContext.Provider value={api}>{children}</StepperContext.Provider>
  );
};

export default StepperProvider;
