import React from "react";
import Switch from "@material-ui/core/Switch";
import {
  useEnablePartnersAPI,
  useDisablePartnersAPI,
} from "mutations/partners-api";
import { usePartnersAPICapacity } from "fetchers/partners-api";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";

export interface PartnersAPISwitchProps {}

const PartnersAPISwitch: React.FC<PartnersAPISwitchProps> = () => {
  const enableAPI = useEnablePartnersAPI();
  const disableAPI = useDisablePartnersAPI();
  const { showAlert } = useAlertMessage();
  const isAcceptingOrders = usePartnersAPICapacity({
    select: (c) => c.is_accepting_orders,
  });
  const handleChange = (isEnabling: boolean) => {
    if (isEnabling) {
      enableAPI
        .mutateAsync()
        .then((_) =>
          showAlert({
            message: "Partners API enabled successfuly",
            severity: "success",
          }),
        )
        .catch(({ message }) => {
          showAlert({
            message: message || "Unknown error",
            severity: "error",
          });
        });
    } else {
      disableAPI
        .mutateAsync()
        .then((_) =>
          showAlert({
            message: "Partners API disabled successfuly",
            severity: "success",
          }),
        )
        .catch(({ message }) => {
          showAlert({
            message: message || "Unknown error",
            severity: "error",
          });
        });
    }
  };
  return (
    <Switch
      disabled={
        enableAPI.isLoading ||
        disableAPI.isLoading ||
        isAcceptingOrders.isLoading
      }
      onChange={(_, isEnabling) => handleChange(isEnabling)}
      checked={!!isAcceptingOrders.data}
    />
  );
};

export default PartnersAPISwitch;
