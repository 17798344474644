import React, { useMemo } from "react";

import DestinationSelect from "components/teleop/DestinationSelect";
import useRobotDestinationGoal from "../useRobotDestinationGoal";
import { robotDestinationAsPlace } from "types/teleop/RobotDestination";

export interface DeliveryTeleopMapDestinationOverrideSelectProps {}

const DeliveryTeleopMapDestinationOverrideSelect: React.FC<
  DeliveryTeleopMapDestinationOverrideSelectProps
> = () => {
  const destinationGoal = useRobotDestinationGoal();
  return useMemo(() => {
    if (destinationGoal) {
      return (
        <DestinationSelect
          defaultValue={robotDestinationAsPlace(destinationGoal)}
        />
      );
    } else {
      return <DestinationSelect />;
    }
  }, [destinationGoal]);
};

export default DeliveryTeleopMapDestinationOverrideSelect;
