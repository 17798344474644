import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useUberEatsOrderQuery } from "fetchers/deliveries/uber-eats-orders";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import UberEatsOrderSummaryView from "./View";

export interface UberEatsOrderSummaryProps {
  orderUUID: string;
}

const UberEatsOrderSummary: React.FC<UberEatsOrderSummaryProps> = ({
  orderUUID,
}) => {
  const order = useUberEatsOrderQuery(orderUUID);
  const { showAlert } = useAlertMessage();

  useEffect(() => {
    if (order.error) {
      showAlert({
        message: `Error when trying to fetch order details. Message: ${order.error.message}`,
        severity: "error",
      });
    }
  }, [order.error, showAlert]);

  if (!order.data) {
    if (order.error) {
      return <></>;
    } else {
      return <CircularProgress />;
    }
  } else {
    return <UberEatsOrderSummaryView order={order.data} />;
  }
};

export default UberEatsOrderSummary;
