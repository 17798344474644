import Geocode from "types/Location/Geocode";

class GoogleMapsService {
  private injectionError?: Error;
  private injectionPromise?: Promise<typeof google.maps>;

  constructor(private apiKey: string) {}

  private injectScript() {
    this.injectionPromise = new Promise((resolve, reject) => {
      const script = document.createElement("script");

      script.type = "text/javascript";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;

      const onScriptLoad = () => {
        // Resolve current promise
        resolve(window.google.maps);
        cleanup();
      };

      const onScriptLoadError = () => {
        this.injectionError = new Error(
          "[react-google-places-autocomplete] Could not inject Google script",
        );
        reject(this.injectionError);
        cleanup();
      };

      // Release callbacks and unregister listeners
      const cleanup = () => {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptLoadError);
      };

      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptLoadError);

      document.body.appendChild(script);
    });
  }

  async getMaps() {
    if (!this.injectionPromise) {
      this.injectScript();
    }

    return await this.injectionPromise;
  }

  async getDirectionsService() {
    const api = await this.getMaps();
    return new api!.DirectionsService();
  }

  async getRoutePolylines(
    origin: Geocode,
    destination: Geocode,
    color?: string,
  ): Promise<google.maps.Polyline[]> {
    const api = await this.getMaps();
    const service = await this.getDirectionsService();
    return await new Promise((resolve, reject) =>
      service.route(
        {
          origin: origin,
          destination: destination,
          travelMode: api!.TravelMode.WALKING,
        },
        (response, status) => {
          if (status === api!.DirectionsStatus.OK) {
            const polylines = _legsToPolylines(api!, response.routes[0].legs);
            const options = getPolylineOptions(api!, color);
            polylines.forEach((p) => p.setOptions(options));
            resolve(polylines);
          } else {
            reject(response);
          }
        },
      ),
    );
  }

  async getBoundsForPolylines(polylines: google.maps.Polyline[]) {
    const api = await this.getMaps();
    return polylines
      .flatMap((polyline) => polyline.getPath().getArray())
      .reduce((bounds, point) => bounds.extend(point), new api!.LatLngBounds());
  }
}

const _legsToPolylines = (
  mapsApi: typeof google.maps,
  legs: google.maps.DirectionsLeg[],
): google.maps.Polyline[] => {
  return legs.flatMap((leg) =>
    leg.steps.map((step) => _stepToPolyline(mapsApi, step)),
  );
};

const _stepToPolyline = (
  mapsApi: typeof google.maps,
  step: google.maps.DirectionsStep,
): google.maps.Polyline => {
  const polyline = new mapsApi.Polyline();
  polyline.setPath(step.path);
  return polyline;
};

const getPolylineOptions = (mapsApi: typeof google.maps, color = "#C83939") => {
  return {
    strokeColor: color,
    strokeOpacity: 0,
    strokeWeight: 4,
    icons: [
      {
        icon: {
          path: mapsApi.SymbolPath.CIRCLE,
          fillColor: color,
          fillOpacity: 1,
          scale: 2,
          strokeColor: color,
          strokeOpacity: 1,
        },
        offset: "0",
        repeat: "10px",
      },
    ],
  };
};

export default GoogleMapsService;
