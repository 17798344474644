import { StoreConfig, EntityStore } from "@datorama/akita";

import RobotTelemetry from "types/teleop/RobotTelemetry";

export interface RobotTelemetryStoreState {
  ui: {};
}

const defaultInitialState = {
  ui: {},
};

@StoreConfig({ name: "RobotTelemetryStore", idKey: "robotID" })
export default class RobotTelemetryStore extends EntityStore<
  RobotTelemetryStoreState,
  RobotTelemetry
> {
  constructor(initialState: RobotTelemetryStoreState = defaultInitialState) {
    super(initialState);
  }
}
