import React from "react";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { Link } from "react-router-dom";
import VisibleTo from "components/auth/VisibleTo";
import IconButton from "@material-ui/core/IconButton";

export interface DeliverWithButtonProps {
  robotID: string;
}

const DeliverWithButton: React.FC<DeliverWithButtonProps> = ({ robotID }) => {
  return (
    <VisibleTo roles={["Pilot", "Supervisor"]}>
      <Link
        to={`/deliver-with/${robotID}`}
        title="Use robot to do delivery tasks"
      >
        <IconButton>
          <LocalShippingIcon />
        </IconButton>
      </Link>
    </VisibleTo>
  );
};

export default DeliverWithButton;
