import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

import Robot from "types/robots/Robot";
import RobotCardActions from "./Actions";
import AssignedPilot from "./AssignedPilot";
import ParkingSpot from "./ParkingSpot";
import { useStyles } from "./styles";
import RobotCardHeaderTitle from "./HeaderTitle";
import CargoTemperature from "../CargoTemperature";
import BatterySOC from "../BatterySOC";

export interface RobotCardViewProps {
  robot: Robot;
  selected?: boolean;
  canDrive?: boolean;
}

const RobotCardView: React.FC<RobotCardViewProps> = ({
  robot,
  selected = false,
  canDrive = false,
}) => {
  const cls = useStyles();

  const rootCls = clsx(cls.root, { [cls.rootSelected]: selected });
  return (
    <Card className={rootCls}>
      <CardContent className={cls.content}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <RobotCardHeaderTitle robot={robot} />
          </Grid>
          <Grid item>
            <AssignedPilot robot={robot} />
          </Grid>
          <Grid item>
            <ParkingSpot robot={robot} />
          </Grid>
          <Grid item>
            <BatterySOC robot={robot} />
          </Grid>
          <Grid item>
            <CargoTemperature robot={robot} />
          </Grid>
        </Grid>
      </CardContent>
      <RobotCardActions robotID={robot.id} canDrive={canDrive} />
    </Card>
  );
};

export default RobotCardView;
