import React from "react";

import classnames from "classnames";

import { useStyles } from "./styles";
import Box, { BoxProps } from "@material-ui/core/Box";

export interface TeleopHUDContainerProps
  extends Omit<BoxProps, "className" | "position"> {
  readonly verticalPosition?: "top" | "center" | "bottom";
  readonly horizontalPosition?: "left" | "center" | "right";
  alwaysActive?: boolean;
}

const TeleopHUDContainer: React.FC<TeleopHUDContainerProps> = ({
  children,
  verticalPosition = "top",
  horizontalPosition = "right",
  alwaysActive = false,
  ...boxProps
}) => {
  const cls = useStyles();
  const className = classnames(cls.container, {
    [cls.opaqueContainer]: !alwaysActive,
    [cls.verticalTop]: verticalPosition === "top",
    [cls.verticalCenter]: verticalPosition === "center",
    [cls.verticalBottom]: verticalPosition === "bottom",
    [cls.horizontalLeft]: horizontalPosition === "left",
    [cls.horizontalCenter]: horizontalPosition === "center",
    [cls.horizontalRight]: horizontalPosition === "right",
  });
  return (
    <Box {...boxProps} className={className} position="fixed">
      {children}
    </Box>
  );
};

export default TeleopHUDContainer;
