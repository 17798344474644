import React from "react";

import User from "types/auth/User";
import { useDeactivateUser } from "mutations/users";
import SimpleMutationActionContainer from "../SimpleMutationActionContainer";

export interface DeactivateAccountActionProps {
  user: User;
  onSuccess: () => void;
}

const DeactivateAccountAction = React.forwardRef<
  HTMLLIElement,
  DeactivateAccountActionProps
>(({ user, onSuccess }, ref) => {
  const deactivateUserMutation = useDeactivateUser();

  return (
    <SimpleMutationActionContainer
      ref={ref}
      onSuccess={onSuccess}
      userUUID={user.uuid}
      mutate={(userUUID) => deactivateUserMutation.mutateAsync(userUUID)}
      successMessage={(user) => `User ${user.email} deactivated successfully`}
      failMessage={(msg) =>
        `Something went wrong when trying to deactivate account: ${msg}`
      }
    >
      Deactivate Account
    </SimpleMutationActionContainer>
  );
});

export default DeactivateAccountAction;
