import { useCallback } from "react";

import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";
import useGamepadBind from "components/gamepad/useGamepadBind";
import GamepadButtons from "lib/gamepad/types/GamepadButtons";

export type LidCommandKeymap = {
  toggleLidButton: GamepadButtons;
};

const useBindLidCommand = (
  robotID: string,
  keymap: LidCommandKeymap = { toggleLidButton: "y" },
) => {
  const controller = useRobotConnectionController();
  const onYPressed = useCallback(() => {
    controller!.sendLidToggleCommand(robotID);
  }, [controller, robotID]);
  useGamepadBind(keymap.toggleLidButton, onYPressed);
};

export default useBindLidCommand;
