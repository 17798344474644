import DialogWithTriggerButton from "components/common/DialogWithTriggerButton";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import CreateUserForm from "components/users/CreateUserForm";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";

export type CreatUserDialogProps = {};

const CreatUserDialog: React.FC<CreatUserDialogProps> = () => {
  const { showAlert } = useAlertMessage();

  const handleSubmit = (closeDialog: () => void) => {
    showAlert({
      message: "User created successfully",
      severity: "success",
    });
    closeDialog();
  };

  const handleErr = (err: { message: string }) => {
    showAlert({
      message: `Failed creating user. Reason: ${err.message}`,
      severity: "error",
    });
  };

  return (
    <DialogWithTriggerButton
      button={(props) => (
        <IconButton {...props}>
          <AddIcon />
        </IconButton>
      )}
    >
      {(params) => (
        <DialogContent>
          <CreateUserForm
            onSubmit={() => handleSubmit(() => params.setOpen(false))}
            onSubmissionError={handleErr}
          />
        </DialogContent>
      )}
    </DialogWithTriggerButton>
  );
};

export default CreatUserDialog;
