import React from "react";
import { Formik, FormikHelpers } from "formik";
import { useCreateUberEatsOrder } from "mutations/deliveries/uber-eats-orders";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import FormikWizardForm from "components/common/formik/FormikWizardForm";
import * as yup from "yup";
import OrderParameters from "types/deliveries/OrderParameters";

export interface OrderWizardFormikProps<TFormData> {
  onCreated?: (order: UberEatsOrder) => void;
  onSubmissionError?: (error: { message: string }) => void;
  validationSchema: yup.AnyObjectSchema;
  initialValues: TFormData;
  formDataTransformer: (formData: TFormData) => OrderParameters;
  stepsCount: number;
}

export default function OrderWizardFormik<TFormData>({
  onCreated = () => {},
  onSubmissionError = () => {},
  validationSchema,
  children,
  stepsCount,
  formDataTransformer,
  initialValues,
}: React.PropsWithChildren<OrderWizardFormikProps<TFormData>>) {
  const mutation = useCreateUberEatsOrder();
  const submitHandler = (
    values: TFormData,
    helpers: FormikHelpers<TFormData>,
  ) => {
    mutation
      .mutateAsync(formDataTransformer(values))
      .then((data) => {
        onCreated(data);
      })
      .catch((err) => {
        onSubmissionError(err);
      })
      .finally(() => helpers.setSubmitting(false));
  };
  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={submitHandler}
      initialValues={initialValues}
    >
      <FormikWizardForm stepsCount={stepsCount}>{children}</FormikWizardForm>
    </Formik>
  );
}
