import * as yup from "yup";

import AddressFieldSchema from "../RestaurantAddressField/Schema";
import ContactsFieldArraySchema from "../RestaurantContactsFieldArray/Schema";
import EmployeesFieldArraySchema from "../RestaurantEmployeesFieldArray/Schema";
import RestaurantExternalRefsFieldArraySchema from "../RestaurantExternalRefsFieldArray/Schema";

export const ContactInfoSchema = yup.object().shape({
  name: yup.string().required("Required"),
  phone_number: yup.string().required("Required"),
});

export default yup.object().shape({
  name: yup.string().required("Required"),
  external_refs: RestaurantExternalRefsFieldArraySchema.required("Required"),
  address: AddressFieldSchema,
  contacts: ContactsFieldArraySchema,
  employees: EmployeesFieldArraySchema,
  drop_off_deadline_in_minutes: yup.number().required("Required"),
});
