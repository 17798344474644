import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface ActionConfirmationDialogViewProps {
  onConfirm: () => void;
  onClose: () => void;
  title?: string;
  description?: string;
  isOpen: boolean;
  isLoading?: boolean;
}

const ActionConfirmationDialogView: React.FC<
  ActionConfirmationDialogViewProps
> = ({
  onConfirm,
  onClose,
  title = "Confirm Action?",
  description = "",
  isOpen,
  isLoading = false,
}) => {
  if (isLoading) {
    return (
      <Dialog maxWidth="xs" open={isOpen} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionConfirmationDialogView;
