import React, { useRef, useEffect } from "react";
import { useRobotConnectionController } from "controllers/teleop/RobotConnection/Context";

export interface RobotCameraFeedRawProps {
  robotID: string;
}

const RobotCameraFeedRaw: React.FC<RobotCameraFeedRawProps> = ({ robotID }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const controller = useRobotConnectionController();
  useEffect(() => {
    if (videoRef.current) {
      controller!.bindVideo(robotID, videoRef.current);
    }
  }, [robotID, controller]);
  return (
    <video
      width="100%"
      height="100%"
      ref={videoRef}
      autoPlay
      playsInline
      muted
    />
  );
};

export default RobotCameraFeedRaw;
