import React from "react";

import Button from "@material-ui/core/Button";
import FeedbackIcon from "@material-ui/icons/Feedback";

export type SuggestionsLinkProps = {};

const SuggestionsLink: React.FC<SuggestionsLinkProps> = () => {
  return (
    <Button
      href="https://airtable.com/shrzV75Kqumofutaz"
      endIcon={<FeedbackIcon />}
      size="small"
    >
      {"Suggestions? We'd love to hear!"}
    </Button>
  );
};

export default SuggestionsLink;
