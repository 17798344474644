import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useSetUberEatsOrderSubTotalInCents } from "mutations/deliveries/uber-eats-orders";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";

export interface SubTotalInCentsAutoSaveFieldProps {
  orderUUID: string;
  currentSubTotalInCents: number;
  isEditable: boolean;
}

const SubTotalInCentsAutoSaveField: React.FC<
  SubTotalInCentsAutoSaveFieldProps
> = ({ orderUUID, currentSubTotalInCents, isEditable }) => {
  const [subTotalInCents, setSubTotalInCents] = useState(
    currentSubTotalInCents,
  );
  const { showAlert } = useAlertMessage();
  const setOrderSubTotalInCents = useSetUberEatsOrderSubTotalInCents();

  const handleSubmit = () => {
    if (currentSubTotalInCents !== subTotalInCents) {
      setOrderSubTotalInCents
        .mutateAsync({ orderUUID, subTotalInCents })
        .then((order) => {
          setSubTotalInCents(order.charges!.sub_total_in_cents);
          showAlert({
            message: "Order charges - sub total field updated successfully",
            severity: "success",
          });
        })
        .catch(({ message }) => {
          setSubTotalInCents(currentSubTotalInCents);
          showAlert({
            message: message || "Unknown error",
            severity: "error",
            autoCloseAfterMillis: 5000,
          });
        });
    }
  };
  return (
    <Box lineHeight={1}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextField
          value={subTotalInCents || ""}
          onChange={(e) => setSubTotalInCents(+e.currentTarget.value)}
          onBlur={handleSubmit}
          variant="outlined"
          label="SubTotalInCents"
          disabled={!isEditable || setOrderSubTotalInCents.isLoading}
          fullWidth
        />
      </form>
    </Box>
  );
};

export default SubTotalInCentsAutoSaveField;
