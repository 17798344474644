import create from "zustand";
import GamepadState from "lib/gamepad/types/GamepadState";

export type GamepadStore = {
  gamepadState: GamepadState | null;
};

const useGamepadStore = create<GamepadStore>(() => ({ gamepadState: null }));

export default useGamepadStore;
