import React, { useEffect, useState, useMemo } from "react";
import { useGoogleMapsService } from "services/location/GoogleMapsService/Context";
import { useControl } from "react-three-gui";
import { Line } from "./line";
import * as THREE from "three";
import { WGS84ToLocal } from "./coords";

interface Props {
  robotID: string;
}

const ARDirections: React.FC<Props> = ({ robotID }) => {
  const mapsService = useGoogleMapsService();
  const [polylines, setPolylines] = useState<google.maps.Polyline[]>();
  const [ps, setPs] = useState<number[][]>([]);

  useEffect(() => {
    // dissabling because it is causing re-renderings.
    // mapsService!.getRoutePolylines(destination, destination).then(setPolylines);
    setPolylines([]);
  }, [mapsService]);
  const x: number = useControl("x", {
    type: "number",
    min: -10,
    max: 5,
    value: -3.75,
  });
  const y: number = useControl("y", {
    type: "number",
    min: -10,
    max: 5,
    value: -1.75,
  });
  useEffect(() => {
    if (!polylines) {
      return;
    }
    let points: number[][] = [];
    for (let i = 0; i < polylines.length; i++) {
      points = points.concat(extractPoints(polylines[i].getPath(), x, y));
    }
    setPs(points);
  }, [polylines, x, y]);

  const interp = useMemo(() => {
    if (!ps.length) {
      return;
    }

    const threeps = ps.map((pos) => new THREE.Vector3(pos[0], pos[1], -0.1));
    return new THREE.CatmullRomCurve3(threeps).getSpacedPoints(ps.length * 10);
  }, [ps]);

  return <Line points={interp} width={0.75} color={"lightblue"} />;
};

const extractPoints = (
  path: google.maps.MVCArray<google.maps.LatLng>,
  x: number,
  y: number,
) => {
  /* For some unkown reason the path return by google maps doesn't perfectly match
   * The sidewalks polygons from the city of Toronto. We do displace it a few meters
   * to get a better match.
   */
  const points = [];
  for (let i = 0; i < path.getLength(); i++) {
    const lat = path.getAt(i).lat();
    const lng = path.getAt(i).lng();
    const xy = WGS84ToLocal(lat, lng);
    points.push([xy[0] + x, xy[1] + y]);
  }
  return points;
};

export default ARDirections;
