import create from "zustand";
import { StateCreator } from "zustand";

export type DeliveryTeleopSessionState = {
  robotID: string;
};

export type DeliveryTeleopSessionStoreAPI = {};

export type DeliveryTeleopSession = DeliveryTeleopSessionState &
  DeliveryTeleopSessionStoreAPI;

const activeSessionCheckerMiddleware =
  <T extends DeliveryTeleopSession>(config: StateCreator<T>): StateCreator<T> =>
  (set, get, api) =>
    config(
      set,
      () => {
        const s = get();
        if (s.robotID === "") {
          throw new Error("tried to get session state before starting it");
        }
        return s;
      },
      api,
    );

export const DeliveryTeleopSessionStore = create<DeliveryTeleopSession>(
  activeSessionCheckerMiddleware(() => ({
    robotID: "",
  })),
);

const useDeliveryTeleopSessionStore = DeliveryTeleopSessionStore;

export default useDeliveryTeleopSessionStore;
