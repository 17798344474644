import React from "react";

import Button from "@material-ui/core/Button";
import useStepper from "./useStepper";

export type StepperBackButtonProps = {};

const StepperBackButton: React.FC<StepperBackButtonProps> = () => {
  const { goBackOneStep, activeStep } = useStepper();
  return (
    <Button disabled={activeStep === 0} onClick={() => goBackOneStep()}>
      Back
    </Button>
  );
};

export default StepperBackButton;
