import React from "react";
import LeftForPickUpActionButton from "components/deliveries/LeftForPickUpActionButton";
import useDeliveryTeleopSessionStore from "../useDeliveryTeleopSessionStore";
import useOrderForTeleopSession from "../useOrderForTeleopSession";
import StageActionSnackbarContainer from "./StageActionSnackbarContainer";
import { useRestaurant } from "fetchers/deliveries/restaurants";

export interface LeftForPickUpActionSnackbarProps {}

const LeftForPickUpActionSnackbar: React.FC<
  LeftForPickUpActionSnackbarProps
> = () => {
  const robotID = useDeliveryTeleopSessionStore((s) => s.robotID);
  const order = useOrderForTeleopSession();
  const restaurant = useRestaurant(order.data?.restaurant_uuid);

  if (!order.data) {
    return <></>;
  }

  return (
    <StageActionSnackbarContainer
      action={
        <LeftForPickUpActionButton
          assignedRobotID={robotID}
          color="primary"
          variant="contained"
        />
      }
    >
      {restaurant.data
        ? `A delivery pick up is expected at ${restaurant.data.name} on ${restaurant.data.address_description.address_line_1}`
        : `A delivery pick up is expected at ${order.data.restaurant_uuid}`}
    </StageActionSnackbarContainer>
  );
};

export default LeftForPickUpActionSnackbar;
