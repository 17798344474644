import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    xbox_white: {
      background: "url(/xbox_white.svg)",
      height: "630px",
      width: "750px",
      display: "block",
      position: "absolute",
    },
    xbox_black: {
      background: "url(/xbox_black.svg)",
      height: "630px",
      width: "750px",
      display: "block",
      position: "absolute",
    },
    disconnected: {
      background: "url(/xbox_disconnected.svg)",
      height: "630px",
      width: "750px",
      display: "block",
    },
    triggers: {
      width: "446px",
      height: "121px",
      position: "absolute",
      left: "152px",
    },
    trigger_left: {
      position: "absolute",
      left: 0,
      backgroundPosition: "0 0",
      width: "88px",
      height: "121px",
      background: "url(/xbox_trigger.svg)",
    },
    trigger_right: {
      position: "absolute",
      right: 0,
      backgroundPosition: "0 0",
      width: "88px",
      height: "121px",
      transform: "rotateY(180deg)",
      background: "url(/xbox_trigger.svg)",
    },
    bumpers: {
      position: "absolute",
      width: "536px",
      height: "61px",
      left: "107px",
      top: "129px",
    },
    bumper_left: {
      float: "left",
      width: "170px",
      height: "61px",
      background: "url(/xbox_bumper.svg)",
    },
    bumper_right: {
      float: "right",
      width: "170px",
      height: "61px",
      background: "url(/xbox_bumper.svg)",
      transform: "rotateY(180deg)",
    },
    arrows: {
      position: "absolute",
      width: "141px",
      height: "33px",
      top: "264px",
      left: "306px",
    },
    back: {
      float: "left",
      background: "url(/xbox_start_select.svg)",
      width: "33px",
      height: "33px",
    },
    start: {
      float: "right",
      background: "url(/xbox_start_select.svg)",
      backgroundPosition: "33px 0px",
      width: "33px",
      height: "33px",
    },
    abxy: {
      position: "absolute",
      width: "153px",
      height: "156px",
      top: "192px",
      left: "488px",
    },
    button_a: {
      top: "108px",
      left: "55px",
      position: "absolute",
      background: "url(/xbox_abxy.svg)",
      width: "48px",
      height: "48px",
    },
    button_b: {
      backgroundPosition: "-49px 0",
      top: "58px",
      right: "0px",
      position: "absolute",
      background: "url(/xbox_abxy.svg)",
      width: "48px",
      height: "48px",
    },
    button_x: {
      backgroundPosition: "-98px 0",
      top: "58px",
      left: "4px",
      position: "absolute",
      background: "url(/xbox_abxy.svg)",
      width: "48px",
      height: "48px",
    },
    button_y: {
      backgroundPosition: "48px 0",
      top: "7px",
      left: "55px",
      position: "absolute",
      background: "url(/xbox_abxy.svg)",
      width: "48px",
      height: "48px",
    },
    sticks: {
      top: "255px",
      left: "160px",
      position: "absolute",
      width: "371px",
      height: "196px",
    },
    stick_left: {
      top: "0",
      left: "0",
      position: "absolute",
      background: "url(/xbox_stick.svg)",
      backgroundPosition: "-85px 0",
      height: "83px",
      width: "83px",
    },
    stick_right: {
      top: "113px",
      left: "288px",
      position: "absolute",
      background: "url(/xbox_stick.svg)",
      backgroundPosition: "-85px 0",
      height: "83px",
      width: "83px",
    },
    dpad: {
      position: "absolute",
      width: "110px",
      height: "111px",
      top: "345px",
      left: "223px",
    },
    face_up: {
      backgroundPosition: "34px 0",
      left: "38px",
      top: "0px",
      width: "34px",
      height: "56px",
      background: "url(/xbox_dpad.svg)",
      position: "absolute",
    },
    face_down: {
      left: "38px",
      bottom: "0px",
      width: "34px",
      height: "56px",
      background: "url(/xbox_dpad.svg)",
      position: "absolute",
    },
    face_left: {
      backgroundPosition: "0 -93px",
      left: "0px",
      top: "38px",
      width: "55px",
      height: "35px",
      background: "url(/xbox_dpad.svg)",
      position: "absolute",
    },
    face_right: {
      backgroundPosition: "0 -57px",
      right: "0px",
      top: "38px",
      width: "55px",
      height: "35px",
      background: "url(/xbox_dpad.svg)",
      position: "absolute",
    },
  }),
);
