import { useLocation } from "react-router-dom";
import querystring from "querystring";

const useFeatureFlag = (flag: string) => {
  const { search } = useLocation();
  return {
    name: flag,
    isEnabled: Boolean(querystring.parse(search.substr(1))[`${flag}_enabled`]),
  };
};

export default useFeatureFlag;
