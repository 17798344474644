import proj4 from "proj4";
import * as THREE from "three";

export const centerUniverse = new THREE.Vector3(632484, 4835008, 0);
//export const centerUniverse = new THREE.Vector3(0, 0, 0);

proj4.defs("EPSG:32617", "+proj=utm +zone=17 +datum=WGS84 +units=m +no_defs");

export const WGS84ToLocal = (lat: number, lng: number) => {
  const xy = proj4("EPSG:32617", [lng, lat]);
  return [xy[0] - centerUniverse.x, xy[1] - centerUniverse.y];
};

export const LocalToESPG32617 = (x: number, y: number) => {
  return [x + centerUniverse.x, y + centerUniverse.y];
};

export const ESPG32617ToLocal = (x: number, y: number) => {
  return [x - centerUniverse.x, y - centerUniverse.y];
};
