import React, { useState, useEffect, useCallback } from "react";
import IconButton from "@material-ui/core/IconButton";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import { useGoogleMap } from "@react-google-maps/api";
import GeocodedAddress from "types/Location/GeocodedAddress";

export interface MapBoundsFitterProps {
  pointsOfInterest: GeocodedAddress[];
}

// Configures map zoom and bounds so it shows all markers
const MapBoundsFitter: React.FC<MapBoundsFitterProps> = ({
  pointsOfInterest,
}) => {
  const [isCentered, setIsCentered] = useState(true);
  const dragStartCallback = useCallback(() => {
    setIsCentered(false);
  }, []);

  const map = useGoogleMap();

  useEffect(() => {
    if (map) {
      const listener = map.addListener("dragstart", dragStartCallback);
      return () => {
        listener.remove();
      };
    }
  }, [map, dragStartCallback]);

  useEffect(() => {
    if (isCentered) {
      const bounds = new google.maps.LatLngBounds();
      for (const point of pointsOfInterest) {
        bounds.extend(point);
      }
      map?.setCenter(bounds.getCenter());
      map?.fitBounds(bounds);
    }
  }, [map, isCentered, pointsOfInterest]);

  if (isCentered) {
    return <></>;
  } else {
    return (
      <IconButton onClick={() => setIsCentered(true)}>
        <GpsFixedIcon />
      </IconButton>
    );
  }
};

export default MapBoundsFitter;
