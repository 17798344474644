import React from "react";
import LeftForDropOffActionButton from "components/deliveries/LeftForDropOffActionButton";
import useDeliveryTeleopSessionStore from "../useDeliveryTeleopSessionStore";
import useOrderForTeleopSession from "../useOrderForTeleopSession";
import StageActionSnackbarContainer from "./StageActionSnackbarContainer";

export interface LeftForDropOffActionSnackbarProps {}

const LeftForDropOffActionSnackbar: React.FC<
  LeftForDropOffActionSnackbarProps
> = () => {
  const robotID = useDeliveryTeleopSessionStore((s) => s.robotID);
  const order = useOrderForTeleopSession();

  if (!order.data) {
    return <></>;
  }

  return (
    <StageActionSnackbarContainer
      action={
        <LeftForDropOffActionButton
          variant="contained"
          color="primary"
          assignedRobotID={robotID}
        />
      }
    >
      A delivery drop-off is expected on{" "}
      {order.data.recipient.address_description.address_line_1}
    </StageActionSnackbarContainer>
  );
};

export default LeftForDropOffActionSnackbar;
