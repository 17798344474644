import React from "react";
import BusinessIcon from "@material-ui/icons/Business";

import DrawerLink from "./DrawerLink";

const RestaurantsScreenButton: React.FC = () => (
  <DrawerLink
    to="/restaurants"
    primary="Delivery Partners"
    icon={<BusinessIcon />}
    restrictToRoles={["Admin", "Supervisor"]}
  />
);

export default RestaurantsScreenButton;
