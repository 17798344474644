import React from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useFormikContext, FormikProps } from "formik";
import AddressFieldData from "./Model";

export interface EaterAddressComplementFieldProps {
  name: string;
  isEditable: boolean;
}

const EaterAddressComplementField: React.FC<
  EaterAddressComplementFieldProps
> = ({ name, isEditable }) => {
  const formik: FormikProps<AddressFieldData> = useFormikContext();
  return (
    <Box lineHeight={1}>
      <TextField
        name={name}
        value={formik.values.address_complement || ""}
        onChange={formik.handleChange}
        onBlur={formik.submitForm}
        variant="outlined"
        label="Address Complement"
        disabled={!isEditable}
        fullWidth
      />
    </Box>
  );
};

export default EaterAddressComplementField;
