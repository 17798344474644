import React from "react";
import Box from "@material-ui/core/Box";
import GamepadTrajectoryIndicator from "components/AR/TrajectoryIndicator";
import TeleopHUDContainer from "components/teleop/HUDContainer";

export type TrajectoryIndicatorWidgetProps = {};

const TrajectoryIndicatorWidget: React.FC<
  TrajectoryIndicatorWidgetProps
> = () => {
  return (
    <TeleopHUDContainer
      horizontalPosition="center"
      verticalPosition="bottom"
      alwaysActive
    >
      <Box height="500px">
        <GamepadTrajectoryIndicator />
      </Box>
    </TeleopHUDContainer>
  );
};

export default TrajectoryIndicatorWidget;
