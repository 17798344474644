import React from "react";
import deepEqual from "deep-equal";
import useGamepadStore from "../useGamepadStore";
import VisualGamepadConnectedView from "./ConnectedView";
import VisualGamepadDisconnectedView from "./DisconnectedView";

export interface VisualGamepadProps {}

const VisualGamepad: React.FC<VisualGamepadProps> = (): JSX.Element => {
  const state = useGamepadStore(
    (s) =>
      s.gamepadState
        ? {
            buttons: s.gamepadState.buttons,
            axes: s.gamepadState.axes,
          }
        : null,
    deepEqual,
  );

  if (state) {
    return <VisualGamepadConnectedView {...state} />;
  } else {
    return <VisualGamepadDisconnectedView />;
  }
};

export default VisualGamepad;
