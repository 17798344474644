import React from "react";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import OrderCardView from "./View";

export interface OrderCardProps {
  order: UberEatsOrder;
}

const OrderCard: React.FC<OrderCardProps> = ({ order }) => {
  const asOf = new Date();
  return <OrderCardView order={order} asOf={asOf} />;
};

export default OrderCard;
