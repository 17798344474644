import { Typography } from "@material-ui/core";
import React from "react";
import Robot from "types/robots/Robot";

export interface CargoTemperatureProps {
  robot: Robot;
}

const CargoTemperature: React.FC<CargoTemperatureProps> = ({ robot }) => {
  let tempStr = "unknown";
  if (robot.last_known_cargo_temperature) {
    tempStr = `${robot.last_known_cargo_temperature.temperature_in_celsius}°C`;
  }
  return (
    <>
      <Typography variant="caption" display="block">
        Cargo temperature
      </Typography>
      {tempStr}
    </>
  );
};

export default CargoTemperature;
