// A bit of a name clash here, but the robot controller component refers to ways
// of controling the robot, like a joystick. Not to be confused by Controllers
// in the sense of a software abstraction

import React from "react";
import { useTeleopDrivingHotkeys } from "../driving-hotkeys-hook";
import TeleopGamepad from "../Gamepad";

export interface TeleopRobotControllerProps {
  robotID: string;
  disableKeyboardHotkeys?: boolean;
  disableGamepad?: boolean;
}

const TeleopRobotController: React.FC<TeleopRobotControllerProps> = ({
  robotID,
  disableKeyboardHotkeys = false,
  disableGamepad = false,
}) => {
  useTeleopDrivingHotkeys(robotID, disableKeyboardHotkeys);
  if (disableGamepad) {
    return <></>;
  } else {
    return <TeleopGamepad robotID={robotID} />;
  }
};

export default TeleopRobotController;
