import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useSetUberEatsOrderRecipientContacts } from "mutations/deliveries/uber-eats-orders";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";

export interface RecipientPhoneNumberAutoSaveFieldProps {
  orderUUID: string;
  currentPhoneNumber: string;
  isEditable?: boolean;
}

const RecipientPhoneNumberAutoSaveField: React.FC<
  RecipientPhoneNumberAutoSaveFieldProps
> = ({ orderUUID, currentPhoneNumber, isEditable = true }) => {
  const [phoneNumber, setPhoneNumber] = useState(currentPhoneNumber);
  const { showAlert } = useAlertMessage();
  const setContacts = useSetUberEatsOrderRecipientContacts();

  const handleSubmit = () => {
    if (currentPhoneNumber !== phoneNumber) {
      setContacts
        .mutateAsync({ orderUUID, phoneNumber })
        .then((order) => {
          setPhoneNumber(order.recipient.phone_number);
          showAlert({
            message: "Recipient phone number updated successfully",
            severity: "success",
          });
        })
        .catch(({ message }) => {
          setPhoneNumber(currentPhoneNumber);
          showAlert({
            message: message || "Unknown error",
            severity: "error",
            autoCloseAfterMillis: 5000,
          });
        });
    }
  };
  return (
    <Box lineHeight={1}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextField
          value={phoneNumber || ""}
          onChange={(e) => setPhoneNumber(e.currentTarget.value)}
          onBlur={handleSubmit}
          variant="outlined"
          label="Recipient Phone Number"
          disabled={!isEditable || setContacts.isLoading}
          fullWidth
        />
      </form>
    </Box>
  );
};

export default RecipientPhoneNumberAutoSaveField;
