import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import UsersDataGrid from "../UsersDataGrid";
import CreateUserDialog from "./CreateUserDialog";

export type UsersListScreenProps = RouteComponentProps;

const UsersListScreen: React.FC<UsersListScreenProps> = ({ history }) => {
  return (
    <DashboardScreenTemplate screenTitle="Users">
      <CreateUserDialog />
      <UsersDataGrid
        onSelectUser={({ uuid }) => history.push(`/users/${uuid}`)}
      />
    </DashboardScreenTemplate>
  );
};

export default UsersListScreen;
