import React from "react";

import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AutocompleteInput from "components/common/AutocompleteInput";
import { useRobots } from "fetchers/robots/robots";

export interface RobotAutocompleteFieldProps {
  onChange: (robotID: null | string) => void;
  autoFocus?: boolean;
  value?: null | string;
  label?: string;
  error?: boolean;
  helperText?: boolean | string;
  required?: boolean;
  disabled?: boolean;
}

const RobotAutocompleteField: React.FC<RobotAutocompleteFieldProps> = ({
  onChange,
  autoFocus,
  value = null,
  label = "Robot",
  error = false,
  helperText = false,
  required = false,
  disabled = false,
}) => {
  const activeRobots = useRobots({
    select: (robots) =>
      robots.filter((robot) => robot.deployment_kind !== "retired"),
  });
  const inputProps = { error, autoFocus, label, required };

  return (
    <Autocomplete
      options={activeRobots.data?.map((robot) => robot.id) || []}
      value={value}
      renderInput={(params) => (
        <>
          <AutocompleteInput {...inputProps} {...params} />
          {helperText ? (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          ) : null}
        </>
      )}
      autoSelect={false}
      onChange={(_, newValue: null | string) => onChange(newValue)}
      style={{ width: "100%" }}
      disabled={disabled || !activeRobots.data}
    />
  );
};

export default RobotAutocompleteField;
