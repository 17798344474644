import { Marker } from "@react-google-maps/api";
import MapBox from "components/Map/MapBox";
import PersonPinMarker from "components/Map/PersonPinMarker";
import MapBoundsFitter from "components/Map/BoundsFitter";
import RobotMapMarker from "components/teleop/Map/RobotMarker";
import { useRestaurant } from "fetchers/deliveries/restaurants";
import React from "react";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import useActivateTeleop from "components/teleop/useActivateTeleop";

export interface OrderCardLiveMapProps {
  order: UberEatsOrder;
}

const OrderCardLiveMap: React.FC<OrderCardLiveMapProps> = ({ order }) => {
  const restaurant = useRestaurant(order.restaurant_uuid);
  const pointsOfInterest = [order.recipient.geocoded_address];
  useActivateTeleop(order.assigned_robot_id);
  if (restaurant.data) {
    pointsOfInterest.push(restaurant.data.geocoded_address);
  }
  return (
    <MapBox width="280px" height="220px">
      {restaurant.data ? (
        <Marker
          position={restaurant.data.geocoded_address}
          title={restaurant.data.name}
        />
      ) : null}
      <PersonPinMarker
        position={order.recipient.geocoded_address}
        tooltip={order.recipient.name}
      />
      <MapBoundsFitter pointsOfInterest={pointsOfInterest} />
      {order.assigned_robot_id ? (
        <RobotMapMarker robotID={order.assigned_robot_id} />
      ) : null}
    </MapBox>
  );
};

export default OrderCardLiveMap;
