import GamepadState from "lib/gamepad/types/GamepadState";
import React from "react";
import { useStyles } from "./styles";

export interface VisualGamepadConnectedViewProps {
  buttons: GamepadState["buttons"];
  axes: GamepadState["axes"];
}

const VisualGamepadConnectedView: React.FC<VisualGamepadConnectedViewProps> = ({
  buttons,
  axes,
}) => {
  const cls = useStyles();
  return (
    <>
      <div className={cls.xbox_black}>
        <div className={cls.triggers}>
          <span
            className={cls.trigger_left}
            style={{ opacity: axes.l_trigger }}
          ></span>
          <span
            className={cls.trigger_right}
            style={{ opacity: axes.r_trigger }}
          ></span>
        </div>
        <div className={cls.bumpers}>
          <span
            className={cls.bumper_left}
            style={{ opacity: +buttons.l_bumper }}
          ></span>
          <span
            className={cls.bumper_right}
            style={{ opacity: +buttons.r_bumper }}
          ></span>
        </div>
        <div className={cls.arrows}>
          <span className={cls.back} style={{ opacity: +buttons.view }}></span>
          <span className={cls.start} style={{ opacity: +buttons.menu }}></span>
        </div>
        <div className={cls.abxy}>
          <span className={cls.button_a} style={{ opacity: +buttons.a }}></span>
          <span className={cls.button_b} style={{ opacity: +buttons.b }}></span>
          <span className={cls.button_x} style={{ opacity: +buttons.x }}></span>
          <span className={cls.button_y} style={{ opacity: +buttons.y }}></span>
        </div>
        <div className={cls.sticks}>
          <span
            className={cls.stick_left}
            style={stickStyle(buttons.l_stick, axes.l_x, axes.l_y)}
          ></span>
          <span
            className={cls.stick_right}
            style={stickStyle(buttons.r_stick, axes.r_x, axes.r_y)}
          ></span>
        </div>
        <div className={cls.dpad}>
          <span
            className={cls.face_up}
            style={{ opacity: +buttons.d_up }}
          ></span>
          <span
            className={cls.face_down}
            style={{ opacity: +buttons.d_down }}
          ></span>
          <span
            className={cls.face_left}
            style={{ opacity: +buttons.d_left }}
          ></span>
          <span
            className={cls.face_right}
            style={{ opacity: +buttons.d_right }}
          ></span>
        </div>
      </div>
    </>
  );
};

const stickStyle = (isPressed: boolean, x: number, y: number) => {
  let backgroundPosition: string;
  if (isPressed) {
    backgroundPosition = "0px 0";
  } else {
    backgroundPosition = "-85px 0";
  }
  return {
    marginTop: `${y * -22}px`,
    marginLeft: `${x * 22}px`,
    transform: `rotateX(${y * -22}deg) rotateY(${x * -22}deg)`,
    backgroundPosition,
  };
};

export default VisualGamepadConnectedView;
