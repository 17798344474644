import create from "zustand";

export type ActionConfirmationOptions = {
  confirmationText: string;
  onConfirm: () => void;
  abortLabel?: string;
  confirmLabel?: string;
};

export type ActionConfirmationDialogState = {
  options: ActionConfirmationOptions;
  isOpen: boolean;
  isLoading: boolean;
};

export type ActionConfirmationDialogAPI = {
  open: (options: ActionConfirmationOptions) => void;
  setLoading: () => void;
  clearLoading: () => void;
  dismiss: () => void;
};

export const useActionConfirmationDialogStore = create<
  ActionConfirmationDialogState & ActionConfirmationDialogAPI
>((set) => ({
  options: {
    confirmationText: "",
    onConfirm: () => {},
  },
  isLoading: false,
  isOpen: false,
  open: (options: ActionConfirmationOptions) => set({ isOpen: true, options }),
  dismiss: () => set({ isOpen: false, isLoading: false }),
  setLoading: () => set({ isLoading: true }),
  clearLoading: () => set({ isLoading: false }),
}));
