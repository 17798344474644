import AddressFieldData, {
  emptyAddressField,
} from "components/Location/AddressField/Model";

export default interface GenericParkingSpotFormData {
  description: string;
  address: AddressFieldData;
}

export const emptyGenericParkingSpotFormField: GenericParkingSpotFormData = {
  description: "",
  address: emptyAddressField,
};
