import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import UserRolesForm from "components/users/UserRolesForm";

import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import useSetRolesDialogStore from "./useSetRolesDialogStore";

export type SetRolesDialogProps = {};

const SetRolesDialog: React.FC<SetRolesDialogProps> = () => {
  const { showAlert } = useAlertMessage();
  const dialog = useSetRolesDialogStore();

  const onSubmit = () => {
    dialog.close();
    showAlert({
      message: "Roles were set successfully",
      severity: "success",
    });
  };

  const onSubmissionError = (error: { message: string }) => {
    showAlert({
      message: error.message,
      severity: "error",
      autoCloseAfterMillis: 2000,
    });
  };

  if (dialog.user) {
    return (
      <Dialog open maxWidth="md" onClose={() => dialog.close()}>
        <DialogContent>
          <Box width="450px">
            <UserRolesForm
              onSubmit={() => onSubmit()}
              onSubmissionError={onSubmissionError}
              userUUID={dialog.user.uuid}
              initialData={dialog.user}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );
  } else {
    return <></>;
  }
};

export default SetRolesDialog;
