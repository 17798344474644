import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, LinkProps } from "react-router-dom";

import VisibleTo from "components/auth/VisibleTo";

export interface DrawerLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  restrictToRoles?: string[];
}

const DrawerLink: React.FC<DrawerLinkProps> = (props) => {
  const { icon, primary, to, restrictToRoles } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  const baseElement = (
    <ListItem button component={renderLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );

  if (restrictToRoles) {
    return <VisibleTo roles={restrictToRoles}>{baseElement}</VisibleTo>;
  } else {
    return baseElement;
  }
};

export default DrawerLink;
