import React from "react";

import { useCanAccess } from "services/auth/identity";

export interface VisibleToProps {
  roles: string[];
}

const VisibleTo: React.FC<VisibleToProps> = ({ roles, children }) => {
  const canAccess = useCanAccess();
  if (canAccess(roles)) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};

export default VisibleTo;
