import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "@material-ui/core/Container";
import UberEatsOrderSummary from "../UberEatsOrderSummary";

export type ViewUberEatsOrderScreenProps = RouteComponentProps<{
  orderUUID: string;
}>;

const ViewUberEatsOrderScreen: React.FC<ViewUberEatsOrderScreenProps> = ({
  match,
}) => {
  return (
    <DashboardScreenTemplate screenTitle="View Order">
      <Container>
        <UberEatsOrderSummary orderUUID={match.params.orderUUID} />
      </Container>
    </DashboardScreenTemplate>
  );
};

export default ViewUberEatsOrderScreen;
