import React from "react";

import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";

import RestaurantContactsForm from "components/deliveries/RestaurantContactsForm";
import Restaurant from "types/deliveries/Restaurant";

export interface RestaurantSetContactsDialogProps {
  restaurant: Restaurant;
  onSubmit: () => void;
  onSubmissionError?: (error: { message: string }) => void;
  isOpen: boolean;
  onClose: () => void;
}

const RestaurantSetContactsDialog: React.FC<
  RestaurantSetContactsDialogProps
> = ({
  restaurant,
  onSubmit,
  onSubmissionError = () => {},
  isOpen,
  onClose,
}) => {
  return (
    <Dialog scroll="body" open={isOpen} onClose={onClose}>
      <Box padding={3}>
        <RestaurantContactsForm
          restaurantUUID={restaurant.uuid}
          onSubmit={onSubmit}
          onSubmissionError={onSubmissionError}
          initialValues={{ contacts: restaurant.contacts }}
        />
      </Box>
    </Dialog>
  );
};

export default RestaurantSetContactsDialog;
