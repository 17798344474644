import { Form, Formik } from "formik";
import { useSetUserRoles } from "mutations/users";
import React from "react";

import FormSchema from "./Schema";
import FormData from "./Model";

export interface UserRolesFormikProps {
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
  userUUID: string;
  initialData: FormData;
}

const UserRolesFormik: React.FC<UserRolesFormikProps> = ({
  children,
  userUUID,
  initialData,
  onSubmit = () => {},
  onSubmissionError = () => {},
}) => {
  const userRoles = useSetUserRoles();
  return (
    <Formik
      initialValues={initialData}
      validationSchema={FormSchema}
      onSubmit={(values, helpers) => {
        userRoles
          .mutateAsync({ uuid: userUUID, roles: values.roles })
          .then(() => onSubmit())
          .catch((err) => onSubmissionError(err))
          .finally(() => helpers.setSubmitting(false));
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default UserRolesFormik;
