import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { useUberEatsOrderQuery } from "fetchers/deliveries/uber-eats-orders";
import { googleMapsLink } from "lib/location/places";
import { useRestaurant } from "fetchers/deliveries/restaurants";

export interface RestaurantDirectionsButtonProps
  extends Omit<ButtonProps, "href" | "onClick" | "children"> {
  orderUUID: string;
}

const RestaurantDirectionsButton: React.FC<RestaurantDirectionsButtonProps> = ({
  orderUUID,
  ...buttonProps
}) => {
  const order = useUberEatsOrderQuery(orderUUID);
  const restaurantLocation = useRestaurant(order.data?.restaurant_uuid, {
    select: (r) => r.geocoded_address,
  });
  if (restaurantLocation.data) {
    return (
      <Button {...buttonProps} href={googleMapsLink(restaurantLocation.data)}>
        Restaurant directions
      </Button>
    );
  } else {
    return (
      <Button {...buttonProps} disabled>
        Restaurant directions
      </Button>
    );
  }
};

export default RestaurantDirectionsButton;
