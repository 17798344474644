import User from "types/auth/User";
import create, { StateSelector, EqualityChecker } from "zustand";
import createContext from "zustand/context";

export type IdentityState = {
  accessToken: null | string;
  isAuthenticated: boolean;
  user: null | User;
};

export type IdentityAPI = {
  setIdentity: (accessToken: string, user: User) => void;
  signOut: () => void;
};

export type IdentityStore = IdentityState & IdentityAPI;

export const createIdentityStore = () =>
  create<IdentityStore>((set) => ({
    isAuthenticated: false,
    accessToken: null,
    user: null,
    setIdentity: (accessToken, user) =>
      set({ isAuthenticated: true, accessToken, user }),
    signOut: () =>
      set({
        user: null,
        accessToken: null,
        isAuthenticated: false,
      }),
  }));

const { Provider, useStore } = createContext<IdentityStore>();

type UseStore = <StateSlice = IdentityStore>(
  selector?: StateSelector<IdentityStore, StateSlice> | undefined,
  equalityFn?: EqualityChecker<StateSlice>,
) => StateSlice;

export const useIdentityStore: UseStore = useStore;
export const IdentityStoreProvider = Provider;
