import { useObservable } from "@libreact/use-observable";
import { useContext, createContext } from "react";
import * as rxjs from "rxjs/operators";

import Query from ".";

const QueryContext = createContext<Query | undefined>(undefined);

export default QueryContext;

export const useRobotTelemetryQuery = () => useContext(QueryContext);

export const useThrottledRobotPosition = (
  robotID: string,
  throttleMs = 2000,
) => {
  const telemetryQuery = useRobotTelemetryQuery();
  const [robotPosition] = useObservable(
    telemetryQuery!
      .selectValidRobotPosition(robotID)
      .pipe(rxjs.throttleTime(throttleMs)),
  );

  return robotPosition;
};

export const useThrottledRobotDirection = (
  robotID: string,
  throttleMs = 100,
) => {
  const telemetryQuery = useRobotTelemetryQuery();
  const [robotDirection] = useObservable(
    telemetryQuery!
      .selectDirection(robotID)
      .pipe(rxjs.throttleTime(throttleMs)),
  );

  return robotDirection;
};

export const useThrottledSirenState = (robotID: string, throttleMs = 100) => {
  const telemetryQuery = useRobotTelemetryQuery();
  const [sirenState] = useObservable(
    telemetryQuery!
      .selectSirenState(robotID)
      .pipe(rxjs.throttleTime(throttleMs)),
  );

  return sirenState;
};
