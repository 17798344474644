import React from "react";

import Menu from "@material-ui/core/Menu";

export interface UserRowActionsContainerProps {
  anchorEl: null | Element;
  open: boolean;
  onClose: () => void;
}

const UserRowActionsContainer: React.FC<UserRowActionsContainerProps> = ({
  anchorEl,
  open,
  children,
  onClose,
}) => {
  return (
    <Menu
      id="user-row-actions"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{ style: { width: "35ch" } }}
    >
      {children}
    </Menu>
  );
};

export default UserRowActionsContainer;
