import React, { useState } from "react";

import DataGridRowActionsButton from "components/common/DataGridRowActionsButton";
import RestaurantRowMenuContainer from "./Container";
import Restaurant from "types/deliveries/Restaurant";
import RestaurantSetContactsAction from "./SetContactsAction";

export interface RestaurantRowMenuProps {
  restaurant: Restaurant;
}

const RestaurantRowMenu: React.FC<RestaurantRowMenuProps> = ({
  restaurant,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const closeMenu = () => setAnchorEl(null);

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <DataGridRowActionsButton
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
      />
      <RestaurantRowMenuContainer
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
      >
        <RestaurantSetContactsAction
          restaurant={restaurant}
          onSubmit={closeMenu}
        />
      </RestaurantRowMenuContainer>
    </>
  );
};

export default RestaurantRowMenu;
