import React from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import DialogContent from "@material-ui/core/DialogContent";

import DialogWithTriggerButton from "components/common/DialogWithTriggerButton";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import RobotCreationForm from "../RobotCreationForm";
import VisibleTo from "components/auth/VisibleTo";

export interface RobotsScreenActionsProps {}

const RobotsScreenActions: React.FC<RobotsScreenActionsProps> = () => {
  const { showAlert } = useAlertMessage();

  const onSubmit = (setOpen: (isOpen: boolean) => void) => {
    setOpen(false);
    showAlert({
      message: "Robot created!",
      severity: "success",
    });
  };

  const onSubmissionError = (error: { message?: string }) => {
    showAlert({
      message: error.message || "Unknown error.",
      severity: "error",
      autoCloseAfterMillis: 2000,
    });
  };

  return (
    <Box paddingBottom={2}>
      <VisibleTo roles={["Admin"]}>
        <DialogWithTriggerButton
          button={(props) => (
            <Button {...props} variant="contained" color="primary">
              New Robot
            </Button>
          )}
          dialogMaxWidth="md"
        >
          {(props) => (
            <DialogContent>
              <Box width="700px">
                <RobotCreationForm
                  onSubmit={() => onSubmit(props.setOpen)}
                  onSubmissionError={onSubmissionError}
                />
              </Box>
            </DialogContent>
          )}
        </DialogWithTriggerButton>
      </VisibleTo>
    </Box>
  );
};

export default RobotsScreenActions;
