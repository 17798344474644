import React, { useEffect, useState } from "react";
import { useGoogleMapsService } from "services/location/GoogleMapsService/Context";
import Geocode from "types/Location/Geocode";
import { useGoogleMap } from "@react-google-maps/api";

export interface MapRoutePathProps {
  origin: Geocode;
  destination: Geocode;
  color?: string;
  fitBounds?: boolean;
}

const MapRoutePath: React.FC<MapRoutePathProps> = ({
  origin,
  destination,
  color,
  fitBounds,
}) => {
  const mapsService = useGoogleMapsService();
  const [polylines, setPolylines] = useState<google.maps.Polyline[]>();
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const map = useGoogleMap();

  useEffect(() => {
    mapsService!
      .getRoutePolylines(origin, destination, color)
      .then(setPolylines);
  }, [color, origin, destination, mapsService]);

  useEffect(() => {
    if (!polylines) {
      return;
    } else {
      mapsService!.getBoundsForPolylines(polylines).then(setBounds);
    }
  }, [polylines, mapsService]);

  useEffect(() => {
    if (!map || !bounds) {
      return;
    }
    if (fitBounds) {
      const newBounds = map.getBounds() ?? bounds;
      map.fitBounds(newBounds.union(bounds));
    }
  }, [bounds, fitBounds, map]);

  useEffect(() => {
    if (!map || !polylines) {
      return;
    }
    polylines.forEach((l) => l.setMap(map));
    return () => {
      polylines.forEach((l) => l.setMap(null));
    };
  }, [map, polylines]);

  return <></>;
};

export default MapRoutePath;
