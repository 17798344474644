import React from "react";

import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import NavigationDrawer, { NavigationDrawerProps } from "./";

import DispatchButton from "./DispatchButton";
import SignOutButton from "./SignOutButton";
import TeleopButton from "./TeleopButton";
import UsersButton from "./UsersButton";
import VisibleTo from "components/auth/VisibleTo";
import RestaurantsScreenButton from "./RestaurantsScreenButton";
import SpectateRobotsButton from "./SpectateRobotsButton";
import OrderCardsButton from "./OrderCardsButton";
import PartnersIntegrationsButton from "./PartnersIntegrationsButton";
import MediaSearchButton from "./MediaSearchButton";

const NavigationDrawerWithLinks: React.FC<NavigationDrawerProps> = (props) => (
  <NavigationDrawer {...props}>
    <VisibleTo roles={["Supervisor", "Admin"]}>
      <List>
        <DispatchButton />
        <OrderCardsButton />
        <UsersButton />
        <RestaurantsScreenButton />
        <SpectateRobotsButton />
        <MediaSearchButton />
        <PartnersIntegrationsButton />
      </List>
      <Divider />
    </VisibleTo>
    <VisibleTo roles={["Supervisor", "Admin", "Pilot", "Rescuer"]}>
      <List>
        <TeleopButton />
      </List>
      <Divider />
    </VisibleTo>
    <List>
      <SignOutButton />
    </List>
  </NavigationDrawer>
);

export default NavigationDrawerWithLinks;
