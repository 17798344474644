import DashboardScreenTemplate from "components/DashboardScreenTemplate";
import React, { useEffect, useCallback, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import useAxios from "services/axios/useAxios";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";
import FullScreen from "@material-ui/icons/Fullscreen";
import FullScreenExit from "@material-ui/icons/FullscreenExit";
import { KeyboardDatePicker } from "@material-ui/pickers";
import RobotAutocompleteFieldView from "../AutocompleteField";

export interface PlaybackScreenProps {}

type Chunk = number;

const pad = (n: number) => {
  return n.toString().padStart(2, "0");
};

const toSeconds = (time: any) => {
  return time.hour * 3600 + time.minutes * 60 + time.seconds;
};

const getChunkLimits = (chunk_id: Chunk) => {
  return {
    start: {
      hour: Math.floor(chunk_id / 12),
      minutes: (chunk_id * 5) % 60,
      seconds: 0,
    },
    end: {
      hour: Math.floor(chunk_id / 12),
      minutes: ((chunk_id * 5) % 60) + 4,
      seconds: 59,
    },
  };
};

const getFilename = (chunk_id: Chunk, chunkMetadata: any) => {
  if (!chunkMetadata) {
    return "";
  }
  if (!chunkMetadata.has(chunk_id)) {
    return "";
  }
  return chunkMetadata.get(chunk_id).download_url;
};

const toHHMMSS = (sec_num: number) => {
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  const seconds = sec_num - hours * 3600 - minutes * 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

enum ChunkStatus {
  NotFound = 0,
  Unknown,
  OK,
}

const parseDate = (value: any) => {
  if (typeof value === "object") {
    return value.toJSON().slice(0, 10);
  }
  return value;
};

const PlaybackScreen: React.FC<PlaybackScreenProps> = () => {
  const [src, setSrc] = useState<string>("");
  const [video, setVideo] = useState<HTMLVideoElement>();
  const [speed, setSpeed] = useState<number>(0);
  const [curChunk, setCurChunk] = useState<Chunk>();
  const [time, setTime] = useState(20 * 60 * 60);
  const [paused, setPaused] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);
  const [robot, setRobot] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [colors, setColors] = useState<string>(
    "linear-gradient(to right, yellow 0% 100%)",
  );
  const [chunkStatus, setChunkStatus] = useState<Map<number, ChunkStatus>>(
    new Map(),
  );
  const [chunkMetadata, setChunkMetadata] = useState<any>(null);
  const axios = useAxios("media-downloader");

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setRobot(params.robot);
    if (params.date != undefined) {
      setStartDate(new Date(params.date + "T00:00"));
    }
    if (params.time) {
      setTime(parseInt(params.time));
    }
  }, []);

  useEffect(() => {
    if (!robot || !startDate) {
      return;
    }

    axios
      .get("/playback", {
        params: {
          robot: robot,
          date: parseDate(startDate),
        },
      })
      .then((res) => {
        if (!res.data) {
          alert("No video available for this robot on this date");
          return;
        }
        const metadata = new Map();
        for (let i = 0; i < res.data.length; i++) {
          const hms = res.data[i].start_time.split(":");
          const seconds = +hms[0] * 60 * 60 + +hms[1] * 60 + +hms[2];
          const chunk_id = Math.floor(seconds / (5 * 60));
          metadata.set(chunk_id, res.data[i]);
        }
        setChunkMetadata(metadata);
      });
  }, [axios, robot, startDate]);

  const start = 0;
  const end = 60 * 60 * 24;
  useEffect(() => {
    if (!chunkMetadata) {
      return;
    }
    let gradient = "linear-gradient(to right";
    for (let chunk_id = 0; chunk_id < end / (5 * 60); chunk_id++) {
      let segment_color;
      if (chunkMetadata.has(chunk_id)) {
        segment_color = "green";
      } else {
        segment_color = "red";
      }
      gradient += `, ${segment_color} ${(chunk_id / (end / (5 * 60))) * 100}% ${
        ((chunk_id + 1) / (end / (5 * 60))) * 100
      }%`;
    }
    gradient += ")";
    setColors(gradient);
  }, [chunkMetadata, curChunk, end]);

  const loadNextChunk = React.useCallback(() => {
    setTime(toSeconds(getChunkLimits(curChunk! + 1).start));
  }, [curChunk]);

  useEffect(() => {
    if (video === undefined || chunkMetadata === null) {
      return;
    }
    const chunk_id = Math.floor(time / (5 * 60));
    if (curChunk !== chunk_id) {
      setCurChunk(chunk_id);
      setSrc(getFilename(chunk_id, chunkMetadata));
      video!.load();
    }
    video.onended = (ev: Event) => {
      loadNextChunk();
    };
  }, [time, video, curChunk, loadNextChunk, chunkMetadata]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!curChunk || !video) {
        return;
      }
      const chunk_start = toSeconds(getChunkLimits(curChunk).start);
      setTime(chunk_start + Math.round(video!.currentTime));
      const params = {
        date: parseDate(startDate),
        robot,
        time: chunk_start + Math.round(video!.currentTime),
      };
      window.history.replaceState(
        null,
        "Video Playback",
        "playback?" + new URLSearchParams(params).toString(),
      );
      video!.playbackRate = Math.pow(2, speed);

      if (video.readyState === HTMLMediaElement.HAVE_NOTHING) {
        chunkStatus.set(curChunk, ChunkStatus.NotFound);
        loadNextChunk();
      } else if (
        video.readyState === HTMLMediaElement.HAVE_FUTURE_DATA ||
        video.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA
      ) {
        chunkStatus.set(curChunk, ChunkStatus.OK);
        setChunkStatus(chunkStatus);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [video, curChunk, speed, chunkStatus, loadNextChunk, robot, startDate]);

  const videoRef = useCallback((node: HTMLVideoElement) => {
    if (node == null) {
      return;
    }
    setVideo(node);
  }, []);

  const onSeek = (sec: number) => {
    setTime(sec);
  };

  useEffect(() => {
    const downHandler = (event: any) => {
      if (event.key === "ArrowUp") {
        setSpeed(Math.min(speed + 1, 4));
      }
      if (event.key === "ArrowDown") {
        setSpeed(Math.max(speed - 1, -4));
      }
    };
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [speed]);

  useEffect(() => {
    if (!video) {
      return;
    }
    if (paused) {
      video.pause();
    } else {
      video.play();
    }
  }, [video, paused, time]);

  useEffect(() => {
    //TODO: fix full screen mode so that custom controls are shown.
    if (!video) {
      return;
    }
    if (fullscreen) {
      video.requestFullscreen();
    } else {
      //document.exitFullscreen();
    }
  }, [fullscreen, video]);

  const cls = useStyles();
  return (
    <DashboardScreenTemplate screenTitle="Robot Video Playback">
      <div className={cls.form}>
        <RobotAutocompleteFieldView
          label="robot"
          value={robot}
          onChange={setRobot}
        />
        <KeyboardDatePicker
          autoOk
          label={"date"}
          disableFuture
          variant="inline"
          format="yyyy-MM-dd"
          value={startDate}
          onChange={setStartDate}
        />
      </div>
      <div className={cls.container} onClick={(e) => setPaused(!paused)}>
        <video ref={videoRef} className={cls.video} autoPlay playsInline>
          <p>This browser does not support the video element.</p>
          <source src={src} type="video/mp4" />
        </video>

        <div className={cls.videomenu}>
          <div className={cls.toolbar} onClick={(e) => setPaused(!paused)}>
            <div className={cls.button}>
              {paused ? (
                <PlayArrow fontSize={"small"} />
              ) : (
                <Pause fontSize={"small"} />
              )}
            </div>
            <div className={cls.time}>
              {`${toHHMMSS(time)} / ${toHHMMSS(end)} - ${Math.pow(2, speed)}x`}
            </div>
            <div
              className={cls.button}
              onClick={(e) => setFullscreen(!fullscreen)}
            >
              {fullscreen ? (
                <FullScreen fontSize={"small"} />
              ) : (
                <FullScreenExit fontSize={"small"} />
              )}
            </div>
          </div>
          <div className={cls.timeline}>
            <input
              className={cls.range}
              style={{ backgroundImage: colors }}
              value={time}
              onChange={(e) => onSeek(parseInt(e.target.value))}
              type="range"
              min={start}
              max={end}
            />
            <div
              className={cls.range_cover}
              style={{ width: `${(time / end) * 100}%` }}
            />
          </div>
        </div>
      </div>
    </DashboardScreenTemplate>
  );
};

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      width: "800px",
    },
    form: {
      display: "flex",
      flexDirection: "row",
      width: "800px",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: "10px",
    },
    videomenu: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: "400px",
      width: "inherit",
      paddingLeft: "4px",
      paddingRight: "4px",
      zIndex: 2,
    },
    toolbar: {
      display: "flex",
      flexDirection: "row",
    },
    button: {
      display: "flex",
      height: "38px",
      width: "38px",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        borderRadius: "38px",
        backgroundColor: "#01000040",
        opacity: 0.6,
        transition: "0.3s",
      },
    },
    time: {
      display: "flex",
      flexGrow: 2,
      alignItems: "center",
    },
    timeline: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "2px",
      paddingLeft: "10px",
      paddingRight: "10px",
      flexGrow: 1,
    },
    range: {
      display: "flex",
      "-webkit-appearance": "none",
      transition: "opacity .2s",
      height: "4px",
      width: "100%",
      backgroundRepeat: "repeat",
      borderRadius: "2px",
      cursor: "pointer",
      "&::-webkit-slider-thumb": {
        "-webkit-appearance": "none",
        height: "12px",
        width: "12px",
        borderRadius: "12px",
        opacity: 0.4,
        background: "white",
        "&:hover": {
          transition: "background 0.3s ease-in-out",
          opacity: 1,
          background: "white",
        },
      },
      "&::-webkit-slider-runnable-track": {
        "-webkit-appearance": "none",
        boxShadow: "none",
        border: "none",
        background: "transparent",
      },
      "&::-webkit-media-controls-enclosure": {
        display: "none !important",
      },
    },
    range_cover: {
      height: "4px",
      backgroundColor: "#ffffff70",
      marginLeft: "2px",
      marginRight: "2px",
      marginTop: "-6px",
    },
    video: {
      width: "inherit",
    },
  }),
);
export default PlaybackScreen;
