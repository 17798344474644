import React from "react";
import * as THREE from "three";
import { ESPG32617ToLocal } from "./coords";
import { useAsyncMemo } from "use-async-memo";

// eslint-disable-next-line
const { Wkt } = require("wicket");

interface Point {
  x: number;
  y: number;
}

const toVector2 = (arr: Point[]) => {
  // The vectors that we were parsing were very large float numbers
  // This was causing a lost of precisiong making the shapes irregular.
  // That's why we substract a number for all polygons to increase precision.
  const out = [];
  for (let i = 0; i < arr.length; i++) {
    const point = ESPG32617ToLocal(arr[i].x, arr[i].y);
    out.push(new THREE.Vector2(point[0], point[1]));
  }
  return out;
};

const parseWkt = (wktStr: string): THREE.Shape => {
  // Sometimes polygons have holes, wkt in these cases returns multiple components.
  // The first one is the outer shell and the rest are the inner holes.
  const wkt = new Wkt().read(wktStr);
  const shape = new THREE.Shape(toVector2(wkt.components[0]));
  for (let i = 1; i < wkt.components.length; i++) {
    shape.holes.push(new THREE.Path(toVector2(wkt.components[i])));
  }
  return shape;
};

export interface PolygonProps {
  url: string;
}

const Polygon: React.FC<PolygonProps> = ({ url }) => {
  const shape = useAsyncMemo(async () => {
    const response = await fetch(url);
    const content: string = await response.text();
    return parseWkt(content);
  }, [url]);
  if (!shape) {
    return <></>;
  }
  return (
    <mesh>
      <shapeGeometry args={[shape]} />
      <meshBasicMaterial color={"orange"} side={THREE.DoubleSide} />
    </mesh>
  );
};

export default Polygon;
