import React from "react";
import OrderPickedUpActionButton from "components/deliveries/OrderPickedUpActionButton";
import useDeliveryTeleopSessionStore from "../useDeliveryTeleopSessionStore";
import useOrderForTeleopSession from "../useOrderForTeleopSession";
import StageActionSnackbarContainer from "./StageActionSnackbarContainer";

export interface OrderPickedUpActionSnackbarProps {}

const OrderPickedUpActionSnackbar: React.FC<
  OrderPickedUpActionSnackbarProps
> = () => {
  const robotID = useDeliveryTeleopSessionStore((s) => s.robotID);
  const order = useOrderForTeleopSession();

  if (!order.data) {
    return <></>;
  }

  if (order.data.pick_up_actor.kind === "pilot") {
    return (
      <StageActionSnackbarContainer
        action={
          <OrderPickedUpActionButton
            variant="contained"
            color="primary"
            assignedRobotID={robotID}
          />
        }
      >
        Wait for the restaurant staff to identify themselves. Then open the lid
        and wait for staff to put the items inside the robot.
      </StageActionSnackbarContainer>
    );
  } else {
    return (
      <StageActionSnackbarContainer>
        Wait for pick-up to be completed. Order will automatically advance to
        next stage.
      </StageActionSnackbarContainer>
    );
  }
};

export default OrderPickedUpActionSnackbar;
