import React from "react";
import TimelineIcon from "@material-ui/icons/Timeline";

import DrawerLink from "./DrawerLink";

const DispatchButton: React.FC = () => (
  <DrawerLink
    to="/dispatch"
    primary="Dispatch"
    icon={<TimelineIcon />}
    restrictToRoles={["Supervisor", "Admin"]}
  />
);

export default DispatchButton;
