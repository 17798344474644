import React from "react";

import Grid from "@material-ui/core/Grid";

import RestaurantAddressPlaceField from "./PlaceField";

export interface RestaurantAddressFieldProps {
  autoFocus?: boolean;
  required?: boolean;
  name: string;
}

const RestaurantAddressField: React.FC<RestaurantAddressFieldProps> = ({
  autoFocus = false,
  required = false,
  name,
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <RestaurantAddressPlaceField
          name={`${name}.address_place`}
          label="Address"
          autoFocus={autoFocus}
          required={required}
        />
      </Grid>
    </Grid>
  );
};

export default RestaurantAddressField;
