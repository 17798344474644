import React from "react";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

import DrawerLink from "./DrawerLink";

const UsersButton: React.FC = () => (
  <DrawerLink
    to="/users"
    primary="Users Dashboard"
    icon={<SupervisedUserCircleIcon />}
    restrictToRoles={["Admin", "Supervisor"]}
  />
);

export default UsersButton;
