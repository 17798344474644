import React, { useState, useEffect } from "react";
import { GoogleMap, GoogleMapProps } from "@react-google-maps/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

import { useGoogleMapsService } from "services/location/GoogleMapsService/Context";

import defaultBoxOptions from "./defaultOptions";
import { OFFICE_ADDRESS } from "config/constants";

export interface MapBoxProps extends GoogleMapProps {
  width?: string | number;
  height?: string | number;
}

const MapBox: React.FC<MapBoxProps> = (props) => {
  const { width = 300, height = 300 } = props;

  const mapsApiService = useGoogleMapsService();
  const [mapsApi, setMapsApi] = useState<undefined | typeof google.maps>();

  useEffect(() => {
    mapsApiService.getMaps().then(setMapsApi);
  }, [mapsApiService]);

  if (!mapsApi) {
    return <CircularProgress />;
  }

  return (
    <Box width={width} height={height}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={initialCenter}
        {...props}
        options={defaultBoxOptions}
        zoom={15}
      >
        {props.children}
      </GoogleMap>
    </Box>
  );
};

const initialCenter = OFFICE_ADDRESS.geocoded_address;
const containerStyle = { height: "100%", width: "100%" };

export default MapBox;
