import React from "react";
import {
  createIdentityStore,
  IdentityStoreProvider as OriginalProvider,
} from "services/auth/identity/store";

export interface IdentityStoreProviderProps {}

const IdentityStoreProvider: React.FC<IdentityStoreProviderProps> = ({
  children,
}) => {
  return (
    <OriginalProvider initialStore={createIdentityStore()}>
      {children}
    </OriginalProvider>
  );
};

export default IdentityStoreProvider;
