import React from "react";
import Typography from "@material-ui/core/Typography";

export interface DataPreviewEntryProps {
  label: string;
  value: null | undefined | string | React.ReactElement;
}

const DataPreviewEntry: React.FC<DataPreviewEntryProps> = ({
  label,
  value,
}) => (
  <>
    <Typography variant="caption">{label}</Typography>
    <Typography variant="body1">{value || "-"}</Typography>
  </>
);

export default DataPreviewEntry;
