import * as THREE from "three";

import EquirectangularMaterial, {
  Projection as ProjectionCode,
} from "./EquirectangularMaterial";

export type Projection = "equirectangular" | "gnomonic" | "stereographic";

export interface POV {
  projection: Projection;
  yaw: number;
  height: number;
}

export interface ReprojectionController {
  setPOV: (pov: POV) => void;
  render: () => void;
}

export interface GLParams {
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  sourceVideo: HTMLVideoElement;
}

export const videoReprojection = ({
  renderer,
  sourceVideo,
}: GLParams): ReprojectionController => {
  const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(1024, {
    minFilter: THREE.LinearFilter,
    magFilter: THREE.LinearFilter,
    format: THREE.RGBAFormat,
    encoding: renderer.outputEncoding,
  });
  const cubeCamera = new THREE.CubeCamera(0.1, 300, cubeRenderTarget);
  const plane = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(1, 1),
    new EquirectangularMaterial(),
  );

  const orthoScene = new THREE.Scene();
  orthoScene.add(plane);
  const videoTexture = new THREE.VideoTexture(sourceVideo);
  const orthoCam = new THREE.OrthographicCamera(
    -0.5,
    0.5,
    0.5,
    -0.5,
    -1000,
    1000,
  );

  updateScene(cubeCamera, plane, initialPOV);

  plane.material.uniforms.video.value = videoTexture;
  plane.material.uniforms.projection.value = ProjectionCode.Equirectangular;

  return {
    render: () => renderer.render(orthoScene, orthoCam),
    setPOV: (pov: POV) => {
      updateScene(cubeCamera, plane, pov);
    },
  };
};

type PlaneMesh = THREE.Mesh<THREE.PlaneBufferGeometry, EquirectangularMaterial>;

const updateScene = (
  cubeCamera: THREE.CubeCamera,
  plane: PlaneMesh,
  pov: POV,
) => {
  plane.material.uniforms.projection.value = projectionCode(pov.projection);
  plane.material.uniforms.angle.value = pov.yaw;
  plane.material.uniforms.height.value = pov.height;
  if (pov.projection === "gnomonic") {
    updateCameraForGnomonicProjection(cubeCamera);
  } else {
    updateCameraForNonGnomonicProjection(cubeCamera);
  }
};

const updateCameraForGnomonicProjection = (cubeCamera: THREE.CubeCamera) => {
  cubeCamera.position.set(0, 0, 50);
  cubeCamera.lookAt(0.01, 0, 50);
  cubeCamera.up.set(0, 0, -1);
  cubeCamera.updateMatrixWorld();
};

const updateCameraForNonGnomonicProjection = (cubeCamera: THREE.CubeCamera) => {
  cubeCamera.position.set(-0.34, 0, 1.1);
  cubeCamera.lookAt(100, 0, 1.1);
  cubeCamera.up.set(0, 0, -1);
  cubeCamera.updateMatrixWorld();
};

const initialPOV: POV = {
  projection: "equirectangular",
  yaw: 0.0,
  height: 1.1,
};

const projectionCode = (projection: Projection): ProjectionCode => {
  switch (projection) {
    case "equirectangular":
      return ProjectionCode.Equirectangular;
    case "gnomonic":
      return ProjectionCode.Gnomonic;
    case "stereographic":
      return ProjectionCode.Stereographic;
  }
};
