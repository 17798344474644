import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import MenuItem from "@material-ui/core/MenuItem";

import RobotCardMenuItemContainer from "./MenuItemContainer";
import AssignedPilotAutosaveField from "components/robots/AssignedPilotAutosaveField";
import RobotDeploymentKindAutosaveField from "components/robots/DeploymentKindAutosaveField";
import ParkingSpotWizardDialog from "components/robots/ParkingSpotWizardDialog";

export interface RobotCardMenuProps {
  robotID: string;
}

const RobotCardMenu: React.FC<RobotCardMenuProps> = ({ robotID }) => {
  const { showAlert } = useAlertMessage();
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const [isParkingSpotWizardOpen, setOpenParkingSpotWizard] = useState(false);
  const closeMenu = () => setAnchorEl(null);
  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        PaperProps={{ style: { width: "35ch" } }}
      >
        <RobotCardMenuItemContainer requiredRoles={["Admin", "Supervisor"]}>
          <AssignedPilotAutosaveField robotID={robotID} onSuccess={closeMenu} />
        </RobotCardMenuItemContainer>
        <RobotCardMenuItemContainer requiredRoles={["Admin", "Robots/Admin"]}>
          <RobotDeploymentKindAutosaveField
            robotID={robotID}
            onSuccess={closeMenu}
          />
        </RobotCardMenuItemContainer>
        <MenuItem onClick={() => setOpenParkingSpotWizard(true)}>
          Set parking spot
        </MenuItem>
      </Menu>
      <IconButton onClick={(evt) => setAnchorEl(evt.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <ParkingSpotWizardDialog
        robotID={robotID}
        isOpen={isParkingSpotWizardOpen}
        onClose={() => setOpenParkingSpotWizard(false)}
        onSubmit={() => {
          showAlert({
            message: "Parking spot updated successfully",
            severity: "success",
          });
          setOpenParkingSpotWizard(false);
        }}
        onSubmissionError={({ message }) =>
          showAlert({
            message: message,
            severity: "error",
          })
        }
      />
    </>
  );
};

export default RobotCardMenu;
