import React from "react";

import Grid from "@material-ui/core/Grid";

import DeliveryDetailsField from "../DeliveryDetailsField";
import FormikWizardStep from "components/common/formik/FormikWizardStep";
import StepperBackButton from "components/common/Stepper/BackButton";
import StepperNextButton from "components/common/Stepper/StepperNextButton";

export interface OrderWizardDropOffInfoStepProps {
  stepIndex: number;
}

const OrderWizardDropOffInfoStep: React.FC<OrderWizardDropOffInfoStepProps> = (
  stepProps,
) => {
  return (
    <FormikWizardStep
      {...stepProps}
      fieldName="delivery_information"
      label="Delivery details"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <DeliveryDetailsField
            autoFocus
            required
            name="delivery_information"
          />
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item>
            <StepperBackButton />
          </Grid>
          <Grid item>
            <StepperNextButton />
          </Grid>
        </Grid>
      </Grid>
    </FormikWizardStep>
  );
};

export default OrderWizardDropOffInfoStep;
