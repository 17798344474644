import { createContext } from "react";
import axios, { AxiosError, AxiosInstance } from "axios";
import { SERVICES_URLS } from "config/constants";
import * as R from "ramda";

export type ServiceName = keyof Omit<typeof SERVICES_URLS, "maps" | "teleop">;

export type AxiosInstances = Record<ServiceName, AxiosInstance>;
interface CreateInstanceOpts {
  timeoutMs?: number;
  withCredentials?: boolean;
}

const createInstance = (
  serviceName: ServiceName,
  { timeoutMs = 3000, withCredentials = false }: CreateInstanceOpts = {},
) => {
  const instance = axios.create({
    baseURL: SERVICES_URLS[serviceName],
    timeout: timeoutMs,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: withCredentials,
  });
  instance.interceptors.response.use(
    (req) => {
      req.headers["X-Correlation-Id"] = `pilot-ui-${genCorrelationID()}`;
      return req;
    },
    (err: AxiosError) => {
      if (err.response?.data && err.response.data.message) {
        const newErr = R.clone(err);
        newErr.message = err.response.data.message;
        throw newErr;
      } else {
        throw err;
      }
    },
  );

  return instance;
};

const AxiosContext = createContext<AxiosInstances>({
  "pilot-api": createInstance("pilot-api"),
  "delivery-orders": createInstance("delivery-orders"),
  restaurants: createInstance("restaurants"),
  "uber-eats-orders": createInstance("uber-eats-orders"),
  auth: createInstance("auth"),
  robots: createInstance("robots"),
  notifications: createInstance("notifications"),
  "partners-api": createInstance("partners-api"),
  "media-downloader": createInstance("media-downloader", {
    timeoutMs: 5 * 60 * 1000,
  }),
});

const genCorrelationID = (size = 6) =>
  R.map(
    () => correlationIDChars[Math.floor(Math.random() * 16)],
    R.range(0, size),
  ).join("");

const correlationIDChars = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
];

export default AxiosContext;
