import React from "react";
import { OFFICE_ADDRESS } from "config/constants";
import RobotParkingSpot from "types/robots/RobotParkingSpot";
import { useSetParkingSpot } from "mutations/robots";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export interface SetParkToOfficeProps {
  robotID: string;
  onSubmit?: () => void;
  onSubmissionError?: (error: { message: string }) => void;
}

const SetParkToOffice: React.FC<SetParkToOfficeProps> = ({
  robotID,
  onSubmit = () => {},
  onSubmissionError = () => {},
}) => {
  const setParkingSpot = useSetParkingSpot();
  const handleSubmit = () => {
    setParkingSpot
      .mutateAsync({ robotID, parkingSpot: officeParkingSpot })
      .then(() => onSubmit())
      .catch((err) => onSubmissionError(err));
  };
  return (
    <>
      <Typography variant="body1" paragraph>
        The pilots will be instructed to park the robot {robotID} outside the
        office at {OFFICE_ADDRESS.address_description.address_line_1} whenever
        the robot is idle.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
};

const officeParkingSpot: RobotParkingSpot = {
  description: "Park outside the office",
  ...OFFICE_ADDRESS,
};

export default SetParkToOffice;
