import React from "react";

import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable, { Column } from "material-table";

import RestaurantRowMenu from "./RowMenu";
import { useRestaurants } from "fetchers/deliveries/restaurants";
import StableLinearProgress from "components/common/StableLinearProgress";
import Restaurant from "types/deliveries/Restaurant";
import tableIcons from "./tableIcons";

export type RestaurantsDataGridProps = {};

const RestaurantsDataGrid: React.FC<RestaurantsDataGridProps> = () => {
  const { data, isFetching } = useRestaurants({
    refetchInterval: 10 * 1000,
  });
  const history = useHistory();

  if (!data) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <StableLinearProgress show={isFetching} />
        <MaterialTable
          columns={columns}
          data={data}
          icons={tableIcons}
          title="Restaurants"
          onRowClick={(_, restaurant) => {
            if (restaurant) {
              history.push(`/restaurants/${restaurant.uuid}`);
            }
          }}
          options={tableOptions}
        />
      </>
    );
  }
};

const columns: Column<Restaurant>[] = [
  {
    title: "Menu",
    render: (restaurant) => <RestaurantRowMenu restaurant={restaurant} />,
    disableClick: true,
  },
  {
    field: "name",
    title: "Restaurant Name",
  },
  {
    field: "drop_off_deadline_in_minutes",
    title: "Drop Off deadline (minutes)",
  },
  { title: "Address", field: "address_description.address_line_1" },
];

const tableOptions = {
  filtering: true,
  sorting: true,
};

export default RestaurantsDataGrid;
