import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { KnownOrderSalesChannel } from "types/deliveries/OrderSalesChannel";

export interface OrderWizardOnboardingProps {
  onSelectOrigin: (origin: KnownOrderSalesChannel) => void;
}

const OrderWizardOnboarding: React.FC<OrderWizardOnboardingProps> = ({
  onSelectOrigin,
}) => (
  <>
    <Typography variant="body1" paragraph>
      Where is the order coming from?
    </Typography>
    <Grid container justify="space-around" spacing={3}>
      {Object.entries(buttonsLabels).map(([origin, label]) => (
        <Grid item key={origin}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onSelectOrigin(origin as KnownOrderSalesChannel)}
          >
            {label}
          </Button>
        </Grid>
      ))}
    </Grid>
  </>
);

const buttonsLabels: Record<KnownOrderSalesChannel, string> = {
  "uber-eats": "Uber Eats",
  facedrive: "Facedrive",
  doordash: "Doordash",
  "skip-the-dishes": "SkipTheDishes",
};

export default OrderWizardOnboarding;
