import GoogleMapsLinks from "components/Location/GoogleMapsLinks";
import React from "react";
import useDestinationStore from "../useDestinationStore";

export type TeleopDestinationLinksProps = {};

const TeleopDestinationLinks: React.FC<TeleopDestinationLinksProps> = () => {
  const destination = useDestinationStore(({ destination }) => destination);
  if (destination) {
    return (
      <GoogleMapsLinks address={destination} direction="column" fullWidth />
    );
  } else {
    return <></>;
  }
};

export default TeleopDestinationLinks;
