import React, { useState } from "react";
import useWebSocket from "react-use-websocket";
import Grid from "@material-ui/core/Grid";
import RobotCard from "./cards.js";
import { ROBOT_DISCOVERY_URL } from "config/constants";
import DashboardScreenTemplate from "components/DashboardScreenTemplate";

export default function SpacingGrid() {
  const [robots, setRobots] = useState([]);

  useWebSocket(ROBOT_DISCOVERY_URL, {
    onMessage: (event) => {
      const json = JSON.parse(event.data).robots;
      setRobots(json);
    },
    shouldReconnect: () => true,
    reconnectInterval: 10,
  });

  return (
    <DashboardScreenTemplate screenTitle="Robots">
      <Grid container spacing={4}>
        {robots.map((robot) => (
          <Grid key={robot["id"]} item>
            <RobotCard name={robot["id"]} driver={""} />
          </Grid>
        ))}
      </Grid>
    </DashboardScreenTemplate>
  );
}
