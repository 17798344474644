import useAlertMessage from "components/common/AlertMessage/useAlertMessage";
import { useUser } from "fetchers/auth/users";
import { useRobot } from "fetchers/robots/robots";
import { useRemoveAssignedPilot, useSetAssignedPilot } from "mutations/robots";
import React, { useCallback } from "react";
import User from "types/auth/User";
import Robot from "types/robots/Robot";
import PilotAutocompleteFieldView from "../PilotAutocompleteField/View";

export interface AssignedPilotAutosaveFieldProps {
  robotID: string;
  onSuccess?: (robot: Robot) => void;
}

const AssignedPilotAutosaveField: React.FC<AssignedPilotAutosaveFieldProps> = ({
  robotID,
  onSuccess = () => {},
}) => {
  const robot = useRobot(robotID);
  const currentPilot = useUser(robot.data?.assigned_pilot_uuid);

  const setAssignedPilot = useSetAssignedPilot();
  const removeAssignedPilot = useRemoveAssignedPilot();

  const { showAlert } = useAlertMessage();

  const handleChange = useCallback(
    (_, newPilot: User | null) => {
      if (newPilot) {
        setAssignedPilot
          .mutateAsync({ robotID, userUUID: newPilot.uuid })
          .then((robot) => {
            onSuccess(robot);
            showAlert({
              message: `${newPilot.email} successfully assigned to drive ${robot.id}`,
              severity: "success",
            });
          })
          .catch(({ message }) => {
            showAlert({
              message: message || "Unknown error",
              severity: "error",
              autoCloseAfterMillis: 5000,
            });
          });
      } else {
        removeAssignedPilot
          .mutateAsync(robotID)
          .then((robot) => {
            onSuccess(robot);
            showAlert({
              message: `successfully unassigned pilot of ${robot.id}`,
              severity: "success",
            });
          })
          .catch(({ message }) => {
            showAlert({
              message: message || "Unknown error",
              severity: "error",
              autoCloseAfterMillis: 5000,
            });
          });
      }
    },
    [setAssignedPilot, removeAssignedPilot, robotID, showAlert, onSuccess],
  );
  return (
    <PilotAutocompleteFieldView
      value={currentPilot.data || null}
      onChange={handleChange}
      isLoading={
        currentPilot.isLoading ||
        robot.isLoading ||
        setAssignedPilot.isLoading ||
        removeAssignedPilot.isLoading
      }
    />
  );
};

export default AssignedPilotAutosaveField;
