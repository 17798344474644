import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useSetUberEatsOrderTipsInCents } from "mutations/deliveries/uber-eats-orders";
import useAlertMessage from "components/common/AlertMessage/useAlertMessage";

export interface TipsInCentsAutoSaveFieldProps {
  orderUUID: string;
  currentTipsInCents: number;
  isEditable: boolean;
}

const TipsInCentsAutoSaveField: React.FC<TipsInCentsAutoSaveFieldProps> = ({
  orderUUID,
  currentTipsInCents,
  isEditable,
}) => {
  const [tipsInCents, setTipsInCents] = useState(currentTipsInCents);
  const { showAlert } = useAlertMessage();
  const setOrderTipsInCents = useSetUberEatsOrderTipsInCents();

  const handleSubmit = () => {
    if (currentTipsInCents !== tipsInCents) {
      setOrderTipsInCents
        .mutateAsync({ orderUUID, tipsInCents })
        .then((order) => {
          setTipsInCents(order.charges!.tips_in_cents);
          showAlert({
            message: "Order charges - tips field updated successfully",
            severity: "success",
          });
        })
        .catch(({ message }) => {
          setTipsInCents(currentTipsInCents);
          showAlert({
            message: message || "Unknown error",
            severity: "error",
            autoCloseAfterMillis: 5000,
          });
        });
    }
  };
  return (
    <Box lineHeight={1}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextField
          value={tipsInCents || ""}
          onChange={(e) => setTipsInCents(+e.currentTarget.value)}
          onBlur={handleSubmit}
          variant="outlined"
          label="TipsInCents"
          disabled={!isEditable || setOrderTipsInCents.isLoading}
          fullWidth
        />
      </form>
    </Box>
  );
};

export default TipsInCentsAutoSaveField;
