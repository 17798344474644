import AddressFieldData, {
  emptyAddressField,
} from "components/Location/AddressField/Model";

export default interface DeliveryDetailsFieldData {
  recipient_address: AddressFieldData;
  has_direct_recipient_contact: boolean;
  recipient_phone_number_or_code: string;
  drop_off_notes: string;
}

export const emptyDeliveryDetailsField: DeliveryDetailsFieldData = {
  recipient_address: emptyAddressField,
  has_direct_recipient_contact: false,
  recipient_phone_number_or_code: "",
  drop_off_notes: "",
};
