import React from "react";
import Typography from "@material-ui/core/Typography";
import Robot from "types/robots/Robot";
import Box from "@material-ui/core/Box";
import Link from "components/common/Link";

export interface AssignedPilotProps {
  robot: Robot;
}

const AssignedPilot: React.FC<AssignedPilotProps> = ({ robot }) => {
  return (
    <Box width={240}>
      <Typography variant="caption" display="block">
        Assigned pilot
      </Typography>
      {robot.assigned_pilot_uuid ? (
        <Link to={`/users/${robot.assigned_pilot_uuid}`}>
          <Typography noWrap>{robot.assigned_pilot_uuid}</Typography>
        </Link>
      ) : (
        "-"
      )}
    </Box>
  );
};

export default AssignedPilot;
