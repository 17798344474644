import React from "react";
import { useStyles } from "./styles";

export interface VisualGamepadDisconnectedViewProps {}

const VisualGamepadDisconnectedView: React.FC<
  VisualGamepadDisconnectedViewProps
> = () => {
  const cls = useStyles();
  return <div className={cls.disconnected}></div>;
};

export default VisualGamepadDisconnectedView;
