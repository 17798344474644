import React from "react";
import UberEatsOrder from "types/deliveries/UberEatsOrder";
import Timeline from "@material-ui/lab/Timeline";
import UberEatsOrderTimelineEventEntry from "./EventEntry";
import { sortBy } from "ramda";

export interface UberEatsOrderTimelineViewProps {
  order: UberEatsOrder;
}

const UberEatsOrderTimelineView: React.FC<UberEatsOrderTimelineViewProps> = ({
  order,
}) => {
  const sortedStages = sortBy((s) => s.started_at, order.completed_stages);

  return (
    <Timeline align="alternate">
      {sortedStages.map((stage, i) => (
        <UberEatsOrderTimelineEventEntry key={i} stage={stage} />
      ))}
      <UberEatsOrderTimelineEventEntry
        stage={order.current_stage}
        isCurrentStage
      />
    </Timeline>
  );
};

export default UberEatsOrderTimelineView;
