import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((_: Theme) =>
  createStyles({
    container: {
      position: "absolute",
      height: "630px",
      width: "750px",
      right: "1em",
      bottom: "1em",
      zoom: "0.2",
      zIndex: 2,
      transition: "transform 300ms ease-in-out",
      "&:hover": {
        transform: "scale(5) translate(-40%, -40%)",
      },
    },
  }),
);
