import { QueryClient, useMutation, useQueryClient } from "react-query";
import {
  useRobotsService,
  RobotCreationParameters,
} from "services/robots/RobotsService";
import Robot from "types/robots/Robot";
import RobotParkingSpot from "types/robots/RobotParkingSpot";

export const useSetAssignedPilot = () => {
  const svc = useRobotsService();
  const queryClient = useQueryClient();
  return useMutation(
    ({ robotID, userUUID }: SetAssignedPilotInput) =>
      svc.setAssignedPilot(robotID, userUUID).then((res) => res.data),
    {
      mutationKey: ["robots/robots", "assigned-pilot"],
      onSuccess: successfulRobotMutationCallback(queryClient),
    },
  );
};

export const useRemoveAssignedPilot = () => {
  const svc = useRobotsService();
  const queryClient = useQueryClient();
  return useMutation(
    (robotID: string) =>
      svc.removeAssignedPilot(robotID).then((res) => res.data),
    {
      mutationKey: ["robots/robots", "assigned-pilot"],
      onSuccess: successfulRobotMutationCallback(queryClient),
    },
  );
};

export const useCreateRobot = () => {
  const svc = useRobotsService();
  const queryClient = useQueryClient();
  return useMutation(
    (creationParameters: RobotCreationParameters) =>
      svc.createRobot(creationParameters).then((res) => res.data),
    {
      mutationKey: ["robots/robots", "create"],
      onSuccess: successfulRobotMutationCallback(queryClient),
    },
  );
};

export const useSendHeartbeat = () => {
  const svc = useRobotsService();
  const queryClient = useQueryClient();
  return useMutation(
    (robotID: string) =>
      svc.sendPilotHeartbeat(robotID).then((res) => res.data),
    {
      mutationKey: ["robots/robots", "pilot-heartbeat"],
      onSuccess: successfulRobotMutationCallback(queryClient),
    },
  );
};
export const useSetDeploymentKind = () => {
  const svc = useRobotsService();
  const queryClient = useQueryClient();
  return useMutation(
    ({ robotID, deploymentKind }: SetDeploymentKindInput) =>
      svc.setDeploymentKind(robotID, deploymentKind).then((res) => res.data),
    {
      mutationKey: ["robots/robots", "deployment-kind"],
      onSuccess: successfulRobotMutationCallback(queryClient),
    },
  );
};

export const useSetParkingSpot = () => {
  const svc = useRobotsService();
  const queryClient = useQueryClient();
  return useMutation(
    ({ robotID, parkingSpot }: SetParkingSpotInput) =>
      svc.setParkingSpot(robotID, parkingSpot).then((res) => res.data),
    {
      mutationKey: ["robots/robots", "parking-spot"],
      onSuccess: successfulRobotMutationCallback(queryClient),
    },
  );
};

interface SetAssignedPilotInput {
  robotID: string;
  userUUID: string;
}

interface SetDeploymentKindInput {
  robotID: string;
  deploymentKind: string;
}

interface SetParkingSpotInput {
  robotID: string;
  parkingSpot: RobotParkingSpot;
}

const successfulRobotMutationCallback =
  (queryClient: QueryClient) => (robot: Robot) => {
    queryClient.invalidateQueries("robots/robots");
    queryClient.setQueryData(["robots/robots", { id: robot.id }], robot);
  };
