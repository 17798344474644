import React from "react";

import { useField } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { countryLabelByCode, countryCodes } from "./countries";
import AutocompleteInput, {
  AutocompleteInputProps,
} from "components/common/AutocompleteInput";

export interface CountryCodeFieldProps
  extends Pick<
    AutocompleteInputProps,
    "label" | "autoFocus" | "required" | "fullWidth"
  > {
  name: string;
}

const CountryCodeField: React.FC<CountryCodeFieldProps> = ({
  name,
  ...inputProps
}) => {
  const [field, , helpers] = useField<string>(name);
  return (
    <Autocomplete
      autoComplete
      options={countryCodes}
      getOptionLabel={(code) => countryLabelByCode[code] || ""}
      autoHighlight
      renderOption={(option) => (
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Typography variant="subtitle1">{option}</Typography>
          </Grid>
          <Grid item>{countryLabelByCode[option]}</Grid>
        </Grid>
      )}
      renderInput={(params) => (
        <AutocompleteInput {...inputProps} {...params} />
      )}
      value={field.value === "" ? null : field.value}
      onChange={(_, newVal: null | string) => {
        helpers.setValue(newVal || "");
      }}
      onBlur={() => helpers.setTouched(true)}
    />
  );
};

export default CountryCodeField;
