import React from "react";

export interface TrajectoryIndicatorViewProps {
  rudder: number;
  throttle: number;
}
const TrajectoryIndicator: React.FC<TrajectoryIndicatorViewProps> = ({
  rudder,
  throttle,
}) => {
  return (
    <div
      style={{
        height: "40%",
        width: "20%",
        position: "absolute",
        bottom: "-10%",
        left: "40%",
        transform: `rotate(${rudder * 20}deg)`,
        background: `hsl(${255 - throttle * 255},100%,50%,20%)`,
      }}
    ></div>
  );
};

export default TrajectoryIndicator;
